import clsx from "clsx"
import { ChangeEvent } from "react"
import { dToS } from "../lib/Util"
import style from "../style/Calendar.module.scss"

interface ICalendarProps {
  value: Date | null
  type?: string
  name?: string
  min?: string
  max?: string
  cls?: string
  inputCls?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Calendar = ({ value, type, name, min, max, cls, inputCls, onChange }: ICalendarProps) => {
  return (
    <div className={clsx(cls)}>
      <input
        type={type ?? "datetime-local"}
        className={clsx(style.input, inputCls)}
        name={name ?? ""}
        min={min ?? ""}
        max={max ?? ""}
        value={dToS(value)}
        onChange={onChange}
      />
    </div>
  )
}
