import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import { Dispatch, Action } from "redux";
import { IEntry, ISecondInterview } from "../types/state";
import SecondInterviewRequestForm, {
  ISecondInterviewRequestFormProps,
} from "../components/forms/second_interview/SecondInterviewRequestForm";

import { requestSecondInterview } from "../thunks/second_interview";

class SecondInterviewRequest extends React.Component<
  ISecondInterviewRequestFormProps
> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    dispatch(Actions.fetchEntries());
  }
  render() {
    return <SecondInterviewRequestForm {...this.props} />;
  }
}

function mapStateToProps(state: any) {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
  return {
    entry:
      state.entries.filter((entry: IEntry) => entry.id === entryID)[0] || {},
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return {
    dispatch,
    requestSecondInterview: (data: Partial<ISecondInterview>) =>
      dispatch(requestSecondInterview(data) as any),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondInterviewRequest);
