import * as React from "react"
import HintTooltip from "../forms/common/HintTooltip/HintTooltip"
import styles from "../../style/ProblemContentsForm.module.scss"
import { IProblemFormState } from "./index"
import { ErrorMessage } from "../common/ErrorMessage"
import { PublicStatusLabel } from "./PublicStatusLabel"

export const SituationForm = (props: {
  problem: IProblemFormState
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
  updateValue: (args: { [key: string]: any }) => void
}) => {
  return (
    <div id="situation">
      <div className={styles.labelWrap}>
        <label className="pure-u-1 problemLabel" onClick={(e) => e.preventDefault()}>
          ② 1の実現が必要だと考えた背景と1の実現ができていない理由
          <HintTooltip
            title={
              <span>
                記入ポイント
                <ul>
                  <li>社内で実際に起きていることなどをできるだけ具体的に記入してください。</li>
                  <li>具体性が高いほど、人材からの具体的な提案が集まります。</li>
                </ul>
              </span>
            }
          />
        </label>
        <PublicStatusLabel publishType={props.problem.publish_type} problemContentsType="situation" />
      </div>
      <textarea
        className="textarea"
        value={props.problem.situation}
        onChange={(e: React.ChangeEvent<any>) => {
          props.updateValue({
            ...props.problem,
            situation: e.target.value
          })
        }}
        placeholder={
          "記入例\n" +
          "（a）現状では勤怠管理を紙のタイムカードで行っている。マネージャーが手で集計作業する時間を減らし、より付加価値の高い業務に時間を使えるようにしたい。既存の社内メンバーは定常業務をこなすことが手一杯で、こうした改善を行う時間やノウハウが不足している。"
        }
      />
      {props.isSubmitted && props.validates["situation"] && (
        <ErrorMessage cls="mb20">{props.validates["situation"](props.problem)}</ErrorMessage>
      )}
    </div>
  )
}
