import React from "react";
import * as Definition from "../lib/Definition";
import style from "../style/Recruitment.module.scss";
import { ICompany, IFile, IBusiness } from "../types/state";
import { delimitize, industryStr, strToTag, convertMillions } from "../lib/Util";
import DetailedPresidentProfile from "./DetailedPresidentProfile";

const hasDetailedPresidentProfile = (c: ICompany): boolean => {
  return !!(
    c.president_position_name ||
    c.president_birth_year ||
    c.president_hometown ||
    c.president_first_job_desc ||
    c.president_how_become ||
    c.president_motivation ||
    c.president_generation ||
    c.president_hobbies
  );
};

interface ICompanyProfileDispatchProps {}

interface ICompanyProfileStateProps {
  company: ICompany;
  contractList: string[][];
}

interface ICompanyProfileProps extends ICompanyProfileDispatchProps, ICompanyProfileStateProps {}

export const CompanyProfile = ({ company, contractList }: ICompanyProfileProps) => {
  return (
    <div>
      <h2 className="contentsTitle">
        {company.name} ({company.prefecture})
      </h2>
      {company.eye_catch && (
        <div className={style.imgArea}>
          <img src={company.eye_catch.url} alt="eye_catch" />
        </div>
      )}
      <h3 className="contentsSubTitle">企業概要</h3>
      <table className={style.recruitmentTable}>
        <tbody>
          <tr>
            <th>会社名</th>
            <td>{company.name}</td>
          </tr>
          <tr>
            <th>会社名(カナ)</th>
            <td>{company.name_kana}</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>
              {company.zip_code && (
                <>〒{company.zip_code.substr(0, 3)}-{company.zip_code.substr(3, 7)}{" "}</>
              )}
              {company.prefecture}
              {company.address1}
              {company.address2}
            </td>
          </tr>
          <tr>
            <th>代表者名</th>
            <td>{company.president}</td>
          </tr>
          <tr>
            <th>設立年</th>
            <td>
              {!!company.establishment && company.establishment > 0 && (
                <>{company.establishment}年</>
              )}
            </td>
          </tr>
          <tr>
            <th>資本金</th>
            <td>
              {!!company.capital && company.capital > 0 && (
                <>{delimitize(convertMillions(company.capital))}百万円</>
              )}
            </td>
          </tr>
          <tr>
            <th>役員数</th>
            <td>
              {company.executive && company.executive >= 0 && (
                <>{company.executive}名</>
              )}
            </td>
          </tr>
          <tr>
            <th>
              概算社員数
              <br />
              （正規・契約・
              <br className="PCHide" />
              アルバイト
              <br className="PCHide" />
              含む）
            </th>
            <td>
              {company.employee && company.employee >= 0 && (
                <>{company.employee}名</>
              )}
            </td>
          </tr>
          <tr>
            <th>業界</th>
            <td>{industryStr(company.industries, company.industry_other)}</td>
          </tr>
          <tr>
            <th>URL</th>
            <td>
              {!company.urls ||
                (company.urls.split(" ").length === 0 && "なし")}
              {company.urls &&
                company.urls.split(" ").map((url: string) => {
                  return (
                    <div key={url}>
                      <a href={url} target="_blank" rel="noopener noreferrer" className="link">
                        {url}
                      </a>
                    </div>
                  );
                })}
            </td>
          </tr>
          <tr>
            <th>ファイル</th>
            <td>
              {!company.files || (company.files.length === 0 && "なし")}
              {company.files &&
                company.files.map((file: IFile) => {
                  return (
                    <div key={"file" + file.id}>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </a>
                    </div>
                  );
                })}
            </td>
          </tr>
          <tr>
            <th>事業概要</th>
            <td className={style.companyOutline}>
              {company.businesses &&
                company.businesses.map((business: IBusiness, i) => {
                  return (
                    <div key={"business" + business.id}>
                      <b>事業その{`${i + 1}`}</b>
                      <br />
                      {strToTag(business.description)}（売上比率：{Definition.RATE[business.rate]}）
                    </div>
                  );
                })}
            </td>
          </tr>
          <tr>
            <th>売上</th>
            <td>{Definition.SALES[company.sales]}</td>
          </tr>
          <tr>
            <th>
              ビジョン・
              <br className="PCHide" />
              企業理念
            </th>
            <td>{strToTag(company.vision)}</td>
          </tr>
          {(company.president_profile || hasDetailedPresidentProfile(company)) ? (
            <tr>
              <th>
                経営者
                <br className="PCHide" />
                プロフィール
              </th>
              {hasDetailedPresidentProfile(company) ? (
                <DetailedPresidentProfile company={company} />
              ) : (
                <td>{strToTag(company.president_profile)}</td>
              )}
            </tr>
          ) : null}
          {company.other ? (
            <tr>
              <th>その他</th>
              <td>{strToTag(company.other)}</td>
            </tr>
          ) : null}
        </tbody>
      </table>

      <h3 className="contentsSubTitle">募集条件</h3>
      <table className={style.recruitmentTable}>
        <tbody>
          <tr>
            <th>
              初回契約の
              <br className="PCHide" />
              条件概要
            </th>
            <td>
              <ul className={style.listDataList}>
                {contractList.map((c, i) => {
                  return (
                    <li key={i}>
                      {c.length === 1 ? (
                        c[0]
                      ) : (
                        <>
                          {c[0]}
                          <ul>
                            {c.slice(1).map((cc, j) => {
                              return <li key={`${j}`}>{cc}</li>;
                            })}
                          </ul>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
