import * as React from "react";
import { Link } from "react-router-dom";
import { registrantPath } from "../routes/paths";
import NameField from "./NameField";
import KanaNameField from "./KanaNameField";
import EditablePasswordField from "./EditablePasswordField";
import * as Definition from "../lib/Definition";
import { IUpdateRegistrantParams } from "../actions";
import { NormalTextField } from "./NormalTextField";
import { RadioField } from "./RadioField";
import { formatHyphenStr } from "../lib/Util"

export interface IEditRegistrantDispatchProps {
  alert: (content: string) => Promise<void>;
  updateValue: (args: any) => void;
  updateRegistrant: (
    isUpdate: boolean,
    params: IUpdateRegistrantParams
  ) => void;
}

export interface IEditRegistrantStateProps {
  enableCancel: boolean;
  registrant: {
    id: number;
    showPassword: boolean;
    last_kana: string;
    first_kana: string;
    last_name: string;
    first_name: string;
    email: string;
    department: string;
    position: string;
    tel: string;
    password: string;
    isSubmitted: boolean;
    error?: string;
    errorField?: string;
  };
}

const validates: { [key: string]: (value: any) => string | null } = {};
validates["first_name"] = (val) => {
  return val.first_name ? null : "名前を入力してください。";
};
validates["last_name"] = (val) => {
  return val.last_name ? null : "苗字を入力してください。";
};
validates["first_kana"] = (val) => {
  return !val.first_kana || !val.first_kana.match(/^[0-9A-Za-zァ-ヶー]*$/)
    ? "カナで入力してください。"
    : null;
};
validates["last_kana"] = (val) => {
  return !val.last_kana || !val.last_kana.match(/^[0-9A-Za-zァ-ヶー]*$/)
    ? "カナで入力してください。"
    : null;
};
validates["tel"] = (val) => {
  if (!val.tel) return "電話番号を入力してください。";
  if (!/^\d{1,12}$/.test(formatHyphenStr(val.tel))) return "電話番号は12桁以内の数字で入力してください。";
  return null;
};
validates["department"] = (val) => {
  return !val.department ? "管理者部署名を入力してください。" : null;
};
validates["position"] = (val) => {
  return !val.position ? "管理者役職を選択してください。" : null;
};
validates["email"] = (val) => {
  return val.email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
    ? null
    : "正しいメールアドレスを入力してください。";
};
validates["password"] = (val) => {
  return val.password && (val.password.length < 6 || val.password.length > 32)
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : null;
};

export const EditRegistrantForm = (
  props: IEditRegistrantDispatchProps & IEditRegistrantStateProps
) => {
  return (
    <main className="contentsWrapper">
      <form
        className="pure-form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          const params: IUpdateRegistrantParams = {
            id: props.registrant.id,
            last_kana: props.registrant.last_kana,
            first_kana: props.registrant.first_kana,
            last_name: props.registrant.last_name,
            first_name: props.registrant.first_name,
            email: props.registrant.email,
            department: props.registrant.department,
            position: props.registrant.position,
            tel: formatHyphenStr(props.registrant.tel)
          };
          if (props.registrant.password) {
            params["password"] = props.registrant.password;
          }
          const error = Object.keys(validates).filter((field) => {
            return validates[field](params);
          })[0];
          if (error) {
            props.alert("入力に誤りがあります").then(() => {
              props.updateValue({ scrollID: error, isSubmitted: true });
            });
            return;
          }
          props.updateRegistrant(props.enableCancel, {
            ...params,
          });
        }}
      >
        <div className="formWrap">
          {props.registrant.error && (
            <div className="text-danger">{props.registrant.error}</div>
          )}
          <NameField
            values={props.registrant}
            updateValue={props.updateValue}
            validates={validates}
          />
          <KanaNameField
            values={props.registrant}
            updateValue={props.updateValue}
            validates={validates}
          />
          <NormalTextField
            cls="formWidth_m"
            name="email"
            label="メールアドレス"
            placeholder="sample@example.com"
            required={true}
            error={
              props.registrant.errorField === "email"
                ? props.registrant.error
                : ""
            }
            values={props.registrant}
            updateValue={props.updateValue}
            validates={validates}
          />
          <NormalTextField
            cls="formWidth_m"
            name="tel"
            label="電話番号"
            placeholder="04511112222"
            required={true}
            values={props.registrant}
            updateValue={props.updateValue}
            validates={validates}
          />
          <EditablePasswordField
            error={
              props.registrant.errorField === "password"
                ? props.registrant.error
                : ""
            }
            values={props.registrant}
            updateValue={props.updateValue}
            validates={validates}
          />
          <NormalTextField
            name="department"
            label="担当者部署名"
            note="代表の場合、「代表」と記載してください。"
            cls="formWidth_m"
            placeholder="○○部○○課"
            required={true}
            values={props.registrant}
            validates={validates}
            updateValue={props.updateValue}
          />
          <RadioField
            values={props.registrant}
            name="position"
            label="担当者役職"
            note="最も近い役職を選択してください。"
            required={true}
            master={Definition.POSITIONS}
            validates={validates}
            updateValue={(key) => props.updateValue({ position: key })}
            formrownoborder={true}
          />
          <div
            className={
              "formBtnArea" + (props.enableCancel ? "" : " formBtnAreaSingle")
            }
          >
            {props.enableCancel && (
              <Link to={registrantPath} className="btn">
                {"キャンセル"}
              </Link>
            )}
            <button type="submit" className="btn btnSave">
              {"更新"}
            </button>
          </div>
        </div>
      </form>
    </main>
  );
};
