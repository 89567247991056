import * as React from "react"
import { ReactNode } from "react"
import { SelectStar } from "../SelectStar"
import clsx from "clsx"

export interface ISatisfiedRateFieldDispatchProps {
  updateValue: (value: number) => void
}

export interface ISatisfiedRateFieldStateProps {
  title: string | ReactNode
  value: number
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
  rowCls?: string
  dataCls?: string
}

export interface ISatisfiedRateFieldProps extends ISatisfiedRateFieldDispatchProps, ISatisfiedRateFieldStateProps {}

export const SatisfiedRateField = ({
  title,
  value,
  isSubmitted,
  validates,
  rowCls,
  dataCls,
  updateValue
}: ISatisfiedRateFieldProps) => {
  return (
    <div className={clsx("formRow", rowCls)}>
      <label className="formHead">
        <span>{title}</span>
        <span className="label label_req">必須</span>
      </label>
      <div className={clsx("formData", dataCls)}>
        <SelectStar
          name="satisfaction"
          value={value}
          max={5}
          isSubmitted={isSubmitted}
          validates={validates}
          onChange={updateValue}
        />
      </div>
    </div>
  )
}
