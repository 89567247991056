import * as React from "react"
import { IPagenatedPartners } from "../../types/state"
import { parseQueryParams, age, definitionStr } from "../../lib/Util"
import { partnerPath } from "../../routes/paths"
import style from "../../style/Scout.module.scss"
import { DEPARTMENTS } from "../../lib/Definition"

export interface IPartnersDispatchProps {
  changePath: (path: string) => void
  updateBookmark: (id: number, bookmarked: boolean) => void
}

export interface IPartnersStateProps {
  partners: IPagenatedPartners
  conditionStrs: string[]
}

export interface IPartnersProps extends IPartnersDispatchProps, IPartnersStateProps {}

export const PartnerList = (props: IPartnersProps) => {
  const result = parseQueryParams()
  if (Object.keys(result).length === 0) {
    return (
      <>
        <h1 className={style.listAreaBeforeTitle}>ご希望の経歴・経験を持った人材を検索できます。</h1>
        <p>
          左の<b>「検索条件」</b>より経歴や条件などご希望の条件を選択のうえ、
          <b>「検索実行」</b>ボタンを押してください。
          <br />
          ご希望条件に沿った経歴をもつ人材の一覧が表示されます。
          <br />
          気になる経歴の人材を見つけたら<b>スカウトを送って面談を申し込む</b>
          ことができます。
        </p>
      </>
    )
  }
  return (
    <div>
      {props.conditionStrs.length > 0 && (
        <>
          <div className={style.listAreaSearchResult}>
            検索結果：<span>{props.partners.total_count}</span>件
          </div>
          {props.partners.partners.length === 0 && (
            <h1 className={style.listAreaBeforeTitle}>該当のパートナー人材は登録されていません</h1>
          )}
          <div className={style.listAreaConditions}>
            <div className={style.listConditionsTitle}>検索された条件</div>
            {props.conditionStrs.map((c) => (
              <div className={style.listConditionsItem} key={c} dangerouslySetInnerHTML={{ __html: c }} />
            ))}
          </div>
        </>
      )}
      {props.partners.partners.length > 0 && (
        <>
          <div className={style.checkMarkMeaning}>
            <div>
              <span className={style.checkMark}>応募もしくはスカウト済み</span>… スカウト済みもしくは応募があった人材
            </div>
            <div>
              <span className={style.verificationIcon}></span>… 本人確認済み人材
            </div>
          </div>
          <table className={style.listTable}>
            <thead>
              <tr>
                <th>ID</th>
                <th className="alLeft">
                  在籍
                  <br className="PCHide" />
                  企業
                </th>
                <th className="alLeft">職種</th>
                <th className={style.listTableNowrap}>
                  人材に
                  <br className="PCHide" />
                  ついて
                </th>
                <th>お気に入り</th>
              </tr>
            </thead>
            <tbody>
              {props.partners.partners.map((partner) => {
                const departments = partner.careers.map((c) => c.departments)
                const departmentBitFlag = departments.reduce((prev, cur) => prev | cur, 0)
                const departmentDefStr = definitionStr(DEPARTMENTS, departmentBitFlag, "／").replace(/／+その他/g, "")
                const departmentOthers = Array.from(new Set(partner.careers.map((c) => c.department_other)))
                const departmentStr = [departmentDefStr]
                  .concat(departmentOthers)
                  .filter((v) => !!v)
                  .join("／")

                return (
                  <tr key={partner.id} onClick={() => props.changePath(`${partnerPath}/${partner.id}`)}>
                    <td className={style.listTableNowrap + " alCent"}>
                      {(partner.scouted || partner.entried) && (
                        <span className={style.checkMark}>応募もしくはスカウト済み</span>
                      )}
                      {partner.id}
                    </td>
                    <td>{partner.careers.map((c) => c.company).join("／")}</td>
                    <td>{departmentStr}</td>
                    <td className={"alCent " + style.listTableNowrap}>
                      <span className={style.listTableAge}>
                        {age(partner.birthday)}歳
                        <br className="PCHide" />
                        {partner.gender === "man" ? " 男性" : " 女性"}
                      </span>
                      {partner.verificated && <span className={style.verificationIconList} />}
                    </td>
                    <td className="alCent">
                      <span className={partner.bookmarked ? "joinsPinkTxt" : "lightGrayTxt"}>★</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}
