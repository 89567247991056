import * as React from "react"
import style from "../style/Recruitment.module.scss"
import { dateStr, strToTag, getProblemTitle } from "../lib/Util"
import { IProblem, IStoreState } from "../types/state"
import { PROBLEM_STATUS_EN } from "../lib/Definition"
import { useSelector } from "react-redux"

export const ProblemInfo = (props: { problemID?: number; totalNum: number; problem: IProblem; index: number }) => {
  const [state, setState] = React.useState({
    isOpen:
      props.problem.status !== PROBLEM_STATUS_EN.closed || props.problemID === props.problem.id || props.totalNum === 1
  })
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  return (
    <>
      {props.index === 1 && props.problemID ? <h3 className="contentsSubTitle">その他の案件</h3> : null}
      <div id={props.index.toString()} className={style.taskName}>
        <div className={style.taskNameTitle}>
          {props.totalNum === 1 && <div>業務</div>}
          {props.totalNum !== 1 && (
            <div>
              業務
              <br />
              (案件ID: {props.problem.id})
            </div>
          )}
        </div>
        <div className={style.taskNameContent}>
          <div className={style.problemHeader}>
            <div className={style.problemTitle}>
              {props.problem.status === PROBLEM_STATUS_EN.closed && (
                <span className={style.problemHeaderClosed + " " + style.problemHeaderState}>募集終了済み</span>
              )}
              {(props.problem.status === PROBLEM_STATUS_EN.new ||
                props.problem.status === PROBLEM_STATUS_EN.resume) && (
                <span className={style.problemHeaderOpen + " " + style.problemHeaderState}>準備中</span>
              )}
              {props.problem.status === PROBLEM_STATUS_EN.active && (
                <span className={style.problemHeaderOpen + " " + style.problemHeaderState}>募集中</span>
              )}
              {getProblemTitle(problemCategoryDetails, props.problem)}
            </div>
            <div
              className={style.problemToggleIcon + " " + (state.isOpen && style.problemToggleOpen)}
              onClick={() => setState({ ...state, isOpen: !state.isOpen })}
            >
              {state.isOpen ? " " : " "}
            </div>
          </div>
        </div>
      </div>

      <div className={style.acordionArea} style={{ height: state.isOpen ? "auto" : 0 }}>
        <div className={style.taskArea}>
          <div className={style.taskAreaNumber}>具体的内容</div>
          <div className={style.taskAreaWrap}>
            <dl className={style.taskAreaStatus + " " + style.taskAreaStatusRed}>
              <dt className={style.taskAreaStatusTitle}>
                ① 契約から3ヶ月経過時点で、パートナー人材に実現して欲しい状態
              </dt>
              <dd className={style.taskAreaStatusText}>{strToTag(props.problem.request)}</dd>
            </dl>
            <dl className={style.taskAreaStatus + " " + style.taskAreaStatusBlue}>
              <dt className={style.taskAreaStatusTitle}>② 1の実現が必要だと考えた背景と1の実現ができていない理由</dt>
              <dd className={style.taskAreaStatusText}>{strToTag(props.problem.situation)}</dd>
            </dl>
            <dl className={style.taskAreaStatus + " " + style.taskAreaStatusBlue}>
              <dt className={style.taskAreaStatusTitle}>③ 1を実現した後に、さらに実現していきたいこと</dt>
              <dd className={style.taskAreaStatusText}>{strToTag(props.problem.goal)}</dd>
            </dl>
            <dl className={style.taskAreaStatus + " " + style.taskAreaStatusBlue}>
              <dt className={style.taskAreaStatusTitle}>応募期限</dt>
              <dd className={style.taskAreaStatusText}>
                {dateStr(props.problem.expired_date)}
                <br />
                <small>
                  応募期限は、応募多数となった場合など企業側の判断で期限前に終了となる可能性がありますので、応募検討の場合はお早めにご応募ください。
                </small>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  )
}
