import React from "react";
import style from "./ErrorMessage.module.scss";
import clsx from "clsx"

export interface IErrorMessage {
  children: React.ReactNode;
  cls?: string;
}

export const ErrorMessage = (props: IErrorMessage) => {
  if (!props.children) return null
  return (
    <div className={clsx(style.errorMessage, props.cls)}>
      {props.children}
    </div>
  )
}
