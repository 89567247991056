import * as React from "react";
import { IEntry } from "../../types/state";
import style from "../../style/ScheduleForm.module.scss"
import { RescheduleForm } from "../RescheduleForm"
import { useState } from "react"
import clsx from "clsx"

interface IRescheduleDispatchProps {
  reSchedule: (entry_id: number, message: string) => void;
}

interface IRescheduleStateProps {
  entry: IEntry;
}

interface IRescheduleProps extends IRescheduleDispatchProps, IRescheduleStateProps {}

export const Reschedule = (props: IRescheduleProps) => {
  const [showReSchedule, setShowReSchedule] = useState(false)

  return (
    <>
      {!showReSchedule && (
        <>
          <h3 className="contentsSubTitle">面談日程の都合が悪くなったとき</h3>
          <div className={clsx(style.reScheduleWrapper, "mb32mb16")}>
            <p className="mt0">
              <b>
                日程がどうしても都合が悪くなった場合は、
                <span className="joinsOrangeTxt">
                  応募者に面談日程のスケジュール調整を再度依頼することが可能
                </span>
                です。下記のボタンをクリックして、入力フォームよりスケジュール調整を依頼してください。
              </b>
            </p>
            <div className="formBtnArea formBtnAreaSingle">
              <button
                className="btn btnEdit"
                onClick={() => setShowReSchedule(true)}
              >
                面談日時候補の再設定を依頼する
              </button>
            </div>
          </div>
        </>
      )}
      {showReSchedule && (
        <>
          <h3 className="contentsSubTitle">面談日程の都合が悪くなったとき</h3>
          <RescheduleForm
            entry={props.entry}
            close={() => setShowReSchedule(false)}
            reSchedule={props.reSchedule}
          />
        </>
      )}
    </>
  );
};
