import * as React from "react";
import { Link } from "react-router-dom";
import { loginPath } from "../routes/paths";
export default function ResetPasswordFinish() {
  return (
    <main className="is-outside">
      <div className="container">
        <p>
          いただいたメールアドレス宛てにパスワードをリセットするリンクを記載したメールを送信しました。
          <br />
          ご確認お願いします。
          <br />
          <br />
        </p>
        <div className="formBtnArea formBtnAreaSingle">
          <Link to={loginPath} className="btn btnEdit">
            ログインページへ戻る
          </Link>
        </div>
      </div>
    </main>
  );
}
