import * as React from "react"
import { connect } from "react-redux"
import { Actions } from "../actions"
import { EntryInfo, IEntryProps, IEntryStateProps, IEntryDispatchProps } from "../components/Entry/EntryInfo"
import { updateEntry, showContract, createScout, showAlert, ModalArgs, openModal, cancelEntry } from "../thunks"
import { Dispatch, Action } from "redux"
import { ContractConditions, EntryStatus, ICancelContractEntry, IEntry, IStoreState } from "../types/state"
import { DECLINED_BY, ENTRY_STATUS, LabelType } from "../lib/Definition"
import { push } from "connected-react-router"

class Entry extends React.Component<IEntryProps & { showFirstPsychologicalSafety: boolean }> {
  componentDidMount() {
    const { dispatch } = this.props
    const entryID = parseInt(window.location.href.split("/").pop() as string, 10)
    dispatch(Actions.fetchEntry(entryID))
  }
  render() {
    return <EntryInfo {...this.props} />
  }
}

function mapStateToProps(state: IStoreState): IEntryStateProps {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10)
  const entry = state.entries.find((entry: IEntry) => entry.id === entryID)

  return {
    entry,
    recruitment: state.recruitment,
    registrantEmail: state.registrant.email,
    psychological_status: state.company.psychological_status,
    isNeededVerify: !!(!state.companyVerification.verified && entry?.can_scout)
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): IEntryDispatchProps & { dispatch: Dispatch<any> } {
  return {
    dispatch,
    changePath: (path: string) => dispatch(push(path)),
    confirmContract: (startDate: string) => {
      return dispatch(showContract(startDate) as any)
    },
    acceptSecondInterview: (entry: IEntry) => {
      dispatch(updateEntry({ ...entry, entry_status: ENTRY_STATUS.second_interview_accepted as EntryStatus }) as any)
    },
    reject: (entry: IEntry, apology: string) => {
      dispatch(
        updateEntry({
          ...entry,
          declined_by: DECLINED_BY.company,
          apology
        }) as any
      )
    },
    createScout: (partnerID: number, problemId: number, message: string) =>
      dispatch(createScout(partnerID, problemId, message) as any),
    acceptContract: (entry: IEntry, contractConditions: ContractConditions) => {
      dispatch(
        updateEntry({
          ...entry,
          entry_status: ENTRY_STATUS.contract_accepted as EntryStatus,
          contract_work_time: contractConditions.contract_work_time,
          contract_start_at: contractConditions.contract_start_at,
          contract_amendment: contractConditions.contract_amendment,
          contract_email: contractConditions.contract_email,
          billing_name: contractConditions.billing_name,
          billing_email: contractConditions.billing_email
        }) as any
      )
    },
    cancelContract(entry: IEntry, cancelContractEntry: ICancelContractEntry) {
      return dispatch(
        cancelEntry({
          ...entry,
          entry_status: ENTRY_STATUS.contract_end as EntryStatus,
          ...cancelContractEntry
        }) as any
      )
    },
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any
    },
    updateEntryLabel: (entry_id: number, label_status: LabelType) =>
      dispatch(Actions.updateEntryLabel(entry_id, label_status) as any),
    openModal: (args: ModalArgs) => dispatch(openModal(args) as any)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Entry as any)
