import * as React from "react";
import { ErrorMessage } from "./common/ErrorMessage"

export const TextAreaField = (props: {
  values: { [key: string]: any };
  cls?: string;
  label?: any;
  type?: string;
  note?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  name: string;
  children?: React.ReactNode;
  validates?: { [key: string]: (value: any) => string | null };
  updateValue: (args: { [key: string]: string }) => void;
}) => (
  <>
    <div
      className={
        "formRow" +
        (props.type === "scout" ? " formRowSolo" : "") +
        (props.type === "noborder" ? " formRowNoBorder" : "")
      }
    >
      {props.label && (
        <label className="formHead">
          {props.label}
          {props.required === true ? (
            <span className="label label_req">必須</span>
          ) : props.required === false ? (
            <span className="label label_opt">任意</span>
          ) : null}
        </label>
      )}
      <div className="formData">
        {props.note && <p className="formLead">{props.note}</p>}
        <textarea
          className={"textarea" + (props.cls ? " " + props.cls : "")}
          id={props.name}
          name={props.name}
          value={props.values[props.name]}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={(e: React.ChangeEvent<any>) =>
            props.updateValue({
              [props.name]: e.target.value,
            })
          }
        />
        {props.children}
        {props.values.isSubmitted &&
          props.validates &&
          props.validates[props.name] && (
            <ErrorMessage cls="mb08">
              {props.validates[props.name](props.values)}
            </ErrorMessage>
          )}
      </div>
    </div>
  </>
);
