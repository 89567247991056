import { connect } from "react-redux";
import { IStoreState } from "../types/state";
import RegistrantList from "../components/RegistrantList";

function mapStateToProps(state: IStoreState) {
  return {
    company: state.company,
    registrant: state.registrant
  };
}

export default connect(
  mapStateToProps,
  null
)(RegistrantList);
