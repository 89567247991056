import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import {
  FirstInterviewInfo, IFirstInterviewInfoDispatchProps,
  IFirstInterviewInfoProps,
  IFirstInterviewInfoStateProps
} from "../components/FirstInterview/FirstInterviewInfo"
import { Dispatch, Action } from "redux";
import { IEntry, IStoreState } from "../types/state"
import { push } from "connected-react-router";

class FirstInterview extends React.Component<IFirstInterviewInfoProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    const entryId = parseInt(window.location.href.split("/").pop() as string, 10);
    dispatch(Actions.fetchEntry(entryId));
  }
  render() {
    return <FirstInterviewInfo {...this.props} />;
  }
}

function mapStateToProps(state: IStoreState): IFirstInterviewInfoStateProps {
  const entryId = parseInt(window.location.href.split("/").pop() as string, 10);
  return {
    entry: state.entries.filter((entry: IEntry) => entry.id === entryId)[0]
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): IFirstInterviewInfoDispatchProps & { dispatch: Dispatch<any> } {
  return {
    dispatch,
    reSchedule: (entry_id: number, message: string) => {
      dispatch(Actions.reSchedule({ entry_id, message }) as any)
        .then(() => dispatch(push("/")))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstInterview as any);
