import * as React from "react"
import { useMemo, useState } from "react"
import moment from "moment"
import { CancelContractSatisfactionType, ICancelContractEntry, IEntry } from "../../types/state"
import storedUseState from "../../lib/storedUseState"
import { CancelContractEntry } from "./CancelContractEntry"
import { TextAreaField } from "../TextAreaField"
import { ScrollContext } from "../../containers/ScrollToTop"
import style from "../../style/Entry/CancelContractForm.module.scss"
import styleRec from "../../style/Recruitment.module.scss"
import YearMonthSelector from "../forms/common/YearMonthSelector/YearMonthSelector"
import HintText from "../forms/common/HintText/HintText"
import {
  BOOL_TYPE,
  BOOL_TYPE_STR,
  CANCEL_CONTRACT_SATISFACTION,
  CANCEL_CONTRACT_DISSATISFACTION,
  CANCEL_CONTRACT_SATISFACTION_KEYS
} from "../../lib/Definition"
import { RadioButtons } from "../RadioButtons"
import { keyByValue } from "../../lib/Util"
import { SatisfiedRateField } from "../questionnaireForm/SatisfiedRateField"
import { SatisfiedPointsField } from "../questionnaireForm/SatisfiedPointsField"

const OTHER_TEXT_LIMIT = 255

const getLastDayOfMonth = (date: Date): Date => {
  return new Date(moment(date).format("YYYY-MM-") + moment(date).daysInMonth())
}

export interface ICancelContactDispatchProps {
  cancelContract: (cancelContractEntry: ICancelContractEntry) => any
  alert: (content: string) => Promise<void>
  close: () => void
}

export interface ICancelContactStateProps {
  entry: IEntry
}

export interface ICancelContactProps extends ICancelContactDispatchProps, ICancelContactStateProps {}

export const CancelContractForm = ({ entry, cancelContract, alert, close }: ICancelContactProps) => {
  const currentDate = React.useMemo(() => new Date(), [])

  const lastAvailableDate = React.useMemo(() => {
    return moment(currentDate).add(2, "months").toDate()
  }, [currentDate])

  const lastDayOfCurrentMonth = React.useMemo(() => {
    return getLastDayOfMonth(currentDate)
  }, [currentDate])

  const ctx = React.useContext(ScrollContext)
  const [contractCancelAt, setContractCancelAt] = useState(lastDayOfCurrentMonth)
  const [state, setState] = storedUseState("CancelContractForm", {
    isCompleted: false,
    isSubmitted: false,
    satisfaction: 0,
    satisfactions: [] as CancelContractSatisfactionType[],
    satisfaction_other: "",
    dissatisfactions: [] as CancelContractSatisfactionType[],
    dissatisfaction_other: "",
    re_offer_flag: null,
    re_use_flag: null,
    contract_cancel_comment: "",
    contract_cancel_company_message: ""
  })

  const isSatisfactionOtherChecked = useMemo(() => {
    return state.satisfactions.includes(CANCEL_CONTRACT_SATISFACTION_KEYS.other as CancelContractSatisfactionType)
  }, [state.satisfactions])

  const isDissatisfactionOtherChecked = useMemo(() => {
    return state.dissatisfactions.includes(CANCEL_CONTRACT_SATISFACTION_KEYS.other as CancelContractSatisfactionType)
  }, [state.dissatisfactions])

  const partnerName = useMemo(() => `${entry.partner.last_name} ${entry.partner.first_name}`, [entry.partner])

  const validates = useMemo(() => {
    const validates: { [key: string]: (value: any) => string | null } = {}
    validates["satisfaction"] = (val) => {
      return val.satisfaction ? null : `${partnerName}様の満足度を選択してください。`
    }
    validates["satisfactions"] = (val) => {
      if (val.satisfactions.length === 0) return "満足した点を選択してください。"
      if (val.satisfaction_other && val.satisfaction_other.length > OTHER_TEXT_LIMIT) {
        return `その他の内容は${OTHER_TEXT_LIMIT}文字以内で入力してください。`
      }
      if (val.satisfaction_other) return null
      if (!val.satisfactions.includes(CANCEL_CONTRACT_SATISFACTION_KEYS.other)) return null
      return "その他の内容を入力してください。"
    }
    validates["dissatisfactions"] = (val) => {
      if (val.dissatisfactions.length === 0) return "不満な点を選択してください。"
      if (val.dissatisfaction_other && val.dissatisfaction_other.length > OTHER_TEXT_LIMIT) {
        return `その他の内容は${OTHER_TEXT_LIMIT}文字以内で入力してください。`
      }
      if (val.dissatisfaction_other) return null
      if (!val.dissatisfactions.includes(CANCEL_CONTRACT_SATISFACTION_KEYS.other)) return null
      return "その他の内容を入力してください。"
    }
    validates["re_offer_flag"] = (val) => {
      return val.re_offer_flag === undefined || val.re_offer_flag === null
        ? `${partnerName}様に再び案件を依頼したいかを選択してください。`
        : null
    }
    validates["re_use_flag"] = (val) => {
      return val.re_use_flag === undefined || val.re_use_flag === null
        ? "またJOINSを利用したいかを選択してください。"
        : null
    }
    validates["contract_cancel_company_message"] = (val) => {
      return val.contract_cancel_company_message ? null : `${partnerName}様へのメッセージを入力してください。`
    }
    return validates
  }, [partnerName])

  return (
    <>
      <h3 className="contentsSubTitle alLeft">解約申し込みフォーム</h3>
      <main className="contentsWrapper" id="js-CancelContractForm">
        {!state.isCompleted && (
          <form
            className="pure-form"
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault()
              const error = Object.keys(validates).find((field) => {
                return validates[field]({ ...state })
              })
              if (error) {
                alert("入力に誤りがあります").then(() => {
                  setState({ ...state, isSubmitted: true })
                  ctx.scrollTo(error)
                })
                return
              }
              ctx.scrollTo("nowContract")
              setState({ ...state, isCompleted: true })
            }}
          >
            <div className="formWrap alLeft">
              <div className="formRow">
                <label className="formHead">
                  <span>解約日</span>
                  <span className="label label_req">必須</span>
                </label>
                <div className="formData">
                  <YearMonthSelector
                    onUpdate={(state) => {
                      const dateStr = `${state.year}-${state.month}-1`
                      const lastDayOfMonth = getLastDayOfMonth(moment(dateStr).toDate())
                      setContractCancelAt(lastDayOfMonth)
                    }}
                    start={currentDate}
                    end={lastAvailableDate}
                    monthLabel="月末"
                  />
                  <HintText>
                    相手方とは事前にメッセージで解約の意向をお伝えするようにお願いします。
                    <br />
                    解約希望の申し込みは当月末まで可能です。
                    <br />
                    それ以降の解約申し込みは翌月末解約となりますのでご了承ください。
                  </HintText>
                </div>
              </div>

              <SatisfiedRateField
                title={
                  <>
                    {partnerName}様の
                    <br />
                    満足度
                  </>
                }
                value={state.satisfaction}
                isSubmitted={state.isSubmitted}
                validates={validates}
                updateValue={(val) => {
                  setState({ ...state, satisfaction: val })
                }}
              />

              <SatisfiedPointsField
                title="満足した点"
                name="satisfactions"
                values={state.satisfactions}
                other={state.satisfaction_other}
                master={CANCEL_CONTRACT_SATISFACTION}
                masterKeys={CANCEL_CONTRACT_SATISFACTION_KEYS}
                altValues={state.dissatisfactions}
                isSubmitted={state.isSubmitted}
                validates={validates}
                updateValue={(e) => {
                  let satisfactions: CancelContractSatisfactionType[] = []
                  if (e.target.name !== CANCEL_CONTRACT_SATISFACTION_KEYS.none) {
                    satisfactions = state.satisfactions.filter((s) => {
                      return s !== e.target.name && s !== CANCEL_CONTRACT_SATISFACTION_KEYS.none
                    })
                  }
                  if (e.target.checked) satisfactions.push(e.target.name as CancelContractSatisfactionType)
                  setState({ ...state, satisfactions })
                }}
                updateOther={(e) => {
                  setState({ ...state, satisfaction_other: e.target.value })
                }}
              />

              <SatisfiedPointsField
                title="不満な点"
                name="dissatisfactions"
                values={state.dissatisfactions}
                other={state.dissatisfaction_other}
                master={CANCEL_CONTRACT_DISSATISFACTION}
                masterKeys={CANCEL_CONTRACT_SATISFACTION_KEYS}
                altValues={state.satisfactions}
                isSubmitted={state.isSubmitted}
                validates={validates}
                updateValue={(e) => {
                  let dissatisfactions: CancelContractSatisfactionType[] = []
                  if (e.target.name !== CANCEL_CONTRACT_SATISFACTION_KEYS.none) {
                    dissatisfactions = state.dissatisfactions.filter((s) => {
                      return s !== e.target.name && s !== CANCEL_CONTRACT_SATISFACTION_KEYS.none
                    })
                  }
                  if (e.target.checked) dissatisfactions.push(e.target.name as CancelContractSatisfactionType)
                  setState({ ...state, dissatisfactions })
                }}
                updateOther={(e) => {
                  setState({ ...state, dissatisfaction_other: e.target.value })
                }}
              />

              <div className="formRow">
                <label className="formHead">
                  <span>
                    {partnerName}様に
                    <br />
                    再び案件を
                    <br />
                    依頼したいですか
                  </span>
                  <span className="label label_req">必須</span>
                </label>
                <div className="formData">
                  <RadioButtons
                    name="re_offer_flag"
                    value={keyByValue(BOOL_TYPE, state.re_offer_flag)}
                    master={BOOL_TYPE_STR}
                    groupCls={style.radioGroup}
                    itemCls={style.radioItem}
                    isSubmitted={state.isSubmitted}
                    validates={validates}
                    onChange={(key) => {
                      setState({ ...state, re_offer_flag: BOOL_TYPE[key] })
                    }}
                  />
                </div>
              </div>

              <div className="formRow">
                <label className="formHead">
                  <span>
                    またJOINSを
                    <br />
                    利用したいですか
                  </span>
                  <span className="label label_req">必須</span>
                </label>
                <div className="formData">
                  <RadioButtons
                    name="re_use_flag"
                    value={keyByValue(BOOL_TYPE, state.re_use_flag)}
                    master={BOOL_TYPE_STR}
                    groupCls={style.radioGroup}
                    itemCls={style.radioItem}
                    isSubmitted={state.isSubmitted}
                    validates={validates}
                    onChange={(key) => {
                      setState({ ...state, re_use_flag: BOOL_TYPE[key] })
                    }}
                  />
                </div>
              </div>

              <TextAreaField
                name="contract_cancel_comment"
                label={
                  <span>
                    JOINSへ
                    <br />
                    連絡したいこと
                  </span>
                }
                required={false}
                values={{
                  ...state,
                  isSubmitted: state.isSubmitted
                }}
                updateValue={(value) => {
                  setState({ ...state, ...value })
                }}
              />

              <TextAreaField
                name="contract_cancel_company_message"
                label={
                  <>
                    <span>
                      {partnerName}様への
                      <br />
                      メッセージ
                    </span>
                    <small className="mt10 pl0 grayTxt">
                      {partnerName}様に表示される
                      <br />
                      メッセージとなります。
                    </small>
                  </>
                }
                required
                values={{
                  ...state,
                  isSubmitted: state.isSubmitted
                }}
                validates={validates}
                updateValue={(value) => {
                  setState({ ...state, ...value })
                }}
              />

              <div className="formBtnArea">
                <button
                  className="btn btnEdit"
                  onClick={() => {
                    ctx.scrollTo("nowContract")
                    close()
                  }}
                >
                  キャンセル
                </button>
                <button type="submit" className="btn btnSave">
                  送信内容を
                  <br className="PCHide" />
                  確認する
                </button>
              </div>
            </div>
          </form>
        )}

        {state.isCompleted && (
          <form
            className="pure-form"
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault()
              cancelContract({
                contract_cancel_at: contractCancelAt,
                satisfaction: state.satisfaction,
                satisfactions: state.satisfactions,
                satisfaction_other: isSatisfactionOtherChecked ? state.satisfaction_other : "",
                dissatisfactions: state.dissatisfactions,
                dissatisfaction_other: isDissatisfactionOtherChecked ? state.dissatisfaction_other : "",
                re_offer_flag: state.re_offer_flag,
                re_use_flag: state.re_use_flag,
                contract_cancel_company_message: state.contract_cancel_company_message,
                contract_cancel_comment: state.contract_cancel_comment
              })
            }}
          >
            <div className="formWrap">
              <div className={styleRec.scoutInfoText + " alCent"}>
                以下の内容で送信いたします。よろしければ
                <b className="joinsPinkTxt">送信するボタン</b>を押してください。
              </div>
              <CancelContractEntry
                entry={{
                  ...entry,
                  contract_cancel_at: contractCancelAt,
                  cancel_contract_questionnaire: {
                    satisfaction: state.satisfaction,
                    satisfactions: state.satisfactions,
                    satisfaction_other: state.satisfaction_other,
                    dissatisfactions: state.dissatisfactions,
                    dissatisfaction_other: state.dissatisfaction_other,
                    re_offer_flag: !!state.re_offer_flag,
                    re_use_flag: !!state.re_use_flag
                  },
                  contract_cancel_company_message: state.contract_cancel_company_message,
                  contract_cancel_comment: state.contract_cancel_comment
                }}
              />
              <div className="formBtnArea ">
                <button className="btn btnEdit" onClick={() => setState({ ...state, isCompleted: false })}>
                  キャンセル
                </button>
                <button type="submit" className="btn btnSave">
                  送信する
                </button>
              </div>
            </div>
          </form>
        )}
      </main>
    </>
  )
}
