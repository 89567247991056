import * as React from "react"
import {
  editRegistrantPath,
  companyPath,
  editCompanyPath,
  recruitmentPath,
  registrantPath,
  staffsPath,
  editStaffsPath,
  psychologicalSafetyPath,
  firstPsychologicalSafetyPath,
  editFirstPsychologicalSafetyPath,
  checkTrustPath,
  problemNewPath,
  problemEditPath,
  problemCopyPath
} from "../routes/paths"
import style from "../style/TabList.module.scss"
import { Link } from "react-router-dom"
import { useMemo } from "react"
import clsx from "clsx"

export const TabList = (props: {
  pathname: string
  isRecruitmentCompleted: boolean
  isCompanyCompleted: boolean
  isVerifyCompleted: boolean
  isLimitedStaff: boolean
}) => {
  const isRecruitmentForm = useMemo(() => {
    return (
      problemNewPath === props.pathname ||
      props.pathname.startsWith(problemEditPath) ||
      props.pathname.startsWith(problemCopyPath)
    )
  }, [props.pathname])

  if (
    [
      registrantPath,
      editRegistrantPath,
      editCompanyPath,
      companyPath,
      recruitmentPath,
      problemNewPath,
      staffsPath,
      editStaffsPath,
      psychologicalSafetyPath,
      firstPsychologicalSafetyPath,
      editFirstPsychologicalSafetyPath,
      checkTrustPath
    ].indexOf(props.pathname) === -1 &&
    !props.pathname.startsWith(problemEditPath) &&
    !props.pathname.startsWith(problemCopyPath)
  ) {
    return null
  }

  return (
    <>
      <div className={style.headerTabs}>
        <h2 className="contentsTitle contentsTitleCompany">マイページ</h2>
        {isRecruitmentForm && (
          <div className={style.backLinkArea}>
            <span
              className={clsx("linkGray", style.backLink)}
              onClick={(e) => {
                e.preventDefault()
                window.history.back()
              }}
            >
              戻る
            </span>
          </div>
        )}
        {!isRecruitmentForm && (
          <ul className={style.tabsWrap}>
            <li
              className={
                [editRegistrantPath, registrantPath].indexOf(props.pathname) !== -1 ? "pure-menu-selected" : ""
              }
            >
              <Link to={registrantPath}>管理者</Link>
            </li>
            <li className={[editCompanyPath, companyPath].indexOf(props.pathname) !== -1 ? "pure-menu-selected" : ""}>
              <Link to={companyPath}>
                企業概要
                {!props.isCompanyCompleted && <span className="uncompleted-label"></span>}
              </Link>
            </li>
            <li className={[checkTrustPath].indexOf(props.pathname) !== -1 ? "pure-menu-selected" : ""}>
              <Link to={checkTrustPath}>
                企業審査
                {!props.isVerifyCompleted && <span className="uncompleted-label"></span>}
              </Link>
            </li>
            <li className={[recruitmentPath].indexOf(props.pathname) !== -1 ? "pure-menu-selected" : ""}>
              <Link to={recruitmentPath}>
                {window.innerWidth < 768 ? "募集概要" : "募集案件概要"}
                {props.isVerifyCompleted && !props.isRecruitmentCompleted && (
                  <span className="uncompleted-label"></span>
                )}
              </Link>
            </li>
            {!props.isLimitedStaff && (
              <li className={[editStaffsPath, staffsPath].indexOf(props.pathname) !== -1 ? "pure-menu-selected" : ""}>
                <Link to={staffsPath}>{window.innerWidth < 768 ? "担当者" : "担当者管理"}</Link>
              </li>
            )}
            <li
              className={
                [psychologicalSafetyPath, firstPsychologicalSafetyPath, editFirstPsychologicalSafetyPath].indexOf(
                  props.pathname
                ) !== -1
                  ? "pure-menu-selected"
                  : ""
              }
            >
              <Link to={psychologicalSafetyPath}>
                {window.innerWidth < 768 ? "心理的安全性" : "心理的安全性プログラム"}
              </Link>
            </li>
          </ul>
        )}
      </div>
    </>
  )
}
