import { TypeKeys, Actions } from "../actions";
import { IIntroducer } from "../types/state";

const initialState: IIntroducer[] = [];

const chats = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.FETCH_INTRODUCERS_SUCCESS:
      return action.payload.data.introducers;
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default chats;
