import * as React from "react";
import style from "../../style/Recruitment.module.scss";

export interface ICancelInterviewButton {
  onClick: () => void;
}

export const CancelInterviewButton = (props: ICancelInterviewButton) => {
  return (
    <div className={style.cancelButton} onClick={() => props.onClick()}>
      <i />
      <span>面談を辞退する</span>
    </div>
  );
};
