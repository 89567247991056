import {
  IStaff,
  IStaffActivationStatus
} from "../types/state"
import { ActionsUnion, createAction } from "./types";
import { apiURL } from "./index"

export enum StaffTypeKeys {
  UPDATE_STAFFS = "UPDATE_STAFFS",
  UPDATE_STAFFS_SUCCESS = "UPDATE_STAFFS_SUCCESS",
  UPDATE_STAFFS_FAIL = "UPDATE_STAFFS_FAIL",
  ACTIVATE_STAFF = "ACTIVATE_STAFF",
  ACTIVATE_STAFF_SUCCESS = "ACTIVATE_STAFF_SUCCESS",
  ACTIVATE_STAFF_FAIL = "ACTIVATE_STAFF_FAIL",
  CHECK_STAFF_ACTIVATION_UUID = "CHECK_STAFF_ACTIVATION_UUID",
  CHECK_STAFF_ACTIVATION_UUID_SUCCESS = "CHECK_STAFF_ACTIVATION_UUID_SUCCESS",
  CHECK_STAFF_ACTIVATION_UUID_FAIL = "CHECK_STAFF_ACTIVATION_UUID_FAIL",
}

const updateStaffs = (staffs: IStaff[]) => {
  const params = {};
  let deleteIndex = 0;
  let createIndex = 0;
  let updateIndex = 0;
  staffs.forEach((staff) => {
    if (staff.deleted) {
      params[`delete_staffs[${deleteIndex}][id]`] = staff.id;
      params[`delete_staffs[${deleteIndex}][email]`] = staff.email;
      deleteIndex += 1;
    } else if (staff.id) {
      params[`update_staffs[${updateIndex}][id]`] = staff.id;
      params[`update_staffs[${updateIndex}][role]`] = staff.role;
      params[`update_staffs[${updateIndex}][email]`] = staff.email;
      if (staff.problem_ids) {
        params[`update_staffs[${updateIndex}][problem_ids]`] = staff.problem_ids.join(",");
      }
      updateIndex += 1;
    } else {
      params[`create_staffs[${createIndex}][role]`] = staff.role;
      params[`create_staffs[${createIndex}][email]`] = staff.email;
      if (staff.problem_ids) {
        params[`create_staffs[${createIndex}][problem_ids]`] = staff.problem_ids.join(",");
      }
      createIndex += 1;
    }
  });
  return createAction(StaffTypeKeys.UPDATE_STAFFS, {
    request: {
      data: { ...params },
      method: "post",
      url: `${apiURL}/staffs/upsert`
    },
  });
};

const activateStaff = (args: {
  uuid: string;
  name: string;
  password: string;
}) => {
  return createAction(StaffTypeKeys.ACTIVATE_STAFF, {
    request: {
      data: { ...args },
      method: "post",
      url: `${apiURL}/staffs/activate_account`
    },
  });
};

const checkStaffActivationUuid = (uuid: string) => {
  return createAction(StaffTypeKeys.CHECK_STAFF_ACTIVATION_UUID, {
    request: {
      data: { uuid },
      method: "get",
      url: `${apiURL}/staffs/check_uuid`
    },
  });
};

type updateStaffsSuccess = {
  type: StaffTypeKeys.UPDATE_STAFFS_SUCCESS;
  payload: {
    data: { staffs: IStaff[] };
  };
};

type activateStaffSuccess = {
  type: StaffTypeKeys.ACTIVATE_STAFF_SUCCESS;
  payload: {
    data: { status: IStaffActivationStatus, email: string };
  };
};

type activateStaffFailure = {
  type: StaffTypeKeys.ACTIVATE_STAFF_FAIL;
  payload: {
    error: { message: string; field: string, data: { status: IStaffActivationStatus } };
  };
};

type checkStaffActivationUuidSuccess = {
  type: StaffTypeKeys.CHECK_STAFF_ACTIVATION_UUID_SUCCESS;
  payload: {
    data: { status: IStaffActivationStatus };
  };
};

type checkStaffActivationUuidFailure = {
  type: StaffTypeKeys.CHECK_STAFF_ACTIVATION_UUID_FAIL;
  payload: {
    error: { message: string; field: string, data: { status: IStaffActivationStatus } };
  };
};

type AutoGenerateActions =
  | updateStaffsSuccess
  | activateStaffSuccess
  | activateStaffFailure
  | checkStaffActivationUuidSuccess
  | checkStaffActivationUuidFailure;

export const StaffActions = {
  updateStaffs,
  activateStaff,
  checkStaffActivationUuid
};
export type StaffActions =
  | ActionsUnion<typeof StaffActions>
  | AutoGenerateActions;
