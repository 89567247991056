import * as React from "react";
import { ICompany, ICompanyVerification } from "../../types/state";
import { ConfirmationProfile } from "./ConfirmationProfile";
import { ProgressedTrust } from "./ProgressedTrust";
import { CompletedTrust } from "./CompletedTrust";
import { AnnounceDocumentSubmitType } from "./AnnounceDocumentSubmitType";
import { CustomFile } from "./CustomUploader/useImageUploader";
import { useMemo } from "react"
import { ModalArgs } from "../../thunks";


export interface ICheckTrustFormDispatchProps {
  setIsEdit: (isEdit: boolean) => void;
  sendCompanyVerification: (corporate_number: number | null, personal_files: CustomFile[] | null, open_business_files: CustomFile[] | null) => void;
  checkCompanyVerification: () => any;
  openModal: (args: ModalArgs) => void;
}

interface IProps {
  company: ICompany;
  setIsEdit: (isEdit: boolean) => void;
  sendCompanyVerification: (corporate_number: number | null, personal_files: CustomFile[] | null, open_business_files: CustomFile[] | null) => void;
  companyVerification: ICompanyVerification;
}

export interface ICheckTrustProps extends IProps, ICheckTrustFormDispatchProps { }

export const CheckTrustForm: React.FC<ICheckTrustProps> = ({
  openModal,
  company,
  setIsEdit,
  sendCompanyVerification,
  companyVerification,
}) => {
  const isNotCompanyInfoRegistered = useMemo(() => {
    return company.name.trim() === "" ||
      company.prefecture?.trim() === "" ||
      company.address1.trim() === "" ||
      company.address2.trim() === ""
  }, [company])

  if (isNotCompanyInfoRegistered) {
    return <ConfirmationProfile />
  }

  if (!companyVerification.isRequested) {
    return (
      <AnnounceDocumentSubmitType
        openModal={openModal}
        setIsEdit={setIsEdit}
        sendCompanyVerification={sendCompanyVerification}
      />
    )
  }

  if (companyVerification.verified) {
    return <CompletedTrust />
  }

  if (!companyVerification.verified) {
    return <ProgressedTrust />
  }

  return null
}
