import * as React from "react"
import { IPartner } from "../types/state"
import { age, strToTag } from "../lib/Util"
import style from "../style/Recruitment.module.scss"

interface IPartnerProfileInfoProps extends IPartner {
  noNameMessage: string
  isCareerSheetDisclosed?: boolean
  isPRDisclosed?: boolean
  showVerification?: boolean
}

export const PartnerProfileInfo = (props: IPartnerProfileInfoProps) => {
  const showVerification = React.useMemo(
    () => props.showVerification && props.verificated,
    [props.showVerification, props.verificated]
  )

  return (
    <>
      <h3 className="contentsSubTitle">プロフィール情報</h3>
      <div className={style.listArea}>
        <div className={style.listRow}>
          <div className={style.listHead}>名前</div>
          <div className={style.listData}>
            <div>
              {props.first_name ? (
                <>
                  <span>{`${props.last_name} ${props.first_name}（${props.last_kana} ${props.first_kana}）`}</span>
                  {showVerification && <span className={style.verificationIconProfile}>本人確認済み</span>}
                </>
              ) : (
                <span style={{ color: "#888" }}>{props.noNameMessage}</span>
              )}
            </div>
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>年齢</div>
          <div className={style.listData}>{age(props.birthday)}歳</div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>性別</div>
          <div className={style.listData}>{props.gender === "woman" ? "女性" : "男性"}</div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>現住所</div>
          <div className={style.listData}>{props.address}</div>
        </div>
        {props.photo && (
          <div className={style.listRow}>
            <div className={style.listHead}>プロフィール写真</div>
            <div className={style.listData}>
              <div>
                <img alt="" src={props.photo.url} className="headShot" />
              </div>
            </div>
          </div>
        )}
        {props.school_name && (
          <div className={style.listRow}>
            <div className={style.listHead}>最終学歴</div>
            <div className={style.listData}>
              {" "}
              {props.school_name}
              <br />
              {props.school_faculty && props.school_faculty + "学部"}{" "}
              {props.school_department && props.school_department + "学科"}
            </div>
          </div>
        )}
        {props.career_sheet && (props.isCareerSheetDisclosed || props.is_career_sheet_public) && (
          <div className={style.listRow}>
            <div className={style.listHead}>職務経歴書</div>
            <div className={style.listData}>
              <a href={props.career_sheet.url} target="_blank" className={style.resume} rel="noopener noreferrer">
                {props.career_sheet.name}
              </a>
            </div>
          </div>
        )}
        {props.pr && (props.isPRDisclosed || props.is_pr_public) && (
          <div className={style.listRow}>
            <div className={style.listHead}>自己PR</div>
            <div className={style.listData}>{strToTag(props.pr)}</div>
          </div>
        )}
      </div>
    </>
  )
}
