import { connect } from "react-redux";
import RenewalPasswordForm, {
  IRenewalPasswordDispatchProps,
  IRenewalPasswordStateProps
} from "../components/RenewalPasswordForm";
import { renewalPassword } from "../thunks";
import { IStoreState, IAjaxError } from "../types/state";
import { Actions } from "../actions";
import { Dispatch } from "redux";

function mapStateToProps(state: IStoreState): IRenewalPasswordStateProps {
  const result = window.location.href.split("uuid=").pop() as string;
  const uuid = result.replace(/&.*$/, "");
  return {
    uuid: uuid,
    password: state.viewState.password || "",
    password_confirm: state.viewState.password_confirm || "",
    isSubmitted: state.viewState.isSubmitted || false,
    error: state.viewState.error || ""
  };
}
function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): IRenewalPasswordDispatchProps {
  return {
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
    renewalPassword: (params: { uuid: string; password: string }) => {
      dispatch(renewalPassword(params) as any).catch((e: IAjaxError) => {
        dispatch(Actions.setViewState({
          error: e.payload.error.message,
          errorField: e.payload.error.field,
          scrollID: e.payload.error.field
        }) as any);
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewalPasswordForm);
