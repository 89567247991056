import * as React from "react";
import { ErrorMessage } from "../../common/ErrorMessage"

export interface IErrorMessage {
  show: boolean;
  message: string;
}

export const InputError = (props: IErrorMessage) => {
  if (!props.show) return null;
  return <ErrorMessage>{props.message}</ErrorMessage>;
};
