import style from "../style/SelectGroupedBadgeField.module.scss"
import clsx from "clsx"
import { ChangeEvent, ReactNode } from "react"
import { SelectBadgeField } from "./SelectBadgeField"

export const SelectGroupedBadgeField = (props: {
  masters: { label: string; children: { id: string | number; label: string }[] }[]
  name: string
  label?: string | ReactNode
  cls?: string
  values: (number | string)[]
  updateValue: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <div id={props.name} className={clsx(props.cls)}>
      {props.label && <label>{props.label}</label>}
      <div className={style.contentWrapper}>
        {props.masters.map((master, idx) => {
          return (
            <SelectBadgeField
              key={master.label}
              label={<span className={style.searchInnerTitle}>{master.label}</span>}
              masters={master.children}
              name={`${props.name}-${idx}`}
              cls={clsx(idx !== 0 && "mt20")}
              values={props.values ?? []}
              updateValue={props.updateValue}
            />
          )
        })}
      </div>
    </div>
  )
}
