import style from "../../style/Recruitment.module.scss"
import { ContractConditions, IEntry } from "../../types/state"
import { NormalTextField } from "../NormalTextField"
import { TextAreaField } from "../TextAreaField"
import { DateField } from "../DateField"
import { FormEvent, useMemo } from "react"
import { dToS, dateStr, isValidEmail, isValidName, isInvalidDate } from "../../lib/Util"

const getWorkTimeErrorMsg = (val: number, max: number) => {
  max = max || 500
  if (!val || val < 0) {
    return "月間の目安稼働時間数を半角数字で入力してください。"
  }
  if (isNaN(val)) {
    return "整数を入力してください。"
  }
  if (val > max) {
    return "パートナー人材が提示した月間の業務可能時間数を超えています。"
  }
  return null
}

const getStartAtErrorMsg = (val: Date | null, min: Date | null) => {
  if (min === null) return null
  if (val === null || isInvalidDate(val) || new Date(dToS(val)) < new Date(dToS(min))) {
    return "パートナー人材が提示した最短契約開始可能日以降の日付を入力してください。"
  }
  return null
}

const getContractEmailErrorMsg = (val: string | null) => {
  if (!isValidEmail(val)) {
    return "正しいメールアドレスを入力してください。"
  }
  return null
}

const getContractBillingNameErrorMsg = (val: string | null) => {
  if (!isValidName(val)) {
    return "請求書送付先の担当者名を入力してください。"
  }
  return null
}

const validates: { [key: string]: (value: any) => string | null } = {}
validates["contract_amendment"] = (val) => {
  return !val.contract_amendment
    ? "パートナーの記載内容に違和感なければ「合意」と入力してください。追加や変更がある場合は詳細を記載ください。"
    : null
}

const isValid = (
  cc: ContractConditions,
  contract_work_time: number | null,
  start_at: Date | null,
  billing_name: string | null
) => {
  const workTimeErr = getWorkTimeErrorMsg(contract_work_time || cc.contract_work_time, cc.condition_work_time)
  const startAtErr = getStartAtErrorMsg(start_at || cc.contract_start_at, cc.condition_start_at)
  const billingNameErr = getContractBillingNameErrorMsg(billing_name || cc.billing_name)
  return workTimeErr == null && startAtErr == null && billingNameErr == null
}

export const ContractConditionsForm = (props: {
  entry: IEntry
  contract_conditions: ContractConditions
  updateState: (state: ContractConditions, isValid: boolean) => any
  isSubmitted: boolean
}) => {
  const entry = props.entry
  const partnerStartAt = dateStr(props.contract_conditions.condition_start_at)
  const isValidatable = useMemo(() => props.isSubmitted, [props.isSubmitted])

  return (
    <>
      <div className={style.listArea}>
        <div className={style.listAreaHeadingContract}>
          <b>最終契約条件</b>
        </div>
        <p className={style.listAreaHeadingContractLead}>
          <b>
            上記 {entry.partner.last_name} {entry.partner.first_name} 氏からの提示条件を踏まえて、契約を希望する場合は、
            <br />
            <span className="joinsPinkTxt">契約書に記載する3つの契約条件</span>
            を決定し、「希望する」ボタンを押してください。
          </b>
        </p>
        <form
          className="pure-form"
          onSubmit={(e: FormEvent) => {
            e.preventDefault()
          }}
        >
          <div className="formRow noUnderline">
            <div className="formHead">
              <span className="optionTextNumber">1</span>
              {entry.condition_reward_type ? "報酬形態" : "時給単価（税抜）"}
            </div>
            <div className="formData">
              <span className="normalTxt">
                {entry.condition_reward_type
                  ? entry.condition_reward_type
                  : entry.condition_reward
                  ? `${entry.condition_reward} 円`
                  : "未入力"}
              </span>
            </div>
          </div>
          <div className="formHint">契約開始時点ではパートナー人材の希望価格から変更できません。</div>
          <NormalTextField
            name="contract_work_time"
            label={
              <>
                <span className="optionTextNumber">2</span>目安稼働時間／月
              </>
            }
            cls="formWidth_sss"
            required
            placeholder={
              props.contract_conditions.condition_work_time
                ? props.contract_conditions.condition_work_time.toString()
                : ""
            }
            values={props.contract_conditions}
            type={"number"}
            error={
              isValidatable
                ? getWorkTimeErrorMsg(
                    props.contract_conditions.contract_work_time,
                    props.contract_conditions.condition_work_time
                  )
                : null
            }
            hint={
              <>
                提示されている業務可能時間、時給および貴社の予算金額を踏まえて、月間の目安稼働時間を半角数字でご指定ください。
                <br />
                例：時給3,000円とし、月間予算を10万円に抑えたい場合には30時間と設定
                <br />
                実際の請求金額は、実際の稼働時間に応じて毎月人材から直接請求となります。
              </>
            }
            suffix={"時間"}
            updateValue={(value) => {
              props.updateState(
                {
                  ...props.contract_conditions,
                  contract_work_time: value["contract_work_time"]
                },
                isValid(props.contract_conditions, value["contract_work_time"], null, null)
              )
            }}
          />
          <DateField
            name="contract_start_at"
            title={
              <>
                <span className="optionTextNumber">3</span>
                契約開始日
              </>
            }
            required
            values={props.contract_conditions}
            hintAbove={
              <>
                パートナー人材は<u>{partnerStartAt}以降</u>の契約を希望しています。それ以降の期日を設定してください。
              </>
            }
            hint={
              <>
                契約開始日はパートナー人材との業務委託契約の契約日かつJOINSの紹介料請求の起算日です。稼働開始日はパートナー人材と調整の上決定をお願いします。
                <strong>また契約開始日は契約合意後は変更ができません。</strong>
              </>
            }
            error={
              isValidatable
                ? getStartAtErrorMsg(
                    props.contract_conditions.contract_start_at,
                    props.contract_conditions.condition_start_at
                  )
                : null
            }
            updateValue={(values) => {
              props.updateState(
                {
                  ...props.contract_conditions,
                  ...values
                },
                isValid(props.contract_conditions, null, values.contract_start_at, null)
              )
            }}
          />
          <TextAreaField
            name="contract_amendment"
            label={
              <span>
                パートナー人材が記載した 3カ月で取り組む業務内容をご確認ください
                <small className="mt10 pl0">
                  3カ月後の振り返りの際に達成されているかの確認する内容となります。記載内容で違和感無ければ合意と記載ください。追加や変更がある場合は詳細を記載ください。
                </small>
              </span>
            }
            type="noborder"
            cls="mb0"
            required={true}
            values={{
              contract_amendment: props.contract_conditions.contract_amendment,
              isSubmitted: props.isSubmitted
            }}
            placeholder={entry.exec_plan}
            updateValue={(value) => {
              props.updateState(
                {
                  ...props.contract_conditions,
                  contract_amendment: value["contract_amendment"]
                },
                isValid(props.contract_conditions, null, null, null)
              )
            }}
            validates={validates}
          />
          <div className={style.listAreaHeadingContract}>
            <b>その他</b>
          </div>
          <NormalTextField
            cls="formWidth_m"
            name="contract_email"
            label="契約書の回覧・承認用のメールアドレスをご入力ください"
            required={true}
            values={props.contract_conditions}
            hint="契約書は電子契約締結サービス「クラウドサイン」より、メールにて送付されます。"
            updateValue={(value) => {
              props.updateState(
                {
                  ...props.contract_conditions,
                  contract_email: value["contract_email"]
                },
                isValid(props.contract_conditions, null, null, null)
              )
            }}
            error={isValidatable ? getContractEmailErrorMsg(props.contract_conditions["contract_email"]) : null}
          />
          <NormalTextField
            cls="formWidth_m"
            name="billing_name"
            label="請求書送付先のご担当者様のお名前をご入力ください"
            required={true}
            values={props.contract_conditions}
            placeholder={"鈴木　太郎"}
            updateValue={(value) => {
              props.updateState(
                {
                  ...props.contract_conditions,
                  billing_name: value["billing_name"]
                },
                isValid(props.contract_conditions, null, null, value["billing_name"])
              )
            }}
            error={isValidatable ? getContractBillingNameErrorMsg(props.contract_conditions["billing_name"]) : null}
          />
          <NormalTextField
            cls="formWidth_m"
            name="billing_email"
            label="請求書送付先のご担当者様のメールアドレスをご入力ください"
            required={true}
            values={props.contract_conditions}
            placeholder={"sample@example.com"}
            updateValue={(value) => {
              props.updateState(
                {
                  ...props.contract_conditions,
                  billing_email: value["billing_email"]
                },
                isValid(props.contract_conditions, null, null, null)
              )
            }}
            error={isValidatable ? getContractEmailErrorMsg(props.contract_conditions["billing_email"]) : null}
          />
        </form>
      </div>
    </>
  )
}
