import * as React from "react"
import clsx from "clsx"
import style from "../../style/StartMeetingModal.module.scss"

export const StartMeetingModal = (props: { meetingUrl: string; closeModal: () => void }) => {
  return (
    <main className={style.modalContainer}>
      <div className="modalCloseButton" onClick={props.closeModal}>
        <i />
      </div>

      <div className={style.modalContent}>
        <div className={style.headline}>3月下旬から新Web会議システム(β版)にアップデートしました。</div>
        <ul>
          <li>これまでよりも安定した通信</li>
          <li>シンプルで大きい画面</li>
          <li>背景ぼかし設定機能</li>
        </ul>
        <div className={style.headline}>【注意事項】</div>
        <ul>
          <li>
            心理的安全性プログラム・Web履歴書はWeb会議ツール上では表示されないため、別タブなどでJOINSサイトを見ながら面談を進めてください
          </li>
          <li>双方が入室するとすぐに接続します。開始時刻になったら入室してください</li>
          <li>トラブル時の対応など対応品質向上のため、録画をしています</li>
        </ul>
      </div>

      <div className="alCent">
        <a
          href={props.meetingUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx("btn btnSave", style.startMeetingButton)}
          onClick={props.closeModal}
        >
          Web会議を開始する
        </a>
      </div>
    </main>
  )
}
