import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { Actions } from "../actions";
import { secondInterviewRequestFinishPath } from "../routes/paths";
import { IStoreState, ISecondInterview } from "../types/state";
import { SecondInterviewActions } from "../actions/second_interview";

export function requestSecondInterview(data: Partial<ISecondInterview>) {
  return async (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    await new Promise(async (resolve, reject) => {
      try {
        dispatch(Actions.setViewState({ loaderVisible: true }));
        const response = await dispatch(
          SecondInterviewActions.createSecondInterview(data) as any
        );
        dispatch(push(`${secondInterviewRequestFinishPath}/${data.entry_id}`));
        dispatch(Actions.setViewState({ loaderVisible: false }));
        resolve(response);
      } catch (e) {
        dispatch(Actions.setViewState({ loaderVisible: false }));
        reject(e)
      }
    });
  };
}
