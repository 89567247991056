import * as React from "react";
import { useState, useCallback, useRef } from "react";
import useOnClickOutside from "../../../usehooks/useOnClickOutside";
import { IconButton, Tooltip } from "@material-ui/core";

interface Props {
  title: JSX.Element;
}

export const HintTooltip: React.FC<Props> = ({ title }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useOnClickOutside(ref, onClose);

  return (
    <Tooltip
      ref={ref}
      arrow={true}
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={title}
    >
      <IconButton
        className="btnHelp"
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default HintTooltip;
