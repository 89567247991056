import { IPrefecture } from "../types/state"
import { Actions, TypeKeys } from "../actions"

const initialState: IPrefecture[] = []

const prefectures = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.prefectures
    }
    default: {
      return state
    }
  }
}

export default prefectures
