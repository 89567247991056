import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Actions } from "../actions";
import { login } from "../thunks";
import LoginForm, {
  ILoginStateProps,
  ILoginDispatchProps
} from "../components/LoginForm";
import { IStoreState, IAjaxError } from "../types/state";
import { homePath } from "../routes/paths";

function mapStateToProps(state: IStoreState): ILoginStateProps {
  const st = (state.router.location as any).state;
  let nextPath = homePath;
  if (st && st.from) {
    nextPath = st.from.pathname + st.from.search;
  }
  return {
    email: state.viewState.email || "",
    password: state.viewState.password || "",
    nextPath,
    isSubmitted: state.viewState.isSubmitted || false,
    error: state.viewState.error || "",
    errorField: state.viewState.errorField || ""
  };
}
function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): ILoginDispatchProps {
  return {
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
    login: params => {
      dispatch(login(params) as any).catch((e: IAjaxError) => {
        dispatch(
          Actions.setViewState({
            error: e.payload.error.message,
            errorField: e.payload.error.field
          }) as any
        );
      });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
