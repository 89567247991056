import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import {
  PsychologicalSafetyInfo,
  IPsychologicalSafetyInfoProps,
  IPsychologicalSafetyInfoDispatchProps,
  IPsychologicalSafetyInfoStateProps
} from "../components/PsychologicalSafety/PsychologicalSafetyInfo";
import { IStoreState } from "../types/state";
import { push } from "connected-react-router";

const PsychologicalSafety = (props: IPsychologicalSafetyInfoProps) => {
  return <PsychologicalSafetyInfo {...props} />;
};

function mapStateToProps(
  state: IStoreState
): IPsychologicalSafetyInfoStateProps {
  return {
    psychologicalStatus: state.company.psychological_status
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action<any>>
): IPsychologicalSafetyInfoDispatchProps {
  return {
    changePath: (path: string) => {
      dispatch(push(path));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PsychologicalSafety);
