import { TypeKeys, Actions } from "../actions";
import { IAnnouncement } from "../types/state";

const initialState: IAnnouncement[] = [];

const announcements = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.announcements.map(
        (announcement: IAnnouncement) => {
          return {
            ...announcement,
          };
        }
      );
    }
    case TypeKeys.CHECK_ANNOUNCEMENT_SUCCESS: {
      return state.filter((announcement: IAnnouncement) => announcement.id !== action.payload.data.id)
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default announcements;
