import * as React from "react";
import style from "../../style/ScheduleForm.module.scss";

export const ContactJoins = () => {
  return (
    <div className={style.buttonNotice}>
      <p className="alCent">
        <b>
          その他ご不明点がございましたら、
          <br className="PCHide" />
          いつでも当社宛にお問い合わせください。
        </b>
      </p>
      <div className="formBtnArea formBtnAreaSingle">
        <a
          href={process.env.REACT_APP_INQUIRY_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btnSave"
        >
          お問い合わせはこちら
        </a>
      </div>
    </div>
  )
}
