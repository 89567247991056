import * as React from "react";

export const InterviewFAQ = () => {
  return (
    <>
      <h3 className="contentsSubTitle">面談に関するよくあるご質問</h3>
      <ul className="faqList mb32mb16">
        <li className="faqListInner">
          <a
            href="https://joins.co.jp/faq/web-meeting-tool-trouble-shooting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            自社Web会議ツールの動作確認方法とトラブルシューティング ≫
          </a>
        </li>
      </ul>
    </>
  )
}
