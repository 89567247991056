import * as React from "react";
import { Link } from "react-router-dom";
import style from "../style/Badge.module.scss";
import secondStyle from "../style/Table.module.scss";

export interface IToDoItemProps {
  value: any;
  linkPath: string;
  target_blank?: boolean;
}

export const ToDoItem = (props: IToDoItemProps) => {
  const {value,  linkPath, target_blank = false} = props;
  return (
    <Link to={linkPath} className="joinsPinkTxt mb4" target={target_blank ? "_blank" : "_self"}>
      <span className={style.todoBadge}>Todo</span>
      <span className={secondStyle.todoText}>{value}<i /></span>
    </Link>
  );
}
