import { Actions, TypeKeys } from "../actions"
import { IEntry, IQuestionnaire, IPartner, ISecondInterview } from "../types/state"
import DateWithOffset from "../lib/DateWithOffset"
import { EntryActions, EntryTypeKeys } from "../actions/entry"

const initialState: IEntry[] = []

export function buildPartner(partner: IPartner) {
  return {
    ...partner,
    birthday: new DateWithOffset(partner.birthday),
    favorite: {
      ...partner.favorite,
      insurance_expires_at:
        partner.favorite.insurance_expires_at && new DateWithOffset(partner.favorite.insurance_expires_at),
      start_date: new DateWithOffset(partner.favorite.start_date)
    }
  }
}

function buildSecondInterview(secondInterview: ISecondInterview): ISecondInterview {
  return {
    ...secondInterview,
    schedule_times: secondInterview.schedule_times
      ? secondInterview.schedule_times.map((t) => new DateWithOffset(t) as Date)
      : [],
    rejected_times: secondInterview.rejected_times.map((t) => new DateWithOffset(t) as Date),
    meeting_at: secondInterview.meeting_at ? (new DateWithOffset(secondInterview.meeting_at) as Date) : null
  }
}

function buildEntry(entry: IEntry) {
  const partner = buildPartner(entry.partner)
  return {
    ...entry,
    meeting_at: entry.meeting_at ? new DateWithOffset(entry.meeting_at) : null,
    second_interview_date: entry.second_interview_date ? new DateWithOffset(entry.second_interview_date) : null,
    schedule_times: entry.schedule_times.map((t) => new DateWithOffset(t)),
    created_at: new DateWithOffset(entry.created_at),
    partner,
    condition_start_at: entry.condition_start_at ? new DateWithOffset(entry.condition_start_at) : null,
    contract_start_at: entry.contract_start_at ? new DateWithOffset(entry.contract_start_at) : null,
    contract_cancel_at: entry.contract_cancel_at ? new DateWithOffset(entry.contract_cancel_at) : null,
    second_interview: entry.second_interview ? buildSecondInterview(entry.second_interview) : null
  }
}

const entries = (state = initialState, action: Actions | EntryActions): any => {
  switch (action.type) {
    case TypeKeys.FETCH_ENTRIES_SUCCESS: {
      return action.payload.data.entries.map((entry) => buildEntry(entry))
    }
    case EntryTypeKeys.UPDATE_ENTRY_SUCCESS:
    case TypeKeys.FETCH_ENTRY_SUCCESS: {
      const entry = action.payload.data
      if (state.filter((e) => e.id === entry.id).length > 0) {
        return state.map((e) => (e.id === entry.id ? buildEntry(entry) : e))
      } else {
        return [...state, buildEntry(entry)]
      }
    }
    case TypeKeys.UPDATE_ENTRY_LABEL_SUCCESS: {
      return state.map((e) => {
        if (e.id === action.payload.data.entry_id) {
          return {
            ...e,
            label_status: action.payload.data.label_status
          }
        }
        return e
      })
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState
    }
    case TypeKeys.CREATE_QUESTIONNAIRE_SUCCESS: {
      const questionnaire: IQuestionnaire = action.payload.data
      const entryId = questionnaire.entry_id || null

      return state.map((e) => {
        if (e.id === entryId) {
          const questionnaires = e.questionnaires || []
          return {
            ...e,
            questionnaires: questionnaires.concat(questionnaire)
          }
        }
        return e
      })
    }
    default: {
      return state
    }
  }
}

export default entries
