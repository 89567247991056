import * as React from "react";
import { IEntry, IRecruitment, IStoreState } from "../types/state"
import { getProblemCategoryDetailNames, strToTag } from "../lib/Util"
import style from "../style/Recruitment.module.scss";
import { useSelector } from "react-redux"

export const PartnerEntryInfo = (props: { entry: IEntry; recruitment: IRecruitment }) => {
  const problem = props.recruitment.problems.find((pro) => pro.id === (props.entry as IEntry).problem_id)
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  return (
    <>
      <h3 className="contentsSubTitle">提案内容</h3>
      <div className={style.listArea}>
        <div className={style.listRow}>
          <div className={style.listHead}>募集タイトル</div>
          {problem && <div className={style.listData}>{problem.title}</div>}
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>業務カテゴリー詳細</div>
          <div className={style.listData}>
            {getProblemCategoryDetailNames(problemCategoryDetails, problem)}
          </div>
        </div>
        {props.entry.reason && (
          <div className={style.listRow}>
            <div className={style.listHead}>
              本案件に関心を
              <br className="SPHide" />
              お持ちいただいた理由
            </div>
            <div className={style.listData}>{strToTag(props.entry.reason)}</div>
          </div>
        )}
        <div className={style.listRow}>
          <div className={style.listHead}>
            「契約から3ヶ月経過時点でパートナー人材に実現して欲しい状態」の実現のために、あなた自身が実行可能な具体的な行動案
          </div>
          <div className={style.listData}>{strToTag(props.entry.idea)}</div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>
            上記の実現に活かせそうな過去のご経験
          </div>
          <div className={style.listData}>
            {strToTag(props.entry.experience)}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>その他コメント</div>
          <div className={style.listData}>{strToTag(props.entry.comment)}</div>
        </div>
      </div>
    </>
  );
};
