import clsx from "clsx"
import { ChangeEvent, ReactNode } from "react"
import { ErrorMessage } from "./common/ErrorMessage"

export const SelectBadgeField = (props: {
  masters: {
    id: number | string
    label: string
  }[]
  name: string
  label?: string | ReactNode
  note?: string
  cls?: string
  values: (number | string)[]
  isSubmitted?: boolean
  validates?: { [key: string]: (value: any) => string | null }
  updateValue: (e: ChangeEvent<HTMLInputElement>) => void
  children?: ReactNode
}) => {
  return (
    <div id={props.name} className={clsx(props.cls)}>
      {props.label && (
        <label>
          {props.label}
          {props.note && (
            <>
              <br />
              <small>{props.note}</small>
            </>
          )}
        </label>
      )}
      <div>
        {props.masters.map((master) => {
          return (
            <label className="selectBtn selectBtnSmall" key={master.id}>
              <input
                type="checkbox"
                checked={props.values.includes(master.id)}
                name={props.name}
                value={master.id.toString()}
                onChange={(e) => {
                  props.updateValue(e)
                }}
              />
              <span className="alLeft">{master.label}</span>
            </label>
          )
        })}
        {props.isSubmitted && props.validates && props.validates[props.name] && (
          <ErrorMessage>{props.validates[props.name](props.values)}</ErrorMessage>
        )}
        {props.children}
      </div>
    </div>
  )
}
