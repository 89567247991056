import * as React from "react"
import { IStaff, IProblem, IStoreState } from "../types/state"
import { Link } from "react-router-dom"
import * as Definition from "../lib/Definition"
import { editStaffsPath } from "../routes/paths"
import style from "../style/Staffs.module.scss"
import { useSelector } from "react-redux"
import { getProblemCategoryDetailNames } from "../lib/Util"

interface IStaffState extends IStaff {
  problems: IProblem[]
}
export interface IStaffListStateProps {
  staffs: IStaffState[]
}
export interface IStaffListDispatchProps {}

export interface IStaffListProps extends IStaffListStateProps, IStaffListDispatchProps {}

const StaffList = (props: IStaffListProps) => {
  const staffs = props.staffs
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  return (
    <main className="contentsWrapper">
      <div className="formWrap confirmWrap">
        <div className="formRow formRowNoBorder">
          <div className="formHead">担当者</div>
          <div className="formData">
            {staffs.map((staff, idx) => {
              return (
                <div className={style.taskWrap} key={staff.id}>
                  <h3 className={style.taskNumber}>{`担当者${idx + 1}`}</h3>
                  <dl className={style.taskList}>
                    <dt>メールアドレス</dt>
                    <dd>{staff.email}</dd>
                  </dl>
                  <dl className={style.taskList}>
                    <dt>権限</dt>
                    <dd>{Definition.STAFF_ROLES[staff.role]}</dd>
                  </dl>
                  <dl className={style.taskList}>
                    <dt>閲覧可能な案件</dt>
                    <dd>
                      <ul>
                        {staff.role === "admin" ? (
                          <li>すべて</li>
                        ) : (
                          staff.problems.map((problem) => (
                            <li key={problem.id}>
                              {`${getProblemCategoryDetailNames(problemCategoryDetails, problem)} (案件ID: ${
                                problem.id
                              })`}
                            </li>
                          ))
                        )}
                      </ul>
                    </dd>
                  </dl>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="formBtnArea formBtnAreaSingle">
        <Link to={editStaffsPath} className="btn btnEdit">
          変更・修正する
        </Link>
      </div>
    </main>
  )
}

export default StaffList
