import * as React from "react";

const YearField = (props: {
  values: {
    year: number;
    startYear: number;
    endYear: number;
    suffix?: string;
    title?: string;
    isSubmitted: boolean;
  };
  required?: boolean;
  updateValue: (args: { [key: string]: number }) => void;
}) => {
  const years = [-1];
  let year = props.values.startYear;
  while (year <= props.values.endYear) {
    years.push(year);
    year += 1;
  }
  return (
    <>
      {props.values.title && (
        <label className="pure-u-1 problemLabel">{props.values.title}</label>
      )}
      <select
        name="year"
        value={props.values.year || -1}
        onChange={(e: React.ChangeEvent<any>) =>
          props.updateValue && props.updateValue(e.target.value)
        }
      >
        {years.map((year) => {
          return (
            <option key={"year-" + year.toString()} value={year}>
              {year > 0 ? year : "----"}
            </option>
          );
        })}
      </select>
      <label className="formData_inline">年</label>
    </>
  );
};

export default YearField;
