import React from "react";
import { useCallback, useState } from "react";
import style from "../../style/ProhibitedMatters.module.scss";
import forbiddenActionImage from "../../images/term-of-use/forbiddenActionImage.png";
import contactorNameRuleImage from "../../images/term-of-use/contactorNameRuleImage.png";
import recommendationImage from "../../images/term-of-use/recommendationImage.png";

export const ProhibitedMatters = (props: {
  closeOnly: boolean;
  closeModal: (result: boolean) => void;
}) => {
  const [isRead, setIsRead] = useState(false);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    if (isRead) return;

    const margin = 100;
    const hasReachedBottom = Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) - margin < e.currentTarget.clientHeight;
    setIsRead(hasReachedBottom);
  }, [isRead])

  const handleCancel = useCallback(() => {
    props.closeModal(false);
  }, [props]);

  const handleConfirm = useCallback(() => {
    props.closeModal(true);
  }, [props]);

  return (
    <main>
      <div
        className={style.scrollableContainer}
        onScroll={handleScroll}
      >
        <h1>JOINSでの禁止行為</h1>
        <div className={style.greyBox}>
          <p>
            JOINSでは、より多くの方に副業・兼業の機会を持っていただき、安全なマッチングを行うために、以下の行為を利用規約で禁止しています。
          </p>
        </div>
        <h2>1. 直接契約ならびにその勧誘行為</h2>
        <img
          className={style.image}
          src={forbiddenActionImage}
          alt="直接契約ならびにその勧誘行為"
        />
        <div className={style.greyBox}>
          <p>こちらについては利用中、並びに退会後５年間の適用となります。</p>
        </div>
        <div className={style.greyBox}>
          <p>
            こちらに違反された場合は、当社よりパートナー人材の方へ、損害賠償の請求をいたします。並びにパートナー人材は違約金100万円を支払う義務を負うものとします。
          </p>
        </div>
        <h3>パートナー人材側の契約できる名義としては以下となります。</h3>
        <ul>
          <li>個人名義</li>
        </ul>
        <ul>
          <li>自身が代表を務める屋号／法人名義</li>
          <ul>
            <li>
              <strong>
                法人の場合は代表者１名で運営している法人に限らせていただきます。
              </strong>
            </li>
            <img
              className={style.image}
              src={contactorNameRuleImage}
              alt="パートナー人材側の契約できる名義"
            />
          </ul>
        </ul>
        <h4>その他注意点</h4>
        <ul>
          <li>ご契約期間は１ヶ月単位の自動更新となります。</li>
        </ul>
        <ul>
          <li>契約書についてはJOINSでご準備いたします。</li>
        </ul>
        <ul>
          <li>
            企業からの承諾有無に関わらず、業務の再委託は、ご遠慮ください。
          </li>
          <ul>
            <li>
              業務を進める上で別の役割が必要となった場合は、企業様が直接その個人・法人と契約を締結してください。
            </li>
            <img
              className={style.image}
              src={recommendationImage}
              alt="その他注意点"
            />
          </ul>
        </ul>

        <h2>2. 副業・兼業を目的としない過度な営業行為</h2>
        <h3>こちらは主にパートナー人材の所属企業のサービス・製品の営業を指します。</h3>
        <div className={style.greyBox}>
          <p>こちらについては発見次第、退会措置とさせていただきます。</p>
        </div>

        <h2>3. 募集内容と異なる営業行為</h2>
        <h3>こちらは例えば、法人営業先開拓の募集に対し、直接関係のないSNSマーケティングの提案等にあたります。</h3>
        <div className={style.greyBox}>
          <p>こちらについては発見次第、退会措置とさせていただきます。</p>
        </div>
      </div>

      {props.closeOnly ? (
        <div className="formBtnArea formBtnAreaSingle">
          <button
            className="btn btnSave"
            onClick={handleConfirm}
          >
            閉じる
          </button>
        </div>
      ) : (
        <div className="formBtnArea formWrap">
          <button className="btn btnEdit" onClick={handleCancel}>
            キャンセル
          </button>
          <button
            className="btn btnSave"
            disabled={!isRead}
            onClick={handleConfirm}
          >
            確認
          </button>
        </div>
      )}
    </main>
  );
};
