import * as React from "react";
import { IEntry, ISecondInterview } from "../../../types/state";
import { PartnerManagementHeader } from "../../headers/PartnerManagementHeader";
import { RadioSelectors, IRadioSelectorOption } from "../../RadioSelectors";
import { OnlineMeetingFields } from "./OnlineMeetingFieldsType";
import { OfflineMeetingFields } from "./OfflineMeetingFieldsType";
import { MEETING_TYPE } from "../../../lib/Definition";
import { IndexedLabel } from "../common/IndexedLabel";
import style from "../../../style/MeetingRequestForm.module.scss";
import { Box } from "@material-ui/core";

export interface ISecondInterviewRequestFormDispatchProps {
  requestSecondInterview: (data: Partial<ISecondInterview>) => void;
}

export interface ISecondInterviewRequestFormStateProps {
  entry: IEntry;
}

export interface ISecondInterviewRequestFormProps
  extends ISecondInterviewRequestFormDispatchProps,
    ISecondInterviewRequestFormStateProps {}

const radioSelectorOptions: IRadioSelectorOption[] = [
  {
    label: "Web面談",
    value: MEETING_TYPE.ONLINE,
  },
  {
    label: "対面面談",
    value: MEETING_TYPE.OFFLINE,
  },
];

const SecondInterviewRequestForm = (props: ISecondInterviewRequestFormProps) => {
  const partner = props.entry.partner;

  const [state, setState] = React.useState({
    confirming: false,
    submitted: false,
    valid: false,
    meetingType: MEETING_TYPE.ONLINE,
  });

  const [meetingFields, setMeetingFields] = React.useState<
    Partial<ISecondInterview>
  >();

  if (!partner) {
    return null;
  }

  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 className="contentsTitle contentsTitlePartner">
        {partner.last_name} {partner.first_name} 様との二次面談希望確認
      </h2>

      <div>
        <p>
          <b className="joinsPinkTxt">
            応募者との二次面談希望を確定してください。
          </b>
        </p>
        <div className={style.selectArea}>
          <IndexedLabel index={1}>
            「Web会議を利用したWeb面談」もしくは「対面での面談」のどちらを希望されるか選択してください。
          </IndexedLabel>

          <div className={style.selectAreaWay}>
            <RadioSelectors
              disabled={state.confirming && state.valid}
              options={radioSelectorOptions}
              onChange={(option) =>
                setState({
                  ...state,
                  meetingType: option.value,
                  confirming: false,
                })
              }
            />
          </div>
        </div>

        <form
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            setState({ ...state, submitted: true });
            props.requestSecondInterview({
              entry_id: props.entry.id,
              meeting_type: state.meetingType,
              ...meetingFields!,
            });
          }}
        >
          {state.meetingType === MEETING_TYPE.ONLINE && (
            <OnlineMeetingFields
              confirming={state.confirming}
              onChange={(values) => {
                setState({ ...state, confirming: false });
                setMeetingFields(values);
              }}
              isValid={(result) => {
                setState({ ...state, valid: result });
              }}
            />
          )}
          {state.meetingType === MEETING_TYPE.OFFLINE && (
            <OfflineMeetingFields
              confirming={state.confirming}
              onChange={(values) => {
                setState({ ...state, confirming: false });
                setMeetingFields(values);
              }}
              isValid={(result) => {
                setState({ ...state, valid: result });
              }}
            />
          )}

          {state.confirming && state.valid ? (
            <>
              <Box className="confirmForMui">
                <span>上記の内容で送信します。よろしければ「二次面談希望を確定する」ボタンを押してください。</span>
              </Box>
              <div className="formBtnArea">
                <button
                  className="btn btnEdit"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, confirming: false });
                  }}
                >
                  キャンセル
                </button>
                <button type="submit" className="btn btnSave">
                  二次面談希望を確定する
                </button>
              </div>
            </>
          ) : (
            <div className="formBtnArea">
              <button
                className="btn btnEdit"
                onClick={(e) => {
                  e.preventDefault();
                  window.history.back();
                }}
              >
                戻る
              </button>
              <button
                className="btn btnSave"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ ...state, confirming: true });
                }}
              >
                確認する
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default SecondInterviewRequestForm;
