import * as React from "react";
import { IRegistrant, ICompany } from "../types/state";
import { Link } from "react-router-dom";
import { editRegistrantPath } from "../routes/paths";
import * as Definition from "../lib/Definition";

const RegistrantList = (props: { registrant: IRegistrant; company: ICompany }) => {
  const registrant = props.registrant;
  return (
    <main className="contentsWrapper">
      <div className="formWrap confirmWrap">
        <div className="formRow">
          <div className="formHead">名前</div>
          <div className="formData">
            {registrant.last_name + registrant.first_name}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">名前(カナ)</div>
          <div className="formData">
            {registrant.last_kana + registrant.first_kana}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">メール</div>
          <div className="formData">{registrant.email}</div>
        </div>
        <div className="formRow">
          <div className="formHead">電話番号</div>
          <div className="formData">{registrant.tel}</div>
        </div>
        <div className="formRow">
          <div className="formHead">パスワード</div>
          <div className="formData">******</div>
        </div>
        <div className="formRow">
          <div className="formHead">管理者部署名</div>
          <div className="formData">{registrant.department}</div>
        </div>
        <div className="formRow">
          <div className="formHead">管理者役職</div>
          <div className="formData">
            {Definition.POSITIONS[registrant.position]}
          </div>
        </div>
      </div>
      {!props.company.staff_id && (
        <div className="formBtnArea formBtnAreaSingle">
          <Link to={editRegistrantPath} className="btn btnEdit">
            変更・修正する
          </Link>
        </div>
      )}
    </main>
  );
};

export default RegistrantList;
