import { Actions, TypeKeys } from "../actions";
import { ICompany } from "../types/state";
import DateWithOffset from "../lib/DateWithOffset";

const initialState: ICompany = {
  id: 0,
  name: "",
  name_kana: "",
  zip_code: "",
  prefecture: "",
  address1: "",
  address2: "",
  president: "",
  trigger: "",
  trigger_other: "",
  introducer_id: 0,
  establishment: -1,
  capital: 0,
  employee: -1,
  executive: -1,
  vision: "",
  industries: 0,
  industry_other: "",
  video_url: "",
  urls: "",
  eye_catch: null,
  files: [],
  contract_condition: "",
  businesses: [],
  meeting_at: null,
  meeting_id: null,
  sales: "",
  psychological_status: "hidden",
  president_profile: "",
  president_position_name: "",
  president_birth_year: -1,
  president_hometown: "",
  president_first_job_desc: "",
  president_how_become: "",
  president_motivation: "",
  president_generation: "",
  president_hobbies: "",
  other: "",
  bellbird_setting_url: ""
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.RECOVER_DATA: {
      return Object.assign({}, state, action.payload.company, {
        eye_catch: null,
        meeting_at:
          action.payload.company && action.payload.company.meeting_at
            ? new DateWithOffset(action.payload.company.meeting_at)
            : null,
      });
    }
    case TypeKeys.LOGIN_SUCCESS:
    case TypeKeys.SIGNUP_SUCCESS: {
      return Object.assign({}, state, action.payload.data.company, {
        meeting_at: action.payload.data.company.meeting_at
          ? new DateWithOffset(action.payload.data.company.meeting_at)
          : null,
      });
    }
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return Object.assign({}, state, action.payload.data.company, {
        meeting_at: action.payload.data.company.meeting_at
          ? new DateWithOffset(action.payload.data.company.meeting_at)
          : null,
      });
    }
    case TypeKeys.FETCH_COMPANY_SUCCESS: {
      return Object.assign({}, state, action.payload.data.company, {
        meeting_at: action.payload.data.company.meeting_at
          ? new DateWithOffset(action.payload.data.company.meeting_at)
          : null,
      });
    }
    case TypeKeys.UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_SUCCESS: {
      return state.psychological_status === "first_wait"
        ? { ...state, psychological_status: "first_done" }
        : state;
    }
    case TypeKeys.CREATE_WEB_MEETING_SUCCESS: {
      return Object.assign({}, state, {
        meeting_id:
          action.payload.data.kind === "Web相談"
            ? action.payload.data.id
            : state.meeting_id,
        meeting_at:
          action.payload.data.kind === "Web相談"
            ? new DateWithOffset(action.payload.data.meeting_at)
            : state.meeting_at,
        bellbird_setting_url: action.payload.data.bellbird_setting_url
      });
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
