import * as React from "react"
import { useCallback } from "react"
import { Link } from "react-router-dom"
import { staffsPath } from "../../routes/paths"
import { IStaff, IProblem } from "../../types/state"
import { EditStaffList } from "./EditStaffList"

export interface IEditStaffsDispatchProps {
  alert: (content: string) => Promise<void>
  confirm: (content: string) => Promise<boolean>
  updateValue: (args: any) => void
  updateStaffs: (staffs: IStaff[]) => void
  setIsEdit: (isEdit: boolean) => void
}

export interface IStaffState extends IStaff {
  problems: number
}
export interface IEditStaffsStateProps {
  enableCancel: boolean
  problemMap: {
    [key: number]: IProblem & { flag: number; message: string }
  }
  isSubmitted: boolean
  staffs: IStaffState[]
  registrantMail: string
  error?: string
}
export const EditStaffsForm = (props: IEditStaffsDispatchProps & IEditStaffsStateProps) => {
  const [staffs, setStaffs] = React.useState(props.staffs)

  const updateStaffs = useCallback(
    (staffs) => {
      setStaffs(staffs)
      props.setIsEdit(true)
    },
    [props]
  )

  const validates: { [key: string]: (value: any) => string | null } = {}
  validates["email"] = (val) => {
    return val.email
      ? !val.deleted && val.email === props.registrantMail
        ? "管理者と同じメールアドレスが登録されています。担当者の削除もしくは管理者のメールアドレスの変更をお願いします"
        : val.email.match(
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
          )
        ? null
        : "正しいメールアドレスを入力してください。"
      : "メールアドレスを入力してください。"
  }
  validates["problems"] = (val) => {
    return val.role !== "admin" && val.problems === 0 ? "閲覧可能な案件を選択してください。" : null
  }

  const checkErrors = (staffs: IStaffState[]) => {
    return Object.keys(validates)
      .map((field) => {
        const errorNum: number[] = staffs
          .map((staff, index) => (validates[field](staff) ? index + 1 : null))
          .filter((val) => val) as number[]
        return errorNum.length > 0 ? `${field}-${errorNum[0] - 1}` : null
      })
      .filter((val) => val)
  }

  return (
    <main className="contentsWrapper">
      <form
        className="pure-form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault()
          const errors = checkErrors(staffs)
          if (errors.length > 0) {
            props.alert("入力に誤りがあります").then(() => {
              props.updateValue({ scrollID: errors[0], isSubmitted: true })
            })
            return
          }
          const deletedStaffEmails = staffs.filter((s) => s.deleted).map((s) => s.email)
          const deletedEmailNewStaffs = staffs.filter((s) => !s.deleted && deletedStaffEmails.includes(s.email))
          if (deletedEmailNewStaffs.length > 0) {
            props.alert(
              "削除する担当者と同じメールアドレスは同時に登録できません。<br />" +
                "一度キャンセルし、登録内容をご変更いただくか、<br />" +
                "削除を一度保存した後、再度ご登録ください。"
            )
            return
          }
          if (new Set(staffs.map((s) => s.email)).size !== staffs.length) {
            props.alert("メールアドレスが重複しています")
            return
          }
          props.updateStaffs(staffs)
        }}
      >
        <div className="formWrap">
          {props.error && <div style={{ color: "red" }}>{props.error}</div>}
          <EditStaffList
            alert={props.alert}
            confirm={props.confirm}
            values={staffs}
            isSubmitted={props.isSubmitted}
            validates={validates}
            problemMap={props.problemMap}
            updateValue={updateStaffs}
          />
          <div className={"formBtnArea" + (props.enableCancel ? "" : " formBtnAreaSingle")}>
            {props.enableCancel && (
              <Link to={staffsPath} className="btn">
                キャンセル
              </Link>
            )}
            <button type="submit" className="btn btnSave">
              上記内容で保存
            </button>
          </div>
        </div>
      </form>
    </main>
  )
}
