import * as React from "react"
import clsx from "clsx"

interface IProps {
  button?: boolean
  children: React.ReactNode
}

export const GuidanceMeetingLink = (props: IProps) => {
  return (
    <a
      href="https://joins-company.com/9e32e0b13b344192a78a78b50693a433"
      className={clsx(props.button ? "btn btnSave" : "link")}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  )
}
