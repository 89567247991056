import * as React from "react"
import { addDate, dateStr, isWithdrawn, monthTimeStr, nextAction } from "../lib/Util"
import {
  entryPath,
  firstInterviewPath,
  guidanceMeetingPath,
  schedulePath,
  secondInterviewPath,
  secondInterviewTodosPath
} from "../routes/paths"
import { IComputedEntry } from "./EntryList"
import { ToDoItem } from "./ToDoItem"
import style from "../style/Table.module.scss"
import { ENTRY_STATUS, GUIDANCE_MEETING_STATUS } from "../lib/Definition"

export interface INextActionDescriptionProps {
  entry: IComputedEntry
  psychological_status: string
}

export const NextActionDescription = (props: INextActionDescriptionProps) => {
  const { entry } = props

  const isNotGuidanceMeeting = React.useMemo(() => {
    return (
      [ENTRY_STATUS.contract_requested, ENTRY_STATUS.contract_accepted].includes(entry.entry_status) &&
      !entry.declined_by &&
      entry.guidance_meeting_status === GUIDANCE_MEETING_STATUS.un_executed
    )
  }, [entry])

  const isWithdrawnPartner = React.useMemo(() => {
    return isWithdrawn(entry.partner)
  }, [entry.partner])
  if (isWithdrawnPartner) return null

  return (
    <>
      {nextAction(entry).indexOf("面談要否決定") !== -1 && (
        <ToDoItem
          linkPath={`${entryPath}/${entry.id}`}
          value={
            <>
              面談希望の有無を回答
              <span className={style.todoTextDate}>
                （{dateStr(addDate(entry.problem.expired_date, 7), true)}まで）
              </span>
            </>
          }
        />
      )}
      {nextAction(entry).indexOf("日時指定") !== -1 && (
        <>
          <ToDoItem linkPath={`${schedulePath}?entry_id=${entry.id}`} value="一次面談の日時を確定" />
          {props.psychological_status === "first_wait" && (
            <div>
              <ToDoItem linkPath={`/psychological_safety`} value="心理的安全性プログラムの入力" />
            </div>
          )}
        </>
      )}
      {nextAction(entry).indexOf("Web面談アクセス") !== -1 && (
        <>
          <ToDoItem
            linkPath={`${firstInterviewPath}/${entry.id}`}
            value={
              <>
                Web面談にアクセス
                <span className={style.todoTextDate}>（{monthTimeStr(entry.meeting_at)}）</span>
              </>
            }
          />
          {props.psychological_status === "first_wait" && (
            <div className="mt2">
              <ToDoItem linkPath={`/psychological_safety`} value="心理的安全性プログラムの入力" />
            </div>
          )}
        </>
      )}
      {nextAction(entry).indexOf("候補日時回答待ち") !== -1 && (
        <div className={style.waiting}>一次面談候補日時の回答待ち</div>
      )}
      {nextAction(entry).indexOf("一次面談結果返信待ち") !== -1 && (
        <div className={style.waiting}>一次面談結果の回答待ち</div>
      )}
      {nextAction(entry).indexOf("二次面談希望有無選択") !== -1 && (
        <ToDoItem linkPath={`${entryPath}/${entry.id}`} value="二次面談実施の希望有無を回答" />
      )}
      {nextAction(entry).indexOf("二次面談候補日時回答待ち") !== -1 && (
        <div className={style.waiting}>二次面談候補日時の回答待ち</div>
      )}
      {nextAction(entry).indexOf("二次面談日時選択") !== -1 && (
        <ToDoItem linkPath={`${schedulePath}?entry_id=${entry.id}`} value="二次面談の日時を確定" />
      )}
      {nextAction(entry).indexOf("二次面談日程調整済") !== -1 && (
        <ToDoItem
          linkPath={`${secondInterviewPath}/${entry.id}`}
          value={
            <>
              面談ページにアクセス
              <span className={style.todoTextDate}>（{monthTimeStr(entry.second_interview?.meeting_at)}）</span>
            </>
          }
        />
      )}
      {nextAction(entry).indexOf("二次面談終了済") !== -1 && (
        <div className={style.waiting}>契約希望有無の回答待ち</div>
      )}
      {nextAction(entry).indexOf("契約希望有無選択") !== -1 && (
        <ToDoItem linkPath={`${entryPath}/${entry.id}`} value="契約の希望有無を回答" />
      )}
      {/* このケースは起こり得ないので削除 */}
      {nextAction(entry).indexOf("二次面談日時調整") !== -1 && (
        <ToDoItem linkPath={`${secondInterviewTodosPath}/${entry.id}`} value="二次面談までに対応することを確認" />
      )}
      {nextAction(entry).indexOf("回答結果確認") !== -1 && (
        <ToDoItem linkPath={`${entryPath}/${entry.id}`} value="一次面談結果の回答を確認" />
      )}
      {nextAction(entry).indexOf("二次面談回答結果確認") !== -1 && (
        <ToDoItem linkPath={`${entryPath}/${entry.id}`} value="二次面談結果の回答を確認" />
      )}
      {nextAction(entry).indexOf("契約合意") !== -1 && (
        <div className="mb4">クラウドサインからの契約書にサイン（メールをご確認ください）</div>
      )}
      {isNotGuidanceMeeting && (
        <ToDoItem
          linkPath={`${guidanceMeetingPath}`}
          value="ガイダンス会議の予約（重要な会議になりますので必ず予約してください）"
          target_blank={true}
        />
      )}
    </>
  )
}
