import {Actions, TypeKeys} from "../actions";
import {IAjaxErrorDetails} from "../types/state";

const initialState: IAjaxErrorDetails = {};

const error = (state = initialState, action: Actions) => {

    switch (action.type) {
        case TypeKeys.INTERNAL_SERVER_ERROR: {
            return action.payload.error
        }
        default: {
            return state;
        }
    }
};

export default error;