import { Link } from "react-router-dom";
import { homePath } from "../../routes/paths";
import * as React from "react";

export const PartnerManagementHeader = () => {
  return (
    <Link to={homePath} className="categoryTitle categoryTitleManagement">
      人材管理
    </Link>
  );
};
