import * as React from "react";
import { Box } from "@material-ui/core";
import LabelIndex from "./LabelIndex";

export interface IIndexedLabel {
  index: number;
  required?: boolean;
  children?: React.ReactNode;
}

export const IndexedLabel = (props: IIndexedLabel) => {
  return (
    <Box style={{ margin: "0px 0px 15px 0", fontWeight: "bold" }}>
      <LabelIndex index={props.index} />
      {props.children}
      {!!props.required && <RequiredTag />}
    </Box>
  );
};

const RequiredTag = () => {
  return (
    <span className="label label_req" style={{ margin: 0 }}>
      必須
    </span>
  );
};
