import { combineReducers } from "redux"
import auth from "./auth"
import registrant from "./registrant"
import company from "./company"
import recruitment from "./recruitment"
import identifier from "./identifier"
import entries from "./entries"
import notices from "./notices"
import rooms from "./rooms"
import availableSchedules from "./availableSchedules"
import partners from "./partners"
import introducers from "./introducers"
import news from "./news"
import staffs from "./staffs"
import staffActivation from "./staffActivation"
import firstPsychologicalSafeties from "./firstPsychologicalSafeties"
import viewState from "./viewState"
import modal from "./modal"
import announcements from "./announcements"
import prefectures from "./prefectures"
import problemCategories from "./problemCategories"
import problemCategoryDetails from "./problemCategoryDetails"
import { licenseCategories } from "./licenseCategories"
import { licenses } from "./licenses"
import { connectRouter } from "connected-react-router"
import error from "./error"
import companyVerification from "./companyVerification"
import { monthlyQuestionnaire } from "./monthlyQuestionnaire"

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    error,
    auth,
    identifier,
    notices,
    modal,
    company,
    registrant,
    recruitment,
    entries,
    rooms,
    partners,
    introducers,
    availableSchedules,
    news,
    staffs,
    staffActivation,
    firstPsychologicalSafeties,
    announcements,
    prefectures,
    problemCategories,
    problemCategoryDetails,
    licenseCategories,
    licenses,
    viewState,
    companyVerification,
    monthlyQuestionnaire
  })
