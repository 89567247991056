import * as React from "react"
import { push } from "connected-react-router"
import { Dispatch } from "redux"
import { Actions, ISignUpParams, IUpdateRegistrantParams, IUpdateCompanyParams } from "../actions"
import {
  homePath,
  resetPasswordFinishPath,
  companyPath,
  checkTrustPath,
  loginPath,
  renewalPasswordFinishPath,
  editCompanyPath,
  registrantPath,
  registerThanksPath,
  staffsPath,
  firstPsychologicalSafetyPath,
  entryFinishPath,
  secondInterviewRequestFinishPath,
  entryCancelFinishPath
} from "../routes/paths"
import {
  IStoreState,
  IStaff,
  IFirstPsychologicalSafety,
  IEntry,
  IQuestionnaireState,
  IAnnouncement
} from "../types/state"
import SignupThanks from "../components/modals/SignupThanks"
import Alert from "../components/modals/Alert"
import Confirm from "../components/modals/Confirm"
import { Announcement } from "../components/modals/Announcement"
import Contract from "../components/modals/Contract"
import { StaffActions } from "../actions/staff"
import { ProhibitedMatters } from "../components/modals/ProhibitedMatters"
import { StartMeetingModal } from "../components/modals/StartMeetingModal"
import { EntryActions } from "../actions/entry"

export function signUp(values: ISignUpParams) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.signUp(values) as any)
        .then((res: any) => {
          dispatch(push(editCompanyPath + "?ev=signup"))
          dispatch(
            openModal({
              component: (props: any) => <SignupThanks {...props} />
            })
          )
          resolve()
          localStorage.setItem("joins_company_session_id", res.payload.data.auth.sessionID)
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function login(values: { nextPath: string; email: string; password: string }) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(Actions.login({ email: values.email, password: values.password }) as any)
        .then((res: any) => {
          dispatch(push(values.nextPath))
          localStorage.setItem("joins_company_session_id", res.payload.data.auth.sessionID)
        })
        .then(() => {
          dispatch(Actions.initialData())
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function logout() {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(Actions.logout() as any)
        .then(() => {
          dispatch(push(loginPath))
          resolve()
          localStorage.removeItem("joins_company_session_id")
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function resetPassword(values: { email: string }) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.resetPassword(values.email) as any)
        .then(() => {
          dispatch(push(resetPasswordFinishPath))
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function renewalPassword(values: { uuid: string; password: string }) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.renewalPassword(values) as any)
        .then(() => {
          dispatch(push(renewalPasswordFinishPath))
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function activateStaff(values: { uuid: string; name: string; password: string }) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(StaffActions.activateStaff(values) as any)
        .then((res: any) => {
          return dispatch(
            login({
              nextPath: homePath,
              email: res.payload.data.email,
              password: values.password
            })
          )
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function updateRegistrant(isUpdate: boolean, values: IUpdateRegistrantParams) {
  return (dispatch: Dispatch<any>, getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true, isEdit: false }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.updateRegistrant(values) as any)
        .then(() => {
          if (!isUpdate && getState().company.industries && getState().recruitment.problems.length > 0) {
            dispatch(push(registerThanksPath))
          } else {
            dispatch(push(registrantPath))
          }
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function updateCompany(isUpdate: boolean, values: IUpdateCompanyParams) {
  return (dispatch: Dispatch<any>, getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true, isEdit: false }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.updateCompany(values) as any)
        .then(() => dispatch(Actions.fetchCompany(values.id) as any))
        .then(() => {
          if (!isUpdate && getState().registrant.department && getState().recruitment.problems.length > 0) {
            dispatch(push(registerThanksPath))
          } else if (!isUpdate) {
            dispatch(push(checkTrustPath + "?ev=signup"))
          } else {
            dispatch(push(companyPath))
          }
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function updateStaffs(staffs: IStaff[]) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true, isEdit: false }))
    return new Promise<{}>((resolve, reject) => {
      dispatch(StaffActions.updateStaffs(staffs) as any)
        .then(() => {
          dispatch(push(staffsPath))
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function createScout(partnerID: number, problemId: number, message: string) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(Actions.createScout(partnerID, problemId, message) as any)
        .then(() => {
          dispatch(push(homePath))
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function createWebMeeting(args: {
  start_at: Date
  entry_id: number
  way: string
  kind: string
  video_type?: string
}) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(Actions.createWebMeeting(args) as any)
        .then((res: any) => resolve(res))
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function fetchPsychologicalSafety() {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(Actions.fetchPsychologicalSafety() as any)
        .then(() => resolve())
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function updateFirstPsychologicalSafety(firstPsychologicalSafety: IFirstPsychologicalSafety) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true, isEdit: false }))
      dispatch(Actions.updateFirstPsychologicalSafety(firstPsychologicalSafety) as any)
        .then(() => {
          dispatch(push(firstPsychologicalSafetyPath))
          resolve()
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function fetchEntryFirstPsychologicalSafeties(entry_id: number) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(Actions.fetchEntryFirstPsychologicalSafeties(entry_id) as any)
        .then((res: any) => resolve(res))
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function updateEntry(entry: IEntry) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(EntryActions.updateEntry(entry) as any)
        .then((res: any) => {
          dispatch(push(`${entryFinishPath}/${entry.id}`))
          resolve(res)
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function cancelEntry(entry: IEntry) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(EntryActions.updateEntry(entry) as any)
        .then((res: any) => {
          dispatch(push(`${entryCancelFinishPath}/${entry.id}`))
          resolve(res)
        })
        .catch((e: any) => reject(e))
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function submitQuestionnaire(questionnaire: IQuestionnaireState) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        await (dispatch(Actions.createQuestionnaire(questionnaire)) as any)
        dispatch(Actions.setViewState({ loaderVisible: false }))
        dispatch(push(`${entryFinishPath}/${questionnaire.entry_id}`))
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
}

export function submitFirstInterviewQuestionnaire(questionnaire: IQuestionnaireState) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        await (dispatch(Actions.createQuestionnaire(questionnaire)) as any)
        dispatch(Actions.setViewState({ loaderVisible: false }))
        dispatch(push(`${secondInterviewRequestFinishPath}/${questionnaire.entry_id}`))
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
}

export type ModalArgs = {
  component: any
  content?: any
  onClose?: (result?: any) => void
  shouldCloseOnOverlayClick?: boolean
  style?: object
}

export function openModal(args: ModalArgs) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve) => {
      return dispatch(
        Actions.setModal({
          isOpen: true,
          component: args.component,
          content: args.content,
          close: (params?: any) => {
            if (args.onClose) {
              args.onClose(params)
            }
            dispatch(
              Actions.setModal({
                isOpen: false,
                component: null,
                content: null,
                close: () => {},
                shouldCloseOnOverlayClick: args.shouldCloseOnOverlayClick ?? true,
                style: args.style
              })
            )
            resolve(params)
          },
          shouldCloseOnOverlayClick: args.shouldCloseOnOverlayClick ?? true,
          style: args.style
        })
      ) as any
    })
  }
}

export function showAlert(content: any) {
  return openModal({
    component: (props: any) => <Alert {...props} />,
    content
  })
}

export function showConfirm(content: any) {
  return openModal({
    component: (props: any) => <Confirm {...props} />,
    content
  })
}

export function showAnnouncement(announcement: IAnnouncement, onClose?: (result: boolean | null) => void) {
  return openModal({
    component: (props: any) => <Announcement {...props} />,
    content: { announcement },
    shouldCloseOnOverlayClick: false,
    onClose
  })
}

export function showContract(content: any) {
  return openModal({
    component: (props: any) => <Contract {...props} />,
    content
  })
}

export function showProhibitedMatters(closeOnly?: boolean) {
  return openModal({
    component: (props: any) => <ProhibitedMatters {...props} closeOnly={closeOnly} />
  })
}

export function showStartMeetingModal(meetingUrl: string) {
  return openModal({
    component: (props: any) => <StartMeetingModal {...props} meetingUrl={meetingUrl} />,
    style: { width: "unset" }
  })
}
