import { Actions, TypeKeys } from "../actions";
import { IFirstPsychologicalSafety } from "../types/state";

const initialState: IFirstPsychologicalSafety[] = [];

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES_SUCCESS: {
      return action.payload.data;
    }
    case TypeKeys.FETCH_PSYCHOLOGICAL_SAFETY_SUCCESS: {
      return action.payload.data.first_psychological_safety.id
        ? [{ ...action.payload.data.first_psychological_safety, isSelf: true }]
        : [];
    }
    case TypeKeys.UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_SUCCESS: {
      return [
        { ...action.payload.data.first_psychological_safety, isSelf: true },
      ];
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
