import * as React from "react";
import { withRouter } from "react-router-dom";

type ContextState = {
  scrollTo(scrollID: string): void;
};

export const ScrollContext = React.createContext<ContextState>({
  scrollTo: (_scrollID: string) => {}
});

function scrollToId(id: string, time: number) {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo(0, element.offsetTop - 80);
    }
  }, time);
}

function scrollToNow(scrollID: string) {
  scrollToId(scrollID, 0);
}

class ScrollToTop extends React.Component<any, any> {
  componentDidMount() {
    const id = window.location.hash;
    if (id) {
      scrollToId(id.replace("#", ""), 200);
    }
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      const id = window.location.hash;
      if (id) {
        scrollToId(id.replace("#", ""), 0);
      } else if (
        !(
          this.props.location.pathname === "/" &&
          prevProps.location.pathname.match("/entry/\\d+$")
        )
      ) {
        setTimeout(() => window.scrollTo(0, 0), 0);
      }
    }
    if (this.props.scrollID) {
      scrollToId(this.props.scrollID, 0);
      this.props.clearScrollID();
    }
  }
  render() {
    return (
      <ScrollContext.Provider value={{ scrollTo: scrollToNow }}>
        {this.props.children}
      </ScrollContext.Provider>
    );
  }
}

export default withRouter(ScrollToTop as any) as any;
