import { ConfirmUpdateRecruitment } from "../components/modals/recruitment/ConfirmUpdateRecruitment"
import * as React from "react"
import { openModal } from "./index"
import { ConfirmCreateProblem } from "../components/modals/recruitment/ConfirmCreateProblem"
import { ConfirmDeleteRecruitment } from "../components/modals/recruitment/ConfirmDeleteRecruitment"
import { ICreateProblemParams, IUpdateProblemParams, ProblemActions } from "../actions/problem"
import { Dispatch } from "redux"
import { IAjaxError, IStoreState } from "../types/state"
import { Actions } from "../actions"
import { push } from "connected-react-router"
import { recruitmentPath, registerThanksPath } from "../routes/paths"
import { IProblemFormState } from "../components/ProblemForm"

export function createProblem(params: ICreateProblemParams) {
  return (dispatch: Dispatch<any>, getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }))
    const isFirstProblem = getState().recruitment.problems.length === 0
    return new Promise<{} | void>((resolve, reject) => {
      dispatch(ProblemActions.createProblem(params) as any)
        .then(() => {
          const isRegistrantInfoRegistered = !!getState().registrant.department
          const isCompanyInfoRegistered = !!getState().company.industries
          const nextPath =
            isFirstProblem && isRegistrantInfoRegistered && isCompanyInfoRegistered
              ? registerThanksPath
              : recruitmentPath
          dispatch(push(nextPath))
          resolve()
        })
        .catch((e: IAjaxError) => {
          dispatch(
            Actions.setViewState({
              error: e.payload.error.message,
              errorField: e.payload.error.field,
              scrollID: e.payload.error.field || "root"
            })
          )
          reject(e)
        })
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function updateProblem(params: IUpdateProblemParams) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }))
    return new Promise<{} | void>((resolve, reject) => {
      dispatch(ProblemActions.updateProblem(params) as any)
        .then(() => {
          dispatch(push(recruitmentPath))
          resolve()
        })
        .catch((e: IAjaxError) => {
          dispatch(
            Actions.setViewState({
              error: e.payload.error.message,
              errorField: e.payload.error.field,
              scrollID: e.payload.error.field || "root"
            })
          )
          reject(e)
        })
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function deleteProblem(problemId: number) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true, error: "", errorField: "", scrollID: "" }))
    return new Promise<{} | void>((resolve, reject) => {
      dispatch(ProblemActions.deleteProblem(problemId) as any)
        .then(() => {
          dispatch(push(recruitmentPath))
          resolve()
        })
        .catch((e: IAjaxError) => {
          dispatch(
            Actions.setViewState({
              error: e.payload.error.message,
              errorField: e.payload.error.field,
              scrollID: e.payload.error.field || "root"
            })
          )
          reject(e)
        })
        .finally(() => dispatch(Actions.setViewState({ loaderVisible: false })))
    })
  }
}

export function showConfirmCreateProblem(content: { problemForm: IProblemFormState }) {
  return openModal({
    component: (props: any) => <ConfirmCreateProblem {...props} />,
    content
  })
}

export function showConfirmUpdateProblem(content: { problemForm: IProblemFormState }) {
  return openModal({
    component: (props: any) => <ConfirmUpdateRecruitment {...props} />,
    content
  })
}

export function showConfirmDeleteProblem(content: { problemId: number }) {
  return openModal({
    component: (props: any) => <ConfirmDeleteRecruitment {...props} />,
    content
  })
}
