import * as React from "react"
import style from "../../style/InterviewInfo.module.scss"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { showProhibitedMatters } from "../../thunks"
import clsx from "clsx"

export const ProhibitedMattersInfo = () => {
  const dispatch = useDispatch()

  const onClickLink = useCallback(() => {
    dispatch(showProhibitedMatters(true))
  }, [dispatch])

  return (
    <>
      <h3 className="contentsSubTitle">JOINSでの禁止行為</h3>
      <div className={clsx(style.prohibitedMatters, "mb32mb16")}>
        <div className={style.prohibitedMattersContent}>
          <p className={style.prohibitedMattersContentDetailWrap}>
            <span className={style.prohibitedMattersContentAlert}>
              JOINSでは、より安全なマッチングを行うために、直接契約などの不正行為を禁止しております。
            </span>
          </p>
          <p className={style.prohibitedMattersContentDetailWrap}>
            <span className={style.prohibitedMattersContentDetail}>
              ※禁止行為の詳細は
              <span className="link" onClick={onClickLink}>
                こちら
              </span>
              よりご確認ください。
            </span>
          </p>
        </div>
      </div>
    </>
  )
}
