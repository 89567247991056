import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import ajax from "../middlewares/ajax";
import saveState from "../middlewares/saveState";
import createRootReducer from "../reducers";
import sendAction from "../middlewares/sendAction";

export interface ICustomWindow extends Window {
  // tslint:disable-next-line
  devToolsExtension?: () => any;
  __REDUX_DEVTOOLS_EXTENSION__?: () => any;
}
declare let window: ICustomWindow;
const devToolsExtension =
  // tslint:disable-next-line
  (window.devToolsExtension && window.devToolsExtension())
  // for ReduxDevTools (https://github.com/reduxjs/redux-devtools/)
  || (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  || ((f: any) => f);

export function getStoreAndHistory() {
  const history = createBrowserHistory({ basename: "/app_companies/" });
  // WebSocketによる動的にリロードさせたい時等でFrontだけでTestする場合
  //const history = createBrowserHistory();
  const store = createStore(
    createRootReducer(history), // new root reducer with router state
    {},
    compose(
      applyMiddleware(
        thunk,
        ajax,
        saveState,
        sendAction,
        routerMiddleware(history)
      ),
      devToolsExtension
    )
  );
  return { store, history };
}
