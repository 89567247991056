import { TypeKeys, Actions } from "../actions";

export default (state = { uid: "", gid: "" }, action: Actions) => {
  switch (action.type) {
    case TypeKeys.SET_UID: {
      return { uid: action.payload.uid, gid: action.payload.gid };
    }
    default: {
      return state;
    }
  }
};
