import * as React from "react";
import { IPartner } from "../types/state";
import style from "../style/Recruitment.module.scss";
import { scoutTypeStr } from "../lib/Util"

export const PartnerScoutInfo = (props: { partner: IPartner }) => {
  if (!props.partner.scouts?.length) return null

  return (
    <>
      <h3 className="contentsSubTitle">スカウト／再申し込み情報</h3>
      {props.partner.scouts.map(scout => {
        return (
          <div key={scout.id} className={style.listArea}>
            {scout.problem && (
              <div className={style.listRow}>
                <div className={style.listHead}>対象の募集案件</div>
                <div className={style.listData}>{scout.problem.title}</div>
              </div>
            )}
            <div className={style.listRow}>
              <div className={style.listHead}>
                {scoutTypeStr(scout.scout_type)}メッセージ
              </div>
              <div className={style.listData}>{scout.message}</div>
            </div>
            {scout.reply && (
              <div className={style.listRow}>
                <div className={style.listHead}>
                  パートナー人材からの<br className="SPHide" />
                  メッセージ
                </div>
                <div className={style.listData}>{scout.reply}</div>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}
