import * as React from "react";
import { Link } from "react-router-dom";
import { recruitmentPath } from "../../routes/paths";
import { Wrapper } from "./Wrapper";
import styles from "../../style/CheckTrustForm.module.scss";

export const CompletedTrust = () => {
  return (
    <Wrapper>
      <h2>審査が完了しました。</h2>
      <div className={styles.completeTrust}>
        <p className={styles.completeTrustBody}>
          募集案件の作成が可能となりました。
        </p>
        <p className={styles.completeTrustLink}>
          <Link to={recruitmentPath}>募集案件概要ページへ ≫</Link>
        </p>
      </div>
    </Wrapper>
  );
};
