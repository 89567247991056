import * as React from "react";
import style from "../style/Footer.module.scss";

export default function Footer(props: {
  isAuthenticated: boolean;
  logout: () => void;
}) {
  return (
    <footer>
      <nav>
        <ul className={style.footerMenu}>
          <li>
            <a
              href={`${process.env.REACT_APP_ROOT_URL}/company`}
              target="_blank"
              rel="noopener noreferrer"
            >
              会社概要
            </a>
          </li>
          <li>
            <a
              href={`${process.env.REACT_APP_ROOT_URL}/policies/company`}
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報保護方針・利用規約
            </a>
          </li>
          <li>
            <a
              href="https://joins-company.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ご利用ガイド
            </a>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_INQUIRY_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              お問い合わせ
            </a>
          </li>
          <li>
            {props.isAuthenticated && (
              <span onClick={props.logout}>ログアウト</span>
            )}
          </li>
        </ul>
      </nav>
      <p className={style.copyright}>
        Copyright © 2018 JOINS inc. All Rights Reserved.
      </p>
    </footer>
  );
}
