import * as React from "react"
import style from "../../style/Recruitment.module.scss"
import { ContractConditions, IEntry } from "../../types/state"
import { ContractConditionsEntry } from "../ContractConditionsEntry"
import storedUseState from "../../lib/storedUseState"
import { ScrollContext } from "../../containers/ScrollToTop"
import { ContractConditionsForm } from "./ContractConditionsForm"
import { EMPTY_APOLOGY, ENTRY_STATUS } from "../../lib/Definition"
import { dateStr, isValidEmail, isValidName } from "../../lib/Util"

export interface IContractInfoDispatchProps {
  alert: (content: string) => Promise<void>
  confirmContract: (startDate: string) => Promise<boolean>
  acceptContract: (entry: IEntry, contractConditions: ContractConditions) => void
  reject: (entry: IEntry, apology: string) => void
}
export interface IContractInfoStateProps {
  entry: IEntry
  registrantEmail: string
}

export interface IContractInfoProps extends IContractInfoDispatchProps, IContractInfoStateProps {}

export const ContractInfo = (props: IContractInfoProps) => {
  const ctx = React.useContext(ScrollContext)

  const [state, setState] = storedUseState("EntryInfo", {
    showRejectContractForm: false,
    contractCompleted: false,
    contractIsSubmitted: false,
    isAvailableSubmit: false,
    isValidContractCondition: false,
    apology: EMPTY_APOLOGY
  })

  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)

  const [contractConditions, setContractConditions] = React.useState<ContractConditions>({
    condition_work_time: props.entry.condition_work_time,
    condition_start_at: new Date(
      props.entry.condition_start_at.getFullYear(),
      props.entry.condition_start_at.getMonth(),
      props.entry.condition_start_at.getDate()
    ),
    contract_work_time: props.entry.condition_work_time,
    contract_start_at: props.entry.contract_start_at
      ? new Date(
          props.entry.contract_start_at.getFullYear(),
          props.entry.contract_start_at.getMonth(),
          props.entry.contract_start_at.getDate()
        )
      : null,
    contract_amendment: "",
    contract_email: props.registrantEmail || props.entry.contract_email,
    billing_name: "",
    billing_email: ""
  })

  return (
    <>
      {(props.entry.second_interview_date || props.entry.entry_status === ENTRY_STATUS.second_interview_skipped) &&
        !props.entry.declined_by && (
          <ContractConditionsEntry
            entry={props.entry}
            before_saved={[ENTRY_STATUS.contract_requested, ENTRY_STATUS.second_interview_skipped].includes(
              props.entry.entry_status
            )}
          />
        )}
      {[ENTRY_STATUS.contract_requested, ENTRY_STATUS.second_interview_skipped].includes(props.entry.entry_status) &&
        !props.entry.declined_by &&
        !state.showRejectContractForm &&
        !state.contractCompleted && (
          <div className={style.entrySecondInterviewResultFormWrapper}>
            <ContractConditionsForm
              entry={props.entry}
              isSubmitted={isSubmitted}
              contract_conditions={contractConditions}
              updateState={(update: ContractConditions, isValid: boolean) => {
                setState({ ...state, isValidContractCondition: isValid })
                setContractConditions(update)
              }}
            />
            <div className="formBtnArea">
              <button
                className="btn btnEdit"
                type="submit"
                onClick={() => {
                  ctx.scrollTo("js-ContractRejectForm")
                  setState({ ...state, showRejectContractForm: true })
                }}
              >
                契約しない
              </button>
              <button
                className="btn btnSave"
                type="submit"
                onClick={() => {
                  if (
                    !isValidEmail(contractConditions["contract_email"]) ||
                    !isValidName(contractConditions["billing_name"]) ||
                    !isValidEmail(contractConditions["billing_email"]) ||
                    !contractConditions["contract_amendment"] ||
                    !state.isValidContractCondition
                  ) {
                    setIsSubmitted(true)
                    props.alert("入力に誤りがあります")
                    return
                  }
                  props.confirmContract(dateStr(contractConditions["contract_start_at"])).then((isOK) => {
                    if (isOK) {
                      props.acceptContract(props.entry, contractConditions)
                    }
                  })
                }}
              >
                契約する
              </button>
            </div>
          </div>
        )}

      {state.showRejectContractForm && !state.contractCompleted && (
        <div id="js-ContractRejectForm" className="mt40mt20">
          <form
            className="pure-form"
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault()
              ctx.scrollTo("js-ContractRejectConfirm")
              setState({ ...state, contractCompleted: true })
            }}
          >
            <h3 className="contentsSubTitle" id="js-ContractReject">
              {props.entry.partner.last_name + " " + props.entry.partner.first_name}
              様との契約辞退
            </h3>
            <div className={style.scoutInfo}>
              <div className={style.scoutInfoText}>
                応募者に対して
                <b className="joinsPinkTxt">貴社からの契約辞退のお知らせ</b>
                を通知いたします。
                <br />
                辞退の理由など、応募者に特に伝えたいメッセージがあれば下記にご記入のうえ、「送信内容を確認する」ボタンを押してください。
                <span className="grayTxt">（メッセージがなければ未記入のままボタンを押してください。）</span>
              </div>
              <div className="pure-form">
                <div className="formRow formRowSolo">
                  <label className="formHead">
                    応募人材へのメッセージ
                    <span className="label label_opt">任意</span>
                  </label>
                  <div className="formData">
                    <textarea
                      className="textarea"
                      onChange={(e) => {
                        setState({ ...state, apology: e.target.value })
                      }}
                      value={state.apology === EMPTY_APOLOGY ? " " : state.apology}
                    />
                  </div>
                </div>
                <div className="formBtnArea">
                  <button
                    className="btn btnEdit"
                    onClick={() => {
                      setState({
                        ...state,
                        showRejectContractForm: false,
                        contractIsSubmitted: false
                      })
                    }}
                  >
                    キャンセル
                  </button>
                  <button className="btn btnSave">入力内容を確認する</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {state.showRejectContractForm && state.contractCompleted && (
        <div className="mt40mt20">
          <h3 className="contentsSubTitle" id="js-ContractRejectConfirm">
            {props.entry.partner.last_name + " " + props.entry.partner.first_name}
            様との契約辞退
          </h3>
          <div className={style.rejectSecondInterviewFormWrapper}>
            <form
              className="pure-form"
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault()
                props.reject(props.entry, state.apology)
              }}
            >
              <div className={style.scoutInfoText}>
                以下の内容でよろしければ、
                <b className="joinsPinkTxt">送信するボタン</b>
                をクリックしてください。
              </div>
              <div className={style.listRow + " " + style.listRowNoBorder + " mb40mb32"}>
                <div className={style.listHead}>応募者へのメッセージ</div>
                <div className={style.listData}>
                  {state.apology.split("\n").map((m, idx) => (
                    <div key={idx}>{m}</div>
                  ))}
                </div>
              </div>
              <div className="formBtnArea">
                <button className="btn btnEdit" onClick={() => setState({ ...state, contractCompleted: false })}>
                  キャンセル
                </button>
                <button type="submit" className="btn btnSave">
                  送信する
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}
