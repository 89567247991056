import * as React from "react";
import { formatHyphenStr } from "../lib/Util"
import { ErrorMessage } from "./common/ErrorMessage"

export const LocationField = (props: {
  values: {
    isSubmitted: boolean;
    zip_code: string;
    prefecture: string;
    address1: string;
    address2: string;
  };
  validates: { [key: string]: (value: any) => string | null };
  updateValue: (args: { [key: string]: string }) => void;
}) => (
  <div className="formRow">
    <label className="formHead">
      会社の所在地
      <br className="SPHide" />
      （本店所在地）
      <span className="label label_req">必須</span>
    </label>

    <div className="formData locationWrap">
      <div className="formDataBox">
        <label className="pure-u-1">
          郵便番号
          <small className="formData_inline">ハイフンなし 半角数字</small>
        </label>
        <input
          type="text"
          id="zip_code"
          name="zip_code"
          value={props.values["zip_code"]}
          onBlur={() => {
            const zipCode = formatHyphenStr(props.values["zip_code"]);
            if (zipCode !== props.values["zip_code"]) {
              props.updateValue({ zip_code: zipCode });
            }
          }}
          onChange={(e: React.ChangeEvent<any>) => {
            props.updateValue({
              zip_code: e.currentTarget.value,
            });
          }}
        />
        <div className="unknown">
          郵便番号がわからない方は
          <a
            href="https://www.post.japanpost.jp/zipcode/index.html"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            コチラ
          </a>
        </div>
        {props.values.isSubmitted && props.validates["zip_code"] && (
          <ErrorMessage cls="mb08">
            {props.validates["zip_code"](props.values)}
          </ErrorMessage>
        )}
        {props.values.isSubmitted && props.validates["prefecture"] && (
          <ErrorMessage cls="mb08">
            {props.validates["prefecture"](props.values)}
          </ErrorMessage>
        )}
      </div>

      <div className="formDataBox">
        <label className="pure-u-1">都道府県</label>
        <div className="address formWidth_m" id="prefecture">
          {props.values["prefecture"]}
        </div>
      </div>

      <div className="formDataBox">
        <label className="pure-u-1">市区町村</label>
        <input
          type="text"
          id="address1"
          name="address1"
          className="formWidth_m"
          value={props.values["address1"]}
          onChange={(e: React.ChangeEvent<any>) => {
            props.updateValue({ address1: e.currentTarget.value });
          }}
        />
        {props.values.isSubmitted && props.validates["address1"] && (
          <ErrorMessage cls="mb08">
            {props.validates["address1"](props.values)}
          </ErrorMessage>
        )}
      </div>
      <div className="formDataBox">
        <label className="pure-u-1">市区町村以下</label>
        <input
          type="text"
          id="address2"
          name="address2"
          className="formWidth_m"
          value={props.values["address2"]}
          onChange={(e: React.ChangeEvent<any>) => {
            props.updateValue({ address2: e.currentTarget.value });
          }}
        />
        {props.values.isSubmitted && props.validates["address2"] && (
          <ErrorMessage cls="mb08">
            {props.validates["address2"](props.values)}
          </ErrorMessage>
        )}
      </div>
    </div>
  </div>
);
