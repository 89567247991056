import { TypeKeys, Actions } from "../actions";
import { IRoom, IMessage } from "../types/state";
import DateWithOffset from "../lib/DateWithOffset";

const initialState: IRoom[] = [];

const chats = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.FETCH_ROOMS_SUCCESS: {
      return action.payload.data.rooms.map((room: IRoom) => {
        return {
          ...room,
          updated_at: new DateWithOffset(room.updated_at),
          messages: [],
        };
      });
    }
    case TypeKeys.LOGIN_SUCCESS:
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.rooms.map((room: IRoom) => {
        return {
          ...room,
          updated_at: new DateWithOffset(room.updated_at),
          messages: [],
        };
      });
    }
    case TypeKeys.FETCH_MESSAGES_SUCCESS: {
      if (action.payload.data.messages.length === 0) {
        return state;
      }
      const roomID = action.payload.data.messages[0].room_id;
      return state.map((room: IRoom) => {
        return room.id === roomID
          ? {
              ...room,
              messages: action.payload.data.messages.map(
                (message: IMessage) => {
                  return {
                    ...message,
                    created_at: new DateWithOffset(message.created_at),
                  };
                }
              ),
            }
          : room;
      });
    }
    case TypeKeys.FETCH_MESSAGES_NEWER_SUCCESS: {
      if (action.payload.data.messages.length === 0) {
        return state;
      }
      const roomID = action.payload.data.messages[0].room_id;
      return state.map((room: IRoom) => {
        return room.id === roomID
          ? {
              ...room,
              messages: room.messages.concat(
                action.payload.data.messages.map((message: IMessage) => {
                  return {
                    ...message,
                    created_at: new DateWithOffset(message.created_at),
                  };
                })
              ),
            }
          : room;
      });
    }
    case TypeKeys.CREATE_MESSAGE_SUCCESS: {
      return state.map((room: IRoom) => {
        return room.id === action.payload.data.room_id
          ? {
              ...room,
              messages: [
                ...room.messages,
                {
                  ...action.payload.data,
                  created_at: new DateWithOffset(
                    action.payload.data.created_at
                  ),
                },
              ],
            }
          : room;
      });
    }
    case TypeKeys.DELETE_MESSAGE_SUCCESS: {
      return state.map((room: IRoom) => {
        return room.id === action.payload.data.room_id
          ? {
              ...room,
              messages: room.messages.map((message) => {
                return message.id === action.payload.data.id
                  ? {
                      ...action.payload.data,
                      created_at: new DateWithOffset(
                        action.payload.data.created_at
                      ),
                    }
                  : message;
              }),
            }
          : room;
      });
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default chats;
