import * as React from "react";
import style from "../style/flow.module.scss";

export const ProceduralFlow = () => {
  return (
    <>
      <div className={style.flowArea}>
        <h3 className={style.flowAreaTitle}>手続きの流れ</h3>
        <div
          className={style.flowVideoContent}
          dangerouslySetInnerHTML={{
            __html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/415766727?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`,
          }}
        />

        <ul className={style.flow}>
          <li className={style.flowList + " " + style.flowListPale2}>
            JOINS担当者の確認を待つ
            <br className="PCHide" />
            （確認期間：３営業日程度）
          </li>
          <li className={style.flowList + " " + style.flowListArrow2}>
            パートナー人材からの応募を待つ
            <br />
            <div className={style.flowListNotice}>
              応募があった場合は
              <br className="PCHide" />
              EメールおよびWeb上で通知
            </div>
          </li>
          <li
            className={
              style.flowList +
              " " +
              style.flowListPale2 +
              " " +
              style.flowListArrow
            }
          >
            応募内容を検討する
            <br className="PCHide" />
            （回答期限：応募期間終了から１週間）
          </li>
          <li className={style.flowList + " " + style.flowListArrow2}>
            「面談希望する」もしくは
            <br className="PCHide" />
            「面談希望しない」ボタンクリック
            <br />
            <div className={style.flowListNotice}>
              回答内容は人材側に通知されます。
              <br />
              ２週間以内に回答がない場合も、
              <br className="PCHide" />
              「面談希望なし」と通知されますので
              <br className="PCHide" />
              ご注意ください。
            </div>
          </li>
          <li
            className={
              style.flowList +
              " " +
              style.flowListPale2 +
              " " +
              style.flowListArrow
            }
          >
            JOINS担当者による
            <br className="PCHide" />
            会議日程調整の
            <br className="PCHide" />
            連絡メールを待つ
            <br />
            <div className={style.flowListNotice}>
              JOINS運営事務局が代行して
              <br className="PCHide" />
              貴社とパートナー人材側の
              <br className="PCHide" />
              面談日程調整を行います。
            </div>
          </li>
          <li className={style.flowList + " " + style.flowListArrow2}>
            一次面談（Web会議）をする
          </li>
          <li
            className={
              style.flowList +
              " " +
              style.flowListPale2 +
              " " +
              style.flowListArrow
            }
          >
            二次面談希望の有無をJOINSに
            <br className="PCHide" />
            回答し、パートナー人材からの回答を待つ。
            <br />
            （回答期限：面談から原則２週間程度）
          </li>
          <li className={style.flowList + " " + style.flowListArrow2}>
            二次面談（現地会議もしくはWeb会議）をする
            <br />
            ※現地までの交通費は企業負担
          </li>
          <li
            className={
              style.flowList +
              " " +
              style.flowListPale2 +
              " " +
              style.flowListArrow
            }
          >
            契約締結希望の有無を
            <br className="PCHide" />
            JOINSに回答し、
            <br className="PCHide" />
            パートナー人材からの回答を待つ。
            <br />
            （回答期限：面談から原則２週間程度）
          </li>
          <li
            className={
              style.flowList +
              " " +
              style.flowListArrow2
            }
          >
            契約合意・業務開始へ
          </li>
        </ul>
      </div>
    </>
  );
};
