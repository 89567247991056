import * as React from "react";
import style from "../../style/Recruitment.module.scss";
import { IEntry } from "../../types/state";
import { ScrollContext } from "../../containers/ScrollToTop";
import storedUseState from "../../lib/storedUseState";
import DateWithOffset from "../../lib/DateWithOffset";
import { EMPTY_APOLOGY, ENTRY_STATUS } from "../../lib/Definition";

export interface IInterviewRejectDispatchProps {
  reject: (entry: IEntry, apology: string) => void;
  close: () => void;
}

export interface IInterviewRejectStateProps {
  entry: IEntry;
}

export interface IInterviewRejectProps extends IInterviewRejectDispatchProps, IInterviewRejectStateProps {}

export const InterviewReject = (props: IInterviewRejectProps) => {
  const [state, setState] = storedUseState("Interview", {
    completed: false,
    isSubmitted: false,
    apology: EMPTY_APOLOGY,
  });
  const ctx = React.useContext(ScrollContext);

  // 一次面談終了後ステータスがfirst_interview_endに変わるまでにラグがあるので、面談日時を見て判定する
  const title = [ENTRY_STATUS.first_interview_requested, ENTRY_STATUS.first_interview_accepted, ENTRY_STATUS.first_interview_date_requested].indexOf(props.entry.entry_status) !== -1 ||
                (props.entry.entry_status === ENTRY_STATUS.first_interview_date_accepted && (props.entry.meeting_at && props.entry.meeting_at.getTime() > new DateWithOffset().getTime()))
                ? "一次面談" : "二次面談";

  return (
    <div>
      {!state.completed ? (
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            ctx.scrollTo("js-InterviewRejectConfirm");
            setState({
              ...state,
              completed: true,
            });
          }}
        >
          <h3 className="contentsSubTitle" id="js-InterviewReject">
            {props.entry.partner.last_name +
              " " +
              props.entry.partner.first_name}
            様との{title}辞退
          </h3>
          <div className={style.scoutInfo}>
            <div className={style.scoutInfoText}>
              応募者に対して
              <b className="joinsPinkTxt">貴社からの{title}辞退のお知らせ</b>
              を通知いたします。
              <br />
              面談辞退の理由など、応募者に特に伝えたいメッセージがあれば下記にご記入のうえ、「送信内容を確認する」ボタンを押してください。
              <span className="grayTxt">
                （メッセージがなければ未記入のままボタンを押してください。）
              </span>
            </div>
            <div className="pure-form">
              <div className="formRow formRowSolo">
                <label className="formHead">
                  応募者へのメッセージ
                  <span className="label label_opt">任意</span>
                </label>
                <div className="formData">
                  <textarea
                    className="textarea"
                    onChange={(e) => {
                      setState({ ...state, apology: e.target.value });
                    }}
                    value={
                      state.apology === EMPTY_APOLOGY ? " " : state.apology
                    }
                  />
                </div>
              </div>
              <div className="formBtnArea">
                <button
                  className="btn btnEdit"
                  onClick={() => {
                    props.close();
                    setState({
                      ...state,
                      isSubmitted: false,
                    });
                  }}
                >
                  キャンセル
                </button>
                <button className="btn btnSave">送信内容を確認する</button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <>
          <h3 className="contentsSubTitle" id="js-InterviewRejectConfirm">
            {props.entry.partner.last_name +
              " " +
              props.entry.partner.first_name}
            様との{title}辞退
          </h3>
          <div className={style.rejectSecondInterviewFormWrapper}>
            <form
              className="pure-form"
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                props.reject(props.entry, state.apology);
              }}
            >
              <div className={style.scoutInfoText}>
                以下の内容でよろしければ、
                <b className="joinsPinkTxt">送信するボタン</b>
                をクリックしてください。
              </div>
              <div
                className={
                  style.listRow + " " + style.listRowNoBorder + " mb40mb32"
                }
              >
                <div className={style.listHead}>応募者へのメッセージ</div>
                <div className={style.listData}>
                  {state.apology.split("\n").map((m, idx) => (
                    <div key={idx}>{m}</div>
                  ))}
                </div>
              </div>
              <div className="formBtnArea">
                <button
                  className="btn btnEdit"
                  onClick={() =>
                    setState({
                      ...state,
                      completed: false,
                    })
                  }
                >
                  キャンセル
                </button>
                <button type="submit" className="btn btnSave">
                  送信する
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
