import * as React from "react";
import style from "../../style/Recruitment.module.scss";
import { IEntry } from "../../types/state";

export const PartnerDecline = (props: { entry: IEntry }) => {
  let title = "";
  let reason = "";
  if (props.entry.first_interview_reject_reason) {
    title = "一次面談";
    reason = props.entry.first_interview_reject_reason;
  } else if (props.entry.second_interview_reject_reason) {
    title = "二次面談";
    reason = props.entry.second_interview_reject_reason;
  } else {
    title = "ご契約について";
    reason = props.entry.contract_reject_reason;
  }

  return (
    <>
      <h3 className="contentsSubTitle">{title}</h3>
      <div className={style.scoutInfo}>
        <div className={style.scoutInfoText}>
          {props.entry.partner.last_name
            ? `${props.entry.partner.last_name} ${props.entry.partner.first_name}様から`
            : ""}
          <b>{title}辞退</b>の連絡を受け取りました。
        </div>
        <div className={style.scountInfoReply + " mb40mb32"}>
          <div className={style.scoutInfoTitle}>応募者からの辞退理由</div>
          <div className={style.scoutInfoMessage}>
            {reason.split("\n").map((m, idx) => (
              <div key={idx}>{m}</div>
            ))}
          </div>
          <div className="alCent">
            <div className={style.scoutInfoMeetingText + " mb32mb16"}>
              貴社にとって最適な人材との出会いのために今後も尽力してまいりますので、
              <br className="SPHide" />
              引き続き、宜しくお願い申し上げます。
            </div>
            <div className="formBtnArea formBtnAreaSingle">
              <button
                className="btn btnSave"
                onClick={() => window.history.back()}
              >
                一覧に戻る
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
