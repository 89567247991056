import * as React from "react";
import style from "../../style/InterviewInfo.module.scss";
import { Link } from "react-router-dom"
import { psychologicalSafetyPath } from "../../routes/paths"

export const InputPsychologicalSafety = () => {
  return (
    <tr>
      <th>
        心理的
        <br className="PCHide" />
        安全性
        <br className="PCHide" />
        プログラム
        <br className="PCHide" />
        の入力
      </th>
      <td>
        <div>
          <Link
            to={psychologicalSafetyPath}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            心理的安全性プログラムを入力する
          </Link>
        </div>
        <div className={style.meetingInfoTableDescription}>
          <a
            href="https://joins-partner.com/33951ba665db4668869719cd6e291534"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            心理的安全性プログラムとは？
          </a>
        </div>
      </td>
    </tr>
  )
}
