export const JOINS_COMPANY_ID = 32

export const MEETING_TIME = 1000 * 60 * 60
export const MEETING_TIME_WITH_ADDITIONAL = 1000 * 60 * 90

export const PREFECTURE_OVERSEAS = "overseas"
export const PREFECTURE_OVERSEAS_MESSAGE = "海外在住"
export const PREFECTURE_OVERSEAS_MESSAGE_FOR_FAVORITE = "特になし"

export const BOOL_TYPE = {
  yes: true,
  no: false
}

export const BOOL_TYPE_STR = {
  yes: "はい",
  no: "いいえ"
}

export const EXISTENCE = {
  true: "あり",
  false: "なし"
}

export const POSITIONS = {
  president: "代表",
  executive: "役員",
  general_manager: "部長",
  manager: "課長",
  chief: "主任/係長",
  staff: "一般社員"
}

export const INDUSTRIES = {
  hotel_ledger: { flag: 1, message: "ホテル・観光・レジャー" },
  food_restaurant: { flag: 2, message: "フード・レストラン" },
  it_internet: { flag: 4, message: "IT・インターネット・ゲーム" },
  maker: { flag: 8, message: "製造・メーカー" },
  trading_company: { flag: 16, message: "商社" },
  trading_service: { flag: 32, message: "流通・小売・サービス" },
  advertising_publisher: { flag: 64, message: "広告・出版・マスコミ" },
  consultant: { flag: 128, message: "コンサルティング" },
  financial: { flag: 256, message: "金融（銀行、証券、その他）" },
  real_estate: { flag: 512, message: "建設・不動産" },
  medical: { flag: 1024, message: "メディカル" },
  logistics: { flag: 2048, message: "物流・運輸" },
  agriculture_fisher: { flag: 4096, message: "農業・林業・漁業" },
  infra_educator: { flag: 8192, message: "インフラ・教育・官公庁" },
  industry_other: { flag: 16384, message: "その他" }
}

export const DEPARTMENTS = {
  ceo: { flag: 1, message: "経営者（CEO・COO・支社長等）" },
  sales: { flag: 2, message: "個人向け営業" },
  hole_sales: { flag: 4, message: "法人向け営業" },
  planning: { flag: 8, message: "経営企画" },
  accounting: { flag: 16, message: "財務・経理" },
  legal: { flag: 32, message: "法務" },
  human_resources: { flag: 64, message: "人事" },
  affairs: { flag: 128, message: "総務" },
  ir: { flag: 256, message: "広報・IR" },
  product_develop: { flag: 512, message: "商品企画・開発" },
  quority_control: { flag: 1024, message: "生産管理・品質管理" },
  marketing: { flag: 2048, message: "マーケティング" },
  it_engineering: { flag: 4096, message: "ITエンジニア" },
  engineering: { flag: 8192, message: "その他エンジニア" },
  creative: { flag: 16384, message: "クリエイティブ" },
  tax_accountant: { flag: 32768, message: "会計士・税理士" },
  smem: { flag: 65536, message: "中小企業診断士" },
  department_other: { flag: 131072, message: "その他" }
}

export const TERM = {
  within_one_year: "1年未満",
  within_three_year: "3年未満",
  over_three_year: "3年以上"
}

export const THEMES = {
  child: { flag: 1, message: "子供" },
  education: { flag: 2, message: "教育" },
  nursing: { flag: 4, message: "介護" },
  manufacturing: { flag: 8, message: "ものづくり" },
  nature: { flag: 16, message: "自然" },
  sustainable_town: { flag: 32, message: "持続可能な街づくり" },
  poverty_reduction: { flag: 64, message: "貧困削減" },
  traditional_culture: { flag: 128, message: "伝統文化" },
  gender_free: { flag: 256, message: "ジェンダーフリー" },
  tourism: { flag: 512, message: "観光・インバウント" },
  theme_other: { flag: 1024, message: "その他" }
}

export const RATE = {
  within_10: "10%以下",
  over_10: "10%〜33%",
  over_33: "33%〜50%",
  over_50: "50%〜75%",
  over_75: "75%〜100%"
}

export const WORK_TIME = {
  eight_hour: "8時間（約1日分）",
  sixteen_hour: "16時間（約2日分）",
  twenty_four_hour: "24時間（約3日分）",
  thirty_two_hour: "32時間（約4日分）",
  forty_hour: "40時間（約5日分）",
  forty_eight_hour: "48時間（約6日分）",
  fifty_six_hour: "56時間（約7日分）",
  sixty_four_hour: "62時間（約8日分）",
  over_sixty_four_hour: "それ以上"
}

export const WORK_TIME_2 = {
  up_to_40: "40時間以下",
  up_to_60: "41 〜 60時間",
  up_to_80: "61 〜 80時間",
  up_to_100: "81 〜 100時間",
  up_to_120: "101 〜 120時間",
  over_120: "121時間以上"
}

export const BUSINESS_TRIP = {
  positive: "積極的に行きたい",
  conditional: "場所や条件次第で行きたい",
  remote: "フルリモートを希望する"
}

export const CONTRACT = {
  ambiguous: "未入力",
  staff: "副業（別に雇用契約を締結している法人がある方）",
  freelance: "兼業（個人事業主としてのみ活動している方）",
  self_employed: "兼業（法人を設立して個人で活動している方）"
}

export const RECRUITMENT_STATUS = {
  inactive: "確認中",
  active: "受付中",
  closed: "受付終了"
}

export const RECRUITMENT_STATUS_EN = {
  inactive: "inactive",
  active: "active",
  closed: "closed"
}

export const STAFF_ROLES = {
  admin: "全権限",
  individual: "個別案件対応"
}

export const STAFF_ROLES_EN = {
  admin: "admin",
  individual: "individual"
}

export const PROBLEM_STATUS = {
  new: "準備中",
  resume: "準備中",
  active: "受付中",
  closed: "受付終了"
} as const

export const PROBLEM_STATUS_EN = {
  new: "new",
  resume: "resume",
  active: "active",
  closed: "closed"
} as const

export const PROBLEM_CHECKED_BO_STATUS_EN = {
  done: "done",
  progress_new: "progress_new",
  progress_resume: "progress_resume"
} as const

export const GUIDANCE_MEETING_STATUS = {
  un_executed: "un_executed",
  done: "done"
}

export const ENTRY_STATUS = {
  first_interview_requested: "first_interview_requested",
  first_interview_accepted: "first_interview_accepted",
  first_interview_date_requested: "first_interview_date_requested",
  first_interview_date_accepted: "first_interview_date_accepted",
  first_interview_end: "first_interview_end",
  second_interview_requested: "second_interview_requested",
  second_interview_accepted: "second_interview_accepted",
  second_interview_date_requested: "second_interview_date_requested",
  second_interview_date_accepted: "second_interview_date_accepted",
  second_interview_end: "second_interview_end",
  second_interview_skipped: "second_interview_skipped",
  contract_requested: "contract_requested",
  contract_accepted: "contract_accepted",
  contract_end: "contract_end"
}

export const ENTRY_STATUS_JP = {
  first_interview_requested: "一次面談希望",
  first_interview_accepted: "一次面談確定",
  first_interview_date_requested: "一次面談日時希望",
  first_interview_date_accepted: "一次面談日時確定",
  first_interview_end: "一次面談終了",
  second_interview_requested: "二次面談希望",
  second_interview_accepted: "二次面談確定",
  second_interview_date_requested: "二次面談日時希望",
  second_interview_date_accepted: "二次面談日時確定",
  second_interview_end: "二次面談終了",
  second_interview_skipped: "二次面談スキップ",
  contract_requested: "契約希望",
  contract_accepted: "契約合意",
  contract_end: "契約終了"
}

export const DECLINED_BY = {
  partner: "partner",
  company: "company",
  joins: "joins"
}

export const CLOUDSIGN_STATUS = {
  draft: "ドラフト",
  waiting: "未回答",
  concluded: "契約成立",
  manual_concluded: "手動契約",
  rejected: "不成立"
}

export const SCOUT_STATUS = {
  waiting: "回答待ち",
  rejected: "不成立",
  accepted: "承諾"
}

export const REGISTER_DATES = {
  one_week: "1週間以内",
  one_month: "1ヶ月以内",
  three_month: "3ヶ月以内"
}

export const SALES = {
  within_one_hundred_million: "1億円未満",
  over_one_hundred_million: "1億〜5億円",
  over_five_hundred_million: "5億〜10億円",
  over_thousand_million: "10億円以上"
}

export enum PUBLISH_TYPE {
  limit_public = "limit_public",
  public = "public",
  limited = "limited",
  private = "private"
}

export const PUBLISH_TYPE_STR = {
  public: "一般公開（全公開）",
  limit_public: "一般公開（部分公開）",
  limited: "JOINS会員限定公開",
  private: "非公開"
}

export const PUBLISH_TYPE_LABEL = {
  public: [
    "JOINS会員だけでなく、当社Webサイトを訪れた非会員の方にも、貴社の案件情報を全て公開します。",
    "一般の方々に幅広く募集情報が閲覧され、期待する人材からの応募確率が高まります。"
  ],
  limit_public: [
    "JOINS会員には貴社案件情報の全てを、当社Webサイトを訪れた非会員の方には、企業特定情報を除く案件情報の一部を公開します。",
    "企業特定情報が不特定多数の目に触れることを回避し、また期待する人材からの応募確率も高まります。"
  ],
  limited: ["JOINS会員のみに限定して、貴社の案件情報全てを公開します。"],
  private: [
    "スカウトや、契約したことがある人材に再度面談を申し込む場合等、特定の人材のみが案件情報を閲覧できるようにする場合に指定します。"
  ]
}

export type ProblemContentsType = "request" | "situation" | "goal"

export const PUBLISH_TYPE_OPEN_STATUS = {
  limit_public: {
    request: {
      member: "公開",
      nonMember: "公開"
    },
    situation: {
      member: "公開",
      nonMember: "非公開"
    },
    goal: {
      member: "公開",
      nonMember: "非公開"
    }
  },
  public: {
    request: {
      member: "公開",
      nonMember: "公開"
    },
    situation: {
      member: "公開",
      nonMember: "公開"
    },
    goal: {
      member: "公開",
      nonMember: "公開"
    }
  },
  limited: {
    request: {
      member: "公開",
      nonMember: "非公開"
    },
    situation: {
      member: "公開",
      nonMember: "非公開"
    },
    goal: {
      member: "公開",
      nonMember: "非公開"
    }
  },
  private: {
    request: {
      member: "非公開",
      nonMember: "非公開"
    },
    situation: {
      member: "非公開",
      nonMember: "非公開"
    },
    goal: {
      member: "非公開",
      nonMember: "非公開"
    }
  }
}

export const TARGET_REASON = {
  web_ad: "Web広告",
  media: "メディア",
  other: "その他"
}

export const TARGET_PURPOSE = {
  interest: "サービスに関心があり、説明を直接聞きたい",
  consider: "サービスの利用を具体的に検討しており、聞きたいことがある"
}

export enum Phases {
  FIRST_INTERVIEW = "first_interview",
  SECOND_INTERVIEW = "second_interview",
  CONTRACT = "contract"
}
export const SCOUT_TYPES = {
  scout: "scout",
  request: "request"
}

export const SCOUT_TYPES_JA = {
  scout: "スカウト",
  request: "再申し込み"
}

export const EMPTY_APOLOGY = "記入なし"

export const INSURANCE_STATUS = {
  NOT_SELECTED: "not_selected",
  PURCHASED: "purchased",
  WILL_PURCHASE_AFTER_CONTRACT: "will_purchase_after_contract",
  WILL_NOT_PURCHASE: "will_not_purchase"
} as const
export type InsuranceStatus = (typeof INSURANCE_STATUS)[keyof typeof INSURANCE_STATUS]
export const INSURANCE_STATUS_TEXT = {
  not_selected: "未選択",
  purchased: "加入している",
  will_purchase_after_contract: "業務を受託したら加入する",
  will_not_purchase: "加入する予定はない"
} as const

export type LabelType = "nothing" | "interested" | "considering" | "disinterested"

export enum MEETING_TYPE {
  ONLINE = "online",
  OFFLINE = "offline"
}

export const MEETING_TYPE_STR = {
  online: "オンライン",
  offline: "対面形式"
}

export enum MEETING_KIND {
  FIRST_INTERVIEW = "一次面談",
  SECOND_INTERVIEW = "二次面談"
}

export const STAFF_ACTIVATION_STATUS = {
  completed: "completed",
  valid: "valid",
  expired: "expired",
  activated: "activated"
}

export const VIDEO_TYPE = {
  joins_video: "joins_video",
  other_video: "other_video",
  bellbird_video: "bellbird_video"
} as const

export const PSYCHOLOGICAL_STATUS = {
  hidden: "hidden",
  first_wait: "first_wait",
  first_done: "first_done",
  second_wait: "second_wait",
  second_done: "second_done"
} as const

export const ANNOUNCEMENT_TYPE = {
  term_update: "term_update",
  privacy_policy: "privacy_policy",
  other: "other"
}

export const CANCEL_CONTRACT_SATISFACTION_KEYS = {
  none: "none",
  frankness: "frankness",
  manipulation: "manipulation",
  contact: "contact",
  independence: "independence",
  cost: "cost",
  proposal: "proposal",
  result: "result",
  progress: "progress",
  active_listening: "active_listening",
  understandability: "understandability",
  other: "other"
}

export const CANCEL_CONTRACT_SATISFACTION = {
  none: "特になし",
  frankness: "率直な意見を伝えてくれた",
  manipulation: "自分で手も動かしてくれた",
  contact: "連絡が取りやすかった",
  independence: "主体的に行動してくれた",
  cost: "金額が安かった",
  proposal: "提案の質がよかった",
  result: "期待していた成果が出た",
  progress: "稼働に対して進捗が速かった",
  active_listening: "意見に耳を傾けてくれた",
  understandability: "話がわかりやすかった",
  other: "その他"
}

export const CANCEL_CONTRACT_DISSATISFACTION = {
  none: "特になし",
  frankness: "率直な意見を伝えてくれなかった",
  manipulation: "自分では手を動かさなかった",
  contact: "連絡が取りづらかった",
  independence: "主体的に行動してくれなかった",
  cost: "金額が高かった",
  proposal: "提案内容が期待はずれだった",
  result: "成果が期待はずれだった",
  progress: "稼働に見合わない進捗だった",
  active_listening: "意見に耳を傾けてくれなかった",
  understandability: "話がわかりづらかった",
  other: "その他"
}

export const MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS = {
  none: "none",
  frankness: "frankness",
  manipulation: "manipulation",
  contact: "contact",
  independence: "independence",
  cost: "cost",
  proposal: "proposal",
  result: "result",
  progress: "progress",
  active_listening: "active_listening",
  understandability: "understandability",
  other: "other"
}

export const MONTHLY_QUESTIONNAIRE_SATISFACTION = {
  none: "特になし",
  frankness: "率直な意見を伝えてくれる",
  manipulation: "自分で手も動かしてくれている",
  contact: "連絡が取りやすい",
  independence: "主体的に行動してくれている",
  cost: "金額が安い",
  proposal: "提案の質がよい",
  result: "期待していた成果が出ている",
  progress: "稼働に対して進捗が速い",
  active_listening: "意見に耳を傾けてくれる",
  understandability: "話がわかりやすい",
  other: "その他"
}

export const MONTHLY_QUESTIONNAIRE_DISSATISFACTION = {
  none: "特になし",
  frankness: "率直な意見を伝えてくれない",
  manipulation: "自分では手を動かさない",
  contact: "連絡が取りづらい",
  independence: "主体的に行動してくれない",
  cost: "金額が高い",
  proposal: "提案内容が期待はずれ",
  result: "成果が期待はずれ",
  progress: "稼働が進捗に見合わない",
  active_listening: "意見に耳を傾けてくれない",
  understandability: "話がわかりづらい",
  other: "その他"
}

export const MANAGEMENT_MEMBERS = {
  up_to_5: "1 〜 5人",
  up_to_10: "6 〜 10人",
  up_to_20: "11 〜 20人",
  up_to_50: "21 〜 50人",
  up_to_100: "51 〜 100人",
  over_100: "101人以上"
}

export const REWARD = {
  0: "0円",
  1000: "1000円",
  2000: "2000円",
  3000: "3000円",
  4000: "4000円",
  5000: "5000円"
}
