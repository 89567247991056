import * as React from "react"
import { ErrorMessage } from "./common/ErrorMessage"
import { RequiredLabel } from "./RequiredLabel"
import { ChangeEvent, ReactNode } from "react"
import { Calendar } from "./Calendar"

interface IDateFieldProps {
  values: { [key: string]: any }
  name: string
  title: string | ReactNode
  required?: boolean
  updateValue: (args: { [key: string]: Date }) => void
  error?: string | null
  hintAbove?: string | ReactNode
  hint?: string | ReactNode
}

export const DateField = ({ values, name, title, required, error, hintAbove, hint, updateValue }: IDateFieldProps) => {
  return (
    <>
      <div className={hint ? "formRow noUnderline" : "formRow"}>
        <label className="formHead">
          <span>{title}</span>
          <RequiredLabel required={required} />
        </label>
        <div className="formData" id={name}>
          {hintAbove && <p className="formHintAbove">{hintAbove}</p>}
          <Calendar
            value={values[name]}
            type="date"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateValue({ [name]: new Date(e.target.value) })
            }}
          />
          <ErrorMessage cls="mb08">{error}</ErrorMessage>
        </div>
      </div>
      {hint && <div className="formHint">{hint}</div>}
    </>
  )
}
