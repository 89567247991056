import * as React from "react"
import style from "../../style/Recruitment.module.scss"
import { IEntry } from "../../types/state"
import { dateStr } from "../../lib/Util"
import { useEntryStatus } from "../../hooks/useEntryStatus"
import { CancelContractEntry } from "./CancelContractEntry"

interface ICanceledContractInfoProps {
  entry: IEntry;
}

export const CanceledContractInfo = ({ entry }: ICanceledContractInfoProps) => {
  const { isContractCanceled } = useEntryStatus(entry)
  if (!isContractCanceled) return null

  return (
    <>
      <div className={style.entryFinishMessageWrapper}>
        <h3 className="contentsTitle contentsSubTitlePlane">
          契約終了
        </h3>
        <p>
          本契約は{dateStr(entry.contract_cancel_at)}
          をもちまして <br />
          終了となりました。
        </p>
      </div>
      <h3 className="contentsSubTitle">解約情報</h3>
      <CancelContractEntry entry={entry} />
    </>
  )
}
