import * as React from "react";
import { useMemo, useState } from "react"
import { IFirstPsychologicalSafety } from "../types/state";
import { isEmptyString, parseQueryParams } from "../lib/Util"
import style from "../style/Recruitment.module.scss";
import TabStyle from "../style/TabList.module.scss";
import psychologicalStyle from "../style/Psychology.module.scss";
import clsx from "clsx"

export interface IEntryFirstPsychologicalSafetyListDispatchProps {}

export interface IEntryFirstPsychologicalSafetyListStateProps {
  firstPsychologicalSafeties: IFirstPsychologicalSafety[];
  withoutReturn?: boolean;
}

export interface IEntryFirstPsychologicalSafetyListProps
  extends IEntryFirstPsychologicalSafetyListDispatchProps,
    IEntryFirstPsychologicalSafetyListStateProps {}

export const EntryFirstPsychologicalSafetyList =  (props: IEntryFirstPsychologicalSafetyListProps) => {
  const [currentTab, setCurrentTab] = useState(
    props.firstPsychologicalSafeties[0]?.name
  );

  React.useEffect(() => {
    if (props.firstPsychologicalSafeties.length > 0) {
      setCurrentTab(props.firstPsychologicalSafeties[0].name);
    }
  }, [props.firstPsychologicalSafeties]);

  const isVisible = (type: string) => {
    if (type === currentTab) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  };

  const showBackButton = useMemo(() => {
    const showBackButtonQuery = parseQueryParams()["b"]
    if (showBackButtonQuery) {
      return showBackButtonQuery === "1"
    }
    return !props.withoutReturn
  }, [props.withoutReturn])

  return (
    <>
      <div className={TabStyle.headerTabs}>
        <ul className={TabStyle.tabsWrap}>
          {props.firstPsychologicalSafeties.map((firstPsychologicalSafety) => {
            return (
              <li
                key={firstPsychologicalSafety.name}
                className={
                  currentTab === firstPsychologicalSafety.name
                    ? "pure-menu-selected"
                    : ""
                }
                onClick={() => {
                  firstPsychologicalSafety.name &&
                    setCurrentTab(firstPsychologicalSafety.name);
                }}
              >
                <span>{firstPsychologicalSafety.name} 様</span>
              </li>
            );
          })}
        </ul>
      </div>
      <main className="contentsWrapper">
        {props.firstPsychologicalSafeties.map((firstPsychologicalSafety) => {
          return (
            <div
              className={psychologicalStyle.contentsWrapperTransparent}
              key={firstPsychologicalSafety.id}
              style={isVisible(firstPsychologicalSafety.name)}
            >
              {firstPsychologicalSafety.type === "partner" && (
                <div className={psychologicalStyle.title}>
                  応募者 {firstPsychologicalSafety.name}様の
                  <br className="PCHide" />
                  一次面談向け
                  <br className="PCHide" />
                  心理的安全性プログラム
                </div>
              )}
              {firstPsychologicalSafety.type !== "partner" && (
                <div className={psychologicalStyle.title}>
                  {firstPsychologicalSafety.company_name}{" "}
                  <br className="PCHide" />
                  {firstPsychologicalSafety.name}様の
                  <br className="PCHide" />
                  <br className="SPHide" />
                  一次面談向け
                  <br className="PCHide" />
                  心理的安全性プログラム
                </div>
              )}
              <div className={style.listArea}>
                <div className={style.listRow}>
                  <div className={style.listHead}>
                    好きなことやものを
                    <br className="SPHide" />
                    3個以上教えてください
                  </div>
                  <div className={style.listData}>
                    {isEmptyString(firstPsychologicalSafety.like) && "未入力"}
                    {firstPsychologicalSafety.like}
                  </div>
                </div>
                <div className={style.listRow}>
                  <div className={style.listHead}>
                    業務を進める上で、特に自分の能力を発揮できる、
                    <br className="SPHide" />
                    頑張れる業務内容・仕事の進め方を教えてください
                  </div>
                  <div className={style.listData}>
                    {isEmptyString(firstPsychologicalSafety.specialties_and_proceeding) && "未入力"}
                    {firstPsychologicalSafety.specialties_and_proceeding}
                  </div>
                </div>
                <div className={style.listRow}>
                  <div className={style.listHead}>
                    業務を進める上で、これだけは苦手、あるいはストレスがかかる
                    <br className="SPHide" />
                    業務内容・仕事の進め方を教えてください
                  </div>
                  <div className={style.listData}>
                    {isEmptyString(firstPsychologicalSafety.weak) && "未入力"}
                    {firstPsychologicalSafety.weak}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {props.firstPsychologicalSafeties.length === 0 && (
          <div className={clsx(psychologicalStyle.contentsWrapperTransparent, "center")}>
            一次面談向け心理的安全性プログラムは未入力です。
          </div>
        )}
        {showBackButton && (
          <div className="formBtnArea formBtnAreaSingle">
            <button
              className="btn btnEdit"
              onClick={() => window.history.back()}
            >
              戻る
            </button>
          </div>
        )}
      </main>
    </>
  );
};
