import * as React from "react"
import style from "../../style/MonthlyQuestionnaireFinish/MonthlyQuestionnaireFinishInfo.module.scss"
import styleRec from "../../style/Recruitment.module.scss"
import { homePath } from "../../routes/paths"
import { Link } from "react-router-dom"
import clsx from "clsx"

export const MonthlyQuestionnaireFinishInfo = () => {
  return (
    <div className="contentsWrapper-transparent">
      <div className={clsx(styleRec.entryFinishMessageWrapper, style.contentWrapper)}>
        <h3 className="contentsTitle contentsSubTitlePlane">
          アンケートを送信しました。
        </h3>
        <p>
          <b>
            アンケートにご入力いただきありがとうございました。<br />
            引き続き何卒よろしくお願い致します。
          </b>
        </p>
        <div className={clsx("formBtnArea formBtnAreaSingle", styleRec.entryFinishMessageButtonArea)}>
          <Link to={homePath} className={clsx("btn btnLogin")}>
            人材管理画面に戻る
          </Link>
        </div>
      </div>
    </div>
  )
}
