import * as React from "react";
import { IEntry } from "../../types/state";
import { dateStr } from "../../lib/Util";

interface ICancelContractButtonProps {
  entry: IEntry;
  onClick: () => void;
}

export const CancelContractButton = ({ entry, onClick }: ICancelContractButtonProps) => {
  return (
    <>
      {entry.contract_start_at && (
        <h3 className="contentsSubTitle contentsSubTitlePlane">
          契約開始日:
          {dateStr(entry.contract_start_at)}
        </h3>
      )}
      <p>解約をご希望の方は下記のボタンよりご連絡ください。</p>
      <button
        type="submit"
        className="btn btnSave"
        onClick={onClick}
      >
        解約を申し込む
      </button>
    </>
  )
}
