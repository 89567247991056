import * as React from "react";
import style from "../../style/GuidanceMeeting.module.scss";

export const GuidanceOtherInfo = () => {
  return (
    <>
      <div className={style.infoWrap}>
        <h3 className={style.infoTitleBar}>【ガイダンス会議とは】</h3>
        <div className={style.infoWrapInner}>
          <p>契約締結及び業務開始の前に、JOINS担当者が以下について説明させていただく会議です。</p>
          <ul className={style.infoWrapInnerList}>
            <li>契約や費用・請求に関する重要事項の説明</li>
            <li>人材とお仕事を開始するにあたっての留意事項の説明</li>
            <li>実際にあったトラブル事例の紹介と回避策の説明</li>
            <li>御社にとってよりよい進め方のアドバイス</li>
          </ul>
        </div>
      </div>
    </>
  )
}
