import * as React from "react";

export interface ICancelButton {
  onClick: () => void;
}

const CancelButton = (props: ICancelButton) => {
  return (
    <button className="btn btnEdit" onClick={() => props.onClick()}>
      キャンセル
    </button>
  );
};

export default CancelButton;
