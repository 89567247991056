import * as React from "react";

interface IUseJoinsVideoChatProps {
  videoMeetingUrl: string;
}

export const UseJoinsVideoChat = ({ videoMeetingUrl }: IUseJoinsVideoChatProps) => {
  return (
    <div className="fz14 grayTxt">
      ※ JOINSのWeb会議システムをご利用いただくことも可能です。
      <br />
      <a
        className="link"
        href={videoMeetingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        JOINSのWeb会議室はこちら ≫
      </a>
    </div>
  )
}
