import * as React from "react"
import B64 from "../lib/bitwise64"
import { ErrorMessage } from "./common/ErrorMessage"

export interface IBitFlagDefinition {
  [key: string]: {
    flag: number
    message: string
  }
}

export const SelectBitFlagField = (props: {
  flags: IBitFlagDefinition
  label?: any
  type?: string
  name: string
  note?: string
  required?: boolean
  cls?: string
  values: any
  children?: React.ReactNode
  isSubmitted?: boolean
  validates?: { [key: string]: (value: any) => string | null }
  updateValue: (nextValue: number) => void
}) => (
  <div className={props.label === "閲覧可能な案件" ? "formRowBorder" : ""}>
    <div className={props.cls ? props.cls : "formRow"} id={props.name}>
      {props.label && (
        <label className="formHead">
          {props.label}
          {props.required === true ? (
            <span className="label label_req">必須</span>
          ) : props.required === false ? (
            <span className="label label_opt">任意</span>
          ) : null}
          {props.type !== "scout" && <br />}
          {props.note && <small>{props.note}</small>}
        </label>
      )}
      <div className={props.label === "閲覧可能な案件" ? "" : "formData"}>
        {Object.keys(props.flags).map((flag) => {
          return (
            <label className={"selectBtn" + (props.type === "scout" ? " selectBtnSmall" : "")} key={flag}>
              <input
                type="checkbox"
                checked={B64.and(props.values[props.name], props.flags[flag]["flag"]) !== 0}
                onChange={() => {
                  if (B64.and(props.values[props.name], props.flags[flag]["flag"]) !== 0) {
                    const nextValue = props.values[props.name] - props.flags[flag]["flag"]
                    props.updateValue(nextValue)
                  } else {
                    const nextValue = props.values[props.name] + props.flags[flag]["flag"]
                    props.updateValue(nextValue)
                  }
                }}
              />
              <span>{props.flags[flag]["message"]}</span>
            </label>
          )
        })}
        {props.isSubmitted && props.validates && props.validates[props.name] && (
          <ErrorMessage cls={props.children ? "mb24" : "mb08"}>
            {props.validates[props.name](props.values)}
          </ErrorMessage>
        )}
        {props.children}
      </div>
    </div>
  </div>
)
