import * as React from "react"
import { IStoreState } from "../../types/state"
import { PROBLEM_CHECKED_BO_STATUS_EN, PROBLEM_STATUS_EN } from "../../lib/Definition"
import { IProblemFormState } from "./index"
import { useMemo } from "react"
import { useSelector } from "react-redux"

interface IOpenProblemButtonDispatchProps {
  updateValue: (args: { [key: string]: any }) => void
}

interface IOpenProblemButtonStateProps {
  problem: IProblemFormState
}

interface IOpenProblemButtonProps extends IOpenProblemButtonDispatchProps, IOpenProblemButtonStateProps {}

export const OpenProblemButton = ({ problem, updateValue }: IOpenProblemButtonProps) => {
  const recruitment = useSelector((state: IStoreState) => state.recruitment)

  const originalProblem = useMemo(() => {
    return recruitment.problems.find((p) => p.id === problem.id)
  }, [recruitment.problems, problem])

  const [checkedBoState, expiredDate] = useMemo(() => {
    if (!originalProblem) return [null, null]
    if (
      originalProblem.status === PROBLEM_STATUS_EN.closed &&
      originalProblem.checked_bo_status === PROBLEM_CHECKED_BO_STATUS_EN.done
    ) {
      return [PROBLEM_CHECKED_BO_STATUS_EN.progress_resume, null]
    }
    return [originalProblem.checked_bo_status, originalProblem.expired_date]
  }, [originalProblem])

  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        updateValue({
          ...problem,
          status: PROBLEM_STATUS_EN.active,
          checked_bo_status: checkedBoState,
          expired_date: expiredDate
        })
      }}
      className="btn btnOpen"
    >
      募集再開
    </button>
  )
}
