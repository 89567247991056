import { TypeKeys } from "../actions";
import { Actions } from "../actions";

const initialState = {
  isOpen: false,
  content: null
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.SET_MODAL: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
};
