import * as React from "react"
import { Route, Switch } from "react-router-dom"
import {
  homePath,
  loginPath,
  resetPasswordFinishPath,
  resetPasswordPath,
  signUpPath,
  editRegistrantPath,
  companyPath,
  editCompanyPath,
  checkTrustPath,
  recruitmentPath,
  renewalPasswordPath,
  renewalPasswordFinishPath,
  entryPath,
  entryAcceptPath,
  entryRejectPath,
  registrantPath,
  registerThanksPath,
  companyRecruitmentPath,
  roomPath,
  roomsPath,
  schedulePath,
  scoutPath,
  partnerPath,
  firstInterviewPath,
  staffsPath,
  editStaffsPath,
  activateStaffPath,
  psychologicalSafetyPath,
  firstPsychologicalSafetyPath,
  editFirstPsychologicalSafetyPath,
  entryFirstPsychologicalSafetiesPath,
  entryFinishPath,
  secondInterviewRequestPath,
  secondInterviewRequestFinishPath,
  secondInterviewPath,
  noticePath,
  guidanceMeetingPath,
  entryCancelFinishPath,
  monthlyQuestionnairePath,
  monthlyQuestionnaireFinishPath,
  problemNewPath,
  problemCopyPath,
  problemEditPath
} from "./paths"
import ConditionalRoute from "./ConditionalRoute"
import RenewalPasswordFinish from "../components/RenewalPasswordFinish"
import { RegisterThanks } from "../components/RegisterThanks"
import { Error404Page } from "../components/Error404Page"
import ResetPasswordFinish from "../components/ResetPasswordFinish"
import RenewalPassword from "../containers/RenewalPassword"
import EntryAccept from "../containers/EntryAccept"
import EntryReject from "../containers/EntryReject"
import EntryFinish from "../containers/EntryFinish"
import Home from "../containers/Home"
import CompanyRecruitment from "../containers/CompanyRecruitment"
import Rooms from "../containers/Rooms"
import Room from "../containers/Room"
import Schedule from "../containers/Schedule"
import Scout from "../containers/Scout"
import Partner from "../containers/Partner"
import FirstInterview from "../containers/FirstInterview"
import Staffs from "../containers/Staffs"
import EditStaffs from "../containers/EditStaffs"
import ActivateStaff from "../containers/ActivateStaff"
import PsychologicalSafety from "../containers/PsychologicalSafety"
import FirstPsychologicalSafety from "../containers/FirstPsychologicalSafety"
import EditFirstPsychologicalSafety from "../containers/EditFirstPsychologicalSafety"
import SecondInterviewRequest from "../containers/SecondInterviewRequest"
import SecondInterviewRequestFinish from "../containers/SecondInterviewRequestFinish"
import SecondInterview from "../containers/SecondInterview"
import CheckTrust from "../containers/CheckTrust"
import NoticeList from "../containers/NoticeList"
import GuidanceMeeting from "../containers/GuidanceMeeting"
import Login from "../containers/Login"
import ResetPassword from "../containers/ResetPassword"
import SignUp from "../containers/SignUp"
import Registrant from "../containers/Registrant"
import Entry from "../containers/Entry"
import EditRegistrant from "../containers/EditRegistrant"
import Company from "../containers/Company"
import EditCompany from "../containers/EditCompany"
import Recruitment from "../containers/Recruitment"
import EntryFirstPsychologicalSafties from "../containers/EntryFirstPsychologicalSafties"
import EntryCancelFinish from "../containers/EntryCancelFinish"
import MonthlyQuestionnaire from "../containers/MonthlyQuestionnaire"
import MonthlyQuestionnaireFinish from "../containers/MonthlyQuestionnaireFinish"
import ProblemNew from "../containers/ProblemNew"
import ProblemCopy from "../containers/ProblemCopy"
import ProblemEdit from "../containers/ProblemEdit"

interface IStateProps {
  readonly isAuthenticated: boolean
  readonly isRegistrantCompleted: boolean
  readonly isCompanyCompleted: boolean
  readonly isExistStaff: boolean
  readonly isVerifyCompleted: boolean
}

export const Routes = (props: IStateProps) => {
  return (
    <Switch>
      <ConditionalRoute
        path={homePath}
        exact={true}
        component={Home}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={editRegistrantPath}
        component={EditRegistrant}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={companyPath}
        exact={true}
        component={Company}
        redirectPath={editCompanyPath}
        isMatch={props.isCompanyCompleted}
      />
      <ConditionalRoute
        path={editCompanyPath}
        component={EditCompany}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={checkTrustPath}
        exact={true}
        component={CheckTrust}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={recruitmentPath}
        exact={true}
        component={Recruitment}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={problemNewPath}
        exact={true}
        component={ProblemNew}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={`${problemEditPath}/:problemId`}
        exact={true}
        component={ProblemEdit}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={`${problemCopyPath}/:problemId`}
        exact={true}
        component={ProblemCopy}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={registrantPath}
        exact={true}
        component={Registrant}
        redirectPath={editRegistrantPath}
        isMatch={props.isRegistrantCompleted}
      />
      <ConditionalRoute path={entryPath} component={Entry} redirectPath={loginPath} isMatch={props.isAuthenticated} />
      <ConditionalRoute
        path={schedulePath}
        component={Schedule}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute path={scoutPath} component={Scout} redirectPath={loginPath} isMatch={props.isAuthenticated} />
      <ConditionalRoute
        path={partnerPath}
        component={Partner}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={firstInterviewPath}
        component={FirstInterview}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={secondInterviewPath}
        component={SecondInterview}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={psychologicalSafetyPath}
        component={PsychologicalSafety}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={firstPsychologicalSafetyPath}
        component={FirstPsychologicalSafety}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={editFirstPsychologicalSafetyPath}
        component={EditFirstPsychologicalSafety}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={entryFirstPsychologicalSafetiesPath}
        component={EntryFirstPsychologicalSafties}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={entryAcceptPath}
        component={EntryAccept}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={entryRejectPath}
        component={EntryReject}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={companyRecruitmentPath}
        component={CompanyRecruitment}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={entryFinishPath}
        component={EntryFinish}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={entryCancelFinishPath}
        component={EntryCancelFinish}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute path={roomsPath} component={Rooms} redirectPath={loginPath} isMatch={props.isAuthenticated} />
      <ConditionalRoute path={roomPath} component={Room} redirectPath={loginPath} isMatch={props.isAuthenticated} />
      <ConditionalRoute
        path={staffsPath}
        component={Staffs}
        redirectPath={editStaffsPath}
        isMatch={props.isExistStaff}
      />
      <ConditionalRoute
        path={editStaffsPath}
        component={EditStaffs}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={registerThanksPath}
        component={RegisterThanks}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={secondInterviewRequestPath}
        component={SecondInterviewRequest}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={secondInterviewRequestFinishPath}
        component={SecondInterviewRequestFinish}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute path={signUpPath} component={SignUp} redirectPath={homePath} isMatch={!props.isAuthenticated} />
      <ConditionalRoute
        path={renewalPasswordPath}
        component={RenewalPassword}
        redirectPath={homePath}
        isMatch={!props.isAuthenticated}
      />
      <ConditionalRoute
        path={renewalPasswordFinishPath}
        component={RenewalPasswordFinish}
        redirectPath={homePath}
        isMatch={!props.isAuthenticated}
      />
      <ConditionalRoute
        path={activateStaffPath}
        component={ActivateStaff}
        redirectPath={homePath}
        isMatch={!props.isAuthenticated}
      />
      <ConditionalRoute
        path={resetPasswordFinishPath}
        component={ResetPasswordFinish}
        redirectPath={homePath}
        isMatch={!props.isAuthenticated}
      />
      <ConditionalRoute
        path={resetPasswordPath}
        exact={true}
        component={ResetPassword}
        redirectPath={homePath}
        isMatch={!props.isAuthenticated}
      />
      <ConditionalRoute path={loginPath} component={Login} redirectPath={homePath} isMatch={!props.isAuthenticated} />
      <ConditionalRoute
        path={noticePath}
        component={NoticeList}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={guidanceMeetingPath}
        component={GuidanceMeeting}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={monthlyQuestionnairePath}
        component={MonthlyQuestionnaire}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <ConditionalRoute
        path={monthlyQuestionnaireFinishPath}
        component={MonthlyQuestionnaireFinish}
        redirectPath={loginPath}
        isMatch={props.isAuthenticated}
      />
      <Route component={Error404Page} />
    </Switch>
  )
}
