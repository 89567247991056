import * as React from "react";
export default function SignupThanks(props: any) {
  return (
    <main>
      <div className="signUpThanks">
        <div className="signUpThanksTitle">
          ご利用登録ありがとうございました。
        </div>
        <p>引き続き、<span className="withUnderline">企業概要のご入力</span>をお願いいたします。</p>
        <p>
          貴社との最適な人材との出会いのために、尽力してまいります。
          <br />
          今後とも何卒宜しくお願い申し上げます。
          <br />
          <br />
        </p>
        <p>JOINS株式会社　代表取締役　猪尾愛隆</p>
      </div>
      <div className="formBtnArea formBtnAreaSingle">
        <button className="btn btnLogin" onClick={() => props.closeModal()}>
          {"企業概要の入力に進む"}
        </button>
      </div>
    </main>
  );
}
