import * as React from "react"
import Loader from "../components/Loader"
import Modal from "react-modal"
import { connect } from "react-redux"
import { Action, Dispatch } from "redux"
import { Actions } from "../actions"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { Routes } from "../routes/Routes"
import { IStoreState, IAjaxErrorDetails, IAnnouncement } from "../types/state"
import { TabList } from "../components/TabList"
import { logout, showAnnouncement } from "../thunks"
import ScrollToTop from "./ScrollToTop"
import { Prompt } from "react-router"
import ErrorBoundary from "./ErrorBoundary"
import { ErrorViewHandler } from "./ErrorViewHandler"
import { AnnouncementObserver } from "../components/AnnouncementObserver"

interface IStateProps {
  readonly scrollID: string | null
  readonly isExistStaff: boolean
  readonly isAuthenticated: boolean
  readonly isRegistrantCompleted: boolean
  readonly isCompanyCompleted: boolean
  readonly isVerifyCompleted: boolean
  readonly isRecruitmentCompleted: boolean
  readonly isMenuVisible: boolean
  readonly isLoaderVisible: boolean
  readonly isLimitedStaff: boolean
  readonly isModalOpen: boolean
  readonly modalComponent: any
  readonly modalContent: any
  readonly modalStyle: object
  readonly modalClose: () => void
  readonly shouldCloseOnOverlayClick: boolean
  readonly meetingAt: Date | null
  readonly meetingID: number | null
  readonly messageCount: number
  readonly noticeCount: number
  readonly pathname: string
  readonly isEdit: boolean
  readonly error: IAjaxErrorDetails
  readonly masquerade: boolean
  readonly announcements: IAnnouncement[]
}

interface IDispatchProps {
  dispatch: (args: any) => void
  logout: () => void
  setMenuStatus: (isOpen: boolean) => void
  clearScrollID: () => void
  showAnnouncement: (announcement: IAnnouncement, onClose: (result: boolean | null) => void) => void
  checkAnnouncement: (announcement: IAnnouncement, agreed: boolean | null) => void
}

Modal.setAppElement("#root")

const customStylesContent = {
  width: "95%",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  padding: "20px",
  transform: "translate(-50%, -50%)",
  maxWidth: "95%",
  maxHeight: "95%"
}

const customStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: customStylesContent
}

const alertMessage =
  "入力中にページを移動するとこれまでに入力された情報は消去されます。保存する場合はページ末尾の「上記内容を保存」ボタンを押してください。"

class app extends React.Component<IStateProps & IDispatchProps> {
  location: Location | undefined = undefined

  componentDidUpdate() {
    if (this.location == null || window.location.pathname !== this.location.pathname) {
      this.onRouteChanged()
      this.location = { ...window.location }
    }
  }

  onRouteChanged() {
    this.props.dispatch(Actions.setViewState({ isEdit: false }))
  }

  render() {
    const props = this.props
    customStyles.content = Object.assign({}, customStylesContent, props.modalStyle ?? {})

    return (
      <ScrollToTop scrollID={props.scrollID} clearScrollID={props.clearScrollID}>
        {!props.pathname.match(/room\//) && (
          <Header
            isAuthenticated={props.isAuthenticated}
            setMenuStatus={props.setMenuStatus}
            isMenuVisible={props.isMenuVisible}
            meetingAt={props.meetingAt}
            meetingID={props.meetingID}
            pathname={props.pathname}
            noticeCount={props.noticeCount}
            messageCount={props.messageCount}
            noCompletedCount={
              (props.isCompanyCompleted ? 0 : 11) +
              (props.isRecruitmentCompleted ? 0 : 3) +
              (props.isVerifyCompleted ? 0 : 1)
            }
            logout={props.logout}
          />
        )}
        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
        <Modal
          isOpen={props.isModalOpen}
          onRequestClose={props.modalClose}
          style={customStyles}
          shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
        >
          {props.modalComponent &&
            props.modalComponent({
              content: props.modalContent,
              closeModal: props.modalClose
            })}
        </Modal>
        <ErrorBoundary>
          <ErrorViewHandler error={props.error}>
            <Loader isVisible={props.isLoaderVisible} />
            <TabList
              pathname={props.pathname}
              isCompanyCompleted={props.isCompanyCompleted}
              isVerifyCompleted={props.isVerifyCompleted}
              isRecruitmentCompleted={props.isRecruitmentCompleted}
              isLimitedStaff={props.isLimitedStaff}
            />
            <Routes
              isExistStaff={props.isExistStaff}
              isAuthenticated={props.isAuthenticated}
              isRegistrantCompleted={props.isRegistrantCompleted}
              isCompanyCompleted={props.isCompanyCompleted}
              isVerifyCompleted={props.isVerifyCompleted}
            />
          </ErrorViewHandler>
        </ErrorBoundary>

        <Prompt when={props.isEdit} message={alertMessage} />
        {!props.pathname.match(/room\//) && <Footer isAuthenticated={props.isAuthenticated} logout={props.logout} />}

        <AnnouncementObserver
          showAnnouncement={props.showAnnouncement}
          checkAnnouncement={props.checkAnnouncement}
          masquerade={props.masquerade}
          announcements={props.announcements}
        />
      </ScrollToTop>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  const isLimitedStaff =
    !!state.company.staff_id && state.staffs.filter((s) => s.id === state.company.staff_id)[0].role !== "admin"
  return {
    scrollID: state.viewState.scrollID,
    isExistStaff: state.staffs.length > 0,
    isAuthenticated: !!state.auth && !!state.auth.sessionID,
    isRegistrantCompleted: !!state.registrant.position,
    isCompanyCompleted: !!state.company.sales,
    isVerifyCompleted: state.companyVerification.verified,
    isRecruitmentCompleted: !!(state.recruitment.problems.length > 0),
    isMenuVisible: !!state.viewState.menuOpen,
    isLoaderVisible: state.viewState.loaderVisible,
    isModalOpen: state.modal.isOpen,
    modalContent: state.modal.content,
    modalComponent: state.modal.component,
    modalClose: state.modal.close,
    modalStyle: state.modal.style,
    shouldCloseOnOverlayClick: !!state.modal.shouldCloseOnOverlayClick,
    meetingAt: state.company.meeting_at,
    meetingID: state.company.meeting_id,
    noticeCount: state.notices.filter((notice) => !notice.checked).length,
    messageCount: state.rooms.map((r) => r.unread_count).reduce((total: number, val: number) => total + val, 0),
    isLimitedStaff,
    pathname: state.router.location.pathname.replace(/\/$/, ""),
    isEdit: state.viewState.isEdit,
    error: state.error,
    masquerade: state.auth.masquerade,
    announcements: state.announcements,
    companyVerification: state.companyVerification
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  dispatch,
  logout: () => dispatch(logout() as any),
  clearScrollID: () => {
    dispatch(Actions.setViewState({ scrollID: null }))
  },
  setMenuStatus: (isOpen: boolean) => dispatch(Actions.setViewState({ menuOpen: isOpen })),
  showAnnouncement: (announcement: IAnnouncement, onClose: (result: boolean | null) => void) =>
    dispatch(showAnnouncement(announcement, onClose) as any),
  checkAnnouncement: (announcement: IAnnouncement, agreed: boolean | null) =>
    dispatch(Actions.checkAnnouncement(announcement.id, agreed))
})

export default connect(mapStateToProps, mapDispatchToProps)(app)
