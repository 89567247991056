import * as React from "react"
import { Link } from "react-router-dom"
import { recruitmentPath } from "../../routes/paths"
import {
  IProblemCategory,
  IProblemCategoryDetail,
  IStoreState,
  ProblemCheckedBoStatus,
  ProblemStatus,
  PublishType
} from "../../types/state"
import { useSelector } from "react-redux"
import { useMemo, useState } from "react"
import { ErrorMessage } from "../common/ErrorMessage"
import { TitleForm } from "../ProblemForm/TitleForm"
import { CategoryFormGroup } from "../ProblemForm/CategoryFormGroup"
import { PublishTypeForm } from "../ProblemForm/PublishTypeForm"
import { RequestForm } from "../ProblemForm/RequestForm"
import { SituationForm } from "../ProblemForm/SituationForm"
import { GoalForm } from "../ProblemForm/GoalForm"
import { PROBLEM_STATUS_EN } from "../../lib/Definition"
import { UnderConfirmationButton } from "./UnderConfirmationButton"
import { CloseProblemButton } from "./CloseProblemButton"
import { OpenProblemButton } from "./OpenProblemButton"

const PROBLEM_TITLE_LIMIT = 30
const SECTION_OTHER_LIMIT = 20

export const buildValidates = (
  problemCategories: IProblemCategory[],
  problemCategoryDetails: IProblemCategoryDetail[]
) => {
  const validates: { [key: string]: (value: any) => string | null } = {}
  validates["title"] = (val) => {
    return val.title && val.title.length <= PROBLEM_TITLE_LIMIT
      ? null
      : "募集したい業務のタイトルを30文字以内で入力してください。"
  }
  validates["problem_category"] = (val) => {
    return !val.problem_category ? "業務カテゴリーを選択してください。" : null
  }
  validates["problem_category_details"] = (val) => {
    const category = problemCategories.find((pc) => pc.slug === val.problem_category)
    return val.problem_category && !category?.other_flag && val.problem_category_details.length === 0
      ? "業務カテゴリーの詳細を選択してください。"
      : null
  }
  validates["section_other"] = (val) => {
    const category = problemCategories.find((pc) => pc.slug === val.problem_category)
    const categoryDetails = problemCategoryDetails.filter((pcd) => val.problem_category_details.includes(pcd.slug))
    const categoryDetailOther = categoryDetails.find((cd) => cd.other_flag)
    if (val.section_other && val.section_other.length > SECTION_OTHER_LIMIT) {
      if (category?.other_flag) {
        return `「その他」の業務の詳細名は${SECTION_OTHER_LIMIT}文字以内で入力してください。`
      }
      if (categoryDetailOther) {
        return `「${categoryDetailOther.name}」の詳細名は${SECTION_OTHER_LIMIT}文字以内で入力してください。`
      }
    }
    if (val.problem_category && !val.section_other) {
      if (category?.other_flag) {
        return "業務カテゴリー「その他」の業務の詳細名を入力してください。"
      }
      if (categoryDetailOther) {
        return `業務カテゴリーの詳細「${categoryDetailOther.name}」の詳細名を入力してください。`
      }
    }
    return null
  }
  validates["request"] = (val) => {
    return val.request ? null : "契約から3ヶ月経過時点で、パートナー人材に実現して欲しい状態を入力してください。"
  }
  validates["situation"] = (val) => {
    return val.situation ? null : "1の実現が必要だと考えた背景と1の実現ができていない理由を入力してください。"
  }
  validates["goal"] = (val) => {
    return val.goal ? null : "1を実現した後に、さらに実現していきたいことを入力してください。"
  }
  return validates
}

export interface IProblemFormState {
  id: number | null
  title: string
  problem_category: string
  problem_category_details: string[]
  section_other: string
  situation: string
  goal: string
  request: string
  status: ProblemStatus
  checked_bo_status: ProblemCheckedBoStatus
  publish_type: PublishType
  expired_date: Date | null
}

export interface IProblemFormDispatchProps {
  alert: (content: string) => Promise<void>
  setViewState: (args: { [key: string]: any }) => void
  onSubmit: (problemForm: IProblemFormState) => void
}

export interface IProblemFormStateProps {
  initialFormState: IProblemFormState
  isStatusEditable?: boolean
}

export interface IProblemFormProps extends IProblemFormDispatchProps, IProblemFormStateProps {}

export const ProblemForm = ({
  initialFormState,
  isStatusEditable,
  alert,
  setViewState,
  onSubmit
}: IProblemFormProps) => {
  const viewState = useSelector((state: IStoreState) => state.viewState)
  const problemCategories = useSelector((state: IStoreState) => state.problemCategories)
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  const [formState, setFormState] = useState(initialFormState)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const validates = useMemo(() => {
    return buildValidates(problemCategories, problemCategoryDetails)
  }, [problemCategories, problemCategoryDetails])

  const updateValue = (args: { [key: string]: any }) => {
    setFormState({
      ...formState,
      title: args.title,
      problem_category: args.problem_category,
      problem_category_details: args.problem_category_details,
      section_other: args.section_other,
      request: args.request,
      situation: args.situation,
      goal: args.goal,
      status: args.status,
      checked_bo_status: args.checked_bo_status,
      publish_type: args.publish_type,
      expired_date: args.expired_date
    })
  }

  return (
    <main className="contentsWrapper">
      <form
        className="pure-form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault()
          const error = Object.keys(validates).find((field) => {
            return validates[field](formState)
          })
          if (error) {
            alert("入力に誤りがあります。").then(() => {
              setViewState({ scrollID: error })
              setIsSubmitted(true)
            })
            return
          }
          onSubmit(formState)
        }}
      >
        <div className="formWrap">
          {viewState.error && <ErrorMessage cls="mb20">{viewState.error}</ErrorMessage>}
          <div className="formRow formRowNoBorder">
            <label className="formHead">
              募集案件について <span className="label label_req">必須</span>
            </label>
            <div className="formData">
              <p className="formLead">
                <b>募集したい案件の内容をご記入ください</b>
              </p>
              <div className="formDataBox separateForm">
                <TitleForm
                  problem={formState}
                  isSubmitted={isSubmitted}
                  validates={validates}
                  updateValue={updateValue}
                />

                <CategoryFormGroup
                  problem={formState}
                  isSubmitted={isSubmitted}
                  validates={validates}
                  updateValue={updateValue}
                />

                <PublishTypeForm problem={formState} updateValue={updateValue} />

                <RequestForm
                  problem={formState}
                  isSubmitted={isSubmitted}
                  validates={validates}
                  updateValue={updateValue}
                />

                <SituationForm
                  problem={formState}
                  isSubmitted={isSubmitted}
                  validates={validates}
                  updateValue={updateValue}
                />

                <GoalForm
                  problem={formState}
                  isSubmitted={isSubmitted}
                  validates={validates}
                  updateValue={updateValue}
                />

                {isStatusEditable && (
                  <div className="formData_btnBox mt20 mb08">
                    {formState.status === PROBLEM_STATUS_EN.closed && (
                      <OpenProblemButton problem={formState} updateValue={updateValue} />
                    )}
                    {formState.status === PROBLEM_STATUS_EN.active && (
                      <CloseProblemButton problem={formState} updateValue={updateValue} />
                    )}
                    {(formState.status === PROBLEM_STATUS_EN.new || formState.status === PROBLEM_STATUS_EN.resume) && (
                      <UnderConfirmationButton />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="formBtnArea">
            <Link to={recruitmentPath} className="btn">
              キャンセル
            </Link>
            <button type="submit" className="btn btnSave">
              上記内容で保存
            </button>
          </div>
        </div>
      </form>
    </main>
  )
}
