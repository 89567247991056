import * as React from "react";
import { useEffect } from "react";
import * as _ from "lodash";
import { Grid, FormControl, TextField } from "@material-ui/core";
import { InputError } from "../common/InputError";
import { ISecondInterview } from "../../../types/state";
import { IndexedLabel } from "../common/IndexedLabel";
import style from "../../../style/MeetingRequestForm.module.scss";

export type OfflineMeetingFieldsType = Pick<
  ISecondInterview,
  "location" | "schedule_comment"
>;

export interface IOfflineMeetingFieldsProps {
  confirming: boolean;
  onChange?: (values: OfflineMeetingFieldsType) => any;
  isValid?: (result: boolean) => any;
}

export const OfflineMeetingFields = (props: IOfflineMeetingFieldsProps) => {
  const [state, setState] = React.useState<OfflineMeetingFieldsType>({
    location: "",
    schedule_comment: "",
  });

  const [errors, setErrors] = React.useState({
    location: false,
    schedule_comment: false,
  });

  useEffect(() => {
    props.onChange && props.onChange(state);
    setErrors({
      location: _.isEmpty(state.location),
      schedule_comment: _.isEmpty(state.schedule_comment),
    });
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    let errorCount = 0;
    _.forEach(errors, (hasError) => {
      hasError && errorCount++;
    });
    props.isValid && props.isValid(errorCount === 0);
    // eslint-disable-next-line
  }, [errors]);

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ paddingBottom: 20 }}
    >
      <IndexedLabel index={2} required>
        面談の実施場所をご記入ください。
      </IndexedLabel>

      <div className={style.selectAreaWay}>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            color="secondary"
            className="pure-form"
            multiline
            fullWidth
            rows={4}
            placeholder={`記入例:\n岡山県○○市○○町\n株式会社〇〇オフィス`}
            disabled={props.confirming && !errors.location}
            error={props.confirming && errors.location}
            onChange={(e) => {
              const value = e.target.value;
              setState({ ...state, location: value });
            }}
          />
          <InputError
            show={props.confirming && errors.location}
            message="面談の日時候補が未入力です。"
          />
        </FormControl>
      </div>
      <IndexedLabel index={3} required>
        面談の日時候補をご記入ください。
      </IndexedLabel>

      <FormControl fullWidth>
        <TextField
          variant="outlined"
          color="secondary"
          className="pure-form"
          multiline
          fullWidth
          rows={4}
          placeholder={`記入例:\n2月22日以降の水曜か木曜が望ましい。`}
          disabled={props.confirming && !errors.schedule_comment}
          error={props.confirming && errors.schedule_comment}
          onChange={(e) => {
            const value = e.target.value;
            setState({ ...state, schedule_comment: value });
          }}
        />
        <InputError
          show={props.confirming && errors.schedule_comment}
          message="面談の日時候補が未入力です。"
        />
      </FormControl>
    </Grid>
  );
};
