import * as React from "react";
import style from "../../style/MonthlyQuestionnaire/MonthlyQuestionnaireInfo.module.scss";
import styleRec from "../../style/Recruitment.module.scss";
import { strToTag } from "../../lib/Util";
import { MonthlyQuestionnaireSatisfactionType } from "../../types/state"
import { SelectStarInfo } from "../SelectStarInfo"
import clsx from "clsx"
import {
  MONTHLY_QUESTIONNAIRE_DISSATISFACTION,
  MONTHLY_QUESTIONNAIRE_SATISFACTION,
  MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS
} from "../../lib/Definition"

interface IMonthlyQuestionnaireInfoProps {
  satisfaction?: number;
  satisfactions?: MonthlyQuestionnaireSatisfactionType[];
  satisfaction_other?: string;
  dissatisfactions?: MonthlyQuestionnaireSatisfactionType[];
  dissatisfaction_other?: string
  comment?: string
}

export const MonthlyQuestionnaireInfo = ({
  satisfaction,
  satisfactions,
  satisfaction_other,
  dissatisfactions,
  dissatisfaction_other,
  comment
}: IMonthlyQuestionnaireInfoProps) => {
  return (
    <div className={styleRec.listArea + " mb30"}>
      <div className={styleRec.listRow}>
        <div className={styleRec.listHead}>
          今月の満足度
        </div>
        <div className={clsx(styleRec.listData, "alLeft")}>
          {satisfaction && (
            <SelectStarInfo
              name="satisfaction"
              value={satisfaction}
              max={5}
            />
          )}
        </div>
      </div>

      <div className={styleRec.listRow}>
        <div className={styleRec.listHead}>
          満足した点<br />(複数回答)
        </div>
        <div className={clsx(styleRec.listData, "alLeft")}>
          {satisfactions && (
            <ul className={style.ul}>
              {Object.keys(MONTHLY_QUESTIONNAIRE_SATISFACTION).map((key) => {
                if (!satisfactions.includes(key as MonthlyQuestionnaireSatisfactionType)) return null
                return (
                  <li key={`satisfactions-${key}`} className={style.li}>
                    {key === MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.other
                      ? `${MONTHLY_QUESTIONNAIRE_SATISFACTION[key]}（${satisfaction_other}）`
                      : MONTHLY_QUESTIONNAIRE_SATISFACTION[key]
                    }
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>

      <div className={styleRec.listRow}>
        <div className={styleRec.listHead}>
          不満な点<br />(複数回答)
        </div>
        <div className={clsx(styleRec.listData, "alLeft")}>
          {dissatisfactions && (
            <ul className={style.ul}>
              {Object.keys(MONTHLY_QUESTIONNAIRE_DISSATISFACTION).map((key) => {
                if (!dissatisfactions.includes(key as MonthlyQuestionnaireSatisfactionType)) return null
                return (
                  <li key={`dissatisfactions-${key}`} className={style.li}>
                    {key === MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.other
                      ? `${MONTHLY_QUESTIONNAIRE_DISSATISFACTION[key]}（${dissatisfaction_other}）`
                      : MONTHLY_QUESTIONNAIRE_DISSATISFACTION[key]
                    }
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>

      <div className={styleRec.listRow}>
        <div className={styleRec.listHead}>
          JOINSへ<br />連絡したいこと
        </div>
        <div className={clsx(styleRec.listData, "alLeft")}>
          {strToTag(comment)}
        </div>
      </div>
    </div>
  )
}
