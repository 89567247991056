import * as React from "react"
import { Link, NavLink } from "react-router-dom"
import style from "../style/Header.module.scss"
import {
  homePath,
  registrantPath,
  roomsPath,
  scoutPath,
  noticePath,
  editRegistrantPath,
  editCompanyPath,
  companyPath,
  recruitmentPath,
  staffsPath,
  editStaffsPath,
  psychologicalSafetyPath,
  firstPsychologicalSafetyPath,
  editFirstPsychologicalSafetyPath,
  checkTrustPath,
  problemNewPath,
  problemEditPath,
  problemCopyPath
} from "../routes/paths"
import SideBar from "./SideBar"

export default function Header(props: {
  pathname: string
  isAuthenticated: boolean
  setMenuStatus: (isOpen: boolean) => void
  isMenuVisible: boolean
  noticeCount: number
  messageCount: number
  noCompletedCount: number
  meetingAt: Date | null
  meetingID: number | null
  logout: () => void
}) {
  const isMyPage =
    [
      registrantPath,
      editRegistrantPath,
      editCompanyPath,
      companyPath,
      recruitmentPath,
      problemNewPath,
      staffsPath,
      editStaffsPath,
      psychologicalSafetyPath,
      firstPsychologicalSafetyPath,
      editFirstPsychologicalSafetyPath,
      checkTrustPath
    ].indexOf(props.pathname) !== -1 ||
    props.pathname.startsWith(problemEditPath) ||
    props.pathname.startsWith(problemCopyPath)

  const [state, setState] = React.useState({
    isMobileView: window.innerWidth < 1200,
    isSideBarOpen: false
  })
  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1200
        ? setState((state) => ({ ...state, isMobileView: true }))
        : setState((state) => ({ ...state, isMobileView: false }))
    }
    setResponsiveness()
    window.addEventListener("resize", () => setResponsiveness())
    return () => {
      window.removeEventListener("resize", () => setResponsiveness())
    }
  }, [])

  const sideBarOpen = () => setState({ ...state, isSideBarOpen: true })
  const sideBarClose = () => setState({ ...state, isSideBarOpen: false })

  const { isMobileView } = state

  return (
    <>
      {isMobileView && (
        <SideBar
          noticeCount={props.noticeCount}
          noCompletedCount={props.noCompletedCount}
          isSideBarOpen={state.isSideBarOpen}
          sideBarClose={() => sideBarClose()}
          logout={() => props.logout()}
        />
      )}

      <header style={{ top: 0, zIndex: 10, position: "sticky" }} className={style.customWrapper}>
        {isMobileView ? (
          // window.innerWidth 1200 以下 のUI
          <div className={style.headerInner}>
            {props.isAuthenticated && <div className={style.menuIcon} onClick={sideBarOpen} />}
            {props.isAuthenticated && props.noticeCount + props.noCompletedCount > 0 && (
              <span className={style.menuBadge}></span>
            )}

            {props.isAuthenticated ? (
              <Link to={homePath} className={style.joinsLogo} title="JOINS" />
            ) : (
              <a title="JOINS" href={process.env.REACT_APP_API_URL} className={style.joinsLogo}>
                {" "}
              </a>
            )}

            {props.isAuthenticated && (
              <>
                <Link to={roomsPath}>
                  <div className={style.chatIcon} />
                  {props.messageCount > 0 && <span className={style.chatBadge}>{props.messageCount}</span>}
                </Link>
              </>
            )}
          </div>
        ) : (
          <div className={style.headerInner}>
            <div className={style.headerLeft}>
              <div className="pure-menu">
                {props.isAuthenticated ? (
                  <Link to={homePath} className={style.headerLogo + " pure-menu-heading"} title="JOINS">
                    JOINS
                  </Link>
                ) : (
                  <a
                    title="JOINS"
                    href={process.env.REACT_APP_ROOT_URL + "/business-lp"}
                    className={style.headerLogo + " pure-menu-heading"}
                  >
                    JOINS
                  </a>
                )}
              </div>
            </div>
            <div className={style.headerRight}>
              <div className={style.customMenu3}>
                {props.isAuthenticated && (
                  <div className={style.secondaryMenu}>
                    <NavLink
                      exact
                      activeClassName={style.currentHeaderManagement + " " + style.currentItem}
                      to={homePath}
                      className={style.headerManagement}
                    >
                      人材管理
                    </NavLink>
                    <NavLink
                      activeClassName={style.currentHeaderScout + " " + style.currentItem}
                      to={`${scoutPath}`}
                      className={style.headerScout}
                    >
                      スカウト
                    </NavLink>
                    <NavLink
                      activeClassName={style.currentHeaderNotice + " " + style.currentItem}
                      to={noticePath}
                      className={style.headerNotice}
                    >
                      お知らせ
                      {props.noticeCount > 0 && <span className={style.badge}>{props.noticeCount}</span>}
                    </NavLink>
                    <NavLink
                      activeClassName={style.currentHeaderMessage + " " + style.currentItem}
                      to={roomsPath}
                      className={style.headerMessage}
                    >
                      メッセージ
                      {props.messageCount > 0 && <span className={style.badge}>{props.messageCount}</span>}
                    </NavLink>
                    <NavLink
                      activeClassName={style.currentHeaderCompany + " " + style.currentItem}
                      to={registrantPath}
                      className={isMyPage ? style.currentHeaderCompany + " " + style.currentItem : style.headerCompany}
                    >
                      <span>マイページ</span>
                      {props.noCompletedCount > 0 && <span className={style.badge}></span>}
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  )
}
