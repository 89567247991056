import * as React from "react";
import styles from "../../style/ActivateStaff.module.scss"
import { loginPath } from "../../routes/paths"
import { Link } from "react-router-dom"
import clsx from "clsx"

export const Activated = () => {
  return (
    <main>
      <div className="container">
        <h1 className={styles.pageTitle}>
          JOINS企業向け管理ツール
          <br />
          担当者登録フォーム
        </h1>
        <div className={styles.leadText}>
          すでに登録が完了しています。
          <br />
          登録したメールアドレスでログインをお願いします。
        </div>
        <div className="formBtnArea formBtnAreaSingle">
          <Link to={loginPath} className={clsx("btn btnLogin", styles.btnLogin)}>
            ログイン画面へ
          </Link>
        </div>
      </div>
    </main>
  );
};
