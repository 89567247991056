import React from "react"
import { TextAreaField } from "./TextAreaField"
import style from "../style/Recruitment.module.scss"
import { UnConfirmationToRequestInterview } from "./UnConfirmationToRequestInterview"
import { SelectField } from "./SelectField"
import { IProblem, IStoreState } from "../types/state"
import { useSelector } from "react-redux"
import { RECRUITMENT_STATUS_EN } from "../lib/Definition"

interface ScoutFormProps {
  scoutableProblems: IProblem[]
  isEntriedBefore: boolean // スカウトリクエスト(再申し込み)かどうかのフラグ
  isNeededVerify: boolean
  hasActiveProblem: boolean
  isEnteredForAllProblems: boolean
  onClickBackButton: () => void
  onClickSave: (problemId: number, message: string) => void
}

export const ScoutForm = ({
  scoutableProblems,
  isEntriedBefore,
  isNeededVerify,
  hasActiveProblem,
  isEnteredForAllProblems,
  onClickBackButton,
  onClickSave
}: ScoutFormProps) => {
  const [scout, setScout] = React.useState({ message: "", isSubmitted: false })
  const [problem, setProblem] = React.useState<IProblem | undefined>(scoutableProblems[0])
  const [completed, setCompleted] = React.useState<Boolean>(false)

  const recruitment = useSelector((state: IStoreState) => state.recruitment)

  const title = isEntriedBefore ? "再面談申し込みフォーム" : "面談申し込みフォーム"
  const message = isEntriedBefore ? "再面談申し込みメッセージ" : "スカウトメッセージ"
  const request = isEntriedBefore ? "再申し込み" : "申し込み"

  const problemSelectMaster = React.useMemo(() => {
    const hash = {}
    scoutableProblems.forEach((p) => {
      if (p.id) hash[p.id] = p.title
    })
    return hash
  }, [scoutableProblems])

  const scoutable = React.useMemo(() => {
    return !isNeededVerify && hasActiveProblem && recruitment.status === RECRUITMENT_STATUS_EN.active
  }, [isNeededVerify, hasActiveProblem, recruitment])

  return (
    <>
      <h3 className="contentsSubTitle">{title}</h3>
      <UnConfirmationToRequestInterview
        isNeededVerify={isNeededVerify}
        hasActiveProblem={hasActiveProblem}
        isEnteredForAllProblems={isEnteredForAllProblems}
      />
      <div className={style.scoutInfo}>
        {!completed && (
          <>
            <div className={style.scoutInfoText}>
              以下のパートナー人材との一次面談を申し込む場合は、
              <b>パートナー人材への{message}</b>
              を記入した上で、
              <br className="SPHide" />
              「入力内容を確認する」ボタンをクリックしてください。
            </div>
            <div className={"pure-form " + style.scoutInfoForm}>
              <SelectField
                label="対象の募集案件"
                name="problem"
                required={true}
                values={{ problem: problem?.id }}
                master={problemSelectMaster}
                updateValue={(val: string) => {
                  const problem = scoutableProblems.find((p) => p.id === parseInt(val))
                  setProblem(problem)
                }}
              />
              <TextAreaField
                disabled={isNeededVerify}
                name="message"
                label={message}
                required={true}
                values={scout}
                validates={{
                  message: (e) => (e.message ? null : "メッセージを入力してください")
                }}
                updateValue={(val) => setScout({ ...scout, message: val["message"] })}
              />
              <div className="formBtnArea">
                <button className={"btn btnEdit"} onClick={onClickBackButton}>
                  戻る
                </button>
                <button
                  className="btn btnSave"
                  disabled={!scoutable}
                  onClick={() => {
                    setScout({ ...scout, isSubmitted: true })
                    if (scout.message) {
                      setCompleted(true)
                    }
                  }}
                >
                  入力内容を確認する
                </button>
              </div>
            </div>
          </>
        )}
        {completed && (
          <div>
            <div className={style.scoutInfoText}>
              以下の内容でよろしければ、
              <b className="joinsPinkTxt">一次面談（Web会議）{request}を確定する</b>
              ボタンをクリックしてください。
            </div>
            <div className={style.scoutInfoText}>対象の募集案件：{problem?.title}</div>
            <div className={style.scountInfoReply + " mb40mb32"}>
              <div className={style.scoutInfoTitle}>パートナー人材へのメッセージ</div>
              <div className={style.scoutInfoMessage}>{scout["message"]}</div>
            </div>
            <div className="formBtnArea">
              <button
                className={"btn btnEdit"}
                onClick={(e) => {
                  e.preventDefault()
                  setCompleted(false)
                }}
              >
                修正する
              </button>
              <button
                className="btn btnSave"
                onClick={() => {
                  if (!problem?.id) return
                  onClickSave(problem.id, scout.message)
                }}
              >
                一次面談
                <br className="PCHide" />
                （Web会議）
                <br className="PCHide" />
                {request}を
                <br className="PCHide" />
                確定する
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
