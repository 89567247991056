import * as React from "react";

export interface ICancelButton {
  disabled?: boolean;
  onClick: () => void;
}

const Sendbutton = (props: ICancelButton) => {
  return (
    <button
      className="btn btnSave"
      disabled={props.disabled}
      onClick={() => props.onClick()}
    >
      送信
    </button>
  );
};

export default Sendbutton;
