import * as React from "react";
export default function ProfileRegisterThanks(props: any) {
  return (
    <main>
      <div className="signUpThanks">
        <div className="signUpThanksTitle">
          企業概要のご入力ありがとうございました。
        </div>
        <p>引き続き、<span className="withUnderline">企業審査の申請</span>をお願いいたします。</p>
        <p>
          審査完了後、募集概要のご入力および当社に登録する人材への募集開始という流れになります。
        </p>
      </div>
      <div className="formBtnArea formBtnAreaSingle">
        <button className="btn btnLogin" onClick={() => props.closeModal()}>
          {"企業審査の申請に進む"}
        </button>
      </div>
    </main>
  );
}
