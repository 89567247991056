import * as React from "react";
import { homePath, firstInterviewPath } from "../../routes/paths";
import { IEntry, VideoType } from "../../types/state"
import { RescheduleForm } from "../RescheduleForm";
import { PartnerManagementHeader } from "../headers/PartnerManagementHeader";
import { PartnerInfoHeader } from "../headers/PartnerInfoHeader";
import { MEETING_KIND, MEETING_TYPE, VIDEO_TYPE } from "../../lib/Definition"
import { ScheduleInfo } from "./ScheduleInfo";
import { ScheduleDateSelector } from "../forms/common/ScheduleDateSelector";
import { ScheduleConfirmationForm } from "../forms/ScheduleConfirmationForm";
import { IndexedLabel } from "../forms/common/IndexedLabel";
import { Link } from "react-router-dom";
import style from "../../style/MeetingRequestForm.module.scss";
import { nextAction } from "../../lib/Util";

export interface IScheduleFormDispatchProps {
  changePath: (path: string) => void;
  alert: (msg: string) => void;
  updateValue: (state: { [key: string]: any }) => void;
  createMeeting: (
    entry_id: number,
    scheduleTime: number,
    video_type: VideoType,
    kind: MEETING_KIND
  ) => void;
  reSchedule: (entry_id: number, message: string) => void;
}

export interface IScheduleFormStateProps {
  index: number;
  kind: MEETING_KIND;
  type: MEETING_TYPE;
  entry: IEntry;
  scheduleTime: number;
  availableTimes: number[];
  selectedDate: Date | null;
  viewState: { loaderVisible: boolean };
}

export interface IScheduleFormProps extends IScheduleFormDispatchProps, IScheduleFormStateProps {}

export const ScheduleForm = (props: IScheduleFormProps) => {
  const { entry, availableTimes, selectedDate, viewState } = props
  const [reschedule, setReschedule] = React.useState(false)

  const hasNoAvailableSchedules = React.useMemo(() => (
    availableTimes?.length === 0 && !!nextAction(entry).find(a => ['日時指定', '二次面談日時選択'].includes(a))
  ), [availableTimes, entry])

  if (!entry) return null

  return (
    <>
      <div className="contentsWrapper-transparent">
        <div>
          <PartnerManagementHeader />
          <PartnerInfoHeader entry={entry} />
          {selectedDate && (
            <div className="container">
              <p className="PCCenterSPLeft mb40mb32">
                面談日時が一度確定した後に
                <span className="joinsOrangeTxt">面談日時を変更したい場合</span>
                は、
                <br className="SPHide" />
                <Link to={`${firstInterviewPath}/${entry.id}`}>
                  Web面談ページ
                </Link>
                内の中程にある<b>「面談日時候補の再設定を依頼する」</b>
                ボタンをクリックして、
                <br className="SPHide" />
                日程の再調整を人材に依頼してください。
              </p>
            </div>
          )}
          {!selectedDate && !viewState.loaderVisible && (
            <div>
              <ScheduleInfo kind={props.kind} type={props.type} />
              <ScheduleDateSelector
                label={
                  <IndexedLabel index={1}>
                    下記から希望日時をご選択ください（面談時間は最大1時間を予定しています）。
                  </IndexedLabel>
                }
                updateValue={props.updateValue}
                setReschedule={setReschedule}
                reschedule={reschedule}
                availableTimes={props.availableTimes}
                scheduleTime={props.scheduleTime}
              />

              {props.scheduleTime && (
                <ScheduleConfirmationForm
                  label={
                    <IndexedLabel index={2}>
                      選択した日時をご確認の上、[送信]ボタンをクリックしてください。
                    </IndexedLabel>
                  }
                  scheduleTime={props.scheduleTime}
                  onCancel={() => props.changePath(homePath)}
                  onSubmit={() =>
                    props.createMeeting(
                      props.entry.id,
                      props.scheduleTime,
                      VIDEO_TYPE.bellbird_video,
                      props.kind
                    )
                  }
                />
              )}

              {hasNoAvailableSchedules && (
                <p className={style.allSchedulesExpiredMessage}>
                  パートナー人材が提出した候補日時が全て終了したか、選択できない日時でした。<br />
                  以下のフォームから、面談日時の再設定を依頼してください。
                </p>
              )}

              {(reschedule || hasNoAvailableSchedules) && (
                <>
                  <h3 className="contentsSubTitle">
                    面談可能な日時がなかったとき（再選択を依頼する）
                  </h3>
                  <RescheduleForm
                    entry={props.entry}
                    close={() => setReschedule(false)}
                    reSchedule={props.reSchedule}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
