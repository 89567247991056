import * as React from "react"
import { useState } from "react"
import Button from "@material-ui/core/Button"
import Modal from "react-modal"
import IconButton from "@material-ui/core/IconButton"
import helpPublicSetting from "../../images/helpPublicSetting.svg"
import { RadioButtons } from "../RadioButtons"
import { PUBLISH_TYPE_LABEL, PUBLISH_TYPE_STR } from "../../lib/Definition"
import styles from "../../style/EditRecruitmentForm.module.scss"
import { IProblemFormState } from "./index"

const modalStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  content: {
    top: "50%",
    left: "50%",
    backgroundColor: "#FFFFFF",
    padding: "24px 0 0",
    borderRadius: "10px",
    width: "90%",
    height: "fit-content",
    maxWidth: "820px",
    transform: "translate(-50%, -50%)",
    overflow: "hidden"
  }
}

interface IPublishTypeFormDispatchProps {
  updateValue: (args: { [key: string]: any }) => void
}

interface IPublishTypeFormStateProps {
  problem: IProblemFormState
}

interface IPublishTypeFormProps extends IPublishTypeFormDispatchProps, IPublishTypeFormStateProps {}

export const PublishTypeForm = ({ problem, updateValue }: IPublishTypeFormProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  return (
    <div id="publish_type">
      <label className="pure-u-1 problemLabel" onClick={(e) => e.preventDefault()}>
        公開設定
        <IconButton
          className="btnHelp"
          onClick={() => {
            setIsEditModalOpen(true)
          }}
        />
      </label>
      <Modal isOpen={isEditModalOpen} onRequestClose={() => setIsEditModalOpen(false)} style={modalStyles}>
        <div className={styles.modalContent}>
          <span className={styles.disclosedToNonMembersTitle}>非会員への公開内容について</span>
          <Button
            className={styles.closeButton}
            onClick={() => {
              setIsEditModalOpen(false)
            }}
          >
            ×
          </Button>
          <div>
            <img className={styles.helpPublicSettingImg} alt="公開設定表" src={helpPublicSetting} />
            <div className={styles.cautionWrap}>
              <p className={styles.caution}>
                ※一般公開（部分公開）では、企業名、設立年、資本金、役員数などは表示されません。
                <br className="SPHide" />
                所在の都道府県、業界、企業規模（概算社員数、売上）のみ公開します。
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <RadioButtons
        name="publish_type"
        value={problem.publish_type}
        master={PUBLISH_TYPE_STR}
        descriptions={PUBLISH_TYPE_LABEL}
        rootCls={styles.radioButtons}
        onChange={(key) => {
          updateValue({
            ...problem,
            publish_type: key
          })
        }}
      />
    </div>
  )
}
