import * as React from "react"
import { IStoreState } from "../../types/state"
import { addDate } from "../../lib/Util"
import DateWithOffset from "../../lib/DateWithOffset"
import { PROBLEM_CHECKED_BO_STATUS_EN, PROBLEM_STATUS_EN } from "../../lib/Definition"
import { IProblemFormState } from "./index"
import { useSelector } from "react-redux"
import { useMemo } from "react"

interface ICloseProblemButtonDispatchProps {
  updateValue: (args: { [key: string]: any }) => void
}

interface ICloseProblemButtonStateProps {
  problem: IProblemFormState
}

interface ICloseProblemButtonProps extends ICloseProblemButtonDispatchProps, ICloseProblemButtonStateProps {}

export const CloseProblemButton = ({ problem, updateValue }: ICloseProblemButtonProps) => {
  const recruitment = useSelector((state: IStoreState) => state.recruitment)

  const originalProblem = useMemo(() => {
    return recruitment.problems.find((p) => p.id === problem.id)
  }, [recruitment.problems, problem])

  const expiredDate = useMemo(() => {
    if (!originalProblem) return null
    if (
      originalProblem.status === PROBLEM_STATUS_EN.closed &&
      originalProblem.checked_bo_status === PROBLEM_CHECKED_BO_STATUS_EN.done
    ) {
      return originalProblem.expired_date
    }
    return addDate(new DateWithOffset(), -1)
  }, [originalProblem])

  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        updateValue({
          ...problem,
          status: PROBLEM_STATUS_EN.closed,
          checked_bo_status: PROBLEM_CHECKED_BO_STATUS_EN.done,
          expired_date: expiredDate
        })
      }}
      className="btn btnClose"
    >
      募集終了
    </button>
  )
}
