import * as React from "react";
import { useEffect } from "react";

export interface IRadioSelectorOption {
  label: string;
  value: any;
}

export interface IRadioSelectorProps {
  options: IRadioSelectorOption[];
  disabled?: boolean;
  onChange?: (option: IRadioSelectorOption) => any;
}

export const RadioSelectors = (props: IRadioSelectorProps) => {
  const [selected, setSelected] = React.useState<IRadioSelectorOption>(
    props.options[0]
  );

  const handleChange = props.onChange;
  useEffect(() => {
    handleChange && handleChange(selected);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <>
      {props.options.map((option) => (
        <label className="selectBtn" key={option.label}>
          <input
            disabled={props.disabled}
            type="radio"
            checked={selected.value === option.value}
            onChange={() => {
              setSelected(option);
            }}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </>
  );
};
