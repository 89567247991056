import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Actions } from "../actions";
import { signUp } from "../thunks";
import SignUpForm, {
  ISignUpDispatchProps,
  ISignUpStateProps,
} from "../components/SignUpForm";
import { IStoreState, IAjaxError } from "../types/state";

class SignUp extends React.Component<
  ISignUpDispatchProps & ISignUpStateProps & { fetchIntroducers: () => void }
> {
  componentDidMount() {
    this.props.fetchIntroducers();
  }

  render() {
    return <SignUpForm {...this.props} />;
  }
}
function mapStateToProps(state: IStoreState): ISignUpStateProps {
  return {
    first_name: state.viewState.first_name || "",
    last_name: state.viewState.last_name || "",
    first_kana: state.viewState.first_kana || "",
    last_kana: state.viewState.last_kana || "",
    email: state.viewState.email || "",
    password: state.viewState.password || "",
    name: state.viewState.name || "",
    position: state.viewState.position || "",
    department: state.viewState.department || "",
    tel: state.viewState.tel || "",
    agreement: state.viewState.agreement || false,
    trigger: state.viewState.trigger || "",
    trigger_other: state.viewState.trigger_other || "",
    introducer_id: state.viewState.introducer_id || 0,
    introducers: [
      { id: 0, name: "" },
      ...state.introducers,
      { id: -1, name: "該当なし" },
    ].reduce((prev, current) => ({ ...prev, [current.id]: current.name }), {}),
    isSubmitted: state.viewState.isSubmitted || false,
    introducer_permit: state.viewState.introducer_permit || false,
    error: state.viewState.error || "",
    errorField: state.viewState.errorField || "",
  };
}
function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): ISignUpDispatchProps & { fetchIntroducers: () => void } {
  return {
    signUp: (params) => {
      dispatch(
        signUp({
          ...params,
          introducer_id: params.introducer_id < 0 ? 0 : params.introducer_id,
        }) as any
      ).catch((e: IAjaxError) => {
        dispatch(
          Actions.setViewState({
            error: e.payload.error.message,
            errorField: e.payload.error.field,
            scrollID: e.payload.error.field,
          }) as any
        );
      });
    },
    fetchIntroducers: () => {
      return dispatch(Actions.fetchIntroducers() as any);
    },
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
