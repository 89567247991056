import * as React from "react";
import { Link } from "react-router-dom";
import { loginPath } from "../routes/paths";

export default function RenewalPasswordFinish() {
  return (
    <main className="is-outside">
      <div className="container">
        <p>
          パスワードを更新しました。
          <br />
          再度ログインをお願いします。
          <br />
          <br />
        </p>
        <div className="formBtnArea formBtnAreaSingle">
          <Link to={loginPath} className="btn btnEdit">
            ログインページへ
          </Link>
        </div>
      </div>
    </main>
  );
}
