/// <reference path="./types/index.d.ts" />
import * as React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { Actions } from "./actions"
import "purecss/build/pure.css"
import "purecss/build/grids-responsive.css"
import "./App.scss"
import { uniqueStr, convertUidToUuid } from "./lib/Util"
import App from "./containers/App"
import getStoreAndHistory from "./store/configureStore"
import { ConnectedRouter } from "connected-react-router"
import { StylesProvider, ThemeProvider, createTheme } from "@material-ui/core/styles"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

const { store, history } = getStoreAndHistory()
let uid = localStorage.getItem("uid")
if (!uid) {
  uid = uniqueStr()
  localStorage.setItem("uid", uid)
}

let gid: string
if (window.location.href.match(/gid=/)) {
  const result = window.location.href.split("gid=").pop() as string
  gid = result.replace(/&.*$/, "")
  localStorage.setItem("gid", gid)
} else {
  gid = localStorage.getItem("gid") as string
  if (!gid) {
    gid = convertUidToUuid(uid)
    localStorage.setItem("gid", gid)
  }
}

store.dispatch(Actions.setUID(uid, gid))

if (window.location.href.match(/session_id=/)) {
  const result = window.location.href.split("session_id=").pop() as string
  const sessionID = result.replace(/&.*$/, "")
  store.dispatch(Actions.recoverSession(sessionID, true))
} else {
  const partnerState = sessionStorage.getItem("joins_company_state")
  if (partnerState) {
    store.dispatch(Actions.recoverData(JSON.parse(partnerState)))
  } else {
    const sessionID = localStorage.getItem("joins_company_session_id")
    if (sessionID) {
      store.dispatch(Actions.recoverSession(sessionID, false))
    }
  }
}

const theme = createTheme({
  palette: {
    secondary: {
      main: "#000"
    }
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Helvetica Neue"',
      '"游ゴシック体"',
      "YuGothic",
      '"游ゴシック Medium"',
      '"Yu Gothic Medium"',
      '"游ゴシック"',
      '"Yu Gothic"',
      '"メイリオ"',
      "Meiryo",
      "sans-serif",
      "Arial"
    ].join(",")
  }
})

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN_COMPANY}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

const AppRender = () => {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  )
}

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)
const result = store.dispatch(Actions.initialData()) as any
result.then(() => {
  root.render(<AppRender />)
})
