import { useEffect } from 'react'
import { IAnnouncement } from '../types/state'

interface IAnnouncementObserverDispatchProps {
  showAnnouncement: (
    announcement: IAnnouncement,
    onClose: (result: boolean | null) => void
  ) => void;
  checkAnnouncement: (announcement: IAnnouncement, agreed: boolean | null) => void;
}

interface IAnnouncementObserverStateProps {
  masquerade: boolean;
  announcements: IAnnouncement[];
}

interface IAnnouncementObserverProps extends IAnnouncementObserverDispatchProps, IAnnouncementObserverStateProps {}

export const AnnouncementObserver = (props: IAnnouncementObserverProps) => {
  const { masquerade, announcements, showAnnouncement, checkAnnouncement } = props

  useEffect(() => {
    if (masquerade) return

    const showNextAnnouncement = (announcements: IAnnouncement[], idx: number) => {
      return new Promise<number>((resolve) => {
        const announcement = announcements[idx]
        if (announcement) {
          showAnnouncement(announcement, (result) => {
            checkAnnouncement(announcements[idx], result)
            resolve(idx + 1)
          })
        }
      }).then(async (nextIdx) => {
        if (nextIdx < announcements.length)
          await setTimeout(async () => {
            await showNextAnnouncement(announcements, nextIdx)
          }, 200)
      })
    }

    showNextAnnouncement(announcements, 0)
  }, [masquerade, announcements, showAnnouncement, checkAnnouncement])

  return null;
}
