import React from "react"
import moment from "moment"
import "./YearMonthSelector.scss"

import { Box, FormControl, Grid, Select } from "@material-ui/core"
import { ErrorMessage } from "../../../common/ErrorMessage"

interface IYearMonthSelectorState {
  year: number
  month: number
}

export interface IYearMonthSelector {
  onUpdate: (state: IYearMonthSelectorState) => void
  validate?: (state: IYearMonthSelectorState) => string
  start: Date
  end: Date
  yearLabel?: string
  monthLabel?: string
}

const YearMonthSelector = (props: IYearMonthSelector) => {
  const { onUpdate, start, end } = props
  const startYear = start.getFullYear()
  const endYear = end.getFullYear()
  const startMonth = start.getMonth() + 1
  const [state, setState] = React.useState<IYearMonthSelectorState>({
    year: startYear,
    month: startMonth
  })

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof state
    const value = event.target.value
    const update = {
      ...state,
      [name]: Number(value)
    }
    setState(update)
    onUpdate(update)
  }

  const calculateYears = (): number[] => {
    const years = []
    for (let i = startYear; i <= endYear; i++) {
      years.push(i)
    }
    return years
  }

  const calculateMonths = (year: number): number[] => {
    const monthCount = moment(end).diff(moment(start), "months", true)
    const months = []
    for (let i = startMonth - 1; i < monthCount + startMonth - 1; i++) {
      months.push((i % 12) + 1)
    }
    const years = calculateYears()
    const monthsByYear = {}
    months.forEach((month, i) => {
      const yearIndex = (i + startMonth - month) / 12
      const year = years[yearIndex]
      if (monthsByYear[year]) {
        monthsByYear[year].push(month)
      } else {
        monthsByYear[year] = [month]
      }
    })

    return monthsByYear[year]
  }

  const errorMsg = !!props.validate && props.validate(state)
  return (
    <Box>
      <FormControl>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Select
              native
              variant="outlined"
              value={state.year}
              onChange={handleChange}
              inputProps={{
                name: "year"
              }}
            >
              {calculateYears().map((year, i) => (
                <option key={i} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <label id="yearLabel">{props.yearLabel || "年"}</label>
          </Grid>
        </Grid>
      </FormControl>

      <FormControl variant="outlined" error={true}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Select
              native
              value={state.month}
              onChange={handleChange}
              inputProps={{
                name: "month"
              }}
            >
              {calculateMonths(state.year).map((month, i) => (
                <option key={i} value={month}>
                  {month}
                </option>
              ))}
            </Select>
          </Grid>
          <Grid item>
            <label id="monthLabel">{props.monthLabel || "月"}</label>
          </Grid>
        </Grid>
      </FormControl>
      <ErrorMessage>{errorMsg}</ErrorMessage>
    </Box>
  )
}

export default YearMonthSelector
