import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Actions } from "../actions";
import {
  EditFirstPsychologicalSafetyForm,
  IEditFirstPsychologicalSafetyFormDispatchProps,
  IEditFirstPsychologicalSafetyFormStateProps,
  IEditFirstPsychologicalSafetyFormProps,
} from "../components/EditFirstPsychologicalSafetyForm";
import {
  showAlert,
  updateFirstPsychologicalSafety,
  fetchPsychologicalSafety,
} from "../thunks";
import { IStoreState, IFirstPsychologicalSafety } from "../types/state";
import { useState, useEffect } from "react";
import { PSYCHOLOGICAL_STATUS } from "../lib/Definition"

const EditFirstPsychologicalSafety = (
  props: IEditFirstPsychologicalSafetyFormProps & {
    fetchPsychologicalSafety: () => Promise<{}>;
  }
) => {
  const [fetched, setFetched] = useState(!!props.firstPsychologicalSafety);
  const fetchPsychologicalSafety = props.fetchPsychologicalSafety;

  useEffect(() => {
    if (!fetched) {
      fetchPsychologicalSafety().then(() => {
        setFetched(true);
      });
    }
  }, [fetched, fetchPsychologicalSafety]);

  if (!fetched) {
    return null;
  }
  return (
    <EditFirstPsychologicalSafetyForm
      {...props}
      enableCancel={!!props.enableCancel}
    />
  );
};

function mapStateToProps(
  state: IStoreState
): IEditFirstPsychologicalSafetyFormStateProps {
  const firstPsychologicalSafety = state.firstPsychologicalSafeties[0];
  return {
    firstPsychologicalSafety: firstPsychologicalSafety
      ? { ...firstPsychologicalSafety, isSubmitted: false, ...state.viewState }
      : {
          id: 0,
          like: "",
          name: "",
          company_name: "",
          type: "partner",
          specialties_and_proceeding: "",
          weak: "",
          isSelf: true,
          error: "",
          errorField: "",
          ...state.viewState,
        },
    enableCancel: state.company.psychological_status !== PSYCHOLOGICAL_STATUS.hidden
                  && state.company.psychological_status !== PSYCHOLOGICAL_STATUS.first_wait
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): IEditFirstPsychologicalSafetyFormDispatchProps & {
  fetchPsychologicalSafety: () => Promise<{}>;
} {
  return {
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any;
    },
    fetchPsychologicalSafety: () => {
      return dispatch(fetchPsychologicalSafety() as any);
    },
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state, isEdit: true }) as any);
    },
    updateFirstPsychologicalSafety: (
      firstPsychologicalSafety: IFirstPsychologicalSafety
    ) => {
      dispatch(updateFirstPsychologicalSafety(firstPsychologicalSafety) as any);
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFirstPsychologicalSafety);
