import { useMemo } from "react"
import { connect, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { useParams } from "react-router"
import { Actions } from "../actions"
import { showAlert } from "../thunks"
import { IStoreState } from "../types/state"
import { createProblem, showConfirmCreateProblem } from "../thunks/problem"
import * as React from "react"
import { useStaff } from "../hooks/useStaff"
import { IProblemFormDispatchProps, IProblemFormProps, IProblemFormState, ProblemForm } from "../components/ProblemForm"
import { PROBLEM_CHECKED_BO_STATUS_EN, PROBLEM_STATUS_EN } from "../lib/Definition"

const ProblemCopy = (props: IProblemFormProps) => {
  const { problemId } = useParams<{ problemId: string }>()
  const recruitment = useSelector((state: IStoreState) => state.recruitment)
  const { isIndividualStaff } = useStaff()

  const problemIdNum = useMemo(() => parseInt(problemId), [problemId])

  const initialFormState = useMemo(() => {
    const problem = recruitment.problems.find((p) => p.id === problemIdNum)
    if (!problem) return null

    return {
      id: null,
      title: problem.title,
      problem_category: problem.problem_category,
      problem_category_details: problem.problem_category_details,
      section_other: problem.section_other,
      request: problem.request,
      situation: problem.situation,
      goal: problem.goal,
      status: PROBLEM_STATUS_EN.new,
      checked_bo_status: PROBLEM_CHECKED_BO_STATUS_EN.progress_new,
      publish_type: problem.publish_type,
      expired_date: problem.expired_date ?? null
    }
  }, [problemIdNum, recruitment.problems])

  if (!initialFormState) return null
  if (isIndividualStaff) return null

  return <ProblemForm {...props} initialFormState={initialFormState} />
}

function mapStateToProps(_state: IStoreState) {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>): IProblemFormDispatchProps {
  return {
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any
    },
    setViewState: (state: { [key: string]: any }) => {
      return dispatch(Actions.setViewState({ ...state }) as any)
    },
    onSubmit: (problemForm: IProblemFormState) => {
      dispatch(showConfirmCreateProblem({ problemForm }) as any).then((result: boolean | MouseEvent) => {
        // モーダルのオーバーレイをクリックした場合にはReact.MouseEventが渡ってくるので注意
        if (result === true) {
          const params = {
            title: problemForm.title,
            problem_category: problemForm.problem_category,
            problem_category_details: problemForm.problem_category_details,
            section_other: problemForm.section_other,
            situation: problemForm.situation,
            goal: problemForm.goal,
            request: problemForm.request,
            publish_type: problemForm.publish_type
          }
          dispatch(createProblem(params) as any)
        }
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemCopy)
