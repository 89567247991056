import * as React from "react";
import { ErrorMessage } from "./common/ErrorMessage"

export const PasswordField = (props: {
  values: { isSubmitted: boolean; password: string };
  validates: { [key: string]: (value: any) => string | null };
  cls?: string;
  name?: string;
  label?: string;
  note?: any;
  error?: string;
  placeholder?: string;
  updateValue: (args: { [key: string]: string }) => void;
}) => {
  const name = props.name ? props.name : "password";
  const error =
    props.error ||
    (props.values.isSubmitted &&
      props.validates &&
      props.validates[name] &&
      props.validates[name](props.values));

  return (
    <div className="formRow">
      {props.label && (
        <label className="formHead">
          {props.label}
          <span className="label label_req">必須</span>
        </label>
      )}
      <div className="formData">
        {props.note && <p className="formLead">{props.note}</p>}
        <input
          className={props.cls ? props.cls : "formWidth_s"}
          type="password"
          placeholder={props.placeholder}
          value={props.values[name]}
          onChange={(e: React.ChangeEvent<any>) =>
            props.updateValue({ [name]: e.target.value })
          }
        />
        <ErrorMessage>{error}</ErrorMessage>
      </div>
    </div>
  );
};
