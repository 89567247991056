import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { push } from "connected-react-router";
import  { SecondInterviewRequested,ISecondInterviewRequested } from "../components/confirmations/SecondInterviewRequested";
import { IEntry, IQuestionnaireState } from "../types/state";
import { submitFirstInterviewQuestionnaire } from "../thunks";

class SecondInterviewRequestFinish extends React.Component<
  ISecondInterviewRequested
> {
  componentDidMount() {}
  render() {
    return <SecondInterviewRequested {...this.props} />;
  }
}

function mapStateToProps(state: any) {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
  return {
    entry:
      state.entries.filter((entry: IEntry) => entry.id === entryID)[0] || {},
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
  return {
    dispatch,
    changePath: (path: string) => dispatch(push(path)),
    submitQuestionnaire: (questionnaire: IQuestionnaireState) =>
      dispatch(submitFirstInterviewQuestionnaire(questionnaire) as any),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondInterviewRequestFinish);
