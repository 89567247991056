import * as React from "react"
import { IFavorite } from "../types/state"
import * as Definition from "../lib/Definition"
import style from "../style/Recruitment.module.scss"
import { BUSINESS_TRIP } from "../lib/Definition"
import clsx from "clsx"

interface IPartnerFavoriteInfoProps extends IFavorite {
  isInsuranceDetailDisclosed?: boolean
}

export const PartnerFavoriteInfo = (props: IPartnerFavoriteInfoProps) => {
  return (
    <>
      <h3 className="contentsSubTitle">希望勤務条件</h3>
      <div className={style.listArea + " " + style.recruitmentFavorite}>
        <div className={style.listRow}>
          <div className={style.listHead}>契約時の業務形態</div>
          <div className={style.listData}>{Definition.CONTRACT[props.contract]}</div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>適格請求書発行事業者登録番号</div>
          <div className={style.listData}>{props.invoice ? <>{props.invoice}</> : <>未入力</>}</div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>損害賠償責任保険加入状況</div>
          <div className={style.listData}>
            <div>{Definition.INSURANCE_STATUS_TEXT[props.insurance_status]}</div>
            {props.isInsuranceDetailDisclosed && (
              <>
                {props.insurance_status === Definition.INSURANCE_STATUS.PURCHASED && (
                  <div className="mt10 pl14">
                    <div className="mb10">
                      <label className="childLabel">引受（幹事）保険会社名</label>
                      <div>{props.insurance_company_name}</div>
                    </div>
                    <div className="mb10">
                      <label className="childLabel">有効期限</label>
                      <div>
                        {props.insurance_expires_flag && "期限なし"}
                        {!props.insurance_expires_flag && props.insurance_expires_at && (
                          <>
                            {props.insurance_expires_at.getFullYear()}年{props.insurance_expires_at.getMonth() + 1}月
                            {props.insurance_expires_at.getDate()}日
                          </>
                        )}
                      </div>
                    </div>
                    {props.insurance_certificate && props.insurance_certificate.url && (
                      <div className="mb10">
                        <label className="childLabel">被保険者証</label>
                        <div>
                          <a href={props.insurance_certificate.url} target="_blank" rel="noopener noreferrer">
                            {props.insurance_certificate.name}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {props.insurance_status === Definition.INSURANCE_STATUS.WILL_PURCHASE_AFTER_CONTRACT &&
                  props.is_understood_warranty_breach_risk && (
                    <div className="mt10 fz14 grayTxt">表明保証違反のリスクについて確認済</div>
                  )}
              </>
            )}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>希望時給単価</div>
          <div className={clsx(style.listData, style.listDataLeft)}>
            {props.reward_from != null && (
              <>
                {props.reward_from !== props.reward_to && (
                  <>
                    {props.reward_from}
                    <span className="tilde">〜</span>
                  </>
                )}
                {props.reward_to}円
              </>
            )}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>出張希望</div>
          <div className={style.listData}>{BUSINESS_TRIP[props.business_trip]}</div>
        </div>
      </div>
    </>
  )
}
