import * as React from "react";
import { Box } from "@material-ui/core";

export interface ILabelIndex {
  index: number;
}

const LabelIndex = (props: ILabelIndex) => {
  return (
    <Box
      component="span"
      m="0 15px 0 0"
      p="1px 7px"
      color="white"
      bgcolor="#529a35"
      textAlign={"center"}
    >
      {props.index}
    </Box>
  );
};

export default LabelIndex;
