import * as React from "react";

import {
  MEETING_KIND,
  MEETING_TYPE,
  MEETING_TYPE_STR,
} from "../../lib/Definition";

export interface IScheduleInfo {
  kind: MEETING_KIND;
  type?: MEETING_TYPE;
}

export const ScheduleInfo = (props: IScheduleInfo) => {
  return (
    <p>
      {props.kind}（{MEETING_TYPE_STR[props.type || MEETING_TYPE.ONLINE]}）は、
      <b className="joinsPinkTxt">企業様とパートナー人材の二者間</b>
      で行っていただく面談です。
    </p>
  );
};
