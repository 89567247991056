import { TypeKeys, Actions } from "../actions";
import DateWithOffset from "../lib/DateWithOffset";

const initialState: Date[] = [];

const availableSchedules = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.FETCH_AVAILABLE_SCHEDULES_SUCCESS: {
      return action.payload.data.available_schedules.map((date: Date) => {
        return new DateWithOffset(date);
      });
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default availableSchedules;
