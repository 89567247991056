import * as React from "react";
import { Link } from "react-router-dom";
import { entryPath } from "../../routes/paths";
import { IEntry } from "../../types/state";

interface IPartnerNameHeader {
  entry: IEntry;
}

export const PartnerNameHeader = (props: IPartnerNameHeader) => {
  return (
    <Link to={`${entryPath}/${props.entry.id}`}>
      応募パートナー人材：{props.entry.partner.last_name}{" "}
      {props.entry.partner.first_name}氏（人材ID：{props.entry.partner.id}）
    </Link>
  );
};
