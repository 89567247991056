import * as React from "react";

const fileInput: React.RefObject<HTMLInputElement> = React.createRef();

const FileField = (props: { updateValue: (file: File) => void }) => (
  <>
    <input
      ref={fileInput}
      type="file"
      style={{ display: "none" }}
      onChange={(e: any) => {
        const file = e.currentTarget.files[0];
        if (!file) {
          return;
        }
        props.updateValue(file);
      }}
    />
    <button
      className="btn"
      type="button"
      onClick={() => {
        fileInput.current && fileInput.current.click();
      }}
    >ファイルを選択</button>
  </>
);

export default FileField;
