import * as React from "react"
import { PUBLISH_TYPE } from "../../../lib/Definition"
import { useMemo } from "react"
import { IProblemFormState } from "../../ProblemForm"

interface IConfirmCreateProblemProps {
  content: { problemForm: IProblemFormState }
  closeModal: (result: boolean) => void
}

export const ConfirmCreateProblem = ({ content, closeModal }: IConfirmCreateProblemProps) => {
  const isPrivate = useMemo(() => {
    return content.problemForm.publish_type === PUBLISH_TYPE.private
  }, [content.problemForm])

  return (
    <main>
      <div className="alCent mb10">
        {!isPrivate && (
          <pre>
            新規案件につきまして
            <br />
            JOINS管理担当者の確認後、入力した内容で案件が公開され、
            <br />
            募集開始の告知メールが配信されます。
          </pre>
        )}
        {isPrivate && (
          <pre>
            非公開の新規案件につきまして
            <br />
            JOINS管理担当者の確認後、利用可能となります。
          </pre>
        )}
      </div>
      <div className="alCent mb10">上記でよろしければ「確認」ボタンを押してください。</div>
      <div className="formBtnArea formWrap">
        <button className="btn" onClick={() => closeModal(false)}>
          キャンセル
        </button>
        <button className="btn btnLogin" onClick={() => closeModal(true)}>
          確認
        </button>
      </div>
    </main>
  )
}
