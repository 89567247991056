import * as React from "react"
import { IStoreState } from "../../types/state"
import { useSelector } from "react-redux"
import { IProblemFormState } from "./index"

export const CategoryDetailsForm = (props: {
  problem: IProblemFormState
  updateValue: (args: { [key: string]: any }) => void
}) => {
  const problemCategories = useSelector((state: IStoreState) => state.problemCategories)
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  const category = React.useMemo(
    () => problemCategories.find((pc) => pc.slug === props.problem.problem_category),
    [problemCategories, props.problem.problem_category]
  )

  const selectableCategoryDetails = React.useMemo(
    () => problemCategoryDetails.filter((pcd) => pcd.problem_category_id === category?.id),
    [problemCategoryDetails, category]
  )

  return (
    <div id="problem_category_details">
      <div>
        <label className="mb10">業務カテゴリーの詳細を選択してください (複数選択可)</label>
      </div>
      {selectableCategoryDetails.map((problemCategoryDetail) => {
        const key = problemCategoryDetail.slug
        return (
          <label key={key} className="selectBtn">
            <input
              type="checkbox"
              name="problem_category_details"
              value={key}
              checked={props.problem.problem_category_details.includes(key)}
              onChange={(e: React.ChangeEvent<any>) => {
                const selectedCategoryDetails = props.problem.problem_category_details.filter(
                  (pcd) => pcd !== e.target.value
                )
                if (e.target.checked) selectedCategoryDetails.push(e.target.value)

                const targetCategoryDetail = selectableCategoryDetails.find((cd) => cd.slug === e.target.value)
                const isOtherUnchecked = targetCategoryDetail?.other_flag && !e.target.checked
                props.updateValue({
                  ...props.problem,
                  problem_category_details: selectedCategoryDetails,
                  section_other: isOtherUnchecked ? "" : props.problem.section_other
                })
              }}
            />
            <span>{problemCategoryDetail.name}</span>
          </label>
        )
      })}
    </div>
  )
}
