import * as React from "react";
import { Wrapper } from "./Wrapper";
import styles from "../../style/CheckTrustForm.module.scss";

export const ProgressedTrust = () => {
  return (
    <Wrapper>
      <h2>現在審査中です。</h2>
      <p className={styles.progressTrust}>
        審査完了までには最短当日から最大5営業日程度のお時間をいただいております。
        <br />
        審査が完了いたしましたらメールおよびお知らせ欄にてお知らせいたします。
      </p>
    </Wrapper>
  );
};
