import * as React from "react";
import { IRoom } from "../types/state";
import { lastMessage, monthTimeStr } from "../lib/Util";
import style from "../style/Room.module.scss";

export interface IRoomListDispatchProps {
  showAlert: (msg: string) => void;
  changePath: (path: string) => void;
}

export interface IRoomListStateProps {
  rooms: IRoom[];
}

export interface IRoomListProps
  extends IRoomListDispatchProps,
    IRoomListStateProps {}

const RoomList = (props: IRoomListProps) => {
  return (
    <div className="contentsWrapper-transparent">
      <h2 className="contentsTitle contentsTitleMessage">メッセージ一覧</h2>
      <div>
        {props.rooms.length === 0 && (
          <p>
            現在、メッセージできる人材はいません。
            <br />
            一次面談確定後に応募人材とメッセージができるようになります。
          </p>
        )}
        {props.rooms.map(room => {
          let previewMessage = ""
          if (room.status === "discarded" || room.force_withdraw_flag) {
            previewMessage = "********************";
          } else {
            previewMessage = room.last_message
              ? lastMessage(room.last_message)
              : "メッセージを送ってみましょう"
          }
          return (
            <div
              className={style.messageListIndex}
              key={`room-${room.id}`}
              onClick={() => {
                if (room.status !== "discarded" && !room.force_withdraw_flag) {
                  props.changePath(`/room/${room.id}`);
                }
              }}
            >
              <span className={style.messageIndexIcon} />

              <span
                className={
                  style.messageIndexName +
                  (room.unread_count > 0 ? " bldTxt" : "")
                }
              >
                {room.force_withdraw_flag ? "規約違反のため退会済み" : room.status !== "discarded" ? room.name : "退会済み"}
                {room.unread_count > 0 ? (
                  <span
                    className={
                      style.messageNotRead + " " + style.messageNotReadSP
                    }
                  >
                    未読
                  </span>
                ) : (
                  <span />
                )}
              </span>

              <span
                className={
                  style.messageIndexText +
                  (room.unread_count > 0
                    ? " " + style.messageIndexTextNotRead
                    : "")
                }
              >
                {room.unread_count > 0 ? (
                  <span className={style.messageNotRead}>未読</span>
                ) : (
                  <span />
                )}

                {previewMessage}
              </span>
              <span className={style.messageIndexDate}>
                {monthTimeStr(room.updated_at)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RoomList;
