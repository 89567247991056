import * as React from "react"
import { ICompany, IRecruitment, IProblem } from "../types/state"
import style from "../style/Recruitment.module.scss"
import { ProblemInfo } from "./ProblemInfo"
import { CompanyProfile } from "./CompanyProfile"
import { PROBLEM_STATUS_EN } from "../lib/Definition"

export interface IRecruitmentInfoProps {
  recruitment: IRecruitment
  problemID: number
  contractList: string[][]
  company: ICompany
  withoutReturn?: boolean
}

export const RecruitmentInfo = (props: IRecruitmentInfoProps) => {
  const recruitment = props.recruitment
  const company: ICompany = props.company
  const problems = (recruitment.problems || []).sort((p0, p1) => {
    if (p0.id === props.problemID) return -1
    if (p1.id === props.problemID) return 1
    if (p0.status === PROBLEM_STATUS_EN.closed && p1.status !== PROBLEM_STATUS_EN.closed) return 1
    if (p1.status === PROBLEM_STATUS_EN.closed && p0.status !== PROBLEM_STATUS_EN.closed) return -1
    return (p0.id as number) - (p1.id as number)
  })

  return (
    <div className="contentsWrapper-transparent">
      <CompanyProfile company={company} contractList={props.contractList} />

      <h3 className="contentsSubTitle">案件内容</h3>

      {problems.map((problem: IProblem, index: number) => {
        return (
          <ProblemInfo
            problem={problem}
            problemID={props.problemID}
            totalNum={problems.length}
            index={index}
            key={problem.id}
          />
        )
      })}

      {company.video_url && (
        <>
          <h3 className="contentsSubTitle">担当者からの動画メッセージ</h3>
          <div className={style.taskVideo}>
            <div className={style.taskVideoContent} dangerouslySetInnerHTML={{ __html: company.video_url }} />
          </div>
        </>
      )}

      <div className={style.taskDesc}>
        契約を締結し業務をスタートするまでに、お互いが確かめ合うための担当者や経営者の方とのミーティング機会が２回あります。
        <br />
        この機会を通じて、実際に実行できそうかどうかを確かめることができますので、まずはご気軽にご応募ください。
      </div>

      {!props.withoutReturn && (
        <div className={"formBtnArea formBtnAreaSingle"}>
          <button onClick={() => window.history.back()} className="btn btnEdit">
            戻る
          </button>
        </div>
      )}
    </div>
  )
}
