import { Dispatch } from "react";
import { Actions } from "../actions";
import { CompanyVerificationActions } from "../actions/company_verification";
import { CustomFile } from "../components/CheckTrustForm/CustomUploader/useImageUploader";
import { IStoreState } from "../types/state";

export function sendCompanyVerification(corporate_number: number | null, personal_files: CustomFile[] | null, open_business_files: CustomFile[] | null) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    dispatch(Actions.setViewState({ loaderVisible: true }));
    return new Promise<{} | void>((resolve, reject) => {
      (dispatch(CompanyVerificationActions.sendCompanyVerification(corporate_number, personal_files, open_business_files)) as any)
        .then(() => {
          dispatch(CompanyVerificationActions.checkCompanyVerification() as any);
          resolve();
        })
        .catch((e: any) => reject(e))
        .finally(() =>
          dispatch(Actions.setViewState({ loaderVisible: false }))
        );
    });
  };
}
