import React from 'react'
import style from "../style/SelectStar.module.scss"
import { ErrorMessage } from "./common/ErrorMessage"
import clsx from "clsx"

interface ISelectStarProps {
  name: string;
  value?: number;
  max: number;
  rootCls?: string;
  isSubmitted?: boolean;
  validates?: { [key: string]: (value: any) => string | null };
  onChange: (val: number) => void;
}

export const SelectStar = ({
  name,
  value,
  max,
  rootCls,
  isSubmitted,
  validates,
  onChange
}: ISelectStarProps) => {
  return (
    <div id={name} className={clsx(style.wrapper, rootCls)}>
      {
        Array.from({ length: max }, (v, i) => i).map(i => {
          return (
            <span
              key={`star-${name}-${i}`}
              className={style.star}
              onClick={() => {
                onChange(i + 1)
              }}
            >
              {(!value || value <= i) ? "☆" : "★"}
            </span>
          )
        })
      }
      {isSubmitted && validates && validates[name] && (
        <ErrorMessage cls="mb08">
          {validates[name]({ [name]: value })}
        </ErrorMessage>
      )}
    </div>
  )
}
