import { IProblemCategory } from "../types/state"
import { Actions, TypeKeys } from "../actions"

const initialState: IProblemCategory[] = []

const problemCategories = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.problem_categories
    }
    default: {
      return state
    }
  }
}

export default problemCategories
