import * as React from "react";
import { ErrorMessage } from "./common/ErrorMessage"

const EditablePasswordField = (props: {
  values: { password: string; showPassword: boolean; isSubmitted: boolean };
  validates: { [key: string]: (value: any) => string | null };
  error?: string | null;
  updateValue: (args: { [key: string]: boolean | string }) => void;
}) => {
  const error =
    props.error ||
    (props.values.showPassword &&
      props.values.isSubmitted &&
      props.validates &&
      props.validates["password"] &&
      props.validates["password"](props.values));
  return (
    <div className="formRow">
      <label htmlFor="password" className="formHead">
        パスワード
        <span className="label label_req">必須</span>
      </label>
      <div className="formData">
        {props.values.showPassword && (
          <input
            type="password"
            className="formWidth_s"
            name="password"
            value={props.values.password}
            onChange={(e: React.ChangeEvent<any>) =>
              props.updateValue({ password: e.target.value })
            }
          />
        )}
        {props.values.showPassword && (
          <button
            type="button"
            className="btn btnS"
            onClick={() =>
              props.updateValue({ password: "", showPassword: false })
            }
          >
            キャンセル
          </button>
        )}
        <ErrorMessage>{error}</ErrorMessage>
        {!props.values.showPassword && (
          <div className="formData_inline">{"*****"} </div>
        )}
        {!props.values.showPassword && (
          <button
            type="button"
            className="btn btnS"
            onClick={() => {
              props.updateValue({ showPassword: true });
            }}
          >
            変更する
          </button>
        )}
      </div>
    </div>
  );
};

export default EditablePasswordField;
