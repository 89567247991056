import { TypeKeys, Actions } from "../actions";
import { IPagenatedPartners } from "../types/state";
import DateWithOffset from "../lib/DateWithOffset";

const initialState: IPagenatedPartners = {
  partners: [],
  current_page: 1,
  total_pages: undefined,
  total_count: undefined
};

const partners = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.RECOVER_DATA: {
      if (!action.payload.partners.partners) {
        return initialState;
      }
      return Object.assign({}, state, {
        partners: action.payload.partners.partners.map(partner => ({
                    ...partner,
                    birthday: new DateWithOffset(partner.birthday)
                  })),
        current_page: action.payload.partners.current_page,
        total_pages: action.payload.partners.total_pages,
        total_count: action.payload.partners.total_count
      });
    }
    case TypeKeys.CLEAR_PARTNERS:
      return initialState;
    case TypeKeys.UPDATE_BOOKMARK_SUCCESS: {
      return Object.assign({}, state, {
        partners: state.partners.map(partner => ({
                    ...partner,
                    bookmarked: partner.id === action.payload.data.partner_id
                                  ? action.payload.data.bookmarked
                                  : partner.bookmarked
                  }))
      });
    }
    case TypeKeys.SEARCH_PARTNERS_SUCCESS: {
      return {
        partners: action.payload.data.partners.map(partner => ({
                    ...partner,
                    birthday: new DateWithOffset(partner.birthday)
                  })),
        current_page: action.payload.data.current_page,
        total_pages: action.payload.data.total_pages,
        total_count: action.payload.data.total_count
      };
    }
    case TypeKeys.FETCH_PARTNER_SUCCESS: {
      return Object.assign({}, state, {
        partners: [
          ...state.partners.filter(pa => pa.id !== action.payload.data.id),
          {
            ...action.payload.data,
            birthday: new DateWithOffset(action.payload.data.birthday)
          }
        ].sort((a, b) => a.id - b.id)
      });
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default partners;
