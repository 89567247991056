import * as React from "react";
import { INotice } from "../types/state";
import { dateStr } from "../lib/Util";
import style from "../style/NoticeList.module.scss";

export interface INoticeListDispatchProps {
  changePath: (path: string) => void;
}

export interface INoticeListStateProps {
  notices: INotice[];
  masquerade: boolean;
}

export interface INoticeListProps
  extends INoticeListDispatchProps,
    INoticeListStateProps {
  masquerade: boolean;
}

const NoticeList = (props: INoticeListProps) => {
  return (
    <main className="contentsWrapper-transparent">
      <h2 className="contentsTitle">お知らせ</h2>
      <div className={style.noticeArea}>
        {props.notices.length === 0 && (
          <div className={style.noticeList + " " + style.noticeListNone}>
            <div className={style.noticeListTitle}>お知らせはありません。</div>
          </div>
        )}
        {props.notices.map((notice) => {
          return notice.link ? (
            <a
              className={style.noticeList}
              key={notice.id}
              href={notice.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                if (!notice.link.match(/^http/)) {
                  e.preventDefault();
                  props.changePath(notice.link);
                }
              }}
            >
              <div className={style.noticeListDate}>
                {dateStr(notice.created_at)}
              </div>
              <div className={style.noticeListCategory}>
                {notice.notice_type === "news" ? (
                  <span className={style.noticeCategoryNews}>ニュース</span>
                ) : (
                  <span className={style.noticeCategoryForYou}>貴社宛</span>
                )}
              </div>
              <div className={style.noticeListTitle}>
                {notice.contents} &#8811;
              </div>
            </a>
          ) : (
            <div key={notice.id}>
              <div className={style.noticeListDate}>
                {dateStr(notice.created_at)}
              </div>
              <div className={style.noticeListCategory}>
                {notice.notice_type === "news" ? (
                  <span className={style.noticeCategoryNews}>ニュース</span>
                ) : (
                  <span className={style.noticeCategoryForYou}>貴社宛</span>
                )}
              </div>
              <span className={style.noticeListTitle}>{notice.contents}</span>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default NoticeList;
