import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import EntryRejectForm, {
  IEntryRejectProps
} from "../components/EntryRejectForm";
import { Dispatch, Action } from "redux";
import { IEntry } from "../types/state";
import { updateEntry, showAlert } from "../thunks";

class EntryReject extends React.Component<IEntryRejectProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    dispatch(Actions.fetchEntries());
  }
  render() {
    return <EntryRejectForm {...this.props} />;
  }
}

function mapStateToProps(state: any) {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
  return {
    entry:
      state.entries.filter((entry: IEntry) => entry.id === entryID)[0] || {},
    recruitment: state.recruitment
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    alert: (content: string) => {
      return dispatch(showAlert(content) as any);
    },
    rejectEntry: (entry: IEntry, apology: string) =>
      dispatch(updateEntry({ ...entry, declined_by: "company", apology }) as any)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryReject);
