import * as React from "react";
import style from "../../style/Entry/CancelContractEntry.module.scss";
import styleRec from "../../style/Recruitment.module.scss";
import { dateStr, strToTag } from "../../lib/Util";
import { CancelContractSatisfactionType, IEntry } from "../../types/state"
import { SelectStarInfo } from "../SelectStarInfo"
import clsx from "clsx"
import {
  BOOL_TYPE_STR,
  CANCEL_CONTRACT_DISSATISFACTION,
  CANCEL_CONTRACT_SATISFACTION,
  CANCEL_CONTRACT_SATISFACTION_KEYS
} from "../../lib/Definition"
import { useMemo } from "react"

interface ICancelContractEntryProps {
  entry: IEntry;
}

export const CancelContractEntry = ({ entry }: ICancelContractEntryProps) => {
  const questionnaire = entry.cancel_contract_questionnaire
  const partnerName = useMemo(() => `${entry.partner.last_name} ${entry.partner.first_name}`, [entry.partner])

  return (
    <div className={styleRec.listArea + " mb30"}>
      {entry.contract_cancel_partner_message && (
        <>
          <div className={styleRec.scoutInfoText}>
            {entry.partner.last_name ? `${partnerName}様から` : ""}
            <b>解約の連絡</b>を受け取りました。
          </div>
          <div className={styleRec.scountInfoReply}>
            <div className={styleRec.scoutInfoTitle}>
              パートナー人材からのメッセージ
            </div>
            <div className={styleRec.scoutInfoMessage}>
              {entry.contract_cancel_partner_message}
            </div>
          </div>
        </>
      )}

      {!entry.contract_cancel_partner_message && (
        <>
          <div className={styleRec.listRow}>
            <div className={styleRec.listHead}>解約日</div>
            <div className={clsx(styleRec.listData, "alLeft")}>
              {dateStr(entry.contract_cancel_at)}
            </div>
          </div>

          {questionnaire && (
            <>
              <div className={styleRec.listRow}>
                <div className={styleRec.listHead}>
                  {partnerName}様の<br />満足度
                </div>
                <div className={clsx(styleRec.listData, "alLeft")}>
                  <SelectStarInfo
                    name="satisfaction"
                    value={questionnaire.satisfaction}
                    max={5}
                  />
                </div>
              </div>

              <div className={styleRec.listRow}>
                <div className={styleRec.listHead}>
                  満足した点<br />(複数回答)
                </div>
                <div className={clsx(styleRec.listData, "alLeft")}>
                  <ul className={style.ul}>
                    {Object.keys(CANCEL_CONTRACT_SATISFACTION).map((key) => {
                      if (!questionnaire.satisfactions.includes(key as CancelContractSatisfactionType)) return null
                      return (
                        <li key={`satisfactions-${key}`} className={style.li}>
                          {key === CANCEL_CONTRACT_SATISFACTION_KEYS.other
                            ? `${CANCEL_CONTRACT_SATISFACTION[key]}（${questionnaire.satisfaction_other}）`
                            : CANCEL_CONTRACT_SATISFACTION[key]
                          }
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

              <div className={styleRec.listRow}>
                <div className={styleRec.listHead}>
                  不満な点<br />(複数回答)
                </div>
                <div className={clsx(styleRec.listData, "alLeft")}>
                  <ul className={style.ul}>
                    {Object.keys(CANCEL_CONTRACT_DISSATISFACTION).map((key) => {
                      if (!questionnaire.dissatisfactions.includes(key as CancelContractSatisfactionType)) return null
                      return (
                        <li key={`dissatisfactions-${key}`} className={style.li}>
                          {key === CANCEL_CONTRACT_SATISFACTION_KEYS.other
                            ? `${CANCEL_CONTRACT_DISSATISFACTION[key]}（${questionnaire.dissatisfaction_other}）`
                            : CANCEL_CONTRACT_DISSATISFACTION[key]
                          }
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

              <div className={styleRec.listRow}>
                <div className={styleRec.listHead}>
                  {partnerName}様に<br />再び案件を<br />依頼したいですか
                </div>
                <div className={clsx(styleRec.listData, "alLeft")}>
                  {questionnaire.re_offer_flag ? BOOL_TYPE_STR.yes : BOOL_TYPE_STR.no}
                </div>
              </div>

              <div className={styleRec.listRow}>
                <div className={styleRec.listHead}>
                  またJOINSを<br />利用したいですか
                </div>
                <div className={clsx(styleRec.listData, "alLeft")}>
                  {questionnaire.re_use_flag ? BOOL_TYPE_STR.yes : BOOL_TYPE_STR.no}
                </div>
              </div>
            </>
          )}

          <div className={styleRec.listRow}>
            <div className={styleRec.listHead}>
              JOINSへ<br />連絡したいこと
            </div>
            <div className={clsx(styleRec.listData, "alLeft")}>
              {strToTag(entry.contract_cancel_comment)}
            </div>
          </div>

          <div className={styleRec.listRow}>
            <div className={styleRec.listHead}>
              <span>
                {partnerName}様への<br />メッセージ
              </span>
            </div>
            <div className={clsx(styleRec.listData, "alLeft")}>
              {strToTag(entry.contract_cancel_company_message)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
