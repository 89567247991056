import { TypeKeys, Actions } from "../actions";
import { IAuth } from "../types/state";

const initialState: IAuth = { sessionID: null, masquerade: false };

const auth = (state = initialState, action: Actions): IAuth => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS:
      return Number(action.payload.data.company.id) === 0
        ? initialState
        : state;
    case TypeKeys.SIGNUP_SUCCESS:
    case TypeKeys.LOGIN_SUCCESS:
      return {
        sessionID: action.payload.data.auth.sessionID,
        masquerade: false
      };
    case TypeKeys.RECOVER_SESSION:
      return {
        sessionID: action.payload.sessionID,
        masquerade: action.payload.masquerade
      };
    case TypeKeys.RECOVER_DATA:
      return {
        sessionID: action.payload.auth.sessionID,
        masquerade: !!action.payload.auth.masquerade
      };
    case TypeKeys.LOGOUT_SUCCESS:
      return initialState;
    default: {
      return state;
    }
  }
};

export default auth;
