import Linkify from 'react-linkify';
import * as React from "react";

export interface ILinkifiedText {
  children: React.ReactNode;
  target?: '_blank' | '_self' | '_top' | '_parent';
}

const LinkifiedText = (props: ILinkifiedText) => {
  const rel = props.target === '_blank' ? 'noopener noreferrer' : ''
  return (
    <span>
      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target={props.target} href={decoratedHref} key={key} rel={rel}>
            {decoratedText}
          </a>
        )}>
        {props.children}
      </Linkify>
    </span>
  );
};

export default LinkifiedText;
