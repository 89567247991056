import * as React from "react";
import { IFile } from "../types/state";

const photoInput: React.RefObject<HTMLInputElement> = React.createRef();

export const PhotoField = (props: {
  photo: IFile | null;
  name?: string;
  removable: boolean;
  updateValue: (args: { [key: string]: any }) => void;
}) => {
  const name = props.name ? props.name : "photo";

  return (
    <div className="form-group row">
      <div className="ml-3 col-10">
        <input
          ref={photoInput}
          name={name}
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => {
            const file = e.currentTarget.files[0];
            if (!file) {
              return;
            }
            if (!file.type.match(/^image\//)) {
              alert("画像以外のファイルはアップロードできません");
              return;
            }
            props.updateValue({
              ...[name],
              [name]: {
                url: URL.createObjectURL(file),
                blob: file,
                name: file.name
              }
            });
          }}
        />
        {props.photo && props.photo.url && props.removable && (
          <div className="photoFieldPhoto">
            <img width={300} src={props.photo.url} alt="写真" />
            <div
              className="photoFieldDelete"
              onClick={() => {
                (photoInput.current as any).value = null;
                props.updateValue({
                  [name]: {
                    url: "",
                    blob: null,
                    name: "",
                    deleted: true
                  }
                });
              }}
            >
              <span>×</span> <small>アップロードした写真を削除する</small>
            </div>
          </div>
        )}
      </div>
      <div className="ml-3 col-10">
        <button
          className="btn"
          type="button"
          onClick={() => {
            photoInput.current && photoInput.current.click();
          }}
        >
          写真をアップロードする
        </button>
      </div>
    </div>
  );
};
