import * as React from "react";
import { PasswordField } from "./PasswordField";

export interface IRenewalPasswordStateProps {
  uuid: string;
  password: string;
  password_confirm: string;
  error: string;
  isSubmitted: boolean;
}
export interface IRenewalPasswordDispatchProps {
  updateValue: (args: any) => void;
  renewalPassword: (values: { uuid: string; password: string }) => void;
}
const validates: { [key: string]: (value: any) => string | null } = {};
validates["password"] = val => {
  return !val.password || val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : null;
};
validates["password_confirm"] = val => {
  return val.password !== val.password_confirm
    ? "パスワードと確認用パスワードが一致していません。"
    : null;
};
const RenewalPasswordForm = (
  props: IRenewalPasswordStateProps & IRenewalPasswordDispatchProps
) => {
  return (
    <main className="is-outside">
      <div className="container">
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            const errors = Object.keys(validates).filter(field => {
              return validates[field](props);
            });
            if (errors.length > 0) {
              props.updateValue({ isSubmitted: true });
              window.scrollTo(0, 0);
              return;
            }
            props.renewalPassword({
              uuid: props.uuid,
              password: props.password
            });
          }}
        >
          {props.error && <div>{props.error}</div>}
          <div className="singleColumnForm signupForm">
            <h1>パスワード再設定</h1>
            <div className="formWrap loginForm">
              <PasswordField
                label="新パスワード"
                placeholder="6文字以上の半角英数字"
                name="password"
                values={props}
                updateValue={props.updateValue}
                validates={validates}
              />
              <PasswordField
                label="パスワード(確認用)"
                name="password_confirm"
                values={props}
                updateValue={props.updateValue}
                validates={validates}
              />
            </div>
            <div className="formBtnArea formBtnAreaCenter">
              <button className="btn btnEdit" type="submit">
                パスワードを更新する
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default RenewalPasswordForm;
