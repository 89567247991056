import * as React from "react"
import { IAnnouncement } from "../../types/state"
import { useMemo } from "react"
import { ANNOUNCEMENT_TYPE } from "../../lib/Definition"
import style from "../../style/modals/Announcement.module.scss"
import clsx from "clsx"

export const Announcement = (props: {
  content: {
    announcement: IAnnouncement
    onCheck: () => void
  }
  closeModal: (result: boolean | null) => void
}) => {
  const { announcement } = props.content

  const isSkippable = useMemo(() => {
    return (
      !announcement.agreement_required &&
      [ANNOUNCEMENT_TYPE.term_update, ANNOUNCEMENT_TYPE.privacy_policy].includes(announcement.announcement_type)
    )
  }, [announcement])

  const isCloseButtonVisible = useMemo(() => {
    return !announcement.agreement_required && !isSkippable
  }, [announcement, isSkippable])

  const [isDisabledButton, setIsDisabledButton] = React.useState(false)

  const onClickCheck = () => {
    setIsDisabledButton(true)
    props.closeModal(true)
  }
  return (
    <div>
      {isCloseButtonVisible && (
        <div
          className="modalCloseButton"
          onClick={() => {
            props.closeModal(false)
          }}
        >
          <i />
        </div>
      )}
      <pre className={style.messageArea}>{announcement.message}</pre>
      <p className="alCent">
        <a className="link" target="_blank" rel="noopener noreferrer" href={announcement.url}>
          {announcement.url_text}
        </a>
      </p>
      <div className={style.btnArea}>
        {isSkippable && (
          <button
            className={clsx("btn btnEdit", style.btn)}
            onClick={() => {
              props.closeModal(false)
            }}
          >
            同意しない
          </button>
        )}
        <button className={clsx("btn btnSave", style.btn)} onClick={onClickCheck} disabled={isDisabledButton}>
          {announcement.action_text}
        </button>
      </div>
      {isSkippable && (
        <div className={style.skipTextArea}>
          <span
            className="linkGray"
            onClick={() => {
              props.closeModal(null)
            }}
          >
            今は同意しない（次回再確認する）
          </span>
        </div>
      )}
    </div>
  )
}
