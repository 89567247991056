import * as React from "react";
import style from "../../style/MeetingRequestForm.module.scss";
import secondStyle from "../../style/ScheduleForm.module.scss";
import { dateTimeStr, timeStr } from "../../lib/Util";
import DateWithOffset from "../../lib/DateWithOffset";
import CancelOrSend from "./common/CancelOrSend";

export interface IScheduleConfirmationForm {
  onSubmit: () => void;
  onCancel: () => void;
  scheduleTime: number;
  label: React.ReactNode;
}

export const ScheduleConfirmationForm = ({
  onSubmit,
  onCancel,
  scheduleTime,
  label
}: IScheduleConfirmationForm) => {
  return (
    <form
      className="pure-form"
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
      }}
    >
      <div className={style.selectArea}>
        {label}

        <dl className={secondStyle.candidateList}>
          <dt className={secondStyle.candidateListTitle}>選択した候補日時</dt>
          <dd className={secondStyle.candidateListDate}>
            <ul className={secondStyle.candidateListDateList}>
              {scheduleTime && (
                <li>
                  {dateTimeStr(new DateWithOffset(scheduleTime))}〜
                  {timeStr(new DateWithOffset(scheduleTime + 1000 * 60 * 60))}
                </li>
              )}
            </ul>
          </dd>
        </dl>

        <CancelOrSend
          onCancel={onCancel}
          onSend={onSubmit}
        />
      </div>
    </form>
  );
};
