import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import RoomList, {
  IRoomListProps,
  IRoomListStateProps
} from "../components/RoomList";
import { Dispatch, Action } from "redux";
import { IStoreState, IRoom } from "../types/state";
import { push } from "connected-react-router";
import { showAlert } from "../thunks";
import DateWithOffset from "../lib/DateWithOffset";

class Rooms extends React.Component<IRoomListProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    dispatch(Actions.fetchRooms());
  }
  render() {
    return <RoomList {...this.props} />;
  }
}

function mapStateToProps(state: IStoreState): IRoomListStateProps {
  return {
    rooms: state.rooms
      ? state.rooms.map((room: IRoom) => {
          return { ...room, updated_at: new DateWithOffset(room.updated_at) };
        })
      : []
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    showAlert: (msg: string) => dispatch(showAlert(msg) as any),
    changePath: (path: string) => dispatch(push(path))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
