import * as React from "react";
import clsx from "clsx"
import { PasswordField } from "../PasswordField";
import { NormalTextField } from "../NormalTextField";
import styles from "../../style/ActivateStaff.module.scss"
import { ErrorMessage } from "../common/ErrorMessage"

export interface IActivateStaffStateProps {
  uuid: string;
  name: string;
  password: string;
  password_confirm: string;
  error: string;
  isSubmitted: boolean;
}
export interface IActivateStaffDispatchProps {
  updateValue: (args: any) => void;
  renewalStaff: (values: {
    uuid: string;
    name: string;
    password: string;
  }) => void;
}
interface IActivateStaffProps extends IActivateStaffStateProps, IActivateStaffDispatchProps {}

const validates: { [key: string]: (value: any) => string | null } = {};
validates["password"] = val => {
  return !val.password || val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : null;
};
validates["password_confirm"] = val => {
  return val.password !== val.password_confirm
    ? "パスワードと確認用パスワードが一致していません。"
    : null;
};
validates["name"] = val => {
  return !val.name ? "名前を入力してください" : null;
};

export const ActivateStaffForm = (props: IActivateStaffProps) => {
  return (
    <main className="is-outside">
      <div className="container">
        <h1 className={styles.pageTitle}>
          JOINS企業向け管理ツール
          <br />
          担当者登録フォーム
        </h1>
        <div className={styles.leadText}>
          お名前とパスワードを入力して、
          <br />
          企業向け管理ツールへの担当者登録を完了してください。
        </div>
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            const errors = Object.keys(validates).filter(field => {
              return validates[field](props);
            });
            if (errors.length > 0) {
              props.updateValue({ isSubmitted: true });
              window.scrollTo(0, 0);
              return;
            }
            props.renewalStaff({
              uuid: props.uuid,
              name: props.name,
              password: props.password
            });
          }}
        >
          <ErrorMessage>{props.error}</ErrorMessage>
          <div className="singleColumnForm signupForm">
            <div className={clsx("formWrap loginForm", styles.listArea)}>
              <NormalTextField
                label="名前"
                placeholder="名前を入力してください"
                name="name"
                values={props}
                updateValue={props.updateValue}
                validates={validates}
              />
              <PasswordField
                label="パスワード"
                placeholder="6文字以上の半角英数字"
                name="password"
                values={props}
                updateValue={props.updateValue}
                validates={validates}
              />
              <PasswordField
                label="パスワード(確認用)"
                name="password_confirm"
                values={props}
                updateValue={props.updateValue}
                validates={validates}
              />
            </div>
            <div className={clsx("formBtnArea", styles.formBtnArea)}>
              <button className={clsx("btn btnEdit", styles.formBtn)} type="submit">
                登録する
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};
