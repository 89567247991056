import * as React from "react";
import { IEntry } from "../../types/state";
import { PartnerNameHeader } from "./PartnerNameHeader";
import { PartnerDetailsButton } from "../buttons/PartnerDetailsButton";

interface IPartnerInfoHeader {
  entry: IEntry;
}

export const PartnerInfoHeader = (props: IPartnerInfoHeader) => {
  return (
    <h2 className="contentsTitle contentsTitlePartner">
      <PartnerNameHeader entry={props.entry} />
      <PartnerDetailsButton entry={props.entry} />
    </h2>
  );
};
