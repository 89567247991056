import { TypeKeys } from "../actions";
import { Actions } from "../actions";

const initialState = {
  currentPath: "",
  isEdit: false,
} as any;

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.RECOVER_DATA: {
      return Object.assign({}, state, {
        ...action.payload.viewState,
        meeting: null
      });
    }
    case TypeKeys.SET_VIEW_STATE: {
      const args = Object.assign({}, action.payload);
      if (
        state.errorField &&
        Object.keys(action.payload).indexOf(state.errorField) !== -1
      ) {
        args.error = null;
        args.errorField = null;
      }
      return Object.assign({}, state, args);
    }
    case TypeKeys.ADDRESS_BY_ZIP_CODE_SUCCESS: {
      const matches = action.payload.data.address.match(/^...??[都道府県]/);
      const prefecture = matches ? matches[0] : "";
      const address1 = prefecture
        ? action.payload.data.address.replace(prefecture, "")
        : "";
      return Object.assign({}, state, {
        prefecture: prefecture,
        address1: address1
      });
    }
    case TypeKeys.UPDATE_COMPANY: {
      return { ...state, spinner: true };
    }
    case TypeKeys.CHANGE_PATH: {
      return action.payload.location.pathname !== state.currentPath
        ? { currentPath: action.payload.location.pathname, isEdit: state.isEdit  }
        : state;
    }
    default: {
      return state;
    }
  }
};
