import * as React from "react";

export default function Contract(props: {
  content: string;
  closeModal: (result: boolean) => void;
}) {
  const contentYM = props.content.match(/(\d+)年(\d+)月/)![0];
  return (
    <main>
      <div
        className="modalCloseButton"
        onClick={() => {
          props.closeModal(false);
        }}
      >
        <i />
      </div>
      <p className="alCent mb0 mt0">
        契約開始日は変更できません。契約を進めてよろしいですか？
      </p>
      <p className="alCent">契約開始日：{props.content}</p>
      <p className="alCent mb10 mt0 joinsOrangeTxt bldTxt">
        ※ 記入頂いた契約開始日の場合、{contentYM}分から<br className="SPHide" />JOINSの手数料4万円（税抜）が発生します。<br /><u>（ 日割計算はありません ）</u>
      </p>
      <div className="alCent">
        <button className="btn btnLogin" onClick={() => props.closeModal(true)}>
          {"契約合意する"}
        </button>
      </div>
    </main>
  );
}
