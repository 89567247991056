import * as React from "react";
import { ErrorMessage } from "./common/ErrorMessage"

const NameField = (props: {
  values: { isSubmitted: boolean; last_name: string; first_name: string };
  updateValue: (args: { [key: string]: string }) => void;
  validates: { [key: string]: (value: any) => string | null };
}) => (
  <div className="formRow nameWrap">
    <label className="formHead">
      お名前<span className="label label_req">必須</span>
    </label>
    <div className="formData">
      <label className="nameLabel">姓</label>
      <input
        type="text"
        className="formWidth_s"
        id="last_name"
        name="last_name"
        placeholder="鈴木"
        required={true}
        value={props.values.last_name}
        onChange={(e: React.ChangeEvent<any>) =>
          props.updateValue({ last_name: e.target.value })
        }
      />
      {props.values.isSubmitted && props.validates["last_name"] && (
        <ErrorMessage>
          {props.validates["last_name"](props.values)}
        </ErrorMessage>
      )}
    </div>
    <div className="formData">
      <label className="nameLabel">名</label>
      <input
        type="text"
        className="formWidth_s"
        id="first_name"
        name="first_name"
        placeholder="太郎"
        required={true}
        value={props.values.first_name}
        onChange={(e: React.ChangeEvent<any>) =>
          props.updateValue({ first_name: e.target.value })
        }
      />
      {props.values.isSubmitted && props.validates["first_name"] && (
        <ErrorMessage>
          {props.validates["first_name"](props.values)}
        </ErrorMessage>
      )}
    </div>
  </div>
);

export default NameField;
