import * as React from "react"
import HintTooltip from "../forms/common/HintTooltip/HintTooltip"
import styles from "../../style/ProblemContentsForm.module.scss"
import { IProblemFormState } from "./index"
import { ErrorMessage } from "../common/ErrorMessage"
import { PublicStatusLabel } from "./PublicStatusLabel"

export const GoalForm = (props: {
  problem: IProblemFormState
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
  updateValue: (args: { [key: string]: any }) => void
}) => {
  return (
    <div id="goal">
      <div className={styles.labelWrap}>
        <label className="pure-u-1 problemLabel" onClick={(e) => e.preventDefault()}>
          ③ 1を実現した後に、さらに実現していきたいこと
          <HintTooltip
            title={
              <span>
                記入ポイント
                <ul>
                  <li>抽象的でも良いので、中長期的に目指している方向性をご記入ください。</li>
                  <li>自発的に提案してくれる人材が集まりやすくなります。</li>
                </ul>
              </span>
            }
          />
        </label>
        <PublicStatusLabel publishType={props.problem.publish_type} problemContentsType="goal" />
      </div>
      <textarea
        className="textarea"
        value={props.problem.goal}
        onChange={(e: React.ChangeEvent<any>) => {
          props.updateValue({
            ...props.problem,
            goal: e.target.value
          })
        }}
        placeholder={
          "記入例\n" +
          "（a）製造工程での不良品や在庫・生産数量の把握などを現状は紙で行っており、把握のスピードが遅い。既存の原価管理システムと連携させることで、今後、少量多品種などの製造体制へのシフトも視野にデジタル化を進めていきたい。\n" +
          "\n" +
          "（b）法人向けの卸販売と個人向けのEC販売、製造プロセス（原料仕入、製造、在庫等）と販売プロセス（受注、出荷等）がそれぞれ別のソフトで管理しており、集計作業などが手作業や紙で行っている。これらをデジタルで全て連携して管理できる状態にしたい。"
        }
      />
      {props.isSubmitted && props.validates["goal"] && (
        <ErrorMessage cls="mb20">{props.validates["goal"](props.problem)}</ErrorMessage>
      )}
    </div>
  )
}
