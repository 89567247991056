import { useMemo } from "react"
import clsx from "clsx"

interface IRequiredLabelProps {
  required?: boolean
  cls?: string
}

export const RequiredLabel = ({ required, cls }: IRequiredLabelProps) => {
  const text = useMemo(() => {
    return required ? "必須" : "任意"
  }, [required])

  const requiredCls = useMemo(() => {
    return required ? "label_req" : "label_opt"
  }, [required])

  return <span className={clsx("label", requiredCls, cls)}>{text}</span>
}
