import * as React from "react";
import { useImageUploader, CustomFile } from "./useImageUploader";
import styles from "../../../style/TrustDockCustomUploader.module.scss";
import { ErrorMessage } from "../../common/ErrorMessage"

interface FileUploaderProps {
  setPdf: (pdf: CustomFile[]) => void;
}

export const FileUploader = ({ setPdf }: FileUploaderProps) => {

  const ACCEPT_MAX_FILE = 10;
  const ACCEPT_FILE_SIZE_MEGA = 30;

  const {
    getRootProps,
    getInputProps,
    imageFiles,
    rejectedItems,
    imageUploaderStyle,
    deleteImage,
    isOverAcceptMaxFile,
  } = useImageUploader({
    acceptType: "application/pdf, image/jpeg, image/png, image/gif",
    acceptMaxFile: ACCEPT_MAX_FILE,
    acceptFileSizeMegaByte: ACCEPT_FILE_SIZE_MEGA,
  });

  React.useEffect(() => {
    setPdf(imageFiles);
  }, [imageFiles, setPdf]);

  const renderOverLimitMaxFileError = () => {
    if (isOverAcceptMaxFile || rejectedItems.length > ACCEPT_MAX_FILE) {
      return <ErrorMessage>アップロード可能なファイル数は{ACCEPT_MAX_FILE}枚までとなります。</ErrorMessage>
    }
    return null;
  };

  const renderAcceptedItems = () => {
    if (imageFiles.length === 0) return null;
    return (
      <div className={styles.pdfAcceptContainer}>
        {imageFiles.map((file) => (
          <li key={file.name}>
            <a href={file.preview} target="_blank" rel="noopener noreferrer">
              {file.name} - {file.size} bytes
            </a>
            <span className={styles.trashWrap}>
              <i
                className={`${styles.trashIcon} ${styles.byPdf}`}
                onClick={() => {
                  deleteImage(file.name);
                }}
              ></i>
            </span>
          </li>
        ))}
      </div>
    );
  };

  const renderRejectedItems = () => {
    if (rejectedItems.length === 0 || rejectedItems.length > ACCEPT_MAX_FILE) {
      return null;
    }
    return (
      <ErrorMessage>
        <ul className={styles.errorMessageWrap}>
          {rejectedItems.map(({ file, errors }) => {
            return (
              <li key={file.name}>
                <p>
                  {file.name} - {file.size} bytes
                </p>
                <ul>
                  {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </ErrorMessage>
    );
  };

  return (
    <>
      <div {...getRootProps({ style: imageUploaderStyle })}>
        <input {...getInputProps()} />
        <p>ドラッグまたはクリックでアップロード</p>
      </div>
      {renderAcceptedItems()}
      {renderRejectedItems()}
      {renderOverLimitMaxFileError()}
      <p>
        ※アップロード可能なファイル数は10枚までとなります。
        <br />
        ※アップロード可能なファイルの容量は{ACCEPT_FILE_SIZE_MEGA}MBまでとなります。
        <br />
        ※アップロード可能なファイルのファイル形式はpdf・jpeg・png・gifになります。
      </p>
    </>
  );
};
