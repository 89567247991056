import * as React from "react"
import { connect } from "react-redux"
import { Actions } from "../actions"
import { PartnerInfo, IPartnerProps } from "../components/PartnerInfo"
import { Dispatch, Action } from "redux"
import { IPartner, IStoreState } from "../types/state"
import { createScout } from "../thunks"

class Partner extends React.Component<IPartnerProps> {
  componentDidMount() {
    const { dispatch } = this.props
    const partnerID = parseInt(window.location.href.split("/").pop() as string, 10)
    dispatch(Actions.fetchPartner(partnerID))
  }
  render() {
    return <PartnerInfo {...this.props} />
  }
}

function mapStateToProps(state: IStoreState) {
  const partnerID = parseInt(window.location.href.split("/").pop() as string, 10)
  const partner = state.partners.partners.find((partner: IPartner) => partner.id === partnerID)

  return {
    partner,
    recruitment: state.recruitment,
    canEntry: state.company.businesses.length > 0,
    isNeededVerify: !state.companyVerification.verified && !partner?.scouted
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    createScout: (partnerID: number, problemId: number, message: string) =>
      dispatch(createScout(partnerID, problemId, message) as any),
    updateBookmark: (id: number, bookmarked: boolean) => dispatch(Actions.updateBookmark(id, bookmarked))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Partner as any)
