import * as React from "react";
import style from "../../style/InterviewInfo.module.scss";
import { dateTimeStr } from "../../lib/Util";
import { MEETING_TYPE, VIDEO_TYPE } from "../../lib/Definition"
import clsx from "clsx"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { showStartMeetingModal } from "../../thunks"

interface IStartMeetingButtonDispatchProps {}

interface IStartMeetingButtonStateProps {
  meetingType: MEETING_TYPE;
  videoType: string;
  meetingAt: Date | null;
  videoMeetingUrl: string;
}

interface IStartMeetingButtonProps extends IStartMeetingButtonDispatchProps, IStartMeetingButtonStateProps {}

export const StartMeetingButton = ({ meetingType, videoType, meetingAt, videoMeetingUrl }: IStartMeetingButtonProps) => {
  const dispatch = useDispatch()

  const onCilckStart = useCallback(() => {
    if (videoType === VIDEO_TYPE.bellbird_video) {
      dispatch(showStartMeetingModal(videoMeetingUrl))
    } else {
      window.open(videoMeetingUrl)
    }
  }, [dispatch, videoType, videoMeetingUrl])

  if (meetingType === MEETING_TYPE.OFFLINE) return null
  if (videoType !== VIDEO_TYPE.bellbird_video) return null

  return (
    <div className={clsx("formBtnArea formBtnAreaSingle", style.startMeetingButtonContainer)}>
      <div className={style.startMeetingButtonText}>
        {dateTimeStr(meetingAt)}開始
      </div>
      <button
        className={clsx("btn btnSave", style.startMeetingButton)}
        onClick={onCilckStart}
      >
        Web面談を開始する
      </button>
    </div>
  )
}
