import * as React from "react";
import { ICompany } from "../types/state";
import style from "../style/Recruitment.module.scss";
import { strToTag } from "../lib/Util";

interface IProfileDetailProps {
  company: ICompany;
  label: string;
  detail: string;
  parser: (val: any) => string;
}

const ProfileDetail = (props: IProfileDetailProps) => {
  const profileDetail = props.company[props.detail];
  if (!profileDetail || profileDetail === "") {
    return null;
  }
  return (
    <div style={{ marginBottom: "1em" }}>
      <span style={{ color: "#77797d" }}>{props.label}</span>
      <br />
      {props.parser(profileDetail)}
    </div>
  );
};

const DetailedPresidentProfile = (props: { company: ICompany }) => {
  return (
    <>
      <td className={style.companyOutline}>
        <ProfileDetail
          company={props.company}
          label={"肩書きと氏名を教えてください。"}
          detail={"president_position_name"}
          parser={strToTag}
        />
        <ProfileDetail
          company={props.company}
          label={"生まれた年を教えてください。"}
          detail={"president_birth_year"}
          parser={(val: number) => val.toString() + "年"}
        />
        <ProfileDetail
          company={props.company}
          label={"出身地を教えてください。"}
          detail={"president_hometown"}
          parser={strToTag}
        />
        <ProfileDetail
          company={props.company}
          label={"卒業後、最初にどんな仕事をされていましたか？"}
          detail={"president_first_job_desc"}
          parser={strToTag}
        />
        <ProfileDetail
          company={props.company}
          label={"今のポジションに就いたきっかけを教えてください。"}
          detail={"president_how_become"}
          parser={strToTag}
        />
        <ProfileDetail
          company={props.company}
          label={"この仕事を選んだのはどんな理由ですか。"}
          detail={"president_motivation"}
          parser={strToTag}
        />
        <ProfileDetail
          company={props.company}
          label={"社長で何代目ですか？"}
          detail={"president_generation"}
          parser={strToTag}
        />
        <ProfileDetail
          company={props.company}
          label={"趣味を教えてください。"}
          detail={"president_hobbies"}
          parser={strToTag}
        />
      </td>
    </>
  );
};

export default DetailedPresidentProfile;
