import "whatwg-fetch";
import {TypeKeys, Actions} from "../actions";

const ajax = (store: any) => (next: any) => (action: any) => {
  if (!action.payload || !action.payload.request) {
    return next(action);
  }
  const beginType = action.types ? action.types[0] : action.type;
  const successType = action.types ? action.types[1] : `${action.type}_SUCCESS`;
  const failType = action.types ? action.types[2] : `${action.type}_FAIL`;
  next({ type: beginType, payload: action.payload });
  let url = action.payload.request.url;
  const type = url
    .split("/")
    .pop()
    .replace(/\?.*/, "")
    .match("feed")
    ? "rss+xml"
    : "json";

  let headers: { [key: string]: string } = { Accept: `application/${type}` };
  if (url.match(process.env.REACT_APP_API_URL)) {
    headers = {
      ...headers,
      "X-APP-UID": store.getState().identifier.uid,
      "X-APP-USERID": store.getState().registrant.id,
      "X-APP-SESSIONID": store.getState().auth.sessionID,
      "X-REQUESTED-WITH": "XMLHttpRequest"
    };
  }
  if (process.env.REACT_APP_ADDITIONAL !== "NONE") {
    headers = {
      ...headers,
      authorization: `Basic ${btoa(
        unescape(
          encodeURIComponent(
            process.env.REACT_APP_NAME +
              ":" +
              (process.env.REACT_APP_NAME as string)
                .substr(0, 1)
                .toUpperCase() +
              (process.env.REACT_APP_NAME as string).substr(1) +
              "-" +
              process.env.REACT_APP_ADDITIONAL
          )
        )
      )}`
    };
  }
  const params: RequestInit = {
    credentials: "omit",
    headers,
    method: ""
  };
  if (
    action.payload.request.method &&
    action.payload.request.method.match(/get/i)
  ) {
    params.method = "get";
    if (action.payload.request.data) {
      const keyValues = Object.keys(action.payload.request.data).map(k =>
        encodeURI(k) + "=" + encodeURI(action.payload.request.data[k])
      );
      url = url + "?" + keyValues.join("&");
    }
  } else {
    params.method = action.payload.request.method;
    const formData = new FormData();
    Object.keys(action.payload.request.data).forEach(name => {
      const fieldName = name.replace(/\[\d+\]$/, "[]");
      const value = action.payload.request.data[name] ?? ""
      formData.append(fieldName, value);
    });
    params.body = formData;
  }
  if (action.payload.request.options?.loader) {
    // Show loader
    store.dispatch(Actions.setViewState({ loaderVisible: true }));
  }
  return new Promise((resolve, reject) => {
    fetch(url, params)
      .then(response => {
        if (response.ok) {
          if (type === "json") {
            response.json().then(result => {
              resolve(
                store.dispatch({
                  payload: { data: result, params: { ...params, url } },
                  type: successType
                })
              );
            });
          } else {
            response.text().then(result => {
              const domParser = new DOMParser();
              const doc = domParser.parseFromString(result, "text/xml");
              const rss = Array.from(doc.querySelectorAll("item")).map(q => ({
                title: (q.querySelector("title") as HTMLElement).textContent,
                pubDate: (q.querySelector("pubDate") as HTMLElement)
                  .textContent,
                link: (q.querySelector("link") as HTMLElement).textContent
              }));
              resolve(
                store.dispatch({
                  payload: { data: rss, params: { ...params, url } },
                  type: successType
                })
              );
            });
          }
        } else {
          if (response.status === 400) {
            response.json().then(json => {
              reject(
                store.dispatch({
                  payload: {
                    error: {
                      message: json.message,
                      field: json.field,
                      status: response.status,
                      data: json
                    },
                    params: { ...params, url }
                  },
                  type: failType
                })
              );
            });
          } else if (response.status === 403) {
            window.location.reload();
            reject();
          } else if (response.status === 422) {
            response.json().then((json) => {
              reject(
                store.dispatch({
                  payload: {
                    error: {
                      ...json,
                      status: response.status,
                    },
                    params: { ...params, url },
                  },
                  type: failType,
                })
              );
            });
          } else if (response.status >= 500) {
            reject(
                store.dispatch({
                  payload: {
                    error: {
                      message: response.statusText,
                      status: response.status
                    },
                    params: { ...params, url }
                  },
                  type: TypeKeys.INTERNAL_SERVER_ERROR
                })
            );
          } else {
            reject(
              store.dispatch({
                payload: {
                  error: {
                    message: response.statusText,
                    status: response.status
                  },
                  params: { ...params, url }
                },
                type: failType
              })
            );
          }
        }
      })
      .catch(() =>
        reject(
          store.dispatch({
            payload: {
              error: { statusText: "fetch error" },
              params: { ...params, url }
            },
            type: failType
          })
        )
      )
      .finally(() => {
        // Hide loader
        store.dispatch(Actions.setViewState({ loaderVisible: false }));
      });
  });
};
export default ajax;
