import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import { Dispatch, Action } from "redux";
import { IEntry, IStoreState } from "../types/state"
import {
  EntryCancelFinishInfo,
  IEntryCancelFinishInfoProps,
  IEntryCancelFinishInfoStateProps,
  IEntryCancelFinishInfoDispatchProps
} from "../components/EntryCancelFinish/EntryCancelFinishInfo"

const getEntryId = () => {
  return parseInt(window.location.href.split("/").pop() as string, 10)
}

interface IStateProps {}

interface IDispatchProps {
  dispatch: Dispatch<Action>;
}

interface IProps extends IStateProps, IDispatchProps {}

class EntryCancelFinish extends React.Component<IEntryCancelFinishInfoProps & IProps> {
  componentDidMount() {
    const { dispatch } = this.props
    const entryId = getEntryId()
    dispatch(Actions.fetchEntry(entryId))
  }

  render() {
    if (!this.props.entry) return null
    return <EntryCancelFinishInfo entry={this.props.entry} />
  }
}

function mapStateToProps(state: IStoreState): IEntryCancelFinishInfoStateProps | IStateProps {
  const entryId = getEntryId()
  const entry = state.entries.find((entry: IEntry) => entry.id === entryId)
  return {
    entry
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action>): IEntryCancelFinishInfoDispatchProps | IDispatchProps {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryCancelFinish)
