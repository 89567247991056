import React from "react";
import {IAjaxErrorDetails} from "../types/state";
import { HttpErrorPage } from "../components/HttpErrorPage";
import { UnexpectedErrorPage } from "../components/UnexpectedErrorPage";

interface Props  {
  error: IAjaxErrorDetails | undefined
  children: React.ReactNode
}

export const ErrorViewHandler: React.FC<Props> = ({error, children}) => {
  const errorStatus = error?.status as number

  if (errorStatus) {
    if (errorStatus >= 500) {
      return (<HttpErrorPage errorCode={errorStatus}/>)
    } else {
      return <UnexpectedErrorPage/>
    }
  }

  return <>{children}</>
}
