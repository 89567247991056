import * as React from "react";
import { Link } from "react-router-dom";
import { homePath } from "../routes/paths";

export const Error404Page = () => {
  return (
    <main className="contentsWrapper-transparent">
      <h2 className="contentsTitle">お探しのページが見つかりませんでした。</h2>
      <p>
        <Link to={homePath}>ホーム画面に戻る</Link>
      </p>
    </main>
  )
}
