import * as React from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  display: "flex",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#e0e0e0",
  borderStyle: "dashed",
  backgroundColor: "#f6f6f6",
  color: "#9e9e9e",
  outline: "none",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#006ca1",
};

const acceptStyle = {
  borderColor: "#84cb63",
};

const rejectStyle = {
  borderColor: "#d84315",
};

const ErrorMessage = {
  "file-too-large": "ファイルの容量が制限を超えてます。",
  "file-invalid-type": "許可されているファイルのタイプではありません。",
} as const;

export type CustomFile = {
  preview: string;
} & File;

interface useImageUploaderProps {
  acceptType: string;
  acceptMaxFile?: number;
  acceptFileSizeMegaByte?: number;
}

const COEFFICIENT_MEGA_TO_BYTE = 1048576;

export const useImageUploader = ({
  acceptType,
  acceptMaxFile = 0,
  acceptFileSizeMegaByte = 5,
}: useImageUploaderProps) => {
  const [imageFiles, setImageFiles] = React.useState<CustomFile[]>([]);
  const [isOverAcceptMaxFile, setIsOverAcceptMaxFile] = React.useState(false);
  const componentWillUnMount = React.useRef(false);

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptType,
    maxFiles: acceptMaxFile,
    maxSize: acceptFileSizeMegaByte * COEFFICIENT_MEGA_TO_BYTE,
    onDrop: (acceptedFiles) => {
      setIsOverAcceptMaxFile(false);
      const filteredFiles =
        acceptedFiles
          .map((af) => {
            const result = imageFiles.findIndex((f) => {
              return af.name === f.name;
            });
            if (result === -1) return af;
            return null;
          })
          .filter((v) => v) ?? [];

      const baseFiles = [...imageFiles, ...filteredFiles];

      if (baseFiles.length > acceptMaxFile) {
        setIsOverAcceptMaxFile(true);
        return;
      }

      setImageFiles(
        baseFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });

  const imageUploaderStyle = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const rejectedItems = React.useMemo(() => {
    return fileRejections.map(({ file, errors }) => {
      return {
        file: file,
        errors: errors.map((error) => ({
          code: error.code,
          message: ErrorMessage[error.code] ?? "エラーが発生しました。",
        })),
      };
    });
  }, [fileRejections]);

  const deleteImage = React.useCallback(
    (fileName) => {
      imageFiles.forEach((file) => {
        if (fileName === file.name) {
          URL.revokeObjectURL(file.preview);
        }
      });
      const filteredImageFiles =
        imageFiles
          .map((file) => {
            if (fileName === file.name) {
              return null;
            } else {
              return file;
            }
          })
          .filter((v) => v) ?? [];

      setIsOverAcceptMaxFile(false);
      setImageFiles([...(filteredImageFiles as CustomFile[])]);
    },
    [imageFiles]
  );

  const revokeAllObjectURL = React.useCallback(() => {
    imageFiles.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [imageFiles]);

  React.useEffect(() => {
    return () => {
      componentWillUnMount.current = true;
    };
  }, []);

  React.useEffect(() => {
    return () => {
      if (componentWillUnMount.current) {
        revokeAllObjectURL();
      }
    };
  }, [revokeAllObjectURL]);

  return {
    getRootProps,
    getInputProps,
    imageFiles,
    rejectedItems,
    imageUploaderStyle,
    deleteImage,
    isOverAcceptMaxFile,
  };
};
