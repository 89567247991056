import * as React from "react";
import styles from "../../style/InterviewInfo.module.scss";
import { MEETING_KIND, MEETING_TYPE, MEETING_TYPE_STR } from "../../lib/Definition"
import { useMemo } from "react"

interface IInterviewIntroMessage {
  kind: MEETING_KIND;
  type: MEETING_TYPE;
}

export const InterviewIntroMessage = ({ kind, type }: IInterviewIntroMessage) => {
  const meetingType = useMemo(() => MEETING_TYPE_STR[type], [type])

  return (
    <>
      <h3 className="contentsSubTitle">
        {kind}（{meetingType}）について
      </h3>
      <p>
        {kind}は、企業様と応募者様の二者間で実施いただく{meetingType}面談です。<br />
        {type === MEETING_TYPE.ONLINE && (
          <>時間となりましたらページ下の「Web面談を開始する」ボタンをクリックし、Web面談のページにアクセスしてください。</>
        )}
      </p>
      <p className={styles.interviewIntroMessageNotice}>
        <u>{kind}前までに必ず</u>以下情報のご確認および事前準備のご対応をお願いいたします。
      </p>
    </>
  )
}
