import * as React from "react";
import { IScout } from "../types/state";
import userIcon from "../images/icon-user.svg";
import style from "../style/Table.module.scss";
import { partnerPath } from "../routes/paths";
import * as Definition from "../lib/Definition";
import { StatusBadge } from "./StatusBadge";
import { getPartnerCompanyName, getPartnerIdentityDesc, isWithdrawn } from "../lib/Util"

export interface IEntrySummaryScoutRowDispatchProps {
  changePath: (path: string) => void;
}

export interface IEntrySummaryScoutRowStateProps {
  scout: IScout;
}

export interface IEntrySummaryScoutRowProps extends IEntrySummaryScoutRowDispatchProps, IEntrySummaryScoutRowStateProps {}

export const EntrySummaryScoutRow = (props: IEntrySummaryScoutRowProps) => {
  const { scout } = props;
  const partner = scout.partner;

  const companyName = React.useMemo(() => {
    return getPartnerCompanyName(partner)
  }, [partner])

  const typeOrStatus = React.useMemo(() => (
    scout.status === "rejected" ? Definition.SCOUT_STATUS[scout.status] : Definition.SCOUT_TYPES_JA[scout.scout_type]
  ), [scout.status, scout.scout_type])

  const showTodo = React.useMemo(() => {
    return !isWithdrawn(partner) && scout.status === "waiting"
  }, [partner, scout.status]);

  return (
    <div className={style.tableRow} key={partner.id}>
      <div className={style.tableRowInnerFirst}>
        <div
          className={`${style.thumbnailCol} alLeft`}
          onClick={() => props.changePath(`${partnerPath}/${partner.id}`)}
        >
          <img className={style.thumbnail} src={userIcon} alt="partner" />
        </div>
        <div className={style.tableRowInnerSecond}>
          <div className={style.tableRowInnerThird}>
            <div className={`${style.badgeCol} alLeft`}>
              <StatusBadge
                value={typeOrStatus}
                active={scout.status !== "rejected"}
                stroked
              />
            </div>
            <div
              className={`${style.nameCol} alLeft`}
              onClick={() => props.changePath(`${partnerPath}/${partner.id}`)}
            >
              <strong> {getPartnerIdentityDesc(partner)} </strong>
              {partner.verificated && <span className={style.verificationIcon}>本人確認済み</span>}
              <span className={style.partnerID}>（ID: {partner.id}）</span>
            </div>
            <div className={style.faceLabel}>&nbsp;</div>
          </div>
          <div className={style.careerCol}>
            {companyName}
          </div>
        </div>
      </div>

      <div className={style.todoCol}>
        {showTodo && "面談希望の連絡待ち"}
      </div>
    </div>
  );
};
