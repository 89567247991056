import * as React from "react";
import style from "../style/Table.module.scss";
import { entryPath } from "../routes/paths";
import userIcon from "../images/icon-user.svg";
import { entryStatus, getPartnerCompanyName, getPartnerIdentityDesc } from "../lib/Util"
import { IComputedEntry } from "./EntryList";
import { StatusBadge } from "./StatusBadge";
import { NextActionDescription } from "./NextActionDescription";
import { Label } from "./Label";
import { ENTRY_STATUS } from "../lib/Definition";


export interface IEntrySummaryTableDispatchProps {
  changePath: (path: string) => void;
}

export interface IEntrySummaryTableStateProps {
  title: any;
  entries: IComputedEntry[];
  psychological_status: string;
  customRows?: any;
}

export interface IEntrySummaryTableProps extends IEntrySummaryTableDispatchProps, IEntrySummaryTableStateProps {}

export const EntrySummaryTable = (props: IEntrySummaryTableProps) => {
  return (
    <>
      {props.title}
      <div className={style.table}>
        <div>
          {props.entries.map((entry) => {
            const partner = entry.partner
            const userIconSrc =
              partner.photo && entry.entry_status !== ENTRY_STATUS.first_interview_requested && !entry.declined_by
                ? partner.photo.url
                : userIcon
            const status = entryStatus(entry)

            return (
              <div key={partner.id}>
                <div
                  className={entry.idea ? style.entryTableRow : style.tableRow}
                >
                  <div className={style.tableRowInnerFirst}>
                    <div
                      className={`${style.thumbnailCol} alLeft`}
                      onClick={() =>
                        props.changePath(`${entryPath}/${entry.id}`)
                      }
                    >
                      <img
                        className={style.thumbnail}
                        src={userIconSrc}
                        alt="partner"
                      />
                    </div>
                    <div className={style.tableRowInnerSecond}>
                      <div className={style.tableRowInnerThird}>
                        <div className={`${style.badgeCol} alLeft`}>
                          <StatusBadge
                            value={status}
                            active={status !== "期限切れ" && status !== "不成立"}
                            stroked
                          />
                        </div>
                        <div
                          className={`${style.nameCol} alLeft`}
                          onClick={() =>
                            props.changePath(`${entryPath}/${entry.id}`)
                          }
                        >
                          <strong> {getPartnerIdentityDesc(partner)} </strong>
                          {partner.verificated && <span className={style.verificationIcon}>本人確認済み</span>}
                          <span className={style.partnerID}>（ID：{entry.id}）</span>
                          <Label type={entry.label_status} iconOnly />
                        </div>
                      </div>
                      <div className={style.careerCol}>
                        {getPartnerCompanyName(partner)}
                      </div>
                    </div>
                  </div>

                  <div className={style.todoCol}>
                    <NextActionDescription
                      entry={entry}
                      psychological_status={props.psychological_status}
                    />
                  </div>
                </div>
                {entry.idea && (
                  <div className={style.entryTableRowSecond}>
                    <div className={style.tableRowSecondInner}>
                      {entry.idea.length <= 200
                        ? entry.idea
                        : `${entry.idea.slice(0, 200)}...`}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {props.customRows}
        </div>
      </div>
    </>
  );
};
