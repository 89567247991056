import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { IStoreState, ICompany, ICompanyVerification } from "../types/state";
import { CheckTrustForm } from "../components/CheckTrustForm";
import { Actions } from "../actions";
import { CompanyVerificationActions } from "../actions/company_verification";
import { CustomFile } from "../components/CheckTrustForm/CustomUploader/useImageUploader";
import { sendCompanyVerification } from "../thunks/company_verification";
import { ICheckTrustProps, ICheckTrustFormDispatchProps } from "../components/CheckTrustForm";
import {
  ModalArgs,
  openModal,
} from "../thunks";

interface Props {
  company: ICompany;
  setIsEdit: (isEdit: boolean) => void;
  companyVerification: ICompanyVerification;
  sendCompanyVerification: (corporate_number: number | null, personal_files: CustomFile[] | null, open_business_files: CustomFile[] | null) => void;
  checkCompanyVerification: () => void
}

const CheckTrust: React.FC<
  ICheckTrustProps & Props
> = (props) => {
  const [isInit, setIsInit] = React.useState(false);
  React.useEffect(() => {
    if (!isInit) {
      props.checkCompanyVerification();
      setIsInit(true);
    }
  }, [props, isInit]);

  return (
    <CheckTrustForm {...props} />
  );
};

function mapStateToProps(state: IStoreState) {
  return {
    company: state.company,
    companyVerification: state.companyVerification,
  }
}

function mapDispatchToProps(
  dispatch: Dispatch<Action<any>>
): ICheckTrustFormDispatchProps & { dispatch: Dispatch<any> } {
  return {
    dispatch,
    setIsEdit: (isEdit: boolean) =>
      dispatch(Actions.setViewState({ isEdit: isEdit })),
    sendCompanyVerification: (corporate_number: number | null, personal_files: CustomFile[] | null, open_business_files: CustomFile[] | null) => {
      return dispatch(sendCompanyVerification(corporate_number, personal_files, open_business_files) as any);
    },
    checkCompanyVerification: () => {
      return dispatch(CompanyVerificationActions.checkCompanyVerification() as any);
    },
    openModal: (args: ModalArgs) => dispatch(openModal(args) as any),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckTrust)
