import React from "react";
import "./HintText.scss";

export interface IHintText {
  children: React.ReactNode;
}

const HintText = (props: IHintText) => {
  return <p className="hint-text">{props.children}</p>;
};
export default HintText;
