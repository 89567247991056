import { Actions, TypeKeys } from "../actions";
import { CompanyVerificationActions, CompanyVerificationTypeKeys } from "../actions/company_verification";
import { ICompanyVerification } from "../types/state";

const initialState: ICompanyVerification = {
  isRequested: false,
  verified: false,
};

const companyVerification = (state = initialState, action: CompanyVerificationActions | Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return {
        isRequested: action.payload.data.company_verification.isRequested,
        verified: action.payload.data.company_verification.verified,
      }
    }
    case CompanyVerificationTypeKeys.SEND_COMPANY_VERIFICATION_SUCCESS: {
      return {
        ...state,
      }
    }
    case CompanyVerificationTypeKeys.CHECK_COMPANY_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isRequested: action.payload.data.isRequested,
        verified: action.payload.data.verified,
      }
    }
    default: {
      return state;
    }
  }
};

export default companyVerification;
