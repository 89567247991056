import { TypeKeys, Actions } from "../actions"
import { IStaffActivation } from "../types/state"
import { StaffActions, StaffTypeKeys } from "../actions/staff"

const initialState: IStaffActivation = { status: undefined,  email: undefined }

const staffActivation = (state = initialState, action: Actions | StaffActions): IStaffActivation => {
  switch (action.type) {
    case StaffTypeKeys.ACTIVATE_STAFF_SUCCESS:
      return {
        ...state,
        status: action.payload.data.status,
        email: action.payload.data.email
      }
    case StaffTypeKeys.CHECK_STAFF_ACTIVATION_UUID_SUCCESS:
      return {
        ...state,
        status: action.payload.data.status
      }
    case StaffTypeKeys.ACTIVATE_STAFF_FAIL:
    case StaffTypeKeys.CHECK_STAFF_ACTIVATION_UUID_FAIL:
      return {
        ...state,
        status: action.payload.error.data.status
      }
    case TypeKeys.LOGOUT_SUCCESS:
      return initialState
    default: {
      return state
    }
  }
}

export default staffActivation
