import * as React from "react";
import {
  firstPsychologicalSafetyPath,
  editFirstPsychologicalSafetyPath,
} from "../../routes/paths";
import { Link } from "react-router-dom";
import style from "../../style/Psychology.module.scss";
import infographic01 from "../../images/shinriBadIll01.png";
import infographic02 from "../../images/shinriBadIll02.png";
import infographic03 from "../../images/shinriBadIll03.png";
import infographic04 from "../../images/shinriGoodIll01.png";
import infographic05 from "../../images/shinriGoodIll02.png";
import infographic06 from "../../images/shinriGoodIll03.png";
import arrow01 from "../../images/circleArrowGray.png";
import arrow02 from "../../images/circleArrowGreen.png";
import arrow03 from "../../images/bigArrowYellow.png";
import { PSYCHOLOGICAL_STATUS } from "../../lib/Definition"
import { useMemo } from "react"

export interface IPsychologicalSafetyInfoDispatchProps {
  changePath: (path: string) => void;
}

export interface IPsychologicalSafetyInfoStateProps {
  psychologicalStatus: string;
}

export interface IPsychologicalSafetyInfoProps extends IPsychologicalSafetyInfoDispatchProps, IPsychologicalSafetyInfoStateProps {}

export const PsychologicalSafetyInfo = ({ psychologicalStatus }: IPsychologicalSafetyInfoProps) => {
  const isToEdit = useMemo(() => (
    psychologicalStatus === PSYCHOLOGICAL_STATUS.hidden || psychologicalStatus === PSYCHOLOGICAL_STATUS.first_wait
  ), [psychologicalStatus])

  return (
    <>
      <div className={style.partnerTop}>
        <div className={style.partnerTopInner}>
          <h2 className={style.partnerTopTitle}>面談の前にご記入ください</h2>
          <div className={style.partnerTopDescription}>
            <div className="mb24mb16">
              <p>
                地域で仕事をはじめる際の課題になりやすいのが、お互いに構えてしまうことで、
                <br />
                <b className="joinsGreenTxt">
                  あなたと企業の担当者との間で無意識にできてしまう「心の壁」。
                </b>
              </p>
              <p>
                そこでJOINSでは、業務内容やスキルだけでなく、関係性を作っていくための
                <br />
                <b className="joinsGreenTxt">お互いの人柄や価値観</b>
                を共有するプログラムを実施し、
                <br />
                安心してお仕事をはじめていただけるよう支援しています。
              </p>
            </div>
            <div className={style.btnWrapper}>
              {isToEdit ? (
                <Link
                  to={editFirstPsychologicalSafetyPath}
                  className="btn btnSave"
                >
                  一次面談の設問へ回答する（未回答）
                </Link>
              ) : (
                <Link to={firstPsychologicalSafetyPath} className="btn btnSave">
                  一次面談の設問の回答内容を見る
                </Link>
              )}
            </div>
            <div className={style.shinriWrap}>
              <div className={style.shinriCaseBox}>
                <div className={style.shinriCaseInner}>
                  <>
                    業務・スキルの
                    <br className={style.brall} />
                    マッチングのみ
                  </>
                  <img src={infographic01} alt="業務・スキルのマッチングのみ" />
                </div>
                <div className={style.shinriCaseArrow}>
                  <img src={arrow01} alt="" />
                </div>
                <div className={style.shinriCaseInner}>
                  <>
                    関係性構築
                    <br className={style.brall} />
                    なし
                  </>
                  <img src={infographic02} alt="関係性構築なし" />
                </div>
                <div className={style.shinriCaseArrow}>
                  <img src={arrow01} alt="" />
                </div>
                <div className={style.shinriCaseInner + " " + style.shinriCaseInnerLong}>
                  <>
                    コミュニ
                    <br className={style.brsp} />
                    ケーションが
                    <br className={style.brall} />
                    取りづらい
                  </>
                  <img
                    src={infographic03}
                    alt="コミュニケーションが取りづらい"
                  />
                </div>
              </div>
              <div className={style.shinriBigArrowBox}>
                <div className={style.shinriBigArrowImage}>
                  <img src={arrow03} alt="" />
                  <div className={style.shinriBigArrowText}>
                    プログラムでこう変わる
                  </div>
                </div>
              </div>
              <div className={style.shinriCaseBox + " " + style.goodCase}>
                <div className={style.shinriCaseInner + " " + style.shinriCaseInnerLong}>
                  <>
                    プログラムで
                    <br className={style.brall} />
                    人柄や価値観
                    <br className={style.brsp} />
                    を共有
                  </>
                  <img
                    src={infographic04}
                    alt="プログラムで人柄や価値観を共有"
                  />
                </div>
                <div className={style.shinriCaseArrow}>
                  <img src={arrow02} alt="" />
                </div>
                <div className={style.shinriCaseInner}>
                  <>
                    関係性構築
                    <br className={style.brall} />
                    あり
                  </>
                  <img src={infographic05} alt="関係性構築あり" />
                </div>
                <div className={style.shinriCaseArrow}>
                  <img src={arrow02} alt="" />
                </div>
                <div className={style.shinriCaseInner}>
                  <>
                    相談・依頼
                    <br className={style.brall} />
                    しやすい
                  </>
                  <img src={infographic06} alt="相談・依頼しやすい" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
