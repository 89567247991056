import * as React from "react"
import styleRec from "../../style/Recruitment.module.scss"
import style from "../../style/InterviewInfo.module.scss"
import { dateTimeStr } from "../../lib/Util"
import { IEntry, VideoType } from "../../types/state"
import { MEETING_KIND, MEETING_TYPE, VIDEO_TYPE } from "../../lib/Definition"
import { UseJoinsVideoChat } from "./UseJoinsVideoChat"
import { VideoTestJoins } from "./VideoTestJoins"
import { VideoTestOther } from "./VideoTestOther"
import clsx from "clsx"
import { BeforehandCheck } from "./BeforehandCheck"
import { InputPsychologicalSafety } from "./InputPsychologicalSafety"
import { InterviewFlow } from "./InterviewFlow"

interface IMeetingInfoTableProps {
  entry: IEntry
  kind: MEETING_KIND
  type: MEETING_TYPE
  videoType: VideoType
  meetingAt: Date | null
  videoMeetingUrl: string
  videoSettingUrl: string
}

export const MeetingInfoTable = ({
  entry,
  kind,
  type,
  videoType,
  meetingAt,
  videoMeetingUrl,
  videoSettingUrl
}: IMeetingInfoTableProps) => {
  return (
    <table className={clsx(styleRec.recruitmentTable, style.meetingInfoTable, "mb32mb16")}>
      <tbody>
        <tr>
          <th>面談日時</th>
          <td>
            {dateTimeStr(meetingAt)}〜　
            <br className="PCHide" />
            （1時間程度）
          </td>
        </tr>
        {kind === MEETING_KIND.FIRST_INTERVIEW && <InputPsychologicalSafety />}
        {type === MEETING_TYPE.ONLINE && (
          <>
            {videoType !== VIDEO_TYPE.other_video && <VideoTestJoins videoSettingUrl={videoSettingUrl} />}
            {videoType === VIDEO_TYPE.other_video && <VideoTestOther videoMeetingUrl={videoMeetingUrl} />}
          </>
        )}
        {type === MEETING_TYPE.OFFLINE && (
          <tr>
            <th>面談の実施場所</th>
            <td>
              <div>
                <p className={clsx("mt0 mb10", style.meetingInfoTableLocation)}>{entry.second_interview?.location}</p>
              </div>
              <UseJoinsVideoChat videoMeetingUrl={videoMeetingUrl} />
            </td>
          </tr>
        )}
        <BeforehandCheck entry={entry} kind={kind} />
        <InterviewFlow kind={kind} type={type} />
      </tbody>
    </table>
  )
}
