import * as React from "react";
import { ChangeEvent, useCallback } from "react";
import * as _ from "lodash";
import { ErrorMessage } from "./common/ErrorMessage"

const SearchSelectInput = (props: {
  flags?: { [key: string]: { flag: number; message: string } };
  master?: { [key: string]: string };
  label?: any;
  type?: string;
  name: string;
  note?: string;
  required?: boolean;
  cls?: string;
  values: any;
  children?: React.ReactNode;
  validates?: { [key: string]: (value: any) => string | null };
  updateValue: (val: string) => void;
  placeholder?: string;
  hint?: any;
}) => {
  const flags = props.flags as {
    [key: string]: { flag: number; message: string };
  };
  const master = React.useMemo(() => {
    return props.master ? props.master : {};
  }, [props.master]);
  if (Object.keys(master).length === 0) {
    Object.keys(flags).forEach((key) => {
      master[flags[key].flag] = flags[key].message;
    });
  }

  const updateValue = props.updateValue;
  const onInputUpdate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const invertedMaster = _.invert(master);
      const id = invertedMaster[value];
      updateValue(id);
    },
    [master, updateValue]
  );

  return (
    <>
      <div className={props.hint ? "formRow noUnderline" : "formRow"}>
        {props.label && (
          <label className="formHead">
            {props.label}
            {props.required ? (
              <span className="label label_req">必須</span>
            ) : (
              <span className="label label_opt">任意</span>
            )}
            {props.type !== "scout" && <br />}
            {props.note && <small>{props.note}</small>}
          </label>
        )}
        <div className="formData">
          <input
            list="option-list"
            type="text"
            className={props.cls ? props.cls : "formWidth_s"}
            id={props.name}
            onChange={onInputUpdate}
            placeholder={props.placeholder}
          />
          <datalist id="option-list">
            {_.map(master, (value, key) => {
              return <option key={key} value={value} />;
            })}
          </datalist>
          {props.values.isSubmitted &&
            props.validates &&
            props.validates[props.name] && (
              <ErrorMessage>
                {props.validates[props.name](props.values)}
              </ErrorMessage>
            )}
        </div>
      </div>
      {props.hint && <div className="formHint">{props.hint}</div>}
    </>
  );
};

export default SearchSelectInput;
