import { Actions, TypeKeys } from "../actions"
import { IRecruitment, IProblem, IScout, ProblemStatus } from "../types/state"
import DateWithOffset from "../lib/DateWithOffset"
import { buildPartner } from "./entries"
import { getProblemStatus } from "../lib/Util"
import { ProblemActions, ProblemTypeKeys } from "../actions/problem"

const initialState: IRecruitment = {
  id: 0,
  company_id: 0,
  problems: [],
  scouts: [],
  status: null,
  request_start_at: null
}

function sortProblem(problems: IProblem[]): IProblem[] {
  return problems.sort((a, b) => (b.expired_date?.getTime() ?? 0) - (a.expired_date?.getTime() ?? 0))
}

function buildProblem(problem: IProblem, recruitment: IRecruitment): IProblem {
  return {
    ...problem,
    status: getProblemStatus(problem, recruitment) as ProblemStatus,
    expired_date: problem.expired_date ? new DateWithOffset(problem.expired_date) : null,
    created_at: new DateWithOffset(problem.created_at)
  }
}

function buildScout(scout: IScout): IScout {
  return {
    ...scout,
    partner: buildPartner(scout.partner),
    created_at: new DateWithOffset(scout.created_at)
  }
}

export default (state = initialState, action: Actions | ProblemActions) => {
  switch (action.type) {
    case TypeKeys.SIGNUP_SUCCESS: {
      return Object.assign({}, state, {
        company_id: action.payload.data.company.id
      })
    }
    case TypeKeys.LOGIN_SUCCESS: {
      return Object.assign({}, state, action.payload.data.recruitment, {
        company_id: action.payload.data.company.id,
        request_start_at: action.payload.data.recruitment.request_start_at
          ? new DateWithOffset(action.payload.data.recruitment.request_start_at)
          : null,
        problems: sortProblem(
          action.payload.data.recruitment.problems.map((problem) =>
            buildProblem(problem, action.payload.data.recruitment)
          )
        ),
        scouts: action.payload.data.recruitment.scouts.map((scout) => buildScout(scout))
      })
    }
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return Object.assign({}, state, action.payload.data.recruitment, {
        company_id: action.payload.data.company.id,
        request_start_at: action.payload.data.recruitment.request_start_at
          ? new DateWithOffset(action.payload.data.recruitment.request_start_at)
          : null,
        problems: sortProblem(
          action.payload.data.recruitment.problems.map((problem) =>
            buildProblem(problem, action.payload.data.recruitment)
          )
        ),
        scouts: action.payload.data.recruitment.scouts.map((scout) => buildScout(scout))
      })
    }
    case ProblemTypeKeys.CREATE_PROBLEM_SUCCESS:
    case ProblemTypeKeys.UPDATE_PROBLEM_SUCCESS:
    case ProblemTypeKeys.DELETE_PROBLEM_SUCCESS: {
      return Object.assign({}, action.payload.data.recruitment, {
        request_start_at: action.payload.data.recruitment.request_start_at
          ? new DateWithOffset(action.payload.data.recruitment.request_start_at)
          : null,
        problems: sortProblem(
          action.payload.data.recruitment.problems.map((problem) =>
            buildProblem(problem, action.payload.data.recruitment)
          )
        ),
        scouts: action.payload.data.recruitment.scouts.map((scout) => buildScout(scout))
      })
    }
    case TypeKeys.CREATE_SCOUT_SUCCESS: {
      return {
        ...state,
        scouts: [
          ...state.scouts,
          buildScout({
            ...action.payload.data,
            created_at: new DateWithOffset(action.payload.data.created_at)
          })
        ]
      }
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState
    }
    default: {
      return state
    }
  }
}
