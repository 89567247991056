import { connect } from "react-redux"
import { IStoreState } from "../types/state"
import {
  IRecruitmentListDispatchProps,
  IRecruitmentListStateProps,
  RecruitmentList
} from "../components/Recruitment/RecruitmentList"
import { Dispatch } from "redux"
import { deleteProblem, showConfirmDeleteProblem } from "../thunks/problem"

function mapStateToProps(state: IStoreState): IRecruitmentListStateProps {
  return {
    recruitment: state.recruitment,
    isVerified: state.companyVerification.verified
  }
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>): IRecruitmentListDispatchProps {
  return {
    deleteProblem: (problemId: number) => {
      dispatch(showConfirmDeleteProblem({ problemId }) as any).then((result: boolean | MouseEvent) => {
        // モーダルのオーバーレイをクリックした場合にはReact.MouseEventが渡ってくるので注意
        if (result === true) {
          dispatch(deleteProblem(problemId) as any)
        }
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentList)
