import { TypeKeys, Actions } from "../actions"
import { IMonthlyQuestionnaire } from "../types/state"
import { MonthlyQuestionnaireActions, MonthlyQuestionnaireTypeKeys } from "../actions/monthly_questionnaire"

const initialState: IMonthlyQuestionnaire | null = null

export const monthlyQuestionnaire = (state = initialState, action: Actions | MonthlyQuestionnaireActions) => {
  switch (action.type) {
    case MonthlyQuestionnaireTypeKeys.FETCH_MONTHLY_QUESTIONNAIRE_SUCCESS: {
      return action.payload.data
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
