export const homePath = "/"
export const loginPath = "/login"
export const signUpPath = "/sign_up"
export const resetPasswordPath = "/reset_password"
export const resetPasswordFinishPath = "/reset_password_finish"
export const registrantPath = "/registrant"
export const editRegistrantPath = "/edit_registrant"
export const editCompanyPath = "/edit_company"
export const checkTrustPath = "/check_trust"
export const companyPath = "/company"

export const recruitmentPath = "/recruitment"
export const problemNewPath = `/problem/new`
export const problemEditPath = `/problem/edit`
export const problemCopyPath = `/problem/copy`

export const renewalPasswordPath = "/renewal_password"
export const renewalPasswordFinishPath = "/renewal_password_finish"

export const entryPath = "/entry"
export const entryAcceptPath = "/entry_accept"
export const entryRejectPath = "/entry_reject"
export const entryFinishPath = "/entry_finish"
export const entryCancelFinishPath = "/entry_cancel_finish"
export const registerThanksPath = "/register_thanks"
export const companyRecruitmentPath = "/company_recruitment"

export const secondInterviewRequestPath = "/second_interview_request"
export const secondInterviewRequestFinishPath = "/second_interview_requested"

export const roomsPath = "/rooms"
export const roomPath = "/room"

export const schedulePath = "/schedule"
export const scoutPath = "/scout"
export const partnerPath = "/partner"
export const firstInterviewPath = "/first_interview"
export const secondInterviewPath = "/second_interview"

export const staffsPath = "/staffs"
export const editStaffsPath = "/edit_staffs"
export const activateStaffPath = "/activate_staff"

export const psychologicalSafetyPath = "/psychological_safety"
export const firstPsychologicalSafetyPath = "/first_psychological_safety"
export const editFirstPsychologicalSafetyPath = "/edit_first_psychological_safety"
export const entryFirstPsychologicalSafetiesPath = "/entry_first_psychological_safeties"
export const secondInterviewTodosPath = "/second_interview_todos"

export const noticePath = "/notice_list"

export const guidanceMeetingPath = "/guidance_meeting"

export const monthlyQuestionnairePath = "/monthly_questionnaire"
export const monthlyQuestionnaireFinishPath = "/monthly_questionnaire_finish"

export const pathPrefix = () => {
  if (process.env.NODE_ENV === "production") {
    return "/app_companies"
  }
  return ""
}
