import * as React from "react"
import style from "../../style/MonthlyQuestionnaire/MonthlyQuestionnaireForm.module.scss"
import styleRec from "../../style/Recruitment.module.scss"
import { useSelector } from "react-redux"
import { IStoreState, MonthlyQuestionnaireSatisfactionType } from "../../types/state"
import {
  MONTHLY_QUESTIONNAIRE_DISSATISFACTION,
  MONTHLY_QUESTIONNAIRE_SATISFACTION,
  MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS
} from "../../lib/Definition"
import { ErrorMessage } from "../common/ErrorMessage"
import { TextAreaField } from "../TextAreaField"
import { useMemo, useState } from "react"
import { ScrollContext } from "../../containers/ScrollToTop"
import { MonthlyQuestionnaireInfo } from "./MonthlyQuestionnaireInfo"
import { IMonthlyQuestionnaireParams } from "../../actions/monthly_questionnaire"
import { SatisfiedRateField } from "../questionnaireForm/SatisfiedRateField"
import { SatisfiedPointsField } from "../questionnaireForm/SatisfiedPointsField"

const OTHER_TEXT_LIMIT = 255

export interface IMonthlyQuestionnaireFormDispatchProps {
  submitMonthlyQuestionnaire: (params: IMonthlyQuestionnaireParams) => any
  alert: (content: string) => Promise<void>
}

export interface IMonthlyQuestionnaireFormStateProps {}

export interface IMonthlyQuestionnaireFormProps
  extends IMonthlyQuestionnaireFormDispatchProps,
    IMonthlyQuestionnaireFormStateProps {}

export const MonthlyQuestionnaireForm = ({ submitMonthlyQuestionnaire, alert }: IMonthlyQuestionnaireFormProps) => {
  const monthlyQuestionnaire = useSelector((state: IStoreState) => state.monthlyQuestionnaire)
  const viewState = useSelector((state: IStoreState) => state.viewState)
  const ctx = React.useContext(ScrollContext)
  const [state, setState] = useState({
    isCompleted: false,
    isSubmitted: false,
    satisfaction: 0,
    satisfactions: [] as MonthlyQuestionnaireSatisfactionType[],
    satisfaction_other: "",
    dissatisfactions: [] as MonthlyQuestionnaireSatisfactionType[],
    dissatisfaction_other: "",
    comment: ""
  })

  const isSatisfactionOtherChecked = useMemo(() => {
    return state.satisfactions.includes(
      MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.other as MonthlyQuestionnaireSatisfactionType
    )
  }, [state.satisfactions])

  const isDissatisfactionOtherChecked = useMemo(() => {
    return state.dissatisfactions.includes(
      MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.other as MonthlyQuestionnaireSatisfactionType
    )
  }, [state.dissatisfactions])

  const validates = useMemo(() => {
    const validates: { [key: string]: (value: any) => string | null } = {}
    validates["satisfaction"] = (val) => {
      return val.satisfaction ? null : "今月の満足度を選択してください。"
    }
    validates["satisfactions"] = (val) => {
      if (val.satisfactions.length === 0) return "満足した点を選択してください。"
      if (val.satisfaction_other && val.satisfaction_other.length > OTHER_TEXT_LIMIT) {
        return `その他の内容は${OTHER_TEXT_LIMIT}文字以内で入力してください。`
      }
      if (val.satisfaction_other) return null
      if (!val.satisfactions.includes(MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.other)) return null
      return "その他の内容を入力してください。"
    }
    validates["dissatisfactions"] = (val) => {
      if (val.dissatisfactions.length === 0) return "不満な点を選択してください。"
      if (val.dissatisfaction_other && val.dissatisfaction_other.length > OTHER_TEXT_LIMIT) {
        return `その他の内容は${OTHER_TEXT_LIMIT}文字以内で入力してください。`
      }
      if (val.dissatisfaction_other) return null
      if (!val.dissatisfactions.includes(MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.other)) return null
      return "その他の内容を入力してください。"
    }
    return validates
  }, [])

  const isRegistered = useMemo(() => !!monthlyQuestionnaire?.satisfactions, [monthlyQuestionnaire])

  if (!monthlyQuestionnaire) return null

  return (
    <div className="contentsWrapper-transparent">
      <h2 className="contentsTitle contentsTitlePartner alignItemsCenter">
        <div className={styleRec.titleWrap}>
          <span>
            {monthlyQuestionnaire.partner_name}様の月次フィードバック（{monthlyQuestionnaire.month_index}ヶ月目）
          </span>
        </div>
      </h2>

      {viewState.error && <ErrorMessage>{viewState.error}</ErrorMessage>}

      {isRegistered && (
        <div className="formWrap">
          <div className={styleRec.scoutInfoText + " alCent"}>
            <b>既にご回答頂いているアンケートです。</b>
          </div>
          <MonthlyQuestionnaireInfo
            satisfaction={monthlyQuestionnaire.satisfaction}
            satisfactions={monthlyQuestionnaire.satisfactions}
            satisfaction_other={monthlyQuestionnaire.satisfaction_other}
            dissatisfactions={monthlyQuestionnaire.dissatisfactions}
            dissatisfaction_other={monthlyQuestionnaire.dissatisfaction_other}
            comment={monthlyQuestionnaire.comment}
          />
        </div>
      )}

      {!isRegistered && (
        <main className="contentsWrapper">
          {!state.isCompleted && (
            <form
              className="pure-form"
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault()
                const error = Object.keys(validates).find((field) => {
                  return validates[field]({ ...state })
                })
                if (error) {
                  alert("入力に誤りがあります").then(() => {
                    setState({ ...state, isSubmitted: true })
                    ctx.scrollTo(error)
                  })
                  return
                }
                ctx.scrollTo("nowContract")
                setState({ ...state, isCompleted: true })
              }}
            >
              <div className="formWrap alLeft">
                <SatisfiedRateField
                  title="今月の満足度"
                  value={state.satisfaction}
                  isSubmitted={state.isSubmitted}
                  validates={validates}
                  rowCls={style.satisfactionRow}
                  dataCls={style.satisfactionData}
                  updateValue={(val) => {
                    setState({ ...state, satisfaction: val })
                  }}
                />

                <SatisfiedPointsField
                  title="満足した点"
                  name="satisfactions"
                  values={state.satisfactions}
                  other={state.satisfaction_other}
                  master={MONTHLY_QUESTIONNAIRE_SATISFACTION}
                  masterKeys={MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS}
                  altValues={state.dissatisfactions}
                  isSubmitted={state.isSubmitted}
                  validates={validates}
                  updateValue={(e) => {
                    let satisfactions: MonthlyQuestionnaireSatisfactionType[] = []
                    if (e.target.name !== MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.none) {
                      satisfactions = state.satisfactions.filter((s) => {
                        return s !== e.target.name && s !== MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.none
                      })
                    }
                    if (e.target.checked) satisfactions.push(e.target.name as MonthlyQuestionnaireSatisfactionType)
                    setState({ ...state, satisfactions })
                  }}
                  updateOther={(e) => {
                    setState({ ...state, satisfaction_other: e.target.value })
                  }}
                />

                <SatisfiedPointsField
                  title="不満な点"
                  name="dissatisfactions"
                  values={state.dissatisfactions}
                  other={state.dissatisfaction_other}
                  master={MONTHLY_QUESTIONNAIRE_DISSATISFACTION}
                  masterKeys={MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS}
                  altValues={state.satisfactions}
                  isSubmitted={state.isSubmitted}
                  validates={validates}
                  updateValue={(e) => {
                    let dissatisfactions: MonthlyQuestionnaireSatisfactionType[] = []
                    if (e.target.name !== MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.none) {
                      dissatisfactions = state.dissatisfactions.filter((s) => {
                        return s !== e.target.name && s !== MONTHLY_QUESTIONNAIRE_SATISFACTION_KEYS.none
                      })
                    }
                    if (e.target.checked) dissatisfactions.push(e.target.name as MonthlyQuestionnaireSatisfactionType)
                    setState({ ...state, dissatisfactions })
                  }}
                  updateOther={(e) => {
                    setState({ ...state, dissatisfaction_other: e.target.value })
                  }}
                />

                <TextAreaField
                  name="comment"
                  label={
                    <span>
                      JOINSへ
                      <br />
                      連絡したいこと
                    </span>
                  }
                  required={false}
                  values={{
                    ...state,
                    isSubmitted: state.isSubmitted
                  }}
                  updateValue={(value) => {
                    setState({ ...state, ...value })
                  }}
                />

                <div className="formBtnArea formBtnAreaSingle">
                  <button type="submit" className="btn btnSave">
                    送信内容を
                    <br className="PCHide" />
                    確認する
                  </button>
                </div>
              </div>
            </form>
          )}

          {state.isCompleted && (
            <form
              className="pure-form"
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault()
                submitMonthlyQuestionnaire({
                  entry_id: monthlyQuestionnaire.entry_id,
                  year: monthlyQuestionnaire.year,
                  month: monthlyQuestionnaire.month,
                  satisfaction: state.satisfaction,
                  satisfactions: state.satisfactions,
                  satisfaction_other: isSatisfactionOtherChecked ? state.satisfaction_other : "",
                  dissatisfactions: state.dissatisfactions,
                  dissatisfaction_other: isDissatisfactionOtherChecked ? state.dissatisfaction_other : "",
                  comment: state.comment
                })
              }}
            >
              <div className="formWrap">
                <div className={styleRec.scoutInfoText + " alCent"}>
                  以下の内容で送信いたします。よろしければ
                  <b className="joinsPinkTxt">送信するボタン</b>を押してください。
                </div>
                <MonthlyQuestionnaireInfo
                  satisfaction={state.satisfaction}
                  satisfactions={state.satisfactions}
                  satisfaction_other={state.satisfaction_other}
                  dissatisfactions={state.dissatisfactions}
                  dissatisfaction_other={state.dissatisfaction_other}
                  comment={state.comment}
                />
                <div className="formBtnArea">
                  <button className="btn btnEdit" onClick={() => setState({ ...state, isCompleted: false })}>
                    キャンセル
                  </button>
                  <button type="submit" className="btn btnSave">
                    送信する
                  </button>
                </div>
              </div>
            </form>
          )}
        </main>
      )}
    </div>
  )
}
