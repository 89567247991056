import * as React from "react"
import { Link } from "react-router-dom"
import style from "../style/Recruitment.module.scss"
import { dateStr, strToTag } from "../lib/Util"
import { IEntry } from "../types/state"
import { useEntryStatus } from "../hooks/useEntryStatus"
import { guidanceMeetingPath } from "../routes/paths"

export const ContractConditionsEntry = (props: { entry: IEntry; before_saved: boolean }) => {
  const entry = props.entry

  const { isSecondInterviewSkipped, isNotGuidanceMeetingDone } = useEntryStatus(entry)

  return (
    <>
      {isNotGuidanceMeetingDone && (
        <div className="mb40">
          <Link to={guidanceMeetingPath} className="joinsPinkTxt" target="_blank" rel="noopener noreferrer">
            <h3>ガイダンス会議はお済みですか？</h3>
          </Link>
          <p>契約締結に際して重要事項等の説明と、トラブル回避のためのポイントなどをJOINSの担当者がご案内いたします。</p>
        </div>
      )}
      <h3 className="contentsSubTitle">契約希望のご確認</h3>
      <p>
        {props.before_saved ? (
          <>
            {isSecondInterviewSkipped ? "一" : "二"}次面談の内容と下記のパートナー人材の契約希望条件を踏まえ、
            <b className="joinsOrangeTxt">契約希望有無を回答してください。</b>
          </>
        ) : (
          ""
        )}
      </p>
      <div className={style.listArea + " mb0"}>
        <div className={style.listRow}>
          <div className={style.listHead}>
            <div className="mb4">パートナー人材の契約者名義</div>
            <small className="mt10 grayTxt">※ 屋号など、個人名と異なる場合もあります。</small>
          </div>
          <div className={style.listData}>
            {entry.contract_partner_name || entry.partner.last_name + " " + entry.partner.first_name}
          </div>
        </div>

        <div className={style.listRow}>
          <div className={style.listHead}>{entry.condition_reward_type ? "報酬形態" : "時給単価"}</div>
          <div className={style.listData}>
            {entry.condition_reward_type
              ? entry.condition_reward_type
              : typeof entry.condition_reward === "number" && entry.condition_reward >= 0
              ? `${entry.condition_reward} 円`
              : "未入力"}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>最短契約開始可能日</div>
          <div className={style.listData}>
            {entry.condition_start_at ? dateStr(entry.condition_start_at) : "未入力"}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>月間最大業務可能な時間</div>
          <div className={style.listData}>
            {entry.condition_work_time ? `${entry.condition_work_time} 時間` : "未入力"}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>
            契約に対する条件や
            <br className="SPHide" />
            希望
          </div>
          <div className={style.listData}>{strToTag(entry.contract_reason)}</div>
        </div>
        {!isSecondInterviewSkipped && (
          <div className={style.listRow}>
            <div className={style.listHead}>二次面談日</div>
            <div className={style.listData}>{dateStr(entry.second_interview_date)}</div>
          </div>
        )}
        <div className={style.listRow + " " + style.listRowNoBorder}>
          <div className={style.listHead}>
            最初の3カ月で取組む
            <br className="SPHide" />
            具体的な業務の内容
          </div>
          <div className={style.listData}>{strToTag(entry.exec_plan)}</div>
        </div>
      </div>
    </>
  )
}
