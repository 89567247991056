import * as React from "react"
import { Link } from "react-router-dom"
import style from "../style/SideBar.module.scss"
import { noticePath, registrantPath, homePath, scoutPath } from "../routes/paths"

interface Props {
  noticeCount: number
  noCompletedCount: number
  isSideBarOpen: boolean
  sideBarClose: () => void
  logout: () => void
}

const SideBar: React.FC<Props> = ({ noticeCount, noCompletedCount, isSideBarOpen, sideBarClose, logout }) => {
  return (
    <>
      {isSideBarOpen && (
        <div className={style.sideBarOpenBackground}>
          <div className={style.closeBtn} onClick={sideBarClose} />
        </div>
      )}
      <div className={style.sideBarWrapper + " " + (isSideBarOpen ? style.sideBarWrapperOpen : "")}>
        <div className={style.sideBarListWrapper}>
          <div className={style.sideBarList}>
            <span className={style.peopleIcon} />
            <Link className={style.sideBarLink} to={homePath} onClick={sideBarClose}>
              人材管理
            </Link>
          </div>
          <div className={style.sideBarList}>
            <span className={style.scoutIcon} />
            <Link className={style.sideBarLink} to={`${scoutPath}`} onClick={sideBarClose}>
              スカウト
            </Link>
          </div>
          <div className={style.sideBarList}>
            <span className={style.noticeIcon} />
            <Link className={style.sideBarLink} to={noticePath} onClick={sideBarClose}>
              お知らせ
              {noticeCount > 0 && <div className={style.badge}>{noticeCount}</div>}
            </Link>
          </div>
          <div className={style.sideBarList}>
            <span className={style.companyIcon} />
            <Link className={style.sideBarLink} to={registrantPath} onClick={sideBarClose}>
              マイページ
              {noCompletedCount > 0 && <div className={style.badge}></div>}
            </Link>
          </div>

          <div className={style.divider} />

          <div className={style.sideBarList}>
            <a
              href={process.env.REACT_APP_INQUIRY_URL}
              rel="noopener noreferrer"
              target="_blank"
              className={style.sideBarLink}
              onClick={sideBarClose}
            >
              お問い合わせ
            </a>
          </div>

          <div className={style.sideBarList} onClick={sideBarClose}>
            <span className={style.sideBarLink} onClick={logout}>
              ログアウト
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default SideBar
