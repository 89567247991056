import * as React from "react"
import { Link } from "react-router-dom"
import { homePath } from "../routes/paths"
import { GuidanceMeetingLink } from "./buttons/GuidanceMeetingLink"

export const RegisterThanks = () => {
  return (
    <main className="is-outside">
      <div className="container">
        <h1>ご入力ありがとうございました。</h1>
        <div className="finishText alCent">
          当社に登録する人材への募集を開始する前に、
          <br />
          JOINS担当者と募集内容について調整する面談を
          <br className="SPHide" />
          実施させて頂いております。
          <br />
          <br />
          <b>
            まだ<u>面談日時を決定されていない場合</u>は、
            <br className="SPHide" />
            下記のボタンより面談のご希望日時をお申し込み下さい。
          </b>
          <br />
          <br />
          <b className="joinsGreenTxt">
            ※ すでに面談をお申し込みいただいている場合は
            <br className="SPHide" />
            再度お申し込みの必要はございません。
          </b>
          <br />
          <Link to={homePath} className="link">こちら</Link>より人材管理画面にお戻りください。
        </div>
        <div className="mt10 formBtnArea formBtnAreaSingle mb40mb32">
          <GuidanceMeetingLink button>
            JOINS担当者との面談を申し込む
          </GuidanceMeetingLink>
        </div>
      </div>
    </main>
  )
}
