import * as React from "react";
import style from "../../style/InterviewInfo.module.scss";
import { roomsPath } from "../../routes/paths";
import { Link } from "react-router-dom";
import { UseJoinsVideoChat } from "./UseJoinsVideoChat"

interface IVideoTestOtherDispatchProps {}

interface IVideoTestOtherStateProps {
  videoMeetingUrl: string;
}

interface IVideoTestOtherProps extends IVideoTestOtherDispatchProps, IVideoTestOtherStateProps {}

export const VideoTestOther = ({ videoMeetingUrl }: IVideoTestOtherProps) => {
  return (
    <tr>
      <th>
        Web面談の
        <br className="PCHide" />
        方法
      </th>
      <td>
        <div className="mb10">
          <b className="joinsPinkTxt">
            <u>
              他社Web会議システムにて、会議室のURLなどをご準備ください。
            </u>
          </b>
          <br />
          <b>
            準備が整いましたら、面談日時前までに
            <Link to={roomsPath} className="link">メッセージ機能</Link>
            から応募者にご連絡ください。
          </b>
        </div>
        <dl className={style.meetingExample}>
          <dt>他社Web会議システム例</dt>
          <dd>
            <a
              href="https://zoom.us/meeting#/upcoming"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Zoom
            </a>{" "}
            ／{" "}
            <a
              href="https://apps.google.com/intl/ja/meet/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Google Meet
            </a>{" "}
            ／{" "}
            <a
              href="https://www.skype.com/ja/"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Skype
            </a>
            　など
          </dd>
        </dl>
        <UseJoinsVideoChat videoMeetingUrl={videoMeetingUrl} />
      </td>
    </tr>
  )
}
