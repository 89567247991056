import React, { ChangeEvent } from 'react'
import style from "../style/CheckBoxes.module.scss"
import { ErrorMessage } from "./common/ErrorMessage"
import clsx from "clsx"

interface ICheckBoxesProps {
  name: string;
  values: string[];
  master: { [key: string]: string };
  disabled?: string[];
  rootCls?: string;
  itemCls?: string;
  isSubmitted?: boolean;
  validates?: { [key: string]: (value: any) => string | null };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CheckBoxes = ({
  name,
  values,
  master,
  disabled,
  rootCls,
  itemCls,
  isSubmitted,
  validates,
  onChange
}: ICheckBoxesProps) => {
  return (
    <div id={name} className={clsx(style.wrapper, rootCls)}>
      {
        Object.keys(master).map(key => {
          const id = `checkbox-${name}-${key}`
          const isDisabled = disabled?.includes(key)
          return (
            <div key={id} className={clsx(style.item, itemCls)}>
              <input
                type="checkbox"
                id={id}
                name={key}
                className={style.checkbox}
                checked={values.includes(key)}
                disabled={isDisabled}
                onChange={onChange}
              />
              <label className={clsx(style.label, isDisabled && style.disbledLabel)} htmlFor={id}>
                {master[key]}
              </label>
            </div>
          )
        })
      }
      {isSubmitted && validates && validates[name] && (
        <ErrorMessage cls="mb08">
          {validates[name]({ [name]: values })}
        </ErrorMessage>
      )}
    </div>
  )
}
