import * as React from "react"
import style from "../../style/EntryCancelFinish/EntryCancelFinishInfo.module.scss"
import styleRec from "../../style/Recruitment.module.scss"
import { IEntry } from "../../types/state"
import { dateStr } from "../../lib/Util"
import { homePath } from "../../routes/paths"
import { Link } from "react-router-dom"
import clsx from "clsx"
import { PartnerManagementHeader } from "../headers/PartnerManagementHeader"

export interface IEntryCancelFinishInfoDispatchProps {}

export interface IEntryCancelFinishInfoStateProps {
  entry: IEntry;
}

export interface IEntryCancelFinishInfoProps extends IEntryCancelFinishInfoDispatchProps, IEntryCancelFinishInfoStateProps {}

export const EntryCancelFinishInfo = ({ entry }: IEntryCancelFinishInfoProps) => {
  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 className="contentsTitle contentsTitlePartner alignItemsCenter">
        <div className={styleRec.titleWrap}>
          <span>応募ID：{entry.id} </span>
        </div>
      </h2>

      <div className={clsx(styleRec.entryFinishMessageWrapper, style.contentWrapper)}>
        <h3 className="contentsTitle contentsSubTitlePlane">
          契約終了
        </h3>
        <p>
          <b>
            本契約は {dateStr(entry.contract_cancel_at)} をもちまして<br className="PCHide" />終了となります。<br />
            JOINSより {entry.partner.last_name} {entry.partner.first_name}様 に<br className="PCHide" />ご連絡いたします。
          </b>
        </p>
        <div className={clsx("formBtnArea formBtnAreaSingle", styleRec.entryFinishMessageButtonArea)}>
          <Link to={homePath} className={clsx("btn btnLogin")}>
            人材管理画面に戻る
          </Link>
        </div>
      </div>
    </div>
  )
}
