import * as React from "react"
import { useEffect } from "react"
import * as _ from "lodash"
import { Grid, FormControl, TextField } from "@material-ui/core"
import { InputError } from "../common/InputError"
import { ISecondInterview } from "../../../types/state"
import { IndexedLabel } from "../common/IndexedLabel"

export type OnlineMeetingFieldsType = Pick<ISecondInterview, "schedule_comment">

export interface IOnlineMeetingFieldsProps {
  confirming: boolean
  onChange?: (values: OnlineMeetingFieldsType) => any
  isValid?: (result: boolean) => any
}

export const OnlineMeetingFields = (props: IOnlineMeetingFieldsProps) => {
  const [state, setState] = React.useState<OnlineMeetingFieldsType>({
    schedule_comment: ""
  })

  const [errors, setErrors] = React.useState({
    schedule_comment: false
  })

  useEffect(() => {
    props.onChange && props.onChange(state)
    setErrors({ schedule_comment: _.isEmpty(state.schedule_comment) })
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    let errorCount = 0
    _.forEach(errors, (hasError) => {
      hasError && errorCount++
    })
    props.isValid && props.isValid(errorCount === 0)
    // eslint-disable-next-line
  }, [errors])

  return (
    <Grid container direction="column" alignItems="stretch" style={{ paddingBottom: 20 }}>
      <IndexedLabel index={2} required={true}>
        面談の日時候補をご記入ください。
      </IndexedLabel>
      <FormControl fullWidth>
        <TextField
          id="date"
          className="pure-form"
          variant="outlined"
          color="secondary"
          multiline
          fullWidth
          minRows={4}
          placeholder={`記入例:\n2月22日以降の水曜か木曜が望ましい。`}
          disabled={props.confirming && !errors.schedule_comment}
          error={props.confirming && errors.schedule_comment}
          onChange={(e) => {
            const value = e.target.value
            setState({ schedule_comment: value })
          }}
        />
        <InputError show={props.confirming && errors.schedule_comment} message="面談の日時候補が未入力です。" />
      </FormControl>
    </Grid>
  )
}
