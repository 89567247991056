import * as React from "react"
import { IProblemFormState } from "./index"
import { ErrorMessage } from "../common/ErrorMessage"

export const TitleForm = (props: {
  problem: IProblemFormState
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
  updateValue: (args: { [key: string]: any }) => void
}) => {
  return (
    <div id="title">
      <label className="pure-u-1 problemLabel">募集タイトル（30文字以内）</label>
      <input
        className="formWidth_m mb14"
        value={props.problem.title}
        onChange={(e: React.ChangeEvent<any>) => {
          props.updateValue({
            ...props.problem,
            title: e.target.value
          })
        }}
      />
      {props.isSubmitted && props.validates["title"] && (
        <ErrorMessage cls="mb20">{props.validates["title"](props.problem)}</ErrorMessage>
      )}
    </div>
  )
}
