import { IFile } from "../types/state";

const resources = [
  "auth",
  "company",
  "viewState",
  "temporaryCompany",
  "partners"
];
const saveState = (store: any) => (next: any) => (action: any) => {
  const beforeState = store.getState();
  const beforeResource = {};
  resources.forEach(
    resource => (beforeResource[resource] = beforeState[resource])
  );

  const ret = next(action);

  const currentState = store.getState();
  let isChange = false;
  Object.keys(beforeResource).forEach(resource => {
    if (currentState[resource] !== beforeResource[resource]) {
      isChange = true;
    }
  });
  if (isChange) {
    const args = {};
    resources.forEach(res => {
      if (res === "viewState") {
        if (currentState[res]["files"]) {
          args[res] = {
            ...currentState[res],
            loaderVisible: false,
            eye_cache:
              currentState[res]["eye_catch"] &&
              !currentState[res]["eye_catch"]["blob"]
                ? currentState[res]["eye_catch"]
                : null,
            files: currentState[res]["files"].filter((r: IFile) => !r.blob)
          };
        } else {
          args[res] = { ...currentState[res], loaderVisible: false };
        }
      } else {
        args[res] = currentState[res];
      }
    });
    sessionStorage.setItem("joins_company_state", JSON.stringify(args));
  }
  return ret;
};
export default saveState;
