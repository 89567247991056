import { TypeKeys, Actions } from "../actions";
import { INotice } from "../types/state";
import DateWithOffset from "../lib/DateWithOffset";

const initialState: INotice[] = [];

const notices = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.FETCH_NOTICES_SUCCESS: {
      return action.payload.data.notices.map((notice: INotice) => {
        return {
          ...notice,
          created_at: new DateWithOffset(notice.created_at),
        };
      });
    }
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.notices.map((notice: INotice) => {
        return {
          ...notice,
          created_at: new DateWithOffset(notice.created_at),
        };
      });
    }
    case TypeKeys.CHECK_NOTICES_SUCCESS: {
      return state.map((notice: INotice) => {
        return { ...notice, checked: true };
      });
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default notices;
