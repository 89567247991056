import { apiURL } from "."
import { ActionsUnion, createAction } from "./types";
import { IMonthlyQuestionnaire, MonthlyQuestionnaireSatisfactionType } from "../types/state"

export enum MonthlyQuestionnaireTypeKeys {
  FETCH_MONTHLY_QUESTIONNAIRE = "FETCH_MONTHLY_QUESTIONNAIRE",
  FETCH_MONTHLY_QUESTIONNAIRE_SUCCESS = "FETCH_MONTHLY_QUESTIONNAIRE_SUCCESS",
  FETCH_MONTHLY_QUESTIONNAIRE_FAIL = "FETCH_MONTHLY_QUESTIONNAIRE_FAIL",
  CREATE_MONTHLY_QUESTIONNAIRE = "CREATE_MONTHLY_QUESTIONNAIRE",
  CREATE_MONTHLY_QUESTIONNAIRE_SUCCESS = "CREATE_MONTHLY_QUESTIONNAIRE_SUCCESS",
  CREATE_MONTHLY_QUESTIONNAIRE_FAIL = "CREATE_MONTHLY_QUESTIONNAIRE_FAIL"
}

const fetchMonthlyQuestionnaire = (entryId: number, year: number, month: number) =>
  createAction(MonthlyQuestionnaireTypeKeys.FETCH_MONTHLY_QUESTIONNAIRE, {
    request: {
      data: { year, month },
      method: "get",
      url: `${apiURL}/monthly_questionnaires/${entryId}`,
    },
  });

export interface IMonthlyQuestionnaireParams {
  entry_id: number;
  year: number;
  month: number;
  satisfaction: number;
  satisfactions: MonthlyQuestionnaireSatisfactionType[];
  satisfaction_other: string;
  dissatisfactions: MonthlyQuestionnaireSatisfactionType[];
  dissatisfaction_other: string
  comment: string
}

const createMonthlyQuestionnaire = (data: IMonthlyQuestionnaireParams) => {
  return createAction(MonthlyQuestionnaireTypeKeys.CREATE_MONTHLY_QUESTIONNAIRE, {
    request: {
      data,
      method: "post",
      url: `${apiURL}/monthly_questionnaires`,
    },
  })
}

type fetchMonthlyQuestionnaireSuccess = {
  type: MonthlyQuestionnaireTypeKeys.FETCH_MONTHLY_QUESTIONNAIRE_SUCCESS;
  payload: {
    data: IMonthlyQuestionnaire
  };
};

type createMonthlyQuestionnaireSuccess = {
  type: MonthlyQuestionnaireTypeKeys.CREATE_MONTHLY_QUESTIONNAIRE_SUCCESS;
  payload: {
    data: {}
  };
};

type AutoGenerateActions =
  | fetchMonthlyQuestionnaireSuccess
  | createMonthlyQuestionnaireSuccess

export const MonthlyQuestionnaireActions = {
  fetchMonthlyQuestionnaire,
  createMonthlyQuestionnaire
}

export type MonthlyQuestionnaireActions =
  | ActionsUnion<typeof MonthlyQuestionnaireActions>
  | AutoGenerateActions;
