import * as React from "react"
import { IEntry, IRecruitment } from "../types/state"
import { PartnerProfileInfo } from "./PartnerProfileInfo"
import { PartnerEntryInfo } from "./PartnerEntryInfo"
import { TextAreaField } from "./TextAreaField"
import style from "../style/Recruitment.module.scss"
import { PartnerManagementHeader } from "./headers/PartnerManagementHeader"

export interface IEntryAcceptDispatchProps {
  acceptEntry: (entry: IEntry) => void
}

export interface IEntryAcceptStateProps {
  entry: IEntry
  recruitment: IRecruitment
}

export interface IEntryAcceptProps extends IEntryAcceptDispatchProps, IEntryAcceptStateProps {}

const validates: {
  [key: string]: (val: { reschedule_comment: string }) => string | null
} = {}
validates["reschedule_comment"] = (val) => {
  return val.reschedule_comment ? null : "Web面談希望日時を入力してください。"
}
const EntryAcceptForm = (props: IEntryAcceptProps) => {
  const partner = props.entry.partner
  const [state, setState] = React.useState({
    isSubmitted: false,
    reschedule_comment: props.entry.reschedule_comment
  })
  if (!partner) {
    return null
  }
  return (
    <div className="contentsWrapper-transparent">
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault()
          const error = Object.keys(validates).filter((field) => {
            return validates[field](state)
          })[0]
          if (error) {
            setState({ ...state, isSubmitted: true })
            return
          }
          props.acceptEntry({
            ...props.entry,
            reschedule_comment: state.reschedule_comment
          })
        }}
      >
        <PartnerManagementHeader />
        <h2 className="contentsTitle contentsTitlePartner">【応募ID：{props.entry.id}】 との面談希望確認</h2>

        <div className={style.scoutInfo}>
          <div className={style.scoutInfoText}>
            <b className="joinsPinkTxt">パートナー人材との一次面談希望を確定してください。</b>
            <br />
            Web面談の実施にあたり、貴社スケジュール内でWeb面談実施可能な日時の候補をいくつかご記入ください。
            <br />
            貴社スケジュールに合わせてパートナー人材より具体的な面談日時の候補をいくつかご提出いただきます。
          </div>
          <div className="pure-form">
            <TextAreaField
              name="reschedule_comment"
              label={<>Web面談希望日時</>}
              type="scout"
              required={true}
              values={state}
              cls={"textarea mb10mb20"}
              updateValue={(val) =>
                setState({
                  ...state,
                  reschedule_comment: val["reschedule_comment"]
                })
              }
              validates={validates}
            />
          </div>
          <div className={style.replySample}>
            <p className={style.replySample_title}>日時候補の記入例：</p>
            <p className={style.replySample_body}>
              水曜以外の平日15時〜20時が比較的空いております。土日も日によっては可能です。
              <br />
              来週以降〜月内で、5日時以上の候補のご提示をお願いいたします。
            </p>
          </div>
          <div className="formBtnArea">
            <button
              className="btn btnEdit"
              onClick={(e) => {
                e.preventDefault()
                window.history.back()
              }}
            >
              戻る
            </button>
            <button className="btn btnSave" type="submit">
              一次面談（Web会議）希望を確定する
            </button>
          </div>
        </div>

        <PartnerProfileInfo
          {...partner}
          noNameMessage="貴社がWeb面談を希望した場合に開示されます。"
          isCareerSheetDisclosed={true}
          isPRDisclosed={true}
        />
        <PartnerEntryInfo entry={props.entry} recruitment={props.recruitment} />
      </form>
    </div>
  )
}

export default EntryAcceptForm
