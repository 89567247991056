import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Actions } from "../actions";
import {
  EditStaffsForm,
  IEditStaffsDispatchProps,
  IEditStaffsStateProps,
  IStaffState
} from "../components/EditStaffs/EditStaffsForm";
import { updateStaffs, showAlert, showConfirm } from "../thunks";
import { IStoreState, IStaff, IProblem, IAjaxError } from "../types/state";
import { getProblemCategoryDetailNames } from "../lib/Util"

function mapStateToProps(state: IStoreState): IEditStaffsStateProps {
  let staffs: IStaffState[];
  const problemMap: { [key: number]: IProblem & { flag: number; message: string }; } = {};
  state.recruitment.problems.forEach((problem, idx) => {
    problemMap[problem.id as number] = {
      ...problem,
      flag: Math.pow(2, idx),
      message: `${getProblemCategoryDetailNames(state.problemCategoryDetails, problem)} (案件ID: ${problem.id})`
    };
  });
  if (state.staffs.length > 0) {
    staffs = state.staffs.map(staff => {
      return {
        ...staff,
        problems: staff.problem_ids
          ? staff.problem_ids.reduce((accum, current) => accum + problemMap[current].flag, 0)
          : 0
      };
    });
  } else {
    staffs = [
      {
        id: 0,
        name: "",
        deleted: false,
        email: "",
        role: "admin",
        problems: 0
      }
    ];
  }
  return {
    staffs,
    registrantMail: state.registrant.email,
    problemMap,
    enableCancel: !!(state.staffs.length > 0),
    error: state.viewState.error,
    isSubmitted: state.viewState.isSubmitted
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): IEditStaffsDispatchProps {
  return {
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any;
    },
    confirm: (content: string) => {
      return dispatch(showConfirm(content) as any) as any;
    },
    updateValue: (state: { [key: string]: any }) => {
      return dispatch(Actions.setViewState({ ...state, isEdit: true }) as any);
    },
    updateStaffs: (staffs: IStaff[]) => {
      dispatch(updateStaffs(staffs) as any).catch((e: IAjaxError) => {
        dispatch(Actions.setViewState({
          error: e.payload.error.message,
          scrollID: "root"
        }) as any);
      });
    },
    setIsEdit: (isEdit: boolean) => {
      return dispatch(Actions.setViewState({ isEdit: isEdit }) as any);
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStaffsForm);
