import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { IStoreState } from "../types/state";
import StaffList from "../components/StaffList";

function mapStateToProps(state: IStoreState) {
  return {
    staffs: state.staffs.map(staff => {
      return {
        ...staff,
        problems: state.recruitment.problems.filter(
          problem =>
            staff.problem_ids &&
            staff.problem_ids.indexOf(problem.id as number) !== -1
        )
      };
    })
  };
}

const mapDispatchToProps = (_dispatch: Dispatch<Action>) => {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffList);
