import * as React from "react"
import {
  IMessage,
  IEntry,
  IProblem,
  IPartner,
  IPrefecture,
  IProblemCategoryDetail,
  IRecruitment,
  IProblemCategory,
  ICareer,
  ILicense
} from "../types/state"
import * as Definition from "../lib/Definition"
import B64 from "./bitwise64"
import DateWithOffset from "../lib/DateWithOffset"
import {
  PREFECTURE_OVERSEAS,
  PROBLEM_CHECKED_BO_STATUS_EN,
  PROBLEM_STATUS_EN,
  RECRUITMENT_STATUS_EN,
  TERM
} from "../lib/Definition"
import { IBitFlagDefinition } from "../components/SelectBitFlagField"

const Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
const ZenHanMap = {
  "０": "0",
  "１": "1",
  "２": "2",
  "３": "3",
  "４": "4",
  "５": "5",
  "６": "6",
  "７": "7",
  "８": "8",
  "９": "9",
  "－": "-",
  "−": "-",
  "–": "-",
  "➖": "-",
  ー: "-"
}

export function delimitize(num: number) {
  return num >= 1 ? String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : String(num)
}
export function convertMillions(num: number) {
  return num / 1000000 // 表示時は単位を百万円にする
}
export function zeroPadding(num: number, padding = 2) {
  const paddingStr = Array.from(Array(padding), () => 0).join("")
  return (paddingStr + num.toString()).slice(-1 * padding)
}
export function convertZenHan(str: string) {
  let result = ""
  for (let i = 0; i < str.length; i++) {
    if (ZenHanMap[str[i]]) {
      result += ZenHanMap[str[i]]
    } else {
      result += str[i]
    }
  }
  return result
}

export function formatHyphenStr(str: string) {
  return convertZenHan(str).replace(/-/g, "")
}

export function parseZenNum(str: string) {
  return parseInt(convertZenHan(str), 10)
}

export function randomStr(n: number) {
  let s = ""
  while (n > 0) {
    s += Chars[Math.floor(Math.random() * Chars.length)]
    n -= 1
  }
  return s
}
export function uniqueStr() {
  const now = new DateWithOffset()
  return now.getFullYear() + zeroPadding(now.getMonth() + 1) + zeroPadding(now.getDate()) + randomStr(6)
}

export function keyByValue<T>(hash: { [key: string]: T }, value: T) {
  return Object.keys(hash).find((key) => hash[key] === value)
}

export function strToTag(child: any) {
  if (typeof child !== "string") {
    return
  }
  const ret: any = []
  let str = ""
  child.split("").forEach((n: string, at: number) => {
    if (n === " ") {
      if (str !== "") {
        ret.push(<span key={`s_${at}`}>{str}</span>)
        str = ""
      }
      ret.push(<span key={`b_${at}`}>&nbsp;</span>)
    } else if (n === "\n") {
      if (str !== "") {
        ret.push(
          <span key={`s_${at}`}>
            {str}
            <br />
          </span>
        )
        str = ""
      } else {
        ret.push(
          <span key={`b_${at}`}>
            <br />
          </span>
        )
      }
    } else {
      str += n
    }
  })
  if (str !== "") {
    ret.push(<span key={`s_last`}>{str}</span>)
  }
  return ret
}

export function timeStr(date: Date | undefined | null) {
  if (!date) return ""
  return zeroPadding(date.getHours()) + ":" + zeroPadding(date.getMinutes())
}

export function addDate(date: Date | undefined | null, num: number) {
  if (!date) return date
  return new DateWithOffset(
    new DateWithOffset(date.getFullYear(), date.getMonth(), date.getDate()).getTime() + 1000 * 60 * 60 * 24 * num
  )
}

export function remainDate(date: Date, num: number) {
  if (!date) return null
  const target: Date = addDate(date, num) as Date
  return (
    "残り" + Math.ceil((target.getTime() - new DateWithOffset().getTime()) / (1000 * 60 * 60 * 24)).toString() + "日"
  )
}

export function age(birthday: Date) {
  const today = new DateWithOffset()
  let bias = 0
  if (
    zeroPadding(today.getMonth()) + zeroPadding(today.getDate()) <
    zeroPadding(birthday.getMonth()) + zeroPadding(birthday.getDate())
  ) {
    bias = -1
  }
  return today.getFullYear() - birthday.getFullYear() + bias
}

export function convertUidToUuid(uid: string) {
  return (
    uid.slice(0, 8) +
    "-0000-4000-8000-" +
    uid
      .slice(8)
      .split("")
      .map((c) => c.charCodeAt(0).toString(16))
      .join("")
  )
}

export function yearMonthStr(yyyymm: string | null) {
  if (!yyyymm) return ""
  const matched = yyyymm.match(/.{1,4}/g)
  if (!matched) return ""
  const [year, month] = matched.map((e) => Number(e))
  return `${year}年${month}月`
}

export function dateStr(date: Date | undefined | null, withoutYear?: boolean) {
  if (!date || isInvalidDate(date)) return ""
  return (
    (withoutYear ? "" : date.getFullYear().toString() + "年") + (date.getMonth() + 1) + "月" + date.getDate() + "日"
  )
}

export function monthTimeStr(date: Date | undefined | null) {
  if (!date) return ""
  return date.getMonth() + 1 + "月" + date.getDate() + "日 " + timeStr(date)
}

export function dToS(date: Date | undefined | null) {
  if (!date) return ""
  return date.getFullYear().toString() + "-" + zeroPadding(date.getMonth() + 1) + "-" + zeroPadding(date.getDate())
}

export function formatTimeStr(date: Date) {
  return dToS(date) + "T" + timeStr(date) + "+09:00"
}

export function dayStr(date: Date | undefined | null) {
  if (!date) return ""
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()]
}

export function dateTimeStr(date: Date | undefined | null, withoutYear?: boolean) {
  if (!date) return ""
  return dateStr(date, withoutYear) + "(" + dayStr(date) + ") " + timeStr(date)
}

export function simpleDateTimeStr(date: Date) {
  return dateStr(date, true) + timeStr(date)
}

export function buildDate(date: Date | string | null) {
  if (!date) return null
  if (typeof date === "string") {
    return new Date(date.replace(" +0900", "+09:00").replace(" ", "T"))
  } else {
    return new Date(date)
  }
}

export function industryStr(industries: number, industry_other: string) {
  const results: string[] = []
  Object.keys(Definition.INDUSTRIES).forEach((industry) => {
    if (Definition.INDUSTRIES[industry]["message"] === "その他") {
      return
    }
    if (B64.and(industries, Definition.INDUSTRIES[industry]["flag"]) !== 0) {
      results.push(Definition.INDUSTRIES[industry]["message"])
    }
  })
  if (industry_other) {
    results.push(industry_other)
  }
  return results.join("・")
}

export function lastMessage(message: IMessage) {
  if (message.content_type === "text") {
    return summarize(message.company_id ? "あなた:" + message.content : message.content)
  } else if (message.content_type === "removed") {
    return message.company_id ? "あなた:メッセージが削除されました" : "メッセージが削除されました"
  } else if (message.content_type === "image") {
    if (message.company_id) {
      return "画像ファイルが送信されました"
    } else {
      return "画像ファイルを受信しました"
    }
  } else {
    if (message.company_id) {
      return "ファイルが送信されました"
    } else {
      return "ファイルを受信しました"
    }
  }
}

export function summarize(str: string, length?: number) {
  const len = length ? length : 15
  return str.length > len ? str.substr(0, len) + "…" : str
}

export function departmentStr(departments: number, department_other: string) {
  const results: string[] = []
  Object.keys(Definition.DEPARTMENTS).forEach((department) => {
    if (Definition.DEPARTMENTS[department]["message"] === "その他") {
      return
    }
    if (B64.and(departments, Definition.DEPARTMENTS[department]["flag"]) !== 0) {
      results.push(Definition.DEPARTMENTS[department]["message"])
    }
  })
  if (department_other) {
    results.push(department_other)
  }
  return results.join(", ")
}

export function problemCategoryDetailsStr(
  slugs: string[],
  otherText: string,
  problemCategoryDetails: IProblemCategoryDetail[]
) {
  const results: string[] = []
  slugs.forEach((slug) => {
    const categoryDetail = problemCategoryDetails.find((pcd) => pcd.slug === slug)
    if (categoryDetail) {
      const addText = categoryDetail.other_flag ? `（${otherText}）` : ""
      results.push(`${categoryDetail.name}${addText}`)
    }
  })
  return results.join(", ")
}

export function employedTermStr(career: ICareer) {
  if (career.employed_start_at) {
    let result = `${yearMonthStr(career.employed_start_at)} 〜 `
    if (career.employed_end_at) result += yearMonthStr(career.employed_end_at)
    if (career.employed) result += "在籍中"
    return result
  }
  if (career.term) {
    return `${TERM[career.term]}${career.employed ? " 在籍中" : ""}`
  }
  return ""
}

export function startDateStr(date: Date | null) {
  const parsedDate = buildDate(date)
  if (!parsedDate) return ""
  return `${parsedDate.getFullYear()}年${parsedDate.getMonth() + 1}月${parsedDate.getDate()}日`
}

export function licensesStr(partnerLicenses: string[], licenses: ILicense[]) {
  if (!partnerLicenses || partnerLicenses.length === 0) return ""
  const results: string[] = []
  partnerLicenses.forEach((license) => {
    const licenseMst = licenses.find((l) => l.slug === license)
    if (licenseMst) {
      results.push(licenseMst.name)
    }
  })
  return results.join(", ")
}

export function definitionStr(definitions: IBitFlagDefinition, value: number, separator?: string) {
  if (!separator) {
    separator = ","
  }
  const results: string[] = []
  Object.keys(definitions).forEach((key) => {
    if (B64.and(value, definitions[key]["flag"]) !== 0) {
      results.push(definitions[key]["message"])
    }
  })
  return results.join(separator)
}

export function parseQueryParams() {
  const result = {}
  if (!window.location.href.match(/\?/)) {
    return result
  }
  const query = window.location.href.replace(/.*\?/, "").split("&")
  query.forEach((r) => {
    const pair = r.split("=")
    const val = decodeURIComponent(pair[1])
    if (pair[0].match(/\[\]$/)) {
      const key = pair[0].replace("[]", "")
      if (!result[key]) {
        result[key] = []
      }
      if (val !== "") {
        result[key].push(val)
      }
    } else {
      result[pair[0]] = val
    }
  })
  return result
}
export function entryStatus(entry: IEntry) {
  let result: string | React.ReactNode = ""
  if (entry.declined_by) {
    result = entry.is_expired ? "期限切れ" : "不成立"
  } else if (entry.entry_status === "first_interview_requested") {
    result = "貴社回答待ち"
  } else if (entry.entry_status === "first_interview_date_accepted") {
    if (
      entry.meeting_at &&
      entry.meeting_at.getTime() + Definition.MEETING_TIME_WITH_ADDITIONAL < new DateWithOffset().getTime()
    ) {
      result = "一次面談終了"
    } else {
      result = "一次面談日時確定"
    }
  } else if (entry.entry_status === "second_interview_date_accepted") {
    if (
      entry.second_interview?.meeting_at &&
      entry.second_interview?.meeting_at.getTime() + Definition.MEETING_TIME_WITH_ADDITIONAL <
        new DateWithOffset().getTime()
    ) {
      result = "二次面談終了"
    } else {
      result = "二次面談日時確定"
    }
  } else if (
    (entry.condition_reward_type &&
      entry.cloudsign_status === "manual_concluded" &&
      entry.entry_status === "contract_accepted") ||
    (!entry.condition_reward_type &&
      (entry.cloudsign_status === "concluded" ||
        entry.cloudsign_status === "manual_concluded" ||
        entry.cloudsign_status === "") &&
      entry.entry_status === "contract_accepted")
  ) {
    result = Definition.CLOUDSIGN_STATUS["concluded"]
  } else {
    result = Definition.ENTRY_STATUS_JP[entry.entry_status]
  }
  return result
}

export function isInvalidDate(date: Date | null) {
  if (!date) return true
  return isNaN(date.getTime())
}

export function toTimeStr(u: number) {
  const time = new Date(u)
  return `${zeroPadding(time.getHours())}:${zeroPadding(time.getMinutes())}`
}

export function getToday() {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

export function monthDateStr(date: Date | undefined | null) {
  if (!date) return ""
  return (date.getMonth() + 1).toString() + "/" + date.getDate()
}

export function nextAction(entry: IEntry) {
  const actions: string[] = []
  if (!entry) return actions

  if (entry.declined_by) {
    if (entry.second_interview_reject_reason) {
      actions.push("回答結果確認")
    } else if (entry.contract_reject_reason) {
      actions.push("二次面談回答結果確認")
    }
    return actions
  }

  switch (entry.entry_status) {
    case "first_interview_requested":
      actions.push("面談要否決定")
      break
    case "first_interview_accepted":
      actions.push("候補日時回答待ち")
      break
    case "first_interview_date_requested":
      actions.push("日時指定")
      break
    case "first_interview_date_accepted":
      if (
        entry.meeting_at &&
        entry.meeting_at.getTime() + Definition.MEETING_TIME_WITH_ADDITIONAL < new DateWithOffset().getTime()
      ) {
        actions.push("一次面談結果返信待ち")
      } else {
        actions.push("Web面談アクセス")
      }
      break
    case "first_interview_end":
      actions.push("一次面談結果返信待ち")
      break
    case "second_interview_requested":
      actions.push("二次面談希望有無選択")
      break
    case "second_interview_accepted":
      actions.push("二次面談候補日時回答待ち")
      break
    case "second_interview_date_requested":
      actions.push("二次面談日時選択")
      break
    case "second_interview_date_accepted":
      if (
        entry.second_interview?.meeting_at &&
        entry.second_interview?.meeting_at.getTime() + Definition.MEETING_TIME_WITH_ADDITIONAL <
          new DateWithOffset().getTime()
      ) {
        actions.push("二次面談終了済")
      } else {
        actions.push("二次面談日程調整済")
      }
      break
    case "second_interview_end":
      actions.push("二次面談終了済")
      break
    case "second_interview_skipped":
      actions.push("契約希望有無選択")
      break
    case "contract_requested":
      actions.push("契約希望有無選択")
      break
    case "contract_accepted":
      if (
        (entry.condition_reward_type && entry.cloudsign_status !== "manual_concluded") ||
        (!entry.condition_reward_type &&
          entry.cloudsign_status !== "concluded" &&
          entry.cloudsign_status !== "manual_concluded" &&
          entry.cloudsign_status !== "")
      ) {
        actions.push("契約合意")
      }
      break
    default:
      break
  }
  return actions
}

interface IComputedEntry extends IEntry {
  problem: IProblem
}

export const getProjectTitleFromEntry = (entry: IComputedEntry) => {
  let projText = ""
  if (entry.problem) {
    if (entry.problem.section_other) {
      projText = entry.problem.section_other
    }
  } else if (entry.scout) {
    projText = Definition.SCOUT_TYPES_JA[entry.scout.scout_type]
  }
  return projText
}

export function getProblemCategoryName(
  problemCategory: IProblemCategory[],
  problem?: { section_other: string; problem_category: string }
) {
  if (!problem) return ""
  const categoryName = problemCategory.find((pc) => problem.problem_category === pc.slug)

  if (!categoryName) return ""
  return categoryName.name
}

export function getProblemCategoryDetailNames(
  problemCategoryDetails: IProblemCategoryDetail[],
  problem?: { section_other: string; problem_category_details: string[] }
) {
  if (!problem) return ""
  const detailNames = problemCategoryDetails
    .filter((pcd) => problem.problem_category_details.includes(pcd.slug) && !pcd.other_flag)
    .sort((a, b) => a.id - b.id)
    .map((pcd) => pcd.name)
  return detailNames
    .concat([problem.section_other])
    .filter((v) => v)
    .join(", ")
}

export function getProblemTitle(
  problemCategoryDetails: IProblemCategoryDetail[],
  problem?: { title: string; section_other: string; problem_category_details: string[] }
) {
  if (!problem) return ""
  return problem.title || getProblemCategoryDetailNames(problemCategoryDetails, problem)
}

export function isEmptyString(target: string | undefined | null): boolean {
  return target == null || target === "" || target === " "
}

const VALID_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export function isValidEmail(val: string | null) {
  return val && val.match(VALID_EMAIL_REGEX)
}

const VALID_NAME_REGEX = /\S/g

export function isValidName(val: string | null) {
  return val && val.match(VALID_NAME_REGEX)
}

export const getPartnerIdentityDesc = (partner: IPartner) => {
  if (partner.force_withdraw_flag) {
    // 強制退会処理されたパートナーの場合、非表示とする
    return "**** ****"
  }
  if (partner.last_name && partner.first_name) {
    // 氏名が登録されている場合、表示する
    return `${partner.last_name} ${partner.first_name}`
  }
  // 氏名が登録されていない場合、「居住地 年齢 性別」の情報を表示する
  const gender = partner.gender === "woman" ? "女性" : "男性"
  const currentAge = age(partner.birthday)
  return `${partner.prefecture} ${currentAge}歳 ${gender}`
}

export const getPartnerCompanyName = (partner: IPartner) => {
  if (partner.force_withdraw_flag) {
    // 強制退会処理されたパートナーの場合、非表示とする
    return "****"
  }
  let careers = partner.careers.find((career) => career.employed)
  if (!careers && partner.careers.length > 0) {
    careers = partner.careers[partner.careers.length - 1]
  }
  return careers?.company ?? ""
}

export const isWithdrawn = (partner: IPartner) => {
  return partner.status === "withdraw" || partner.force_withdraw_flag
}

// TRUSTDOCKの反社チェックに使える文字列がEUC-JPのみであるが、
// ゼロ幅スペースが混入することでエラーが発生することが多々あるため、サーバー側に渡す前に削除する
export function deleteZeroWidthSpace(val: string) {
  return val.replace(/\u200B/g, "")
}

export const scoutTypeStr = (scoutType: string | undefined) => {
  return scoutType === Definition.SCOUT_TYPES.request ? "再申し込み" : "スカウト"
}

export function buildPrefectureDefinitions(prefectures: IPrefecture[], overseasMessage: string) {
  if (!prefectures) return {}
  const definitions: IBitFlagDefinition = {}
  prefectures.forEach((p) => {
    definitions[p.slug] = {
      flag: p.bit_flag,
      message: p.slug === PREFECTURE_OVERSEAS ? overseasMessage : p.name
    }
  })
  return definitions
}

export const getProblemStatus = (problem: IProblem, recruitment: IRecruitment) => {
  switch (problem.checked_bo_status) {
    case PROBLEM_CHECKED_BO_STATUS_EN.progress_new:
      return PROBLEM_STATUS_EN.new
    case PROBLEM_CHECKED_BO_STATUS_EN.progress_resume:
      return PROBLEM_STATUS_EN.resume
    case PROBLEM_CHECKED_BO_STATUS_EN.done:
      switch (recruitment.status) {
        case RECRUITMENT_STATUS_EN.inactive:
          return PROBLEM_STATUS_EN.new
        case RECRUITMENT_STATUS_EN.active:
          if (problem.expired_date) {
            const expired_date =
              typeof problem.expired_date === "string" ? new DateWithOffset(problem.expired_date) : problem.expired_date
            const isExpired = new DateWithOffset().getTime() > expired_date.getTime() + 1000 * 60 * 60 * (24 - 9)
            return isExpired ? PROBLEM_STATUS_EN.closed : PROBLEM_STATUS_EN.active
          } else {
            return PROBLEM_STATUS_EN.new
          }
        case RECRUITMENT_STATUS_EN.closed:
          if (problem.expired_date) {
            const expired_date =
              typeof problem.expired_date === "string" ? new DateWithOffset(problem.expired_date) : problem.expired_date
            const isExpired = new DateWithOffset().getTime() > expired_date.getTime() + 1000 * 60 * 60 * (24 - 9)
            return isExpired ? PROBLEM_STATUS_EN.closed : PROBLEM_STATUS_EN.new
          } else {
            return PROBLEM_STATUS_EN.new
          }
      }
  }
  return ""
}
