import * as React from "react";
import style from "../../style/Recruitment.module.scss";
import secondStyle from "../../style/ScheduleForm.module.scss";
import { IEntry } from "../../types/state";
import { schedulePath } from "../../routes/paths";
import { dateTimeStr } from "../../lib/Util";
import { Link } from "react-router-dom";
import {
  entryFirstPsychologicalSafetiesPath,
  psychologicalSafetyPath
} from "../../routes/paths";

export interface IFirstInterviewInfoDispatchProps {
  changePath: (path: string) => void;
}

export interface IFirstInterviewInfoStateProps {
  entry: IEntry;
  psychological_status: string;
}

export interface IFirstInterviewInfoProps extends IFirstInterviewInfoDispatchProps, IFirstInterviewInfoStateProps {}

export const FirstInterviewInfo = (props: IFirstInterviewInfoProps) => {
  return (
    <>
      <h3 className="contentsSubTitle">一次面談日時（Web面談）</h3>
      {!props.entry.meeting_at ? (
        <div>
          <div className={secondStyle.registerDate}>
            下記のボタンをクリックして、応募者とのWeb面談の候補日時を登録してください。
          </div>
          <div className="formBtnArea formBtnAreaSingle mb40mb32">
            <button
              onClick={() => props.changePath(`${schedulePath}?entry_id=${props.entry.id}`)}
              className="btn btnSave"
              type="submit"
            >
              Web面談候補日時を登録する
            </button>
          </div>
        </div>
      ) : (
        <div className={style.listArea}>
          <div className={style.listRow}>
            <div className={style.listHead}>面談日時</div>
            <div className={style.listData}>
              {dateTimeStr(props.entry.meeting_at)}
            </div>
          </div>
          {!props.entry.declined_by && (
            <div className={style.listRow}>
              <div className={style.listHead}>心理的安全性プログラム</div>
              <div className={style.listData}>
                {props.psychological_status !== "first_wait" ? (
                  <Link
                    to={`${entryFirstPsychologicalSafetiesPath}/${props.entry.id}`}
                    className="link"
                  >
                    お互いの一次面談向け心理的安全性プログラムシートを見る
                  </Link>
                ) : (
                  <Link
                    to={psychologicalSafetyPath}
                    className="link"
                  >
                    ＊面談前に必ず対応してください＊　心理的安全性プログラムを入力する
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
