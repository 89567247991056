import * as React from "react";
import { ErrorMessage } from "./common/ErrorMessage"

const KanaNameField = (props: {
  values: { isSubmitted: boolean; last_kana: string; first_kana: string };
  validates: { [key: string]: (value: any) => string | null };
  updateValue: (args: { [key: string]: string }) => void;
}) => (
  <div className="formRow nameWrap">
    <label className="formHead">
      お名前(カナ)<span className="label label_req">必須</span>
    </label>
    <div className="formData">
      <label className="nameLabel">セイ</label>
      <input
        type="text"
        className="formWidth_s"
        name="last_kana"
        id="last_kana"
        placeholder="スズキ"
        required={true}
        value={props.values.last_kana}
        onChange={(e: React.ChangeEvent<any>) =>
          props.updateValue({ last_kana: e.target.value })
        }
      />
      {props.values.isSubmitted && props.validates["last_kana"] && (
        <ErrorMessage>
          {props.validates["last_kana"](props.values)}
        </ErrorMessage>
      )}
    </div>
    <div className="formData">
      <label className="nameLabel">メイ</label>
      <input
        type="text"
        className="formWidth_s"
        name="first_kana"
        id="first_kana"
        placeholder="タロウ"
        required={true}
        value={props.values.first_kana}
        onChange={(e: React.ChangeEvent<any>) =>
          props.updateValue({ first_kana: e.target.value })
        }
      />
      {props.values.isSubmitted && props.validates["first_kana"] && (
        <ErrorMessage>
          {props.validates["first_kana"](props.values)}
        </ErrorMessage>
      )}
    </div>
  </div>
);

export default KanaNameField;
