import * as React from "react";
import { ICompany } from "../types/state";
import { Link } from "react-router-dom";
import { editCompanyPath } from "../routes/paths";
import * as Definition from "../lib/Definition";
import { delimitize, strToTag, industryStr, convertMillions } from "../lib/Util";
import DetailedPresidentProfile from "./DetailedPresidentProfile";
import { useMemo } from "react"
import style from "../style/Company.module.scss"

const hasDetailedPresidentProfile = (c: ICompany): boolean => {
  return !!(
    c.president_position_name ||
    c.president_birth_year ||
    c.president_hometown ||
    c.president_first_job_desc ||
    c.president_how_become ||
    c.president_motivation ||
    c.president_generation ||
    c.president_hobbies
  );
};

export const CompanyList = (props: { company: ICompany }) => {
  const company = props.company;

  const isWaitingCapture = useMemo(() => {
    return company.eye_catch?.name === "waiting_capture.svg"
  }, [company.eye_catch])

  return (
    <main className="contentsWrapper">
      <div className="formWrap confirmWrap">
        <div className="formRow">
          <div className="formHead">企業ID</div>
          <div className="formData">{company.id}</div>
        </div>
        <div className="formRow">
          <div className="formHead">会社名</div>
          <div className="formData">{company.name}</div>
        </div>
        <div className="formRow">
          <div className="formHead">会社名(カナ)</div>
          <div className="formData">{company.name_kana}</div>
        </div>
        <div className="formRow">
          <div className="formHead">所在地</div>
          <div className="formData">
            〒{company.zip_code} {company.prefecture}
            {company.address1} {company.address2}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">代表者名</div>
          <div className="formData">{company.president}</div>
        </div>
        <div className="formRow">
          <div className="formHead">設立年</div>
          <div className="formData">{company.establishment} 年</div>
        </div>
        {company.capital !== 0 &&
          (<div className="formRow">
            <div className="formHead">資本金</div>
            <div className="formData">{delimitize(convertMillions(company.capital))} 百万円</div>
          </div>) 
        }
        <div className="formRow">
          <div className="formHead">役員数</div>
          <div className="formData">{company.executive} 名</div>
        </div>
        <div className="formRow">
          <div className="formHead">
            概算社員数
            <br className="SPHide" />
            （正規・契約・
            <br className="SPHide" />
            アルバイト含む）
          </div>
          <div className="formData">{company.employee} 名</div>
        </div>
        <div className="formRow">
          <div className="formHead">業界・業種</div>
          <div className="formData">
            {industryStr(company.industries, company.industry_other)}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">Webサイト</div>
          <div className="formData">
            {company.urls.split(/" "/).length === 0 && "なし"}
            {company.urls.split(" ").map((url) => {
              return (
                <div key={url}>
                  <a href={url} target="_blank" rel="noopener noreferrer" className="link">
                    {url}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">紹介写真</div>
          <div className="formData">
            {!company.eye_catch && "なし"}
            {company.eye_catch && !isWaitingCapture && (
              <img
                src={company.eye_catch.url}
                className="companyImg"
                alt="紹介写真"
              />
            )}
            {isWaitingCapture && (
              <div className={style.waitingCaptureAlt}>
                ご登録いただいたURLのキャプチャー画像が<br />
                自動的に取得されます。<br />
                時間をおいてご確認ください。
              </div>
            )}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">
            会社案内・紹介記事等の
            <br />
            ファイル
          </div>
          <div className="formData">
            <ul className="uploadFile">
              {company.files.length === 0 && "なし"}
              {company.files.map((file) => {
                return (
                  <li key={"file" + file.id}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">事業概要</div>
          <div className="formData">
            {company.businesses.map((business, idx) => {
              return (
                <div className="formDataBox" key={"business" + business.id}>
                  <h3 className="dataCaption">{"事業 " + (idx + 1)}</h3>
                  <dl className="dscpBusiness">
                    <dt>事業内容</dt>
                    <dd>{strToTag(business.description)}</dd>
                  </dl>
                  <dl className="dscpBusiness">
                    <dt>概算売上比率</dt>
                    <dd>{Definition.RATE[business.rate]}</dd>
                  </dl>
                </div>
              );
            })}
          </div>
        </div>
        <div className="formRow">
          <div className="formHead">売上規模</div>
          <div className="formData">{Definition.SALES[company.sales]}</div>
        </div>

        <div className="formRow">
          <div className="formHead">
            事業を通じて守り次の世代へ届けたい大切なこと（ビジョン）
          </div>
          <div className="formData">{strToTag(company.vision)}</div>
        </div>

        <div className="formRow">
          <div className="formHead">代表者のプロフィール</div>
          <div className="formData">
            {hasDetailedPresidentProfile(props.company) ? (
              <div className="formDataBox">
                <DetailedPresidentProfile company={company} />
              </div>
            ) : (
              <>{strToTag(company.president_profile)}</>
            )}
          </div>
        </div>

        <div className="formRow">
          <div className="formHead">
            その他
          </div>
          <div className="formData">{strToTag(company.other)}</div>
        </div>
      </div>
      <div className="formBtnArea formBtnAreaSingle">
        <Link to={editCompanyPath} className="btn btnEdit">
          変更・修正する
        </Link>
      </div>
    </main>
  );
};
