import { TypeKeys, Actions } from "../actions";
import { INews } from "../types/state";
import DateWithOffset from "../lib/DateWithOffset";

const initialState: INews[] = [];

const news = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.FETCH_NEWS_SUCCESS: {
      return action.payload.data.map((n: INews) => {
        return {
          link: n.link,
          title: n.title,
          pubDate: new DateWithOffset(n.pubDate)
        };
      });
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default news;
