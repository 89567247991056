import * as React from "react";
import { IPartner } from "../types/state";

export const PartnerWithdrawInfo = (props: { partner: IPartner }) => {
  if (props.partner.force_withdraw_flag) {
    return <div>このユーザーは利用規約に違反したため退会措置となりました。</div>
  }
  if (props.partner.status === "withdraw") {
    return <div>このユーザーは退会しました。</div>
  }
  return null;
}
