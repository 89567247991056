import { TypeKeys, Actions } from "../actions"
import { ILicense } from "../types/state"

const initialState: ILicense[] = []

export const licenses = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.licenses
    }
    default: {
      return state
    }
  }
}
