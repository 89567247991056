import * as React from "react";
import { Link } from "react-router-dom";
import * as Paths from "../routes/paths";
import { ErrorMessage } from "./common/ErrorMessage"
import { useSelector } from "react-redux"
import { IStoreState } from "../types/state"
import { RECRUITMENT_STATUS_EN, STAFF_ROLES_EN } from "../lib/Definition"
import { useMemo } from "react"

interface Props {
  canEntry?: boolean;
  isNeededVerify: boolean;
  hasActiveProblem: boolean;
  isEnteredForAllProblems: boolean;
}

export const UnConfirmationToRequestInterview: React.FC<Props> = (props) => {
  const recruitment = useSelector((state: IStoreState) => state.recruitment)
  const company = useSelector((state: IStoreState) => state.company)
  const staffs = useSelector((state: IStoreState) => state.staffs)

  const staff = useMemo(() => staffs?.find(s => s.id === company.staff_id), [staffs, company])
  const isIndividualStaff = useMemo(() => staff?.role === STAFF_ROLES_EN.individual, [staff])

  return (
    <div className="mb40">
      {props.canEntry !== undefined && !props.canEntry && (
        <ErrorMessage>
          面談を申し込むには<Link to={Paths.editCompanyPath}>企業概要</Link>
          の必要項目を入力してください。
        </ErrorMessage>
      )}
      {props.isNeededVerify && (
        <ErrorMessage>
          面談を申し込むには<Link to={Paths.checkTrustPath}>企業確認</Link>
          を行ってください。
        </ErrorMessage>
      )}
      {recruitment.status === RECRUITMENT_STATUS_EN.closed && (
        <ErrorMessage>
          貴社の募集は終了しているため面談を申し込めません。<br />
          新規案件の募集や過去案件の募集再開をご依頼されている場合、<br />
          JOINSによる確認が完了するまで今しばらくお待ちください。
        </ErrorMessage>
      )}
      {recruitment.status !== RECRUITMENT_STATUS_EN.closed && (
        <>
          {!props.hasActiveProblem && (
            <>
              {!isIndividualStaff && (
                <ErrorMessage>
                  指定可能な案件がないため面談を申し込めません。<br />
                  面談を申し込むには<Link to={Paths.recruitmentPath}>募集案件概要</Link>
                  より案件の登録を行ってください。<br />
                  既に案件の登録がお済みで、案件ステータスが準備中となっている場合、<br />
                  JOINSによる確認が完了するまで今しばらくお待ちください。
                </ErrorMessage>
              )}
              {isIndividualStaff && (
                <ErrorMessage>
                  指定可能な案件がないため面談を申し込めません。<br />
                  <Link to={Paths.recruitmentPath}>募集案件概要</Link>
                  よりご担当者様が権限をお持ちの案件の案件ステータスが<br />受付中となっているかご確認ください。<br />
                  案件ステータスが準備中となっている場合、<br />
                  JOINSによる確認が完了するまで今しばらくお待ちください。
                </ErrorMessage>
              )}
            </>
          )}
          {props.hasActiveProblem && recruitment.status === RECRUITMENT_STATUS_EN.inactive && (
            <ErrorMessage>
              募集開始前のため面談を申し込めません。<br />
              JOINSによる確認が完了するまで今しばらくお待ちください。
            </ErrorMessage>
          )}
        </>
      )}
      {props.isEnteredForAllProblems && (
        <ErrorMessage>
          応募済みの人材のためスカウトできません。<br />
          <Link to={Paths.homePath}>人材管理</Link>
          より応募内容を確認してください。
        </ErrorMessage>
      )}
    </div>
  );
};
