import * as React from "react"
import * as Definition from "../lib/Definition"
import { connect } from "react-redux"
import { Actions } from "../actions"
import { ScoutList, IScoutListProps } from "../components/Scout/ScoutList"
import { Dispatch, Action } from "redux"
import { IStoreState, IScoutConditions } from "../types/state"
import { parseQueryParams, definitionStr, buildPrefectureDefinitions } from "../lib/Util"
import { push } from "connected-react-router"
import {
  BUSINESS_TRIP,
  DEPARTMENTS,
  EXISTENCE,
  INDUSTRIES,
  MANAGEMENT_MEMBERS,
  PREFECTURE_OVERSEAS_MESSAGE,
  REWARD,
  WORK_TIME_2
} from "../lib/Definition"

class Scout extends React.Component<IScoutListProps> {
  componentDidMount() {
    const { dispatch } = this.props as any
    if (this.props.partners.partners.length === 0 || window.location.search.match(/renewal=true/)) {
      search(dispatch, this.props.conditions, true)
    }
  }

  render() {
    return <ScoutList {...this.props} />
  }
}

function initialCondition(page: number): IScoutConditions {
  return {
    page: page,
    industries: 0,
    experienced_job: undefined,
    experienced_job_details: [],
    departments: 0,
    licenses: [],
    work_time_2: "",
    reward_from: undefined,
    reward_to: undefined,
    business_trip: "",
    prefecture: 0,
    registered: "",
    keyword: "",
    management: false,
    management_members: "",
    has_career_sheet: false,
    bookmark: false,
    verificated: false
  }
}

function mapStateToProps(state: IStoreState) {
  const prefectureDefinitions = buildPrefectureDefinitions(state.prefectures, PREFECTURE_OVERSEAS_MESSAGE)
  const result = parseQueryParams()
  const conditions = initialCondition(parseInt(result["page"], 10))
  const conditionStrs = []

  if (result["industries"]) {
    conditions.industries = parseInt(result["industries"], 10)
    conditionStrs.push(
      "<span>経験業界：</span><br class='PCHide' />" + definitionStr(INDUSTRIES, conditions.industries, " もしくは ")
    )
  }
  if (result["experienced_job"]) {
    conditions.experienced_job = parseInt(result["experienced_job"])
    const problemCategory = state.problemCategories.find((pc) => pc.id === conditions.experienced_job)
    conditionStrs.push("<span>経験業務：</span><br class='PCHide' />" + problemCategory?.name)
  }
  if (result["experienced_job_details"]) {
    const val = result["experienced_job_details"].split(",").map((v: string) => parseInt(v))
    conditions.experienced_job_details = val
    const detailNames = val
      .map((v: number) => {
        const problemCategoryDetail = state.problemCategoryDetails.find((pcd) => pcd.id === v)
        return problemCategoryDetail?.name
      })
      .filter((v: number) => !!v)
    conditionStrs.push("<span>経験業務詳細：</span><br class='PCHide' />" + detailNames.join(" もしくは "))
  }
  if (result["departments"]) {
    conditions.departments = parseInt(result["departments"], 10)
    conditionStrs.push(
      "<span>職種：</span><br class='PCHide' />" + definitionStr(DEPARTMENTS, conditions.departments, " もしくは ")
    )
  }
  if (result["licenses"]) {
    const val = result["licenses"].split(",").map((v: string) => parseInt(v))
    conditions.licenses = val
    const licenseNames = val
      .map((v: number) => {
        const license = state.licenses.find((li) => li.id === v)
        return license?.name
      })
      .filter((v: number) => !!v)
    conditionStrs.push("<span>資格：</span><br class='PCHide' />" + licenseNames.join(" もしくは "))
  }
  if (result["work_time_2"]) {
    conditions.work_time_2 = result["work_time_2"]
    conditionStrs.push("<span>1ヶ月あたりの稼動時間：</span>" + WORK_TIME_2[conditions.work_time_2])
  }
  if (result["reward_from"]) {
    conditions.reward_from = result["reward_from"]
    conditionStrs.push("<span>希望時給単価（下限）：</span>" + REWARD[conditions.reward_from])
  }
  if (result["reward_to"]) {
    conditions.reward_to = result["reward_to"]
    conditionStrs.push("<span>希望時給単価（上限）：</span>" + REWARD[conditions.reward_to])
  }
  if (result["business_trip"]) {
    conditions.business_trip = result["business_trip"]
    conditionStrs.push("<span>出張希望：</span>" + BUSINESS_TRIP[conditions.business_trip])
  }
  if (result["prefecture"]) {
    conditions.prefecture = parseInt(result["prefecture"], 10)
    conditionStrs.push(
      "<span>現在居住地：</span>" + definitionStr(prefectureDefinitions, conditions.prefecture, "かつ")
    )
  }
  if (result["registered"]) {
    conditions.registered = result["registered"]
    conditionStrs.push("<span>登録日：</span>" + Definition.REGISTER_DATES[result["registered"]])
  }
  if (result["keyword"]) {
    conditions.keyword = unescape(result["keyword"])
    conditionStrs.push("<span>キーワード:</span>" + conditions.keyword)
  }
  if (result["management"] === "true") {
    conditions.management = result["management"] === "true"
    conditionStrs.push("<span>マネジメント経験：</span>" + EXISTENCE[conditions.management])
  }
  if (result["management_members"]) {
    conditions.management_members = result["management_members"]
    conditionStrs.push("<span>マネジメント人数：</span>" + MANAGEMENT_MEMBERS[conditions.management_members])
  }

  if (result["has_career_sheet"] === "true") {
    conditions.has_career_sheet = true
    conditionStrs.push("<span>職務経歴書：</span>あり")
  }
  if (result["bookmark"] === "true") {
    conditions.bookmark = true
    conditionStrs.push("<span>お気に入り：</span>追加済み")
  }
  if (result["verificated"] === "true") {
    conditions.verificated = true
    conditionStrs.push("<span>本人確認：</span>確認済み")
  }
  return {
    conditions,
    conditionStrs: conditionStrs,
    searched: state.viewState.searched,
    partners: state.partners,
    prefectureDefinitions
  }
}

function search(dispatch: Dispatch<Action<any>>, conditions: IScoutConditions, replace: boolean) {
  const conds: string[] = []
  const searches = {}
  Object.keys(conditions).forEach((key) => {
    if (["industries", "departments", "prefecture"].includes(key) && conditions[key] === 0) return
    if (Array.isArray(conditions[key]) && conditions[key].length === 0) return
    if (conditions[key] || conditions[key] === 0) {
      conds.push(`${key}=${encodeURIComponent(conditions[key])}`)
      searches[key] = conditions[key]
    }
  })
  if (conds.length === 1 && conds[0].startsWith("page=")) conds.pop()
  if (replace) {
    window.history.replaceState(null, "", window.location.pathname + (conds.length > 0 ? "?" + conds.join("&") : ""))
  } else {
    window.history.pushState(null, "", window.location.pathname + (conds.length > 0 ? "?" + conds.join("&") : ""))
  }
  if (conds.length > 0) {
    dispatch(Actions.searchPartners(searches) as any)
  } else {
    dispatch(Actions.clearPartners())
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    clearCondition: () => initialCondition(1),
    changePath: (path: string) => dispatch(push(path)),
    updateBookmark: (id: number, bookmarked: boolean) => dispatch(Actions.updateBookmark(id, bookmarked)),
    search: (conditions: IScoutConditions) => {
      search(dispatch, conditions, false)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scout)
