import * as actionFrom from "../actions";
import LZString from "lz-string";
import DateWithOffset from "../lib/DateWithOffset";

const dataSet: any = [];
let timer: any = null;
const pattern = /password|blob/;

function sendLogData() {
  if (dataSet.length === 0) {
    return;
  }
  const sendData = LZString.compressToEncodedURIComponent(
    JSON.stringify(dataSet)
  );
  dataSet.splice(0, dataSet.length);
  if (!process.env.REACT_APP_LOG_URL) {
    return;
  }
  const xhr = new XMLHttpRequest();
  xhr.open("POST", process.env.REACT_APP_LOG_URL as string);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send('{ "content": "' + sendData + '"}');
}
function deepMask(target: Object | any[]): Object {
  if (!target) {
    return target;
  }
  if (Array.isArray(target)) {
    return target.map((v) => {
      if (typeof v === "object" || Array.isArray(v)) {
        return deepMask(v);
      } else {
        return v;
      }
    });
  } else if (typeof target === "object") {
    return Object.keys(target).reduce((result, key) => {
      if (typeof target[key] === "string" && key.match(pattern)) {
        result[key] = "[FILTERED]";
      } else if (
        typeof target[key] === "object" ||
        Array.isArray(target[key])
      ) {
        result[key] = deepMask(target[key]);
      } else {
        result[key] = target[key];
      }
      return result;
    }, {});
  } else {
    return target;
  }
}

function storeLog(
  action: any,
  uid: string,
  gid: string,
  companyID: number,
  path: string
) {
  let dataRaw: any;
  switch (action.type) {
    case actionFrom.TypeKeys.LOGIN: {
      dataRaw = { ...action.payload, password: "" };
      break;
    }
    default:
      if (action.type.match(/_SUCCESS$/)) {
        dataRaw = action.payload ? action.payload.data : null;
      } else if (action.type.match(/_FAIL$/)) {
        if (!!action.error && !!action.error.params) {
          dataRaw = action.error.data;
        } else {
          dataRaw = action.error;
        }
      } else {
        if (!!action.payload && !!action.payload.request) {
          dataRaw = action.payload.request;
        } else {
          dataRaw = action.payload;
        }
      }
  }
  const data = {
    "@timestamp": new DateWithOffset().toISOString(),
    action_type: action.type,
    data_raw: JSON.stringify(deepMask(dataRaw)),
    companyID: companyID || 0,
    uid: uid,
    gid: gid,
    path: path,
  };
  dataSet.push(data);
}

const sendAction = (store: any) => (next: any) => (action: any) => {
  if (
    action.type !== actionFrom.TypeKeys.SET_VIEW_STATE &&
    !action.type.match(
      /FETCH_OFFER|FETCH_ANSWER|FETCH_MESSAGES|POLLING_VIDEO_ROOM/
    ) &&
    !(
      action.type === "@@router/LOCATION_CHANGE" &&
      action.payload.location.pathname ===
        store.getState().router.location.pathname &&
      action.payload.location.search ===
        store.getState().router.location.search &&
      action.payload.location.hash === store.getState().router.location.hash
    )
  ) {
    setTimeout(() =>
      storeLog(
        action,
        store.getState().identifier.uid,
        store.getState().identifier.gid,
        store.getState().company.id,
        store.getState().router.location.pathname
      )
    );
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      sendLogData();
      timer = null;
    }, 500);
  }
  return next(action);
};

export default sendAction;
