import * as React from "react";
import { IBusiness } from "../types/state";
import * as Definition from "../lib/Definition";
import { ErrorMessage } from "./common/ErrorMessage"

const EditBusinessSummaryList = (props: {
  values: { businesses: IBusiness[]; isSubmitted: boolean };
  validates: { [key: string]: (value: any) => string | null };
  updateValue: (args: { [key: string]: any }) => void;
}) => {
  return (
    <div className="formRow">
      <label className="formHead">
        事業概要 <span className="label label_req">必須</span>
      </label>
      <div className="formData">
        <p className="formLead">主な事業を3つまで記載ください</p>
        {props.values.businesses.map((business: IBusiness, index: number) => {
          if (business.deleted) return null;
          return (
            <div className="formDataBox separateForm" key={`career-${index}`}>
              <label className="problemLabel">
                事業内容
                <small>顧客と商品・サービス内容を簡単にご記入ください</small>
              </label>
              <textarea
                className="textarea"
                id={`description-${index}`}
                value={business.description}
                onChange={(e: React.ChangeEvent<any>) => {
                  props.updateValue({
                    businesses: props.values.businesses.map((bus, i) =>
                      i === index
                        ? { ...bus, description: e.target.value }
                        : bus
                    )
                  });
                }}
              />
              {props.values.isSubmitted && props.validates["description"] && (
                <ErrorMessage cls="mb08">
                  {props.validates["description"](business)}
                </ErrorMessage>
              )}
              <label className="problemLabel" id={`rate-${index}`}>
                概算売上比率
              </label>
              {Object.keys(Definition.RATE).map(key => (
                <label className="selectBtn" key={key}>
                  <input
                    type="radio"
                    value={key}
                    onChange={(e: React.ChangeEvent<any>) =>
                      props.updateValue({
                        businesses: props.values.businesses.map((bus, i) =>
                          i === index ? { ...bus, rate: e.target.value } : bus
                        )
                      })
                    }
                    checked={business.rate === key}
                  />
                  <span>{Definition.RATE[key]}</span>
                </label>
              ))}
              {props.values.isSubmitted && props.validates["rate"] && (
                <ErrorMessage cls="mb24">
                  {props.validates["rate"](business)}
                </ErrorMessage>
              )}
              <div className="formData_btnBox">
                {props.values.businesses.filter((b: IBusiness) => !b.deleted)
                  .length > 1 && (
                  <button
                    onClick={() => {
                      props.updateValue({
                        businesses: props.values.businesses
                          .map((bus: IBusiness, j: number) =>
                            index === j ? { ...bus, deleted: true } : bus
                          )
                          .filter(bus => !bus.deleted || bus.id)
                      });
                    }}
                    className="btn btnDelete"
                  >
                    事業概要を削除
                  </button>
                )}

                {props.values.businesses.filter((b: IBusiness) => !b.deleted)
                  .length < 3 &&
                  props.values.businesses
                    .map((b: IBusiness) => !b.deleted)
                    .lastIndexOf(true) === index && (
                    <button
                      className="btn btnAdd"
                      onClick={() => {
                        props.updateValue({
                          businesses: [
                            ...props.values.businesses,
                            {
                              description: "",
                              rate: ""
                            }
                          ]
                        });
                      }}
                    >
                      事業概要を追加
                    </button>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditBusinessSummaryList;
