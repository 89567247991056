import * as React from "react";
import { homePath } from "../../routes/paths";

export interface IHomeButtonProps {
  changePath: (path: string) => void;
}

export const HomeButton = (props: IHomeButtonProps) => {
  return (
    <div className="formBtnArea formBtnAreaSingle">
      <button
        className="btn btnSave"
        onClick={() => props.changePath(homePath)}
      >
        一覧に戻る
      </button>
    </div>
  );
};
