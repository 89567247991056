import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import EntryAcceptFinish from "../components/EntryAcceptFinish";
import EntryRejectFinish from "../components/EntryRejectFinish";
import { Dispatch, Action } from "redux";
import { IEntry, IQuestionnaireState, IStoreState } from "../types/state";
import { push } from "connected-react-router";
import { submitQuestionnaire } from "../thunks";

class EntryFinish extends React.Component<any> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    if (!this.props.entry) {
      dispatch(Actions.fetchEntries());
    }
  }
  render() {
    const entry = this.props.entry;

    if (!entry) {
      return null;
    }

    if (this.props.entry.declined_by) {
      return (
        <EntryRejectFinish
          entry={entry}
          changePath={this.props.changePath}
          submitQuestionnaire={this.props.submitQuestionnaire}
        />
      );
    } else {
      return (
        <EntryAcceptFinish
          entry={entry}
          changePath={this.props.changePath}
          firstPsychologicalSafetyStatus={
            this.props.firstPsychologicalSafetyStatus
          }
          submitQuestionnaire={this.props.submitQuestionnaire}
        />
      );
    }
  }
}

function mapStateToProps(state: IStoreState) {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
  const entry = state.entries.filter(
    (entry: IEntry) => entry.id === entryID
  )[0];

  return {
    entry,
    firstPsychologicalSafetyStatus: state.company.psychological_status,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    changePath: (path: string) => dispatch(push(path)),
    submitQuestionnaire: (questionnaire: IQuestionnaireState) =>
      dispatch(submitQuestionnaire(questionnaire) as any),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryFinish);
