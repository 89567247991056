import { connect } from "react-redux";
import {
  ActivateStaffForm,
  IActivateStaffDispatchProps,
  IActivateStaffStateProps
} from "../components/ActivateStaff/ActivateStaffForm";
import { activateStaff } from "../thunks";
import { IStoreState, IAjaxError, IStaffActivation } from "../types/state"
import { Actions } from "../actions";
import { Dispatch } from "redux";
import * as React from "react"
import { StaffActions } from "../actions/staff"
import { STAFF_ACTIVATION_STATUS } from "../lib/Definition"
import { Activated } from "../components/ActivateStaff/Activated"
import { Expired } from "../components/ActivateStaff/Expired"

interface IStateProps extends IActivateStaffStateProps {
  staffActivation: IStaffActivation
}
interface IDispatchProps extends IActivateStaffDispatchProps {
  checkStaffActivationUuid: (uuid: string) => Promise<{}>
}
interface IProps extends IStateProps, IDispatchProps {}

const ActivateStaff = (props: IProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { props.checkStaffActivationUuid(props.uuid) }, [])
  if (props.staffActivation.status === STAFF_ACTIVATION_STATUS.activated) {
    return <Activated />
  }
  if (props.staffActivation.status === STAFF_ACTIVATION_STATUS.expired) {
    return <Expired />
  }
  if (props.staffActivation.status === STAFF_ACTIVATION_STATUS.valid) {
    return (
      <ActivateStaffForm
        uuid={props.uuid}
        name={props.name}
        password={props.password}
        password_confirm={props.password_confirm}
        error={props.error}
        isSubmitted={props.isSubmitted}
        updateValue={props.updateValue}
        renewalStaff={props.renewalStaff}
      />
    )
  }
  return null
};

function mapStateToProps(state: IStoreState): IStateProps {
  const result = window.location.href.split("uuid=").pop() as string;
  const uuid = result.replace(/&.*$/, "");
  return {
    uuid,
    name: state.viewState.name || "",
    password: state.viewState.password || "",
    password_confirm: state.viewState.password_confirm || "",
    isSubmitted: state.viewState.isSubmitted || false,
    error: state.viewState.error || "",
    staffActivation: state.staffActivation
  };
}

function mapDispatchToProps(dispatch: Dispatch<IStoreState>): IDispatchProps {
  return {
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
    renewalStaff: (params: {
      uuid: string;
      name: string;
      password: string;
    }) => {
      dispatch(activateStaff(params) as any).catch((e: IAjaxError) => {
        dispatch(Actions.setViewState({
          error: e.payload.error.message,
          errorField: e.payload.error.field,
          scrollID: e.payload.error.field
        }) as any);
      });
    },
    checkStaffActivationUuid: (uuid) =>
      dispatch(StaffActions.checkStaffActivationUuid(uuid) as any)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateStaff);
