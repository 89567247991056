import { IStoreState } from "../types/state"
import { useSelector } from "react-redux"
import { STAFF_ROLES_EN } from "../lib/Definition"
import { useCallback, useMemo } from "react"

export const useStaff = () => {
  const company = useSelector((state: IStoreState) => state.company)
  const staffs = useSelector((state: IStoreState) => state.staffs)

  const staff = useMemo(() => {
    return company.staff_id ? staffs.find((s) => s.id === company.staff_id) : undefined
  }, [company, staffs])

  const isStaff = useMemo(() => !!staff, [staff])
  const isAdminStaff = useMemo(() => staff?.role === STAFF_ROLES_EN.admin, [staff])
  const isIndividualStaff = useMemo(() => staff?.role === STAFF_ROLES_EN.individual, [staff])

  const isStaffPermittedProblem = useCallback(
    (problemId: number) => {
      if (!isIndividualStaff) return true
      return (staff?.problem_ids ?? []).includes(problemId)
    },
    [staff, isIndividualStaff]
  )

  return {
    staff,
    isStaff,
    isAdminStaff,
    isIndividualStaff,
    isStaffPermittedProblem
  }
}
