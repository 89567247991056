import * as React from "react";
import style from "../../style/Recruitment.module.scss";
import { IEntry } from "../../types/state";
import { SecondInterviewEntry } from "../SecondInterviewEntry";
import storedUseState from "../../lib/storedUseState";
import { InterviewReject } from "./InterviewReject";
import { Link } from "react-router-dom";
import { secondInterviewRequestPath } from "../../routes/paths";

export interface ISecondInterviewDispatchProps {
  acceptSecondInterview: (entry: IEntry) => void;
  reject: (entry: IEntry, apology: string) => void;
}
export interface ISecondInterviewStateProps {
  entry: IEntry;
}

export interface ISecondInterviewProps extends ISecondInterviewDispatchProps, ISecondInterviewStateProps {}

export const SecondInterviewInfo = (props: ISecondInterviewProps) => {
  const [state, setState] = storedUseState("SecondInterview", {
    showRejectSecondInterviewForm: false,
    secondInterviewEntryResultCompleted: false,
    apology: "",
  });

  const requested = !!props.entry.second_interview;

  return (
    <>
      <SecondInterviewEntry
        second_interview_entry={props.entry.second_interview_entry}
        before_saved={!requested}
      />
      {!requested && !state.showRejectSecondInterviewForm && (
        <div className={style.entrySecondInterviewResultFormWrapper}>
          <div className={style.messageAfterFirstInterview}>
            {props.entry.partner.last_name + " " + props.entry.partner.first_name}
            氏との二次面談実施を希望されますか？
          </div>
          <div className="formBtnArea">
            <button
              className="btn btnEdit"
              type="submit"
              onClick={() => {
                setState({ ...state, showRejectSecondInterviewForm: true });
              }}
            >
              希望しない
            </button>
            <Link
              className="btn btnSave"
              to={`${secondInterviewRequestPath}/${props.entry.id}`}
            >
              希望する
            </Link>
          </div>
        </div>
      )}
      {state.showRejectSecondInterviewForm && (
        <InterviewReject
          entry={props.entry}
          reject={props.reject}
          close={() =>
            setState({ ...state, showRejectSecondInterviewForm: false })
          }
        />
      )}
    </>
  );
};
