import * as React from "react";
import style from "../style/Recruitment.module.scss";
import { ModalArgs } from "../thunks";
import { LabelType } from "../lib/Definition";
import { Label } from "./Label";

export interface ILabelButtonDispatchProps {
  onSelect: (status: LabelType) => void;
  openModal: (args: ModalArgs) => void;
}

export interface ILabelButtonStateProps {}

export interface ILabelButtonProps
  extends ILabelButtonDispatchProps,
    ILabelButtonStateProps {}

export const LabelButton = (props: ILabelButtonProps) => {
  return (
    <>
      <div
        onClick={() => {
          props.openModal({
            component: (props: any) => <SelectLabelModal {...props} />,
            content: {
              onItemClick: props.onSelect,
            },
          });
        }}
        className={style.putLabelButton}
      >
          <i />
          <span>ラベルを貼る</span>
      </div>
    </>
  );
};

const SelectLabelModal = (props: {
  content: { onItemClick: (type: LabelType) => void };
  closeModal: (result: any) => void;
}) => {
  const onClick = (type: LabelType) => {
    props.content.onItemClick(type);
    props.closeModal(true);
  };
  return (
    <>
      <div className={style.labelDescription}>
        <b>応募に評価ラベルをつけることで、<br className="PCHide" />複数の応募を管理しやすくなります。</b><br />
        <span>下記ボタンをクリックしてラベルがつけられます。<br />
          ラベルはパートナー人材側には表示されません。</span>
      </div>
      <div className={style.labelPutButtons}>
        <Label
          type="interested"
          onClick={onClick}
          interactive
        />
        <Label
          type="considering"
          onClick={onClick}
          interactive
        />
        <Label
          type="disinterested"
          onClick={onClick}
          interactive
        />
      </div>
    </>
  );
};