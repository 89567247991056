import { IScoutConditions, IStoreState } from "../../types/state"
import { useDebounceValue } from "../../hooks/useDebounceValue"
import { IBitFlagDefinition, SelectBitFlagField } from "../SelectBitFlagField"
import { SelectField } from "../SelectField"
import style from "../../style/Scout.module.scss"
import {
  BUSINESS_TRIP,
  DEPARTMENTS,
  INDUSTRIES,
  MANAGEMENT_MEMBERS,
  REGISTER_DATES,
  REWARD,
  WORK_TIME_2
} from "../../lib/Definition"
import { SelectBadgeField } from "../SelectBadgeField"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { SelectGroupedBadgeField } from "../SelectGroupedBadgeField"
import clsx from "clsx"

export interface IScoutConditionFormDispatchProps {
  search: (condtion: IScoutConditions) => void
  clearCondition: () => IScoutConditions
}

export interface IScoutConditionFormStateProps {
  conditions: IScoutConditions
  prefectureDefinitions: IBitFlagDefinition
}

export interface IScoutConditionFormProps extends IScoutConditionFormDispatchProps, IScoutConditionFormStateProps {}

export const ScoutConditionForm = (props: IScoutConditionFormProps) => {
  const { problemCategories, problemCategoryDetails, licenseCategories, licenses } = useSelector(
    (state: IStoreState) => state
  )

  const [conditions, setConditions] = useState(props.conditions)
  const [isOpen, setOpenStatus] = useState(true)
  const searchConditions = useDebounceValue<IScoutConditions>(conditions, 1000)

  const experiencedJobDefinitions = useMemo(() => {
    return problemCategories.map((pc) => ({ id: pc.id, label: pc.name }))
  }, [problemCategories])

  const experiencedJobDetailDefinitions = useMemo(() => {
    const problemCategory = problemCategories.find((pc) => pc.id === conditions.experienced_job)
    if (!problemCategory) return []
    return problemCategoryDetails
      .filter((pcd) => pcd.problem_category_id === problemCategory.id)
      .map((pcd) => ({ id: pcd.id, label: pcd.name }))
  }, [problemCategories, problemCategoryDetails, conditions.experienced_job])

  const licenseDefinitions = useMemo(() => {
    return licenseCategories.map((category) => {
      const children = licenses
        .filter((license) => license.license_category_id === category.id)
        .map((license) => ({ id: license.id, label: license.name }))
      return { label: category.name, children }
    })
  }, [licenseCategories, licenses])

  const { search } = props
  useEffect(() => {
    search(searchConditions)
  }, [search, searchConditions])

  return (
    <div className={`${style.accordionItem} ${isOpen && style.accordionItem__opened}`}>
      <div
        className={style.accordionItem__line}
        onClick={() => {
          setOpenStatus(!isOpen)
        }}
      >
        <h3 className={style.accordionItem__title}>検索条件</h3>
        <span className={style.accordionItem__icon} />
      </div>

      <div className={style.accordionItem__inner + " " + style.searchAreaInner}>
        <SelectBitFlagField
          label={<span className={style.searchInnerTitle}>経験業界</span>}
          name="industries"
          type="scout"
          cls="mb20"
          values={conditions}
          flags={INDUSTRIES}
          updateValue={(val: number) => {
            setConditions({ ...conditions, industries: val, page: 1 })
          }}
        />
        <SelectBadgeField
          label={<span className={style.searchInnerTitle}>経験業務</span>}
          masters={experiencedJobDefinitions}
          name="experienced_job"
          cls="mb20"
          values={conditions.experienced_job != null ? [conditions.experienced_job] : []}
          updateValue={(e: ChangeEvent<HTMLInputElement>) => {
            setConditions({
              ...conditions,
              experienced_job: e.target.checked ? parseInt(e.target.value) : undefined,
              experienced_job_details: [],
              page: 1
            })
          }}
        />
        {experiencedJobDetailDefinitions.length > 0 && (
          <SelectBadgeField
            label={<span className={style.searchInnerTitle}>経験業務詳細</span>}
            masters={experiencedJobDetailDefinitions}
            name="experienced_job_details"
            cls="mb20"
            values={conditions.experienced_job_details ?? []}
            updateValue={(e: ChangeEvent<HTMLInputElement>) => {
              const val = parseInt(e.target.value)
              const details = (conditions.experienced_job_details ?? []).filter((pcdId) => pcdId !== val)
              if (e.target.checked) details.push(val)
              details.sort((a, b) => a - b)
              setConditions({ ...conditions, experienced_job_details: details, page: 1 })
            }}
          />
        )}
        <SelectBitFlagField
          label={<span className={style.searchInnerTitle}>職種</span>}
          name="departments"
          type="scout"
          cls="mb20"
          values={conditions}
          flags={DEPARTMENTS}
          updateValue={(val: number) => {
            setConditions({ ...conditions, departments: val, page: 1 })
          }}
        />
        <SelectGroupedBadgeField
          label={<span className={style.searchInnerTitle}>資格</span>}
          name="licenses"
          cls="mb20"
          values={conditions.licenses ?? []}
          masters={licenseDefinitions}
          updateValue={(e: ChangeEvent<HTMLInputElement>) => {
            const val = parseInt(e.target.value)
            const licenses = (conditions.licenses ?? []).filter((licenseId) => licenseId !== val)
            if (e.target.checked) licenses.push(val)
            licenses.sort((a, b) => a - b)
            setConditions({ ...conditions, licenses, page: 1 })
          }}
        />
        <SelectField
          label={<span className={style.searchInnerTitle}>1ヶ月あたり稼動時間</span>}
          cls="mb20"
          name="work_time_2"
          type="scout"
          values={conditions}
          master={WORK_TIME_2}
          allowBlank
          updateValue={(val: string) => {
            setConditions({ ...conditions, work_time_2: val, page: 1 })
          }}
        />
        <SelectField
          label={<span className={style.searchInnerTitle}>希望時給単価（下限）</span>}
          cls="mb20"
          name="reward_from"
          type="scout"
          values={conditions}
          master={REWARD}
          allowBlank
          updateValue={(val: string) => {
            setConditions({ ...conditions, reward_from: val === "" ? undefined : parseInt(val), page: 1 })
          }}
        />
        <SelectField
          label={<span className={style.searchInnerTitle}>希望時給単価（上限）</span>}
          cls="mb20"
          name="reward_to"
          type="scout"
          values={conditions}
          master={REWARD}
          allowBlank
          updateValue={(val: string) => {
            setConditions({ ...conditions, reward_to: val === "" ? undefined : parseInt(val), page: 1 })
          }}
        />
        <SelectField
          label={<span className={style.searchInnerTitle}>出張希望</span>}
          cls="mb20"
          name="business_trip"
          type="scout"
          values={conditions}
          master={BUSINESS_TRIP}
          allowBlank
          updateValue={(val: string) => {
            setConditions({ ...conditions, business_trip: val, page: 1 })
          }}
        />
        <SelectField
          label={<span className={style.searchInnerTitle}>現在居住地</span>}
          name="prefecture"
          type="scout"
          cls="mb20"
          values={conditions}
          flags={props.prefectureDefinitions}
          allowBlank
          updateValue={(val: string) => {
            setConditions({
              ...conditions,
              page: 1,
              prefecture: parseInt(val, 10)
            })
          }}
        />
        <SelectField
          label={<span className={style.searchInnerTitle}>登録日</span>}
          name="registered"
          type="scout"
          cls="mb20"
          values={conditions}
          master={REGISTER_DATES}
          allowBlank
          updateValue={(val: string) => {
            setConditions({
              ...conditions,
              page: 1,
              registered: val
            })
          }}
        />

        <div className="mb20">
          <label className="formHead">
            <span className={style.searchInnerTitle}>キーワードで探す</span>
          </label>
          <div className="formData">
            <input
              className={style.searchInnerSearch}
              type="text"
              value={conditions.keyword}
              onChange={(e) => setConditions({ ...conditions, page: 1, keyword: e.currentTarget.value })}
            />
          </div>
        </div>

        <div className="mb20">
          <div className="formHead">
            <input
              type="checkbox"
              id="management"
              checked={conditions.management}
              onChange={(e) =>
                setConditions({ ...conditions, management: e.target.checked, management_members: "", page: 1 })
              }
            />
            <label className={clsx(style.searchInnerTitle, style.searchInnerTitleLabel)} htmlFor="management">
              マネジメント経験ありのみ表示
            </label>
          </div>
        </div>

        {conditions.management && (
          <SelectField
            label={<span className={style.searchInnerTitle}>マネジメント人数</span>}
            cls="mb20"
            name="management_members"
            type="scout"
            values={conditions}
            master={MANAGEMENT_MEMBERS}
            allowBlank
            updateValue={(val: string) => {
              setConditions({ ...conditions, management_members: val, page: 1 })
            }}
          />
        )}

        <div className="mb20">
          <div className="formHead">
            <input
              type="checkbox"
              id="has_career_sheet"
              checked={conditions.has_career_sheet}
              onChange={(e) =>
                setConditions({
                  ...conditions,
                  page: 1,
                  has_career_sheet: e.target.checked
                })
              }
            />
            <label className={style.searchInnerTitle + " " + style.searchInnerTitleLabel} htmlFor="has_career_sheet">
              職務経歴書ありのみ表示
            </label>
          </div>
        </div>

        <div className="mb20">
          <div className="formHead">
            <input
              type="checkbox"
              id="bookmark"
              checked={conditions.bookmark}
              onChange={(e) =>
                setConditions({
                  ...conditions,
                  page: 1,
                  bookmark: e.target.checked
                })
              }
            />
            <label className={style.searchInnerTitle + " " + style.searchInnerTitleLabel} htmlFor="bookmark">
              お気に入りのみ表示
            </label>
          </div>
        </div>

        <div className="mb20">
          <div className="formHead">
            <input
              type="checkbox"
              id="verificated"
              checked={conditions.verificated}
              onChange={(e) =>
                setConditions({
                  ...conditions,
                  page: 1,
                  verificated: e.target.checked
                })
              }
            />
            <label className={style.searchInnerTitle + " " + style.searchInnerTitleLabel} htmlFor="verificated">
              本人確認済みのみ表示
            </label>
          </div>
        </div>

        <div className="formBtnArea">
          <button className="btn btnEdit" onClick={() => setConditions(props.clearCondition())}>
            クリア
          </button>
          <button
            type="submit"
            className="btn btnSave"
            onClick={() => {
              props.search(conditions)
              if (window.innerWidth < 950) {
                setOpenStatus(false)
              }
              window.scrollTo(0, 0)
            }}
          >
            検索実行
          </button>
        </div>
      </div>
    </div>
  )
}
