import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import { ScheduleForm, IScheduleFormProps } from "../components/Schedule/ScheduleForm";
import { MEETING_KIND, MEETING_TYPE } from "../lib/Definition";
import { Action, Dispatch } from "redux";
import { IStoreState, VideoType } from "../types/state"
import { push } from "connected-react-router";
import { createWebMeeting, showAlert } from "../thunks";
import { parseQueryParams } from "../lib/Util";
import { firstInterviewPath, secondInterviewPath } from "../routes/paths";
import DateWithOffset from "../lib/DateWithOffset";

class Schedule extends React.Component<IScheduleFormProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    const result = parseQueryParams();
    const entry_id = parseInt(result["entry_id"], 10);

    dispatch(Actions.setViewState({ loaderVisible: true }));
    Promise.all([
      dispatch(
        Actions.fetchAvailableSchedules(entry_id) as any
      ),
      dispatch(Actions.fetchEntry(parseInt(result["entry_id"], 10)) as any),
    ]).then(() => dispatch(Actions.setViewState({ loaderVisible: false })));
  }
  render() {
    return <ScheduleForm {...this.props} />;
  }
}

function mapStateToProps(state: IStoreState) {
  const index = state.viewState.index || 0;
  const result = parseQueryParams();
  const entry_id = parseInt(result["entry_id"], 10);
  const entry = state.entries.filter((e) => e.id === entry_id)[0];

  if (!entry) {
    return {};
  }

  const isSecondInterview = entry?.second_interview !== null;

  const kind = isSecondInterview
    ? MEETING_KIND.SECOND_INTERVIEW
    : MEETING_KIND.FIRST_INTERVIEW;

  const type = isSecondInterview
    ? entry.second_interview?.meeting_type
    : MEETING_TYPE.ONLINE;

  const selectedDate = isSecondInterview
    ? entry.second_interview?.meeting_at
    : entry.meeting_at;

  return {
    index,
    kind,
    type,
    entry,
    availableTimes: state.availableSchedules.map((t) => t.getTime()),
    scheduleTime: state.viewState.scheduleTime,
    selectedDate,
    viewState: state.viewState
  } as IScheduleFormProps;
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    alert: (msg: string) => {
      dispatch(showAlert(msg) as any);
    },
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
    changePath: (path: string) => dispatch(push(path)),
    createMeeting: (
      entry_id: number,
      scheduleTime: number,
      video_type: VideoType,
      kind: MEETING_KIND = MEETING_KIND.FIRST_INTERVIEW
    ) => {
      dispatch(
        createWebMeeting({
          start_at: new DateWithOffset(scheduleTime),
          entry_id,
          video_type,
          way: "video",
          kind: kind
        }) as any
      ).then(() => {
        if (kind === MEETING_KIND.FIRST_INTERVIEW) {
          dispatch(push(firstInterviewPath + "/" + entry_id));
        } else {
          dispatch(push(secondInterviewPath + "/" + entry_id));
        }
      })
      .catch((err: any) => {
        dispatch(showAlert(err.payload.error.message) as any)
          .then(() => dispatch(Actions.fetchAvailableSchedules(entry_id) as any))
      });
    },
    reSchedule: (entry_id: number, message: string) => {
      dispatch(Actions.reSchedule({ entry_id, message }) as any)
        .then(() => dispatch(push("/")))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
