import { ErrorMessage } from "./common/ErrorMessage"
import { ReactNode, useMemo } from "react"

export const SelectField = (props: {
  flags?: { [key: string]: { flag: number; message: string } }
  master?: { [key: string]: string }
  label?: any
  type?: string
  name: string
  note?: string
  required?: boolean
  cls?: string
  values: any
  allowBlank?: boolean
  children?: ReactNode
  validates?: { [key: string]: (value: any) => string | null }
  updateValue: (val: string) => void
}) => {
  const flags = props.flags as {
    [key: string]: { flag: number; message: string }
  }
  const master = props.master ? props.master : {}
  if (Object.keys(master).length === 0 && !!flags) {
    Object.keys(flags).forEach((key) => {
      master[flags[key].flag] = flags[key].message
    })
  }

  const blankKey = useMemo(() => (flags ? "0" : ""), [flags])

  return (
    <div className={props.cls ? props.cls : "formRow"} id={props.name}>
      {props.label && (
        <label className="formHead">
          {props.label}
          {props.required === true ? (
            <span className="label label_req">必須</span>
          ) : props.required === false ? (
            <span className="label label_opt">任意</span>
          ) : null}
          {props.type !== "scout" && (
            <>
              <br />
            </>
          )}
          {props.note && <small>{props.note}</small>}
        </label>
      )}
      <div className="formData">
        <div className={props.type === "scout" ? "selectMinWrap" : ""}>
          <select value={props.values[props.name] ?? blankKey} onChange={(e) => props.updateValue(e.target.value)}>
            {props.allowBlank && (
              <option key={blankKey} value={blankKey}>
                指定なし
              </option>
            )}
            {Object.keys(master as { [key: string]: string }).map((key) => {
              return (
                <option key={key} value={key}>
                  {master[key]}
                </option>
              )
            })}
          </select>
        </div>
        {props.values.isSubmitted && props.validates && props.validates[props.name] && (
          <ErrorMessage>{props.validates[props.name](props.values)}</ErrorMessage>
        )}
      </div>
    </div>
  )
}
