import { TypeKeys, Actions } from "../actions";
import { IStaff } from "../types/state";
import { StaffActions, StaffTypeKeys } from "../actions/staff"

const initialState: IStaff[] = [];

const staffs = (state = initialState, action: Actions | StaffActions) => {
  switch (action.type) {
    case TypeKeys.LOGIN_SUCCESS:
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.staffs;
    }
    case StaffTypeKeys.UPDATE_STAFFS_SUCCESS: {
      return action.payload.data.staffs;
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default staffs;
