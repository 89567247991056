import * as React from "react"
import { Link } from "react-router-dom"
import { companyPath } from "../routes/paths"
import * as Definition from "../lib/Definition"
import { IUpdateCompanyParams } from "../actions"
import { NormalTextField } from "./NormalTextField"
import { IFile, IBusiness } from "../types/state"
import { LocationField } from "./LocationField"
import { SelectBitFlagField } from "./SelectBitFlagField"
import EditBusinessSummaryList from "./EditBusinessSummaryList"
import B64 from "../lib/bitwise64"
import URLListForm from "./URLListForm"
import FileUploadList from "./FileUploadList"
import { RadioField } from "./RadioField"
import { TextAreaField } from "./TextAreaField"
import { PhotoField } from "./PhotoField"
import { EditPresidentProfile } from "./EditPresidentProfile"
import { formatHyphenStr, deleteZeroWidthSpace } from "../lib/Util"
import { ErrorMessage } from "./common/ErrorMessage"
import { useMemo } from "react"

export interface IEditCompanyDispatchProps {
  alert: (content: string) => Promise<void>
  updateValue: (args: any) => void
  updateCompany: (isUpdate: boolean, params: IUpdateCompanyParams) => void
}

export interface IEditCompany {
  id: number
  name: string
  name_kana: string
  zip_code: string
  prefecture: string
  address1: string
  address2: string
  president: string
  establishment: number
  capital: number
  employee: number
  executive: number
  vision: string
  industries: number
  industry_other: string
  urls: string
  fetch_homepage: boolean
  eye_catch: IFile | null
  files: IFile[]
  businesses: IBusiness[]
  sales: string
  president_profile: string
  president_position_name: string
  president_birth_year: number
  president_hometown: string
  president_first_job_desc: string
  president_how_become: string
  president_motivation: string
  president_generation: string
  president_hobbies: string
  other: string
  isSubmitted: boolean
  spinner: boolean
  error?: string
  errorField?: string
}
export interface IEditCompanyStateProps {
  enableCancel: boolean
  company: IEditCompany
}

const MILLION = 1000000

const isValidZipCode = (zipCode: string) => {
  return /^\d{7}$/.test(formatHyphenStr(zipCode))
}

const validates: { [key: string]: (value: any) => string | null } = {}
validates["name"] = (val) => {
  return val.name ? null : "会社名を入力してください。"
}
validates["name_kana"] = (val) => {
  return val.name_kana ? null : "会社名(カナ)を入力してください。"
}
validates["zip_code"] = (val) => {
  if (!val.zip_code) return "郵便番号を入力してください。"
  if (!isValidZipCode(val.zip_code)) return "郵便番号は7桁の数字で入力してください。"
  return null
}
validates["urls"] = (val) => {
  return val.urls && !val.urls.match(/^https?:\/\//)
    ? "URLは「http://」または「https://」から始まる文字列で入力してください。"
    : null
}
validates["prefecture"] = (val) => {
  return isValidZipCode(val.zip_code) && !val.prefecture ? "正しい郵便番号を入力してください。" : null
}
validates["address1"] = (val) => {
  return val.address1 ? null : "市区町村を入力してください。"
}
validates["address2"] = (val) => {
  return val.address2 ? null : "市区町村以下を入力してください。"
}
validates["president"] = (val) => {
  return val.president ? null : "代表者名を入力してください。"
}
validates["establishment"] = (val) => {
  return val.establishment === -1 ? "設立年を入力してください。" : null
}
validates["capital"] = (val) => {
  return val.capital < 0 || val.capital >= MILLION ? "資本金を入力してください。（1兆円未満）" : null
}
validates["executive"] = (val) => {
  return val.executive === -1 ? "役員数を入力してください。" : null
}
validates["employee"] = (val) => {
  return val.employee === -1 ? "一般社員数を入力してください。" : null
}
validates["industries"] = (val) => {
  return val.industries && val.industries.toString(2).match(/1/g).length > 3
    ? "業種・業界は最大3つまでです"
    : val.industries === 0
    ? "業種・業界を選択してください。"
    : null
}
validates["industry_other"] = (val) => {
  return B64.and(val.industries, Definition.INDUSTRIES["industry_other"]["flag"]) !== 0 && !val.industry_other
    ? "業種・業界のその他を入力してください。"
    : null
}
validates["description"] = (val) => {
  return val.description ? null : "事業内容を入力してください。"
}
validates["vision"] = (val) => {
  return val.vision ? null : "ビジョンを入力してください。"
}
validates["rate"] = (val) => {
  return val.rate ? null : "概算売上比率を入力してください。"
}
validates["sales"] = (val) => {
  return val.sales ? null : "売上規模を入力してください。"
}

const checkErrors = (company: IEditCompany) => {
  return Object.keys(validates)
    .map((field) => {
      if (field === "description") {
        const errorNum: number[] = company.businesses
          .map((business, index) => (validates["description"](business) ? index + 1 : null))
          .filter((val) => val) as number[]
        return errorNum.length > 0 ? `description-${errorNum[0] - 1}` : null
      } else if (field === "rate") {
        const errorNum: number[] = company.businesses
          .map((business, index) => (validates["rate"](business) ? index + 1 : null))
          .filter((val) => val) as number[]
        return errorNum.length > 0 ? `rate-${errorNum[0] - 1}` : null
      } else {
        return validates[field](company) ? field : null
      }
    })
    .filter((val) => val)
}

export const EditCompanyForm = (props: IEditCompanyDispatchProps & IEditCompanyStateProps) => {
  const checkAndUpdate = (val: any) => {
    if (Object.keys(val)[0] === "capital") {
      val["capital"] = val["capital"] * MILLION
    }
    props.updateValue(val)
  }

  const isWaitingCapture = useMemo(() => {
    return props.company.eye_catch?.name === "waiting_capture.svg"
  }, [props.company.eye_catch])

  return (
    <main className="contentsWrapper">
      <form
        className="pure-form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault()
          const params: IUpdateCompanyParams = {
            id: props.company.id,
            name: deleteZeroWidthSpace(props.company.name), // 反社チェックで使用するためゼロ幅スペースを削除
            name_kana: props.company.name_kana,
            zip_code: props.company.zip_code,
            address1: props.company.address1,
            address2: props.company.address2,
            president: deleteZeroWidthSpace(props.company.president), // 反社チェックで使用するためゼロ幅スペースを削除
            establishment: props.company.establishment,
            capital: props.company.capital,
            employee: props.company.employee,
            executive: props.company.executive,
            vision: props.company.vision,
            industries: props.company.industries,
            industry_other: props.company.industry_other,
            urls: props.company.urls,
            fetch_homepage: props.company.fetch_homepage,
            eye_catch: props.company.eye_catch,
            files: props.company.files,
            businesses: props.company.businesses,
            sales: props.company.sales,
            president_profile: props.company.president_profile,
            president_position_name: props.company.president_position_name,
            president_birth_year: props.company.president_birth_year,
            president_hometown: props.company.president_hometown,
            president_first_job_desc: props.company.president_first_job_desc,
            president_how_become: props.company.president_how_become,
            president_motivation: props.company.president_motivation,
            president_generation: props.company.president_generation,
            president_hobbies: props.company.president_hobbies,
            other: props.company.other
          }
          const error = checkErrors({ ...props.company, capital: props.company.capital / MILLION })[0]
          if (error) {
            props.alert("入力に誤りがあります").then(() => {
              props.updateValue({ scrollID: error, isSubmitted: true })
            })
            return
          }
          props.updateCompany(props.enableCancel, {
            ...params
          })
        }}
      >
        <div className="formWrap">
          {props.company.error && !props.company.errorField && <div className="text-danger">{props.company.error}</div>}
          <NormalTextField
            cls="formWidth_m"
            name="name"
            label="会社名"
            required={true}
            placeholder="○×▲株式会社"
            values={props.company}
            error={props.company.errorField === "name" ? props.company.error : ""}
            updateValue={checkAndUpdate}
            validates={validates}
          />
          <NormalTextField
            cls="formWidth_m"
            name="name_kana"
            label="会社名(カナ)"
            required={true}
            placeholder="○×▲カブシキガイシャ"
            values={props.company}
            updateValue={checkAndUpdate}
            validates={validates}
          />
          <LocationField values={props.company} validates={validates} updateValue={checkAndUpdate} />
          <NormalTextField
            cls="formWidth_m"
            name="president"
            label="代表者名"
            required={true}
            placeholder="鈴木太郎"
            values={props.company}
            validates={validates}
            updateValue={checkAndUpdate}
          />
          <NormalTextField
            name="establishment"
            label="設立年"
            type="number"
            suffix="年"
            required={true}
            placeholder="2018"
            values={props.company}
            validates={validates}
            updateValue={checkAndUpdate}
          />
          <NormalTextField
            name="capital"
            label="資本金"
            type="step"
            suffix="百万円"
            required={true}
            placeholder="1"
            hint="NPO法人や一般社団法人等で資本金がない場合は0と入力してください"
            values={{
              ...props.company,
              capital: props.company.capital === -1 ? -1 : props.company.capital / MILLION
            }}
            validates={validates}
            updateValue={checkAndUpdate}
          />
          <NormalTextField
            name="executive"
            label="役員数"
            type="number"
            suffix="名"
            required={true}
            values={props.company}
            validates={validates}
            updateValue={checkAndUpdate}
          />
          <NormalTextField
            name="employee"
            label={
              <>
                概算社員数
                <br className="SPHide" />
                （正規・契約・
                <br className="SPHide" />
                アルバイト含む）
              </>
            }
            type="number"
            suffix="名"
            required={true}
            values={props.company}
            validates={validates}
            updateValue={checkAndUpdate}
          />
          <SelectBitFlagField
            label="業務・業界"
            values={props.company}
            name="industries"
            required={true}
            flags={Definition.INDUSTRIES}
            isSubmitted={props.company.isSubmitted}
            validates={validates}
            updateValue={(val: number) => {
              const args = { industries: val }
              if (B64.and(val, Definition.INDUSTRIES["industry_other"]["flag"]) === 0) {
                args["industry_other"] = ""
              }
              checkAndUpdate(args)
            }}
          >
            <div className="formDataBox">
              <input
                type="text"
                id="industry_other"
                value={props.company.industry_other}
                disabled={B64.and(props.company.industries, Definition.INDUSTRIES["industry_other"]["flag"]) === 0}
                onChange={(e: React.ChangeEvent<any>) => checkAndUpdate({ industry_other: e.target.value })}
              />
              {props.company.isSubmitted && validates["industry_other"] && (
                <ErrorMessage cls="mb08">{validates["industry_other"](props.company)}</ErrorMessage>
              )}
            </div>
          </SelectBitFlagField>
          <URLListForm values={props.company} validates={validates} updateValue={checkAndUpdate} />
          <div className="formRow">
            <label className="formHead">
              紹介画像
              <span className="label label_opt">任意</span>
            </label>
            <div className="formData">
              <div className="photoField">
                <div className="photoFieldWrap">
                  <input
                    type="checkbox"
                    id="check_homepage"
                    name="check_homepage"
                    className="photoFieldCheck"
                    checked={props.company.fetch_homepage}
                    disabled={!props.company.urls}
                    onChange={() => {
                      props.updateValue({
                        fetch_homepage: !props.company.fetch_homepage
                      })
                    }}
                  />
                  <label className="photoFieldLabel" htmlFor="check_homepage">
                    上記「ホームページURL」で表示されるホームページのキャプチャを使用する。
                    <br />
                    <small>※ プログラムで自動的にキャプチャー画像を取得します。</small>
                  </label>
                </div>
                {!props.company.fetch_homepage && (
                  <PhotoField
                    name="eye_catch"
                    photo={props.company.eye_catch}
                    removable={!isWaitingCapture}
                    updateValue={props.updateValue}
                  />
                )}
              </div>
            </div>
          </div>
          <FileUploadList
            files={props.company.files}
            label={
              <>
                会社案内・紹介記事等の
                <br className="SPHide" />
                ファイルアップロード
              </>
            }
            note="(複数入力可)"
            required={false}
            updateValue={(files) => checkAndUpdate({ files })}
          />
          <EditBusinessSummaryList values={props.company} validates={validates} updateValue={checkAndUpdate} />
          <RadioField
            values={props.company}
            name="sales"
            label="売上規模"
            required={true}
            master={Definition.SALES}
            validates={validates}
            updateValue={(key) => checkAndUpdate({ sales: key })}
          />
          <TextAreaField
            name="vision"
            note="地域の自然・文化・技術など、貴社が大切にしてきて、事業（商品やサービス、雇用そのものなど）を通じて次の世代にもその地域に残していきたいと考えていることをご記入ください。"
            label={
              <>
                事業を通じて守り
                <br className="SPHide" />
                次の世代へ届けたい
                <br className="SPHide" />
                大切なこと
                <br className="SPHide" />
                （ビジョン）
              </>
            }
            required={true}
            values={props.company}
            validates={validates}
            updateValue={checkAndUpdate}
          />
          <EditPresidentProfile values={props.company} validates={validates} updateValue={checkAndUpdate} />
          <TextAreaField
            name="other"
            label="その他"
            required={false}
            values={props.company}
            validates={validates}
            updateValue={checkAndUpdate}
          />

          <div className={"formBtnArea" + (props.enableCancel ? "" : " formBtnAreaSingle")}>
            {props.enableCancel && (
              <Link className="btn" to={companyPath}>
                キャンセル
              </Link>
            )}
            <button className="btn btnSave" type="submit" value="上記内容を保存">
              上記内容を保存
            </button>
          </div>
        </div>
      </form>
    </main>
  )
}
