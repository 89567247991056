import * as React from "react"

export default function Alert(props: { content: any; closeModal: () => void }) {
  return (
    <main>
      <div className="alCent mb10" dangerouslySetInnerHTML={{ __html: props.content }} />
      <div className="formBtnArea formBtnAreaSingle">
        <button className="btn btnLogin" onClick={() => props.closeModal()}>
          閉じる
        </button>
      </div>
    </main>
  )
}
