import * as React from "react";
import style from "../style/Recruitment.module.scss";
import { ISecondInterviewEntry } from "../types/state";
import { strToTag } from "../lib/Util";

export const SecondInterviewEntry = (props: {
  second_interview_entry: ISecondInterviewEntry;
  before_saved: boolean;
}) => {
  return (
    <>
      <h3 id="js-SecondInterviewSubTitle" className="contentsSubTitle">
        二次面談希望のご確認
      </h3>
      {props.before_saved ? (
        <p className={style.answer2ndText}>
          <b>
            一次面談の内容と、下記のパートナー人材の希望条件を踏まえ、
            <span className="joinsOrangeTxt">
              二次面談への実施希望有無を回答
            </span>
            してください。
          </b>
        </p>
      ) : (
        <p></p>
      )}
      <div className={style.listArea}>
        <div className={style.listRow}>
          <div className={style.listHead}>
            二次面談を希望
            <br className="SPHide" />
            された理由
          </div>
          <div className={style.listData}>
            {strToTag(props.second_interview_entry.succeed_reason)}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>最短契約開始可能日</div>
          <div className={style.listData}>
            {strToTag(props.second_interview_entry.start_period)}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>業務可能時間数／月</div>
          <div className={style.listData}>
            {strToTag(props.second_interview_entry.work_time)}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>現地訪問可能回数／月</div>
          <div className={style.listData}>
            {strToTag(props.second_interview_entry.days_per_month)}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>希望時給単価（税抜）</div>
          <div className={style.listData}>
            {strToTag(props.second_interview_entry.reward)}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>
            一次面談で感じたことで 企業にお伝えしたいこと
          </div>
          <div className={style.listData}>
            {strToTag(props.second_interview_entry.something)}
          </div>
        </div>
      </div>
    </>
  );
};
