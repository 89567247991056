import { TypeKeys, Actions } from "../actions"
import { ILicenseCategory } from "../types/state"

const initialState: ILicenseCategory[] = []

export const licenseCategories = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.license_categories
    }
    default: {
      return state
    }
  }
}
