import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { IStoreState } from "../types/state"
import { MonthlyQuestionnaireFinishInfo } from "../components/MonthlyQuestionnaireFinish/MonthlyQuestionnaireFinishInfo"

interface IStateProps {}

interface IDispatchProps {}

const MonthlyQuestionnaireFinish = () => {
  return <MonthlyQuestionnaireFinishInfo />
}

function mapStateToProps(_state: IStoreState): IStateProps {
  return {}
}

function mapDispatchToProps(_dispatch: Dispatch<Action>): IDispatchProps {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyQuestionnaireFinish)
