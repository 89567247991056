import { ActionsUnion, createAction } from "./types";
import { IEntry } from "../types/state"
import { apiURL } from "./index"

export enum EntryTypeKeys {
  UPDATE_ENTRY = "UPDATE_ENTRY",
  UPDATE_ENTRY_SUCCESS = "UPDATE_ENTRY_SUCCESS",
  UPDATE_ENTRY_FAIL = "UPDATE_ENTRY_FAIL"
}

const updateEntry = (entry: IEntry) =>
  createAction(EntryTypeKeys.UPDATE_ENTRY, {
    request: {
      data: entry,
      method: "put",
      url: `${apiURL}/entries/${entry.id}`,
    }
  })

type updateEntrySuccess = {
  type: EntryTypeKeys.UPDATE_ENTRY_SUCCESS;
  payload: {
    data: IEntry;
  };
};

type AutoGenerateActions =
  | updateEntrySuccess;

export const EntryActions = {
  updateEntry
}

export type EntryActions =
  | ActionsUnion<typeof EntryActions>
  | AutoGenerateActions;
