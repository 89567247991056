const Zeros = "00000000000000000000000000000000";
const Hi = 0;
const Lo = 1;

function divide(bit: number) {
  const bitString = (Zeros + Zeros + Number(bit).toString(2)).slice(-64);
  return [
    parseInt(bitString.slice(0, 32), 2), // hi
    parseInt(bitString.slice(-32), 2) // lo
  ];
}

function pad(bit: number) {
  return (Zeros + bit.toString(2)).slice(-32);
}

export default class B64 {
  static and(a: number, b: number) {
    const _a = divide(a);
    const _b = divide(b);
    return parseInt(
      pad((_a[Hi] & _b[Hi]) >>> 0) + pad((_a[Lo] & _b[Lo]) >>> 0),
      2
    );
  }

  static or(a: number, b: number) {
    const _a = divide(a);
    const _b = divide(b);
    return parseInt(
      pad((_a[Hi] | _b[Hi]) >>> 0) + pad((_a[Lo] | _b[Lo]) >>> 0),
      2
    );
  }

  static not(a: number) {
    const _a = divide(a);
    return parseInt(pad(~_a[Hi] >>> 0) + pad(~_a[Lo] >>> 0), 2);
  }

  static xor(a: number, b: number) {
    const _a = divide(a);
    const _b = divide(b);
    return parseInt(
      pad((_a[Hi] ^ _b[Hi]) >>> 0) + pad((_a[Lo] ^ _b[Lo]) >>> 0),
      2
    );
  }
}
