import * as React from "react";
import { IProblem } from "../../types/state"
import { addDate, dateStr } from "../../lib/Util"

export interface IAboutMeetingDispatchProps {}

export interface IAboutMeetingStateProps {
  problem: IProblem;
}

export interface IAboutMeetingProps extends IAboutMeetingDispatchProps, IAboutMeetingStateProps {}

export const AboutMeeting = ({
  problem
}: IAboutMeetingProps) => {
  return (
    <div className="taskDesc">
      Web面談については
      <b className="joinsOrangeTxt">貴社に費用負担はございません</b>。
      <br />
      パートナー人材にとっても移動時間もなく負担が少ない方法ですので、
      <br className="SPHide" />
      <b className="joinsOrangeTxt">
        まずはお話を聞いてみたい、という応募者がいればお気軽にWeb面談をご希望ください。
      </b>
      <br />
      <p className="taskDescExpireDate">
        面談希望回答期限: {dateStr(addDate(problem.expired_date, 7))}
      </p>
      <p className="joinsGreenTxt fz14">
        <b>
          応募期限・回答期限前に一次面談希望有無の回答を随時して頂いても構いません。
        </b>
      </p>
      <b>
        面談日程につきましてはパートナー人材より追って候補日の連絡がございます。
      </b>
    </div>
  )
}
