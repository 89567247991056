import * as React from "react"
import { PartnerProfileInfo } from "./PartnerProfileInfo"
import { IPartner, IRecruitment } from "../types/state"
import style from "../style/Recruitment.module.scss"
import { ScoutForm } from "./ScoutForm"
import { PartnerManagementHeader } from "./headers/PartnerManagementHeader"
import { UnConfirmationToRequestInterview } from "./UnConfirmationToRequestInterview"
import { PartnerSkillInfo } from "./PartnerSkillInfo"
import { PartnerFavoriteInfo } from "./PartnerFavoriteInfo"
import { PartnerWithdrawInfo } from "./PartnerWithdrawInfo"
import { isWithdrawn } from "../lib/Util"
import { PartnerScoutInfo } from "./PartnerScoutInfo"
import { RECRUITMENT_STATUS_EN } from "../lib/Definition"
import { useCallback, useMemo, useState } from "react"
import { usePartnerProblems } from "../hooks/usePartnerProblems"

const ACTION_TYPE = {
  show: "show",
  confirm: "confirm"
}

export interface IPartnerDispatchProps {
  dispatch: (args: any) => void
  updateBookmark: (id: number, bookmarked: boolean) => void
  createScout: (partnerID: number, problemId: number, message: string) => void
}

export interface IPartnerStateProps {
  partner: IPartner
  recruitment: IRecruitment
  canEntry: boolean
  isNeededVerify: boolean
}

export interface IPartnerProps extends IPartnerDispatchProps, IPartnerStateProps {}

export const PartnerInfo = (props: IPartnerProps) => {
  const [actionType, setActionType] = useState(ACTION_TYPE.show)
  const partner: IPartner = props.partner

  const showWithdrawInfo = useMemo(() => {
    if (!partner) return false
    return isWithdrawn(partner)
  }, [partner])

  const { hasActiveProblem, scoutableProblems, isEnteredForAllProblems } = usePartnerProblems(props.partner)

  const scoutable = React.useMemo(() => {
    return props.recruitment.status === RECRUITMENT_STATUS_EN.active && !!scoutableProblems.length
  }, [props.recruitment, scoutableProblems])

  const { updateBookmark } = props
  const onClickBookmark = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()
      updateBookmark(partner.id, !partner.bookmarked)
    },
    [partner, updateBookmark]
  )

  if (!partner) return null

  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 className="contentsTitle contentsTitlePartner">
        人材ID：{partner.id}
        {partner.verificated && <span className={style.verificationIconPartner}>本人確認済み</span>}
        <br className="PCHide" />
        {partner.bookmarked && (
          <div onClick={onClickBookmark} className={style.addFavor + " " + style.addFavorChecked}>
            お気に入り<span className={style.addFavorStar}>★</span>
          </div>
        )}
        {!partner.bookmarked && (
          <div onClick={onClickBookmark} className={style.addFavor}>
            お気に入りに追加<span className={style.addFavorStar}>★</span>
          </div>
        )}
      </h2>
      {showWithdrawInfo && <PartnerWithdrawInfo partner={partner} />}
      {!showWithdrawInfo && (
        <>
          {actionType === ACTION_TYPE.confirm && (
            <ScoutForm
              scoutableProblems={scoutableProblems}
              isEntriedBefore={false}
              isNeededVerify={props.isNeededVerify}
              hasActiveProblem={hasActiveProblem}
              isEnteredForAllProblems={isEnteredForAllProblems}
              onClickBackButton={() => setActionType(ACTION_TYPE.show)}
              onClickSave={(problemId, message) => {
                props.createScout(partner.id, problemId, message)
              }}
            />
          )}
          <PartnerScoutInfo partner={partner} />
          <PartnerProfileInfo
            {...partner}
            noNameMessage="人材側が貴社との一次面談（Web会議）を承諾した場合に開示されます。"
          />
          <PartnerSkillInfo {...partner} career_sheet={null} />
          <PartnerFavoriteInfo {...partner.favorite} />
          <UnConfirmationToRequestInterview
            canEntry={props.canEntry}
            isNeededVerify={props.isNeededVerify}
            hasActiveProblem={hasActiveProblem}
            isEnteredForAllProblems={isEnteredForAllProblems}
          />
          {actionType === ACTION_TYPE.show && (
            <>
              {scoutable && (
                <div className={"formBtnArea"}>
                  <button className={"btn btnEdit"} onClick={() => window.history.back()}>
                    一覧に
                    <br className="PCHide" />
                    戻る
                  </button>
                  <button
                    className="btn btnSave"
                    disabled={!props.canEntry || props.isNeededVerify}
                    onClick={() => {
                      setActionType(ACTION_TYPE.confirm)
                      window.scrollTo(0, 0)
                    }}
                  >
                    一次面談
                    <br className="PCHide" />
                    （Web会議）を
                    <br className="PCHide" />
                    申し込む
                  </button>
                </div>
              )}
              {!scoutable && (
                <>
                  <div className={"formBtnArea formBtnAreaSingle"}>
                    <button className="btn btnEdit" onClick={() => window.history.back()}>
                      一覧に戻る
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
