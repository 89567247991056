import * as React from "react"
import { ChangeEvent, useMemo } from "react"
import style from "../../style/questionnaireForm.module.scss"
import { CheckBoxes } from "../CheckBoxes"
import { ErrorMessage } from "../common/ErrorMessage"

export interface ISatisfiedPointsFieldDispatchProps {
  updateValue: (value: ChangeEvent<HTMLInputElement>) => void
  updateOther: (value: ChangeEvent<HTMLInputElement>) => void
}

export interface ISatisfiedPointsFieldStateProps {
  title: string
  name: string
  values: string[]
  other: string
  master: { [key: string]: string }
  masterKeys: { [key: string]: string }
  altValues: string[]
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
}

export interface ISatisfiedPointsFieldProps
  extends ISatisfiedPointsFieldDispatchProps,
    ISatisfiedPointsFieldStateProps {}

export const SatisfiedPointsField = ({
  title,
  name,
  values,
  other,
  master,
  masterKeys,
  altValues,
  isSubmitted,
  validates,
  updateValue,
  updateOther
}: ISatisfiedPointsFieldProps) => {
  const defaultDisabledKeys = useMemo(() => {
    return Object.keys(masterKeys).filter((key) => key !== masterKeys.none)
  }, [masterKeys])

  const isSatisfactionNoneChecked = useMemo(() => values.includes(masterKeys.none), [values, masterKeys])
  const isSatisfactionOtherChecked = useMemo(() => values.includes(masterKeys.other), [values, masterKeys])

  const disabledKeys = useMemo(() => {
    if (isSatisfactionNoneChecked) return defaultDisabledKeys
    return defaultDisabledKeys.filter((key) => key !== masterKeys.other && altValues.includes(key))
  }, [isSatisfactionNoneChecked, defaultDisabledKeys, masterKeys, altValues])

  const singularName = useMemo(() => name.replace(/s$/, ""), [name])

  return (
    <div className="formRow">
      <label className="formHead">
        <span>
          {title}
          <br />
          (複数回答)
        </span>
        <span className="label label_req">必須</span>
      </label>
      <div className="formData normalTxt">
        <CheckBoxes name={name} values={values} master={master} disabled={disabledKeys} onChange={updateValue} />
        <input
          type="text"
          className={style.satisfiedOtherInput}
          value={other}
          disabled={!isSatisfactionOtherChecked}
          onChange={updateOther}
        />
        {isSubmitted && (
          <ErrorMessage cls="mb08">
            {validates[name]({ [name]: values, [`${singularName}_other`]: other })}
          </ErrorMessage>
        )}
      </div>
    </div>
  )
}
