import React from "react"
import { ProblemContentsType, PUBLISH_TYPE_OPEN_STATUS } from "../../lib/Definition"
import { PublishType } from "../../types/state"
import styles from "../../style/PublicStatusLabel.module.scss"

interface Props {
  publishType: PublishType
  problemContentsType: ProblemContentsType
}

export const PublicStatusLabel: React.FC<Props> = ({ publishType, problemContentsType }) => {
  const memberStatus = PUBLISH_TYPE_OPEN_STATUS[publishType][problemContentsType].member
  const nonMemberStatus = PUBLISH_TYPE_OPEN_STATUS[publishType][problemContentsType].nonMember
  return (
    <div className={styles.publicStatusWrap}>
      <span className={styles.publicStatusLabel}>会員：</span>
      <span className={memberStatus === "公開" ? styles.publicStatusValue : styles.privateStatusValue}>
        {memberStatus}
      </span>
      <span className={styles.publicStatusLabel}>非会員：</span>
      <span className={nonMemberStatus === "公開" ? styles.publicStatusValue : styles.privateStatusValue}>
        {nonMemberStatus}
      </span>
    </div>
  )
}
