declare global {
  interface Window {
    unescape: (arg: string) => string;
    escape: (arg: string) => string;
  }
}
const docCookies = {
  getItem: function(sKey: string) {
    if (!sKey || !this.hasItem(sKey)) {
      return null;
    }
    return window.unescape(
      document.cookie.replace(
        new RegExp(
          "(?:^|.*;\\s*)" +
            // eslint-disable-next-line
            window.escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
            "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"
        ),
        "$1"
      )
    );
  },
  setItem: function(
    sKey: string,
    sValue: any,
    vEnd: any = null,
    sPath = "",
    sDomain: any = null,
    bSecure: any = null
  ) {
    // eslint-disable-next-line
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return;
    }
    let sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? "; expires=Tue, 19 Jan 2038 03:14:07 GMT"
              : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toGMTString();
          break;
      }
    }
    document.cookie =
      window.escape(sKey) +
      "=" +
      window.escape(sValue) +
      sExpires +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "") +
      (bSecure ? "; secure" : "");
  },
  removeItem: function(sKey: string, sPath = "") {
    if (!sKey || !this.hasItem(sKey)) {
      return;
    }
    document.cookie =
      window.escape(sKey) +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
      (sPath ? "; path=" + sPath : "");
  },
  hasItem: function(sKey: string) {
    return new RegExp(
      // eslint-disable-next-line
      "(?:^|;\\s*)" +
        // eslint-disable-next-line
        window.escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") +
        "\\s*\\="
    ).test(document.cookie);
  },
  keys: /* optional method: you can safely remove it! */ function() {
    const aKeys = document.cookie
      // eslint-disable-next-line
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "")
      // eslint-disable-next-line
      .split(/\s*(?:\=[^;]*)?;\s*/);
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = window.unescape(aKeys[nIdx]);
    }
    return aKeys;
  }
};

export default docCookies;
