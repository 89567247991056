import * as React from "react";
import { useState } from "react"
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { EntryFirstPsychologicalSafetyList, IEntryFirstPsychologicalSafetyListDispatchProps } from "../components/EntryFirstPsychologicalSafetyList";
import { IStoreState, IFirstPsychologicalSafety } from "../types/state";
import { fetchEntryFirstPsychologicalSafeties } from "../thunks";

const EntryFirstPsychologicalSafeties = (props: {
  fetchEntryFirstPsychologicalSafeties: (
    entry_id: number
  ) => Promise<{ payload: { data: IFirstPsychologicalSafety[] } }>;
  firstPsychologicalSafeties: IFirstPsychologicalSafety[];
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const fetchPsychologicalSafeties = props.fetchEntryFirstPsychologicalSafeties;

  React.useEffect(() => {
    const entryId = Number(window.location.href.split("/").pop()?.replace(/\?.*/, ""))
    fetchPsychologicalSafeties(entryId)
      .finally(() => {
        setIsLoaded(true)
      })
  }, [fetchPsychologicalSafeties]);

  if (!isLoaded) return null

  return (
    <EntryFirstPsychologicalSafetyList
      firstPsychologicalSafeties={props.firstPsychologicalSafeties}
    />
  );
};

function mapStateToProps(state: IStoreState) {
  return { firstPsychologicalSafeties: state.firstPsychologicalSafeties };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action<any>>
): IEntryFirstPsychologicalSafetyListDispatchProps & {
  fetchEntryFirstPsychologicalSafeties: (
    entry_id: number
  ) => Promise<{ payload: { data: IFirstPsychologicalSafety[] } }>;
} {
  return {
    fetchEntryFirstPsychologicalSafeties: (entry_id: number) => {
      return dispatch(fetchEntryFirstPsychologicalSafeties(entry_id) as any);
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryFirstPsychologicalSafeties);
