import * as React from "react"
import { connect } from "react-redux"
import { Dispatch, Action } from "redux"
import { IStoreState } from "../types/state"
import {
  IMonthlyQuestionnaireFormDispatchProps,
  IMonthlyQuestionnaireFormProps,
  MonthlyQuestionnaireForm
} from "../components/MonthlyQuestionnaire/MonthlyQuestionnaireForm"
import { IMonthlyQuestionnaireParams, MonthlyQuestionnaireActions } from "../actions/monthly_questionnaire"
import { useEffect, useMemo, useState } from "react"
import { Error404Page } from "../components/Error404Page"
import { showAlert } from "../thunks"
import { createMonthlyQuestionnaire } from "../thunks/monthly_quetionnaire"
import { Actions } from "../actions"

interface IStateProps {}

interface IDispatchProps {
  fetchMonthlyQuestionnaire: (entryId: number, year: number, month: number) => Promise<{}>;
}

interface IProps extends IStateProps, IDispatchProps {}

const MonthlyQuestionnaire = ({
  fetchMonthlyQuestionnaire,
  submitMonthlyQuestionnaire,
  alert
}: IProps & IMonthlyQuestionnaireFormProps) => {
  const [notFound, setNotFound] = useState(false)

  const { entryId, year, month } = useMemo(() => {
    const paths = window.location.href.replace(/\/$/, "").split("/")
    const month = parseInt(paths.pop() as string)
    const year = parseInt(paths.pop() as string)
    const entryId = parseInt(paths.pop() as string)
    return { entryId, year, month }
  }, [])

  useEffect(() => {
    fetchMonthlyQuestionnaire(entryId, year, month).catch((e) => {
      if (e.payload.error.status === 404) {
        setNotFound(true)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (notFound) return <Error404Page />

  return (
    <MonthlyQuestionnaireForm
      submitMonthlyQuestionnaire={submitMonthlyQuestionnaire}
      alert={alert}
    />
  )
}

function mapStateToProps(_state: IStoreState): IStateProps {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch<Action>): IDispatchProps | IMonthlyQuestionnaireFormDispatchProps {
  return {
    fetchMonthlyQuestionnaire: (entryId: number, year: number, month: number) => {
      dispatch(Actions.setViewState({ error: "" }))
      return dispatch(MonthlyQuestionnaireActions.fetchMonthlyQuestionnaire(entryId, year, month) as any)
    },
    submitMonthlyQuestionnaire: (params: IMonthlyQuestionnaireParams) => {
      dispatch(Actions.setViewState({ error: "" }))
      return dispatch(createMonthlyQuestionnaire(params) as any)
    },
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyQuestionnaire)
