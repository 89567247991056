import style from "../../../style/MeetingRequestForm.module.scss"
import secondStyle from "../../../style/DateTimeSelector.module.scss"
import * as React from "react"
import { dateStr, dayStr, getToday, monthDateStr, toTimeStr } from "../../../lib/Util"

const DATE_COUNT = 5

export interface IScheduleDateSelector {
  updateValue: (state: { [key: string]: any }) => void
  setReschedule: (value: boolean) => any
  reschedule: boolean
  scheduleTime: number
  availableTimes: number[]
  label: React.ReactNode
  todayLabel?: boolean
}

export const ScheduleDateSelector = (props: IScheduleDateSelector) => {
  const [index, setIndex] = React.useState(0)

  const now = new Date().getTime()
  const timeSlots = [...props.availableTimes]
  while (timeSlots.length > 0 && timeSlots[0] < now) {
    timeSlots.shift()
  }

  if (timeSlots.length === 0) {
    return null
  }
  const dateSet = [] as { date: Date; times: number[] }[]
  let cur = timeSlots.shift()
  while (cur) {
    const firstDate = new Date(cur)
    const times = [] as number[]
    while (cur && dateStr(new Date(cur)) === dateStr(firstDate)) {
      times.push(cur)
      cur = timeSlots.shift()
    }
    dateSet.push({ date: firstDate, times })
  }
  const currents = dateSet.slice(index * DATE_COUNT, (index + 1) * DATE_COUNT)
  const maxIndex = Math.ceil(dateSet.length / DATE_COUNT) - 1
  const today = getToday()

  return (
    <div className={style.selectArea}>
      {props.label}
      <div>
        <div className={secondStyle.navigation}>
          <div
            style={{ display: maxIndex === 0 ? "none" : "block" }}
            className={secondStyle.prevButton + ` ${index > 0 ? "" : secondStyle.disabled}`}
            onClick={() => {
              if (index > 0) {
                setIndex(index - 1)
              }
            }}
          >
            前へ
          </div>

          <label className={secondStyle.noChoice}>
            <input
              type="radio"
              value="reschedule"
              name="scheduleTime"
              checked={!props.scheduleTime && props.reschedule}
              onChange={() => {
                if (!props.reschedule) {
                  props.updateValue({
                    scheduleTime: null
                  })
                  props.setReschedule(true)
                }
              }}
            />
            <span>
              面談可能な日時が
              <br className="PCHide" />
              下記に無い場合
            </span>
          </label>

          <div
            style={{ display: maxIndex === 0 ? "none" : "block" }}
            className={secondStyle.nextButton + ` ${index < maxIndex ? "" : secondStyle.disabled}`}
            onClick={() => {
              if (index < maxIndex) {
                setIndex(index + 1)
              }
            }}
          >
            次へ
          </div>
        </div>

        <div className={secondStyle.calendar}>
          {currents.map((d) => {
            return (
              <div className={secondStyle.col} key={d.date.getTime()}>
                <div className={secondStyle.date}>{monthDateStr(d.date)}</div>
                <div className={secondStyle.weekWrap}>
                  <span
                    className={
                      d.date.getDay() === 6
                        ? secondStyle.week + " " + secondStyle.sat
                        : d.date.getDay() === 0
                        ? secondStyle.week + " " + secondStyle.sun
                        : secondStyle.week
                    }
                  >
                    {dayStr(d.date)}
                  </span>
                  {props.todayLabel && dateStr(d.date) === dateStr(today) ? (
                    <span className={secondStyle.label}>今日</span>
                  ) : (
                    ""
                  )}
                </div>
                {d.times.map((t) => {
                  return (
                    <div className={secondStyle.radio} key={t}>
                      <label>
                        <input
                          type="radio"
                          name="select_time"
                          checked={props.scheduleTime === t}
                          onChange={(e: React.ChangeEvent<any>) => {
                            if (e.currentTarget.checked) {
                              props.setReschedule(false)
                              props.updateValue({
                                scheduleTime: t
                              })
                            }
                          }}
                        />
                        <span>{toTimeStr(t)}</span>
                      </label>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
