import * as React from "react";
import { IEntry, IStoreState } from "../../types/state"
import { PartnerManagementHeader } from "../headers/PartnerManagementHeader";
import { PartnerInfoHeader } from "../headers/PartnerInfoHeader";
import { InterviewIntroMessage } from "../interviewInfo/InterviewIntroMessage";
import { MEETING_KIND, VIDEO_TYPE } from "../../lib/Definition"
import { MeetingInfoTable } from "../interviewInfo/MeetingInfoTable";
import { InterviewFAQ } from "../interviewInfo/InterviewFAQ";
import { ContactJoins } from "../interviewInfo/ContactJoins";
import { Reschedule } from "../interviewInfo/Reschedule"
import { StartMeetingButton } from "../interviewInfo/StartMeetingButton"
import { useMemo } from "react"
import { useSelector } from "react-redux"

interface ISecondInterviewInfoDispatchProps {
  reSchedule: (entry_id: number, message: string) => void;
}

interface ISecondInterviewInfoStateProps {
  entry: IEntry;
}

export interface ISecondInterviewInfoProps extends ISecondInterviewInfoDispatchProps, ISecondInterviewInfoStateProps {}

export const SecondInterviewInfo = ({ entry, reSchedule }: ISecondInterviewInfoProps) => {
  const company = useSelector((state: IStoreState) => state.company)

  const secondInterview = useMemo(() => entry?.second_interview, [entry])

  const isBellbirdAvailable = useMemo(() => {
    if (!secondInterview) return false
    return secondInterview.video_type === VIDEO_TYPE.bellbird_video
      || (secondInterview.video_type === VIDEO_TYPE.other_video && !!secondInterview.bellbird_meeting_url)
  }, [secondInterview])

  const videoMeetingUrl = useMemo(() => {
    if (!entry || !secondInterview || !isBellbirdAvailable) return ""
    return secondInterview.bellbird_meeting_url
  }, [entry, secondInterview, isBellbirdAvailable])

  const videoSettingUrl = useMemo(() => {
    if (!entry || !secondInterview || !isBellbirdAvailable) return ""
    return company.bellbird_setting_url
  }, [entry, secondInterview, company, isBellbirdAvailable])

  if (!entry || !secondInterview) return null

  return (
    <>
      <div className="contentsWrapper-transparent">
        <div>
          <PartnerManagementHeader />
          <PartnerInfoHeader entry={entry} />
          <InterviewIntroMessage
            kind={MEETING_KIND.SECOND_INTERVIEW}
            type={secondInterview.meeting_type}
          />
          <MeetingInfoTable
            entry={entry}
            kind={MEETING_KIND.SECOND_INTERVIEW}
            type={secondInterview.meeting_type}
            videoType={secondInterview.video_type}
            meetingAt={secondInterview.meeting_at}
            videoMeetingUrl={videoMeetingUrl}
            videoSettingUrl={videoSettingUrl}
          />
          <InterviewFAQ />
          <Reschedule reSchedule={reSchedule} entry={entry} />
          <ContactJoins />
        </div>
      </div>
      {secondInterview.meeting_at && (
        <StartMeetingButton
          meetingType={secondInterview.meeting_type}
          videoType={secondInterview.video_type}
          meetingAt={secondInterview.meeting_at}
          videoMeetingUrl={videoMeetingUrl}
        />
      )}
    </>
  )
}
