import * as React from "react";
import { Link } from "react-router-dom";
import { homePath } from "../routes/paths";

type Props = {
  errorCode: number
}

export const HttpErrorPage: React.FC<Props> = ({ errorCode }) => {
  return (
    <main className="contentsWrapper-transparent">
      <div className="container">
        <h2 className="contentsTitle">
          HTTP { errorCode } エラー
        </h2>
        <h2 className="contentsTitle">
          アクセスしようとしたページが表示できませんでした。
        </h2>
        <p>
          しばらく時間をおいてから、再度お試しください。
        </p>
        <p>
          問題が解決されない場合は画面下の
          <strong>お問い合わせ</strong>よりご連絡ください。
        </p>
        <p>
          <Link to={homePath}>ホーム画面に戻る</Link>
        </p>
      </div>
    </main>
  )
}
