import { connect } from "react-redux";
import { IStoreState } from "../types/state";
import { CompanyList } from "../components/CompanyList";

function mapStateToProps(state: IStoreState) {
  return {
    company: state.company
  };
}

export default connect(mapStateToProps, null)(CompanyList);
