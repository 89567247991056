export const MILLISECONDS_PER_MINUTE = 60 * 1000;
export const OFFSET_SUFFIX = /(((GMT)?[+-]\d\d:?\d\d)|Z)(\s*\(.+\))?$/;

const applyOffset = (date: Date, offset: number) => {
  const d = new Date();
  d.setTime(date.getTime() + MILLISECONDS_PER_MINUTE * offset);
  return d;
};

const buildDate = (args: any, offset: number) => {
  let date: Date;

  if (args.length === 0 || (args.length === 1 && args[0] == null)) {
    return new Date();
  }

  if (args.length === 1 && args[0] instanceof Date) {
    return args[0];
  }
  if (args.length === 1 && typeof args[0] === "number") {
    return new Date(args[0]);
  }

  if (args.length > 1) {
    args[3] = args[3] || null;
    args[4] = args[4] || null;
    args[5] = args[5] || null;
    args[6] = args[6] || null;

    date = new Date(
      args[0],
      args[1],
      args[2],
      args[3],
      args[4],
      args[5],
      args[6]
    );
    return applyOffset(date, -date.getTimezoneOffset() - offset);
  }

  const string = args[0].toString();
  date = new Date(string);
  const isYYYYmmdd = /\d\d\d\d-\d\d-\d\d/.test(string);
  const isOffsetSpecified = OFFSET_SUFFIX.test(string);
  const isLocal = !isYYYYmmdd && !isOffsetSpecified;

  if (isLocal) {
    date = applyOffset(date, -date.getTimezoneOffset() - offset);
  }

  return date;
};

const formattedOffset = (offsetInMinutes: number) => {
  const sign = offsetInMinutes >= 0 ? "+" : "-";
  offsetInMinutes = Math.abs(offsetInMinutes);
  const hours = Math.floor(offsetInMinutes / 60);
  const minutes = offsetInMinutes - 60 * hours;
  const h = hours < 10 ? `0${hours}` : `${hours}`;
  const m = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `GMT${sign}${h}${m}`;
};

export default class DateWithOffset {
  static __offset = 540;
  public _offset: () => number;
  public _date: () => Date;

  constructor(...args: any[]) {
    const offset = DateWithOffset.__offset;
    const date = new Date(buildDate(args, offset));
    this._date = () => date;
    this._offset = () => offset;
  }

  localDate() {
    return applyOffset(this._date(), this._offset());
  }
  withOffset(offset: number) {
    return new DateWithOffset(this.getTime(), offset);
  }
  getTime() {
    return this._date().getTime();
  }
  getTimezoneOffset() {
    return -this._offset();
  }
  toISOString() {
    return this._date().toISOString();
  }
  valueOf() {
    return this.getTime();
  }
  toJSON() {
    return this.toISOString();
  }
  toString() {
    const localDate = this.localDate();
    const plusBrowserOffset = applyOffset(
      localDate,
      localDate.getTimezoneOffset()
    );
    const asString = plusBrowserOffset.toString();
    return asString.replace(OFFSET_SUFFIX, formattedOffset(this._offset()));
  }

  getYear() {
    return this.localDate().getUTCFullYear() - 1900;
  }

  setYear(year: number) {
    return this.setFullYear(1900 + year);
  }

  setTime(time: any): any {
    this._date = () => {
      return new Date(time);
    };
    return this as any;
  }
  setDate(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCDate(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCDate(newValue: number) {
    const date = this._date();
    date.setUTCDate(newValue);
    return this.setTime(date);
  }
  getDate() {
    return this.localDate().getUTCDate();
  }
  getUTCDate() {
    return this._date().getUTCDate();
  }
  getDay() {
    return this.localDate().getUTCDay();
  }
  getUTCDay() {
    return this._date().getUTCDay();
  }
  setFullYear(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCFullYear(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCFullYear(newValue: number) {
    const date = this._date();
    date.setUTCFullYear(newValue);
    return this.setTime(date);
  }
  getFullYear() {
    return this.localDate().getUTCFullYear();
  }
  getUTCFullYear() {
    return this._date().getUTCFullYear();
  }
  setHours(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCHours(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCHours(newValue: number) {
    const date = this._date();
    date.setUTCHours(newValue);
    return this.setTime(date);
  }
  getHours() {
    return this.localDate().getUTCHours();
  }
  getUTCHours() {
    return this._date().getUTCHours();
  }
  setMilliseconds(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCMilliseconds(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCMilliseconds(newValue: number) {
    const date = this._date();
    date.setUTCMilliseconds(newValue);
    return this.setTime(date);
  }
  getMilliseconds() {
    return this.localDate().getUTCMilliseconds();
  }
  getUTCMilliseconds() {
    return this._date().getUTCMilliseconds();
  }
  setMinutes(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCMinutes(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCMinutes(newValue: number) {
    const date = this._date();
    date.setUTCMinutes(newValue);
    return this.setTime(date);
  }
  getMinutes() {
    return this.localDate().getUTCMinutes();
  }
  getUTCMinutes() {
    return this._date().getUTCMinutes();
  }
  setMonth(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCMonth(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCMonth(newValue: number) {
    const date = this._date();
    date.setUTCMonth(newValue);
    return this.setTime(date);
  }
  getMonth() {
    return this.localDate().getUTCMonth();
  }
  getUTCMonth() {
    return this._date().getUTCMonth();
  }
  setSeconds(newValue: number) {
    const localDate = this.localDate();
    localDate.setUTCSeconds(newValue);
    return this.setTime(applyOffset(localDate, -this._offset()));
  }
  setUTCSeconds(newValue: number) {
    const date = this._date();
    date.setUTCSeconds(newValue);
    return this.setTime(date);
  }
  getSeconds() {
    return this.localDate().getUTCSeconds();
  }
  getUTCSeconds() {
    return this._date().getUTCSeconds();
  }
  toDateString() {
    return this._date().toDateString();
  }
  toTimeString() {
    return this._date().toTimeString();
  }
  toLocaleDateString() {
    return this.localDate().toLocaleDateString();
  }
  toLocaleTimeString() {
    return this.localDate().toLocaleTimeString();
  }
  toUTCString() {
    return this._date().toUTCString();
  }
  [Symbol.toPrimitive](hint: string) {
    return this._date()[Symbol.toPrimitive](hint) as any;
  }
}
