import * as React from "react"
import { IStoreState } from "../../types/state"
import { useSelector } from "react-redux"
import { IProblemFormState } from "./index"

export const CategoryOtherForm = (props: {
  problem: IProblemFormState
  updateValue: (args: { [key: string]: any }) => void
}) => {
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)
  const categoryDetailOther = problemCategoryDetails.find(
    (pc) => props.problem.problem_category_details.includes(pc.slug) && pc.other_flag
  )

  return (
    <div id="section_other" className="mb20mb16">
      <div>
        <label className="mb10">
          {categoryDetailOther ? `「${categoryDetailOther.name}」` : "「その他」の業務"}
          の詳細名を記入してください (20文字以内)
        </label>
      </div>
      <input
        type="text"
        value={props.problem.section_other}
        className="formWidth_l"
        placeholder="例：Webデザイン"
        onChange={(e: React.ChangeEvent<any>) => {
          props.updateValue({
            ...props.problem,
            section_other: e.target.value
          })
        }}
      />
    </div>
  )
}
