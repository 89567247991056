import * as React from "react";
import { IFirstPsychologicalSafety } from "../types/state";
import { Link } from "react-router-dom";
import { editFirstPsychologicalSafetyPath } from "../routes/paths";
import { isEmptyString } from "../lib/Util";
import style from "../style/Psychology.module.scss";

export interface IFirstPsychologicalSafetyInfoDispatchProps {
  changePath: (path: string) => void;
}

export interface IFirstPsychologicalSafetyInfoStateProps {
  firstPsychologicalSafety: IFirstPsychologicalSafety;
}

export interface IFirstPsychologicalSafetyInfoProps
  extends IFirstPsychologicalSafetyInfoDispatchProps,
    IFirstPsychologicalSafetyInfoStateProps {}

const FirstPsychologicalSafetyInfo = (props: IFirstPsychologicalSafetyInfoProps) => {
  return (
    <main className="contentsWrapper">
      <h2 className={style.partnerTopTitle}>
        一次面談向け
        <br className="PCHide" />
        心理的安全性プログラム
      </h2>
      <div className="formWrap">
        <div className="formWrap">
          <div className="formRow">
            <label className="formHead">
              好きなことやものを
              <br className="SPHide" />
              3個以上教えてください
            </label>
            <div className="formData">
              {isEmptyString(props.firstPsychologicalSafety.like) && "未入力"}
              {props.firstPsychologicalSafety.like}
            </div>
          </div>
          <div className="formRow">
            <label className="formHead">
              業務を進める上で、特に自分の能力を発揮できる、
              <br className="SPHide" />
              頑張れる業務内容・仕事の進め方を教えてください
            </label>
            <div className="formData">
              {isEmptyString(props.firstPsychologicalSafety.specialties_and_proceeding) && "未入力"}
              {props.firstPsychologicalSafety.specialties_and_proceeding}
            </div>
          </div>
          <div className="formRow">
            <label className="formHead">
              業務を進める上で、これだけは苦手、あるいはストレスがかかる
              <br className="SPHide" />
              業務内容・仕事の進め方を教えてください
            </label>
            <div className="formData">
              {isEmptyString(props.firstPsychologicalSafety.weak) && "未入力"}
              {props.firstPsychologicalSafety.weak}
            </div>
          </div>
          <div className="formBtnArea formBtnAreaSingle">
            <Link className="btn btnEdit" to={editFirstPsychologicalSafetyPath}>
              修正・変更する
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FirstPsychologicalSafetyInfo;
