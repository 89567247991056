import * as React from "react";
import { IEntry, IStoreState } from "../../types/state"
import { PartnerManagementHeader } from "../headers/PartnerManagementHeader";
import { MEETING_KIND, MEETING_TYPE, VIDEO_TYPE } from "../../lib/Definition"
import { PartnerInfoHeader } from "../headers/PartnerInfoHeader"
import { InterviewIntroMessage } from "../interviewInfo/InterviewIntroMessage"
import { MeetingInfoTable } from "../interviewInfo/MeetingInfoTable"
import { ContactJoins } from "../interviewInfo/ContactJoins"
import { InterviewFAQ } from "../interviewInfo/InterviewFAQ"
import { Reschedule } from "../interviewInfo/Reschedule"
import { ProhibitedMattersInfo } from "./ProhibitedMattersInfo"
import { StartMeetingButton } from "../interviewInfo/StartMeetingButton"
import clsx from "clsx"
import { useMemo } from "react"
import { useSelector } from "react-redux"

export interface IFirstInterviewInfoDispatchProps {
  reSchedule: (entry_id: number, message: string) => void;
}

export interface IFirstInterviewInfoStateProps {
  entry: IEntry;
}

export interface IFirstInterviewInfoProps extends IFirstInterviewInfoDispatchProps, IFirstInterviewInfoStateProps {}

export const FirstInterviewInfo = ({ entry, reSchedule }: IFirstInterviewInfoProps) => {
  const company = useSelector((state: IStoreState) => state.company)

  const isBellbirdAvailable = useMemo(() => {
    if (!entry) return false
    return entry.video_type === VIDEO_TYPE.bellbird_video
      || (entry.video_type === VIDEO_TYPE.other_video && !!entry.bellbird_meeting_url)
  }, [entry])

  const videoMeetingUrl = useMemo(() => {
    if (!entry || !isBellbirdAvailable) return ""
    return entry.bellbird_meeting_url
  }, [entry, isBellbirdAvailable])

  const videoSettingUrl = useMemo(() => {
    if (!entry || !isBellbirdAvailable) return ""
    return company.bellbird_setting_url
  }, [entry, company, isBellbirdAvailable])

  if (!entry) return null

  return (
    <>
      <div className={clsx("contentsWrapper-transparent", entry.meeting_at && "")}>
        <div>
          <PartnerManagementHeader />
          <PartnerInfoHeader entry={entry} />
          <ProhibitedMattersInfo />
          <InterviewIntroMessage
            kind={MEETING_KIND.FIRST_INTERVIEW}
            type={MEETING_TYPE.ONLINE}
          />
          <MeetingInfoTable
            entry={entry}
            kind={MEETING_KIND.FIRST_INTERVIEW}
            type={MEETING_TYPE.ONLINE}
            videoType={entry.video_type}
            meetingAt={entry.meeting_at}
            videoMeetingUrl={videoMeetingUrl}
            videoSettingUrl={videoSettingUrl}
          />
          <InterviewFAQ />
          <Reschedule reSchedule={reSchedule} entry={entry} />
          <ContactJoins />
        </div>
      </div>
      {entry.meeting_at && (
        <StartMeetingButton
          meetingType={MEETING_TYPE.ONLINE}
          videoType={entry.video_type}
          meetingAt={entry.meeting_at}
          videoMeetingUrl={videoMeetingUrl}
        />
      )}
    </>
  );
};
