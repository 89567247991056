import { connect } from "react-redux";
import { Actions } from "../actions";
import {
  MessageBoard,
  IMessageBoardStateProps,
  IMessageBoardDispatchProps
} from "../components/MessageBoard";
import { Dispatch, Action } from "redux";
import { IStoreState, IMessage, IRoom } from "../types/state";
import { push } from "connected-react-router";
import { dateTimeStr } from "../lib/Util";
import { ITimeLineMessage } from "../components/Timeline";
import { showAlert } from "../thunks";

function roomID() {
  return parseInt(window.location.href.split("/").pop() as string, 10);
}

function mapStateToProps(state: IStoreState): IMessageBoardStateProps {
  const room = state.rooms.find((room: IRoom) => room.id === roomID());
  return {
    masquerade: state.auth.masquerade,
    content: state.viewState.content || "",
    commentHeight: state.viewState.commentHeight || 0,
    room,
    messages: room
      ? room.messages.map((message: IMessage) => {
          return {
            id: message.id,
            name: message.partner_id ? room.name : state.company.name,
            isOwn: !!message.company_id,
            avater: "",
            url: message.url,
            checked: message.checked,
            content_type: message.content_type,
            content: message.content,
            postedTime: dateTimeStr(message.created_at)
          };
        })
      : []
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): IMessageBoardDispatchProps {
  return {
    fetchMessages: () => {
      return dispatch(Actions.fetchMessages(roomID()));
    },
    checkMessages: (messages: ITimeLineMessage[]) => {
      const unchecked = messages
        .filter(message => !message.isOwn && !message.checked)
        .map(m => m.id);
      if (unchecked.length > 0) {
        dispatch(Actions.checkMessages(roomID(), unchecked));
      }
    },
    createMessage: (arg: {
      content?: string;
      file?: File;
      content_type: string;
    }) => dispatch(Actions.createMessage({ roomID: roomID(), ...arg })),
    deleteMessage: (id: number) => {
      (dispatch(Actions.deleteMessage(roomID(), id)) as any).catch(
        (err: any) => {
          dispatch(showAlert(err.payload.error.message) as any).then(() => {
            dispatch(Actions.fetchMessages(roomID()));
          });
        }
      );
    },
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
    changePath: (path: string) => dispatch(push(path))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBoard);
