import * as React from "react";
import GeneralStorage from "./generalStorage";
import { EngineTypes } from "./generalStorage";

const storage = new GeneralStorage([EngineTypes.SESSION_STORAGE]);

export default <T>(name: string, arg: T): [T, (arg: T) => void] => {
  const [state, setState] = React.useState(storage.getItem(name) || arg);
  React.useEffect(() => {
    return () => storage.removeItem(name);
  }, [name]);
  return [
    state,
    (arg: T) => {
      storage.setItem(name, arg);
      setState(arg);
    }
  ];
};
