import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination as MuiPagination, PaginationItem } from "@material-ui/lab";
import iconArrowLeft from "../../images/icon-arrow-left-gr.svg"
import iconArrowRight from "../../images/icon-arrow-right-gr.svg"

export interface IPaginationProps {
  currentPage?: number;
  totalPages?: number;
  changePage: (params: { page?: number } | undefined) => void | Promise<any>;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    "& > nav": {
      marginTop: "2rem"
    },
    "& li": {
      "&:first-of-type": {
        "& button": {
          "background": `url("${iconArrowLeft}") no-repeat center center`,
          "margin-left": 0
        }
      },
      "&:last-of-type": {
        "& button": {
          "background": `url("${iconArrowRight}") no-repeat center center`,
          "margin-right": 0
        }
      },
      "&:first-of-type, &:last-of-type": {
        "& button": {
          margin: "0 5px",
          "background-color": "unset",
          border: "none",
          "& svg": {
            display: "none"
          }
        }
      },
      "& button": {
        color: "#539a35",
        margin: "0 10px",
        "font-size": "1rem",
        "background-color": "#fff",
        "border-color": "#539a35",
        "&:hover": {
          "background-color": "#eef7e9"
        },
        "&.Mui-selected.Mui-disabled": {
          color: "#fff",
          "background-color": "#539a35",
        },
        "@media (max-width: 640px)": {
          margin: "0 5px"
        }
      },
      "& .MuiPaginationItem-ellipsis": {
        color: "#539a35",
        "padding-bottom": "0.3rem",
        "user-select": "none"
      }
    }
  }
});

const Pagination = (props: IPaginationProps) => {
  const classes = useStyles();

  const siblingCount = React.useMemo(() => window.innerWidth > 640 ? 1 : 0, [])

  const renderItem = React.useMemo(() => (
    // eslint-disable-next-line react/display-name
    (item: any) => {
      // 現在ページは押下できないようにする
      if (item.selected) item.disabled = true
      return <PaginationItem {...item} />
    }
  ), [])

  const handleChange = (_: React.ChangeEvent<unknown>, page: number) => {
    props.changePage({ page });
  };

  return (
    <div className={classes.root}>
      <MuiPagination
        variant="outlined"
        size="large"
        page={props.currentPage ?? 1}
        count={props.totalPages}
        siblingCount={siblingCount}
        renderItem={renderItem}
        onChange={handleChange}
      />
    </div>
  );
};

export default Pagination;
