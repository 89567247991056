import * as React from "react"
import { IProblem } from "../../types/state"
import { IStaffState } from "./EditStaffsForm"
import { SelectBitFlagField } from "../SelectBitFlagField"
import { ErrorMessage } from "../common/ErrorMessage"

export const EditStaffList = (props: {
  alert: (content: any) => Promise<void>
  confirm: (content: any) => Promise<boolean>
  values: IStaffState[]
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
  problemMap: {
    [key: number]: IProblem & { flag: number; message: string }
  }
  updateValue: (staffs: IStaffState[]) => void
}) => {
  return (
    <div className="formRow formRowNoBorder">
      <label className="formHead">
        担当者<span className="label label_opt">任意</span>
      </label>
      <div className="formData">
        {props.values.map((staff: IStaffState, index: number) => {
          if (staff.deleted) return null
          return (
            <div className="formDataOpen" key={`mail-${index}`}>
              {staff.name && (
                <div className="formDataBox">
                  <label className="problemLabel">名前</label>
                  <div className="formData">{staff.name}</div>
                </div>
              )}
              <div className="formDataBox">
                <label className="problemLabel">
                  メールアドレス
                  {!staff.id && <span className="label label_req">必須</span>}
                </label>
                {staff.id ? (
                  <div className="formData">{staff.email}</div>
                ) : (
                  <input
                    className="formWidth_m"
                    type="text"
                    value={staff.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      props.updateValue(
                        props.values.map((stf, i) => (i === index ? { ...stf, email: e.target.value } : stf))
                      )
                    }}
                  />
                )}
                {props.isSubmitted && props.validates["email"] && (
                  <ErrorMessage cls="mb24">{props.validates["email"](staff)}</ErrorMessage>
                )}
              </div>

              {Object.keys(props.problemMap).length !== 0 ? (
                <div className="formDataBox">
                  <label className="problemLabel">
                    権限
                    <span className="label label_req">必須</span>
                  </label>
                  <label className={"selectBtn"}>
                    <input
                      name={`role${index}`}
                      type="radio"
                      checked={staff.role === "admin"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const args = {}
                        if (e.target.checked) {
                          args["role"] = "admin"
                          args["problems"] = 0
                          args["problem_id"] = []
                        } else {
                          args["role"] = "individual"
                        }
                        props.updateValue(props.values.map((stf, i) => (i === index ? { ...stf, ...args } : stf)))
                      }}
                    />
                    <span>全権限</span>
                  </label>
                  <label className={"selectBtn"}>
                    <input
                      name={`role${index}`}
                      type="radio"
                      checked={staff.role !== "admin"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const args = {}
                        if (e.target.checked) {
                          args["role"] = "individual"
                        } else {
                          args["role"] = "admin"
                          args["problems"] = 0
                          args["problem_id"] = []
                        }
                        props.updateValue(props.values.map((stf, i) => (i === index ? { ...stf, ...args } : stf)))
                      }}
                    />
                    <span>個別案件対応</span>
                  </label>
                </div>
              ) : (
                <div className="formDataBox">
                  <label className="problemLabel">権限</label>
                  <div className="normalTxt mb30">
                    募集案件登録前に追加する担当者は管理者と同じ権限（全案件の応募者情報閲覧等）になります
                  </div>
                </div>
              )}
              {staff.role !== "admin" && (
                <SelectBitFlagField
                  label="閲覧可能な案件"
                  values={staff}
                  cls="normal"
                  name="problems"
                  flags={props.problemMap}
                  isSubmitted={props.isSubmitted}
                  validates={props.validates}
                  updateValue={(val: number) => {
                    const problem_ids = Object.keys(props.problemMap).reduce(
                      (accum, current) => (props.problemMap[current].flag & val ? [...accum, Number(current)] : accum),
                      [] as number[]
                    )
                    props.updateValue(
                      props.values.map((ass, i) => (i === index ? { ...ass, problem_ids, problems: val } : ass))
                    )
                  }}
                />
              )}
              <div className="formData_btnBox">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.confirm("この担当者を削除していいですか？").then((result) => {
                      if (result) {
                        let newStaffs
                        if (props.values[index].id === 0) {
                          newStaffs = props.values.filter((ass, i) => index !== i)
                        } else {
                          newStaffs = props.values.map((ass, i) => (index === i ? { ...ass, deleted: true } : ass))
                        }
                        props.updateValue(newStaffs)
                      }
                    })
                  }}
                  className="btn btnDelete"
                >
                  この担当者を削除
                </button>

                {props.values.map((a) => !a.deleted).lastIndexOf(true) === index && (
                  <button
                    className="btn btnAdd"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      props.updateValue([
                        ...props.values,
                        {
                          id: 0,
                          name: "",
                          deleted: false,
                          email: "",
                          role: "admin",
                          problems: 0
                        }
                      ])
                    }}
                  >
                    担当者をもう1人追加
                  </button>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
