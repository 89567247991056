import * as React from "react"
import { useMemo } from "react"
import { IProblem, IRecruitment, IStoreState } from "../../types/state"
import { Link } from "react-router-dom"
import { checkTrustPath, problemNewPath } from "../../routes/paths"
import style from "../../style/Recruitment.module.scss"
import { ErrorMessage } from "../common/ErrorMessage"
import { PROBLEM_STATUS_EN } from "../../lib/Definition"
import clsx from "clsx"
import { useStaff } from "../../hooks/useStaff"
import { ProblemInfo } from "./ProblemInfo"
import { useSelector } from "react-redux"

const getOrder = (problem: IProblem) => {
  if (problem.status === PROBLEM_STATUS_EN.active) return -1
  if (problem.status === PROBLEM_STATUS_EN.closed) return 1
  return 0
}

export interface IRecruitmentListDispatchProps {
  deleteProblem: (problemId: number) => void
}

export interface IRecruitmentListStateProps {
  recruitment: IRecruitment
  isVerified: boolean
}

interface IRecruitmentListProps extends IRecruitmentListDispatchProps, IRecruitmentListStateProps {}

export const RecruitmentList = ({ recruitment, isVerified, deleteProblem }: IRecruitmentListProps) => {
  const { isIndividualStaff } = useStaff()

  const viewState = useSelector((state: IStoreState) => state.viewState)

  const hasProblem = useMemo(() => {
    return recruitment.problems.length > 0
  }, [recruitment.problems])

  const orderedProblems = useMemo(() => {
    return recruitment.problems.sort((a, b) => {
      if (getOrder(a) < getOrder(b)) return -1
      if (getOrder(a) > getOrder(b)) return 1
      return b.id - a.id
    })
  }, [recruitment.problems])

  return (
    <main className="contentsWrapper">
      <div className="formWrap confirmWrap">
        <div className="formRow formRowNoBorder">
          <div className={clsx("formData", style.formData)}>
            {hasProblem && !isIndividualStaff && (
              <div className={clsx("formBtnArea formBtnAreaSingle", style.newButtonArea)}>
                <Link to={problemNewPath} className={clsx("btn btnSave", style.newButton)}>
                  新規案件作成
                </Link>
              </div>
            )}

            {!isVerified && hasProblem && (
              <ErrorMessage>
                <p>まだ、企業確認審査が完了していません。</p>
                <p>企業確認審査が完了後、募集開始となります。</p>
                <p>
                  恐れ入りますが、<Link to={checkTrustPath}>企業審査</Link>
                  のページにて、申請の手続きをお願いいたします。
                </p>
              </ErrorMessage>
            )}

            {!hasProblem && (
              <div className={style.noDataText}>
                まだ募集案件は
                <br className="PCHide" />
                登録されておりません
              </div>
            )}

            {!hasProblem && !isIndividualStaff && (
              <>
                <p className={style.noDataTextDescription}>
                  新規案件作成ボタンより
                  <br className="PCHide" />
                  募集案件の作成をおこなってください。
                </p>

                <div className={clsx("formBtnArea formBtnAreaSingle")}>
                  <Link to={problemNewPath} className={clsx("btn btnSave", style.newButton)}>
                    新規案件作成
                  </Link>
                </div>
              </>
            )}

            {viewState.error && <ErrorMessage>{viewState.error}</ErrorMessage>}

            {orderedProblems.map((problem) => {
              return (
                <ProblemInfo
                  key={`problem-${problem.id}`}
                  problem={problem}
                  recruitment={recruitment}
                  onClickDelete={deleteProblem}
                />
              )
            })}
          </div>
        </div>
      </div>
    </main>
  )
}
