import React from 'react'
import style from "../style/SelectStarInfo.module.scss"
import clsx from "clsx"

interface ISelectStarInfoProps {
  name: string;
  value: number;
  max: number;
  rootCls?: string;
}

export const SelectStarInfo = ({
  name,
  value,
  max,
  rootCls,
}: ISelectStarInfoProps) => {
  return (
    <div className={clsx(style.wrapper, rootCls)}>
      {
        Array.from({ length: max }, (v, i) => i).map(i => {
          return (
            <span
              key={`star-${name}-${i}`}
              className={style.star}
            >
              {(!value || value <= i) ? "☆" : "★"}
            </span>
          )
        })
      }
    </div>
  )
}
