import * as React from "react"
import { IPartner, IStoreState } from "../types/state"
import * as Definition from "../lib/Definition"
import B64 from "../lib/bitwise64"
import style from "../style/Recruitment.module.scss"
import { departmentStr, employedTermStr, problemCategoryDetailsStr } from "../lib/Util"
import { EXISTENCE, INDUSTRIES, MANAGEMENT_MEMBERS } from "../lib/Definition"
import clsx from "clsx"
import { useSelector } from "react-redux"

export const PartnerSkillInfo = (props: IPartner) => {
  const { problemCategories, problemCategoryDetails, licenses } = useSelector((state: IStoreState) => state)
  return (
    <>
      <h3 className="contentsSubTitle">経験・スキル</h3>
      <div className={style.listArea}>
        <div className={style.listRow}>
          <div className={style.listHead}>経験業界</div>
          <div className={style.listData}>
            <ul className={style.listDataList}>
              {Object.keys(Definition.INDUSTRIES).map((industry) => {
                if (B64.and(props.industries, INDUSTRIES[industry]["flag"]) !== 0) {
                  return <li key={industry}>{INDUSTRIES[industry]["message"]}</li>
                } else {
                  return null
                }
              })}
              {props.industry_other && <li key="industry_other">{props.industry_other}</li>}
            </ul>
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>経験業務</div>
          <div className={style.listData}>
            {props.experienced_jobs.map((job, i) => {
              const category = problemCategories.find((pc) => pc.slug === job.slug)
              return (
                <div key={i}>
                  業務領域 - {category?.name ?? ""}
                  <br />
                  業務詳細 - {problemCategoryDetailsStr(job.details, job.other_text, problemCategoryDetails)}
                </div>
              )
            })}
          </div>
        </div>
        <div className={clsx(style.listRow, style.listDataDlRow)}>
          <div className={style.listHead}>職務経歴</div>
          <div className={style.listData}>
            {props.careers &&
              props.careers.map((career, i) => {
                return (
                  <div className="workExp" key={`career-${i}`}>
                    <dl className={style.listDataDl}>
                      <dt>会社名：{career.company}</dt>
                      <dd>
                        在籍期間 - {employedTermStr(career)}
                        <br />
                        職種 - {departmentStr(career.departments, career.department_other)}
                        <br />
                        マネジメント経験 - {EXISTENCE[career.management]}
                        {career.management && (
                          <>
                            <br />
                            マネジメント人数 - {MANAGEMENT_MEMBERS[career.management_members]}
                          </>
                        )}
                        <div className={style.listDataText}>
                          <div className={style.listDataTextHead}>この企業での実績 -</div>
                          <div className={style.listDataTextData}>{career.achievement}</div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>資格</div>
          <div className={style.listData}>
            <ul className={style.listDataList}>
              {props.licenses.map((license) => {
                const licenseDef = licenses.find((l) => l.slug === license)
                return licenseDef ? <li key={license}>{licenseDef.name}</li> : null
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
