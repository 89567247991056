import {
  IAuth,
  IStoreState,
  ICompany,
  IRegistrant,
  IFile,
  IBusiness,
  IRecruitment,
  IEntry,
  INotice,
  IRoom,
  IMessage,
  IPartner,
  IScoutConditions,
  IScout,
  IIntroducer,
  INews,
  IStaff,
  IFirstPsychologicalSafety,
  IQuestionnaireState,
  IQuestionnaire,
  IAnnouncement,
  IAjaxErrorDetails,
  IPagenatedPartners,
  IPrefecture,
  IProblemCategory,
  IProblemCategoryDetail,
  ICompanyVerification,
  ILicense,
  ILicenseCategory
} from "../types/state"
import { ActionsUnion, createAction } from "./types"
import { formatTimeStr } from "../lib/Util"
import { LabelType } from "../lib/Definition"

export enum TypeKeys {
  LOGIN = "LOGIN",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL",
  INITIAL_DATA = "INITIAL_DATA",
  INITIAL_DATA_SUCCESS = "INITIAL_DATA_SUCCESS",
  INITIAL_DATA_FAIL = "INITIAL_DATA_FAIL",
  SET_MODAL = "SET_MODAL",
  SIGNUP = "SIGNUP",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_FAIL = "SIGNUP_FAIL",
  LOGOUT = "LOGOUT",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAIL = "LOGOUT_FAIL",
  RECOVER_SESSION = "RECOVER_SESSION",
  RECOVER_DATA = "RECOVER_DATA",
  RESET_PASSWORD = "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL",
  UPDATE_REGISTRANT = "UPDATE_REGISTRANT",
  UPDATE_REGISTRANT_SUCCESS = "UPDATE_REGISTRANT_SUCCESS",
  UPDATE_REGISTRANT_FAIL = "UPDATE_REGISTRANT_FAIL",
  UPDATE_COMPANY = "UPDATE_COMPANY",
  UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL",
  FETCH_COMPANY = "FETCH_COMPANY",
  FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS",
  FETCH_COMPANY_FAIL = "FETCH_COMPANY_FAIL",
  FETCH_PSYCHOLOGICAL_SAFETY = "FETCH_PSYCHOLOGICAL_SAFETY",
  FETCH_PSYCHOLOGICAL_SAFETY_SUCCESS = "FETCH_PSYCHOLOGICAL_SAFETY_SUCCESS",
  FETCH_PSYCHOLOGICAL_SAFETY_FAIL = "FETCH_PSYCHOLOGICAL_SAFETY_FAIL",
  UPDATE_FIRST_PSYCHOLOGICAL_SAFETY = "UPDATE_FIRST_PSYCHOLOGICAL_SAFETY",
  UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_SUCCESS = "UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_SUCCESS",
  UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_FAIL = "UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_FAIL",
  FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES = "FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES",
  FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES_SUCCESS = "FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES_SUCCESS",
  FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES_FAIL = "FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES_FAIL",
  CHANGE_PATH = "@@router/LOCATION_CHANGE",
  SET_VIEW_STATE = "SET_VIEW_STATE",
  SET_TEMPORARY_COMPANY = "SET_TEMPORARY_COMPANY",
  SET_UID = "SET_UID",
  ADDRESS_BY_ZIP_CODE = "ADDRESS_BY_ZIP_CODE",
  ADDRESS_BY_ZIP_CODE_SUCCESS = "ADDRESS_BY_ZIP_CODE_SUCCESS",
  ADDRESS_BY_ZIP_CODE_FAIL = "ADDRESS_BY_ZIP_CODE_FAIL",
  RENEWAL_PASSWORD = "RENEWAL_PASSWORD",
  RENEWAL_PASSWORD_SUCCESS = "RENEWAL_PASSWORD_SUCCESS",
  RENEWAL_PASSWORD_FAIL = "RENEWAL_PASSWORD_FAIL",
  FETCH_ENTRIES = "FETCH_ENTRIES",
  FETCH_ENTRIES_SUCCESS = "FETCH_ENTRIES_SUCCESS",
  FETCH_ENTRIES_FAIL = "FETCH_ENTRIES_FAIL",
  FETCH_ENTRY = "FETCH_ENTRY",
  FETCH_ENTRY_SUCCESS = "FETCH_ENTRY_SUCCESS",
  FETCH_ENTRY_FAIL = "FETCH_ENTRY_FAIL",
  FETCH_ROOMS = "FETCH_ROOMS",
  FETCH_ROOMS_SUCCESS = "FETCH_ROOMS_SUCCESS",
  FETCH_ROOMS_FAIL = "FETCH_ROOMS_FAIL",
  FETCH_MESSAGES = "FETCH_MESSAGES",
  FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS",
  FETCH_MESSAGES_FAIL = "FETCH_MESSAGES_FAIL",
  FETCH_MESSAGES_NEWER = "FETCH_MESSAGES_NEWER",
  FETCH_MESSAGES_NEWER_SUCCESS = "FETCH_MESSAGES_NEWER_SUCCESS",
  FETCH_MESSAGES_NEWER_FAIL = "FETCH_MESSAGES_NEWER_FAIL",
  FETCH_NEWS = "FETCH_NEWS",
  FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS",
  FETCH_NEWS_FAIL = "FETCH_NEWS_FAIL",
  CHECK_MESSAGES = "CHECK_MESSAGES",
  CHECK_MESSAGES_SUCCESS = "CHECK_MESSAGES_SUCCESS",
  CHECK_MESSAGES_FAIL = "CHECK_MESSAGES_FAIL",
  CREATE_MESSAGE = "CREATE_MESSAGE",
  CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS",
  CREATE_MESSAGE_FAIL = "CREATE_MESSAGE_FAIL",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS",
  DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL",
  FETCH_NOTICES = "FETCH_NOTICES",
  FETCH_NOTICES_SUCCESS = "FETCH_NOTICES_SUCCESS",
  FETCH_NOTICES_FAIL = "FETCH_NOTICES_FAIL",
  CHECK_NOTICES = "CHECK_NOTICES",
  CHECK_NOTICES_SUCCESS = "CHECK_NOTICES_SUCCESS",
  CHECK_NOTICES_FAIL = "CHECK_NOTICES_FAIL",
  FETCH_AVAILABLE_SCHEDULES = "FETCH_AVAILABLE_SCHEDULES",
  FETCH_AVAILABLE_SCHEDULES_SUCCESS = "FETCH_AVAILABLE_SCHEDULES_SUCCESS",
  FETCH_AVAILABLE_SCHEDULES_FAIL = "FETCH_AVAILABLE_SCHEDULES_FAIL",
  FETCH_INTRODUCERS = "FETCH_INTRODUCERS",
  FETCH_INTRODUCERS_SUCCESS = "FETCH_INTRODUCERS_SUCCESS",
  FETCH_INTRODUCERS_FAIL = "FETCH_INTRODUCERS_FAIL",
  CREATE_SCOUT = "CREATE_SCOUT",
  CREATE_SCOUT_SUCCESS = "CREATE_SCOUT_SUCCESS",
  CREATE_SCOUT_FAIL = "CREATE_SCOUT_FAIL",
  CLEAR_PARTNERS = "CLEAR_PARTNERS",
  SEARCH_PARTNERS = "SEARCH_PARTNERS",
  SEARCH_PARTNERS_SUCCESS = "SEARCH_PARTNERS_SUCCESS",
  SEARCH_PARTNERS_FAIL = "SEARCH_PARTNERS_FAIL",
  FETCH_PARTNER = "FETCH_PARTNER",
  FETCH_PARTNER_SUCCESS = "FETCH_PARTNER_SUCCESS",
  FETCH_PARTNER_FAIL = "FETCH_PARTNER_FAIL",
  CREATE_WEB_MEETING = "CREATE_WEB_MEETING",
  CREATE_WEB_MEETING_SUCCESS = "CREATE_WEB_MEETING_SUCCESS",
  CREATE_WEB_MEETING_FAIL = "CREATE_WEB_MEETING_FAIL",
  UPDATE_BOOKMARK = "UPDATE_BOOKMARK",
  UPDATE_BOOKMARK_SUCCESS = "UPDATE_BOOKMARK_SUCCESS",
  UPDATE_BOOKMARK_FAIL = "UPDATE_BOOKMARK_FAIL",
  UPDATE_ENTRY_LABEL = "UPDATE_ENTRY_LABEL",
  UPDATE_ENTRY_LABEL_SUCCESS = "UPDATE_ENTRY_LABEL_SUCCESS",
  UPDATE_ENTRY_LABEL_FAIL = "UPDATE_ENTRY_LABEL_FAIL",
  RESCHEDULE = "RESCHEDULE",
  RESCHEDULE_SUCCESS = "RESCHEDULE_SUCCESS",
  RESCHEDULE_FAIL = "RESCHEDULE_FAIL",
  CREATE_QUESTIONNAIRE = "CREATE_QUESTIONNAIRE",
  CREATE_QUESTIONNAIRE_SUCCESS = "CREATE_QUESTIONNAIRE_SUCCESS",
  CREATE_QUESTIONNAIRE_FAIL = "CREATE_QUESTIONNAIRE_FAIL",
  CHECK_ANNOUNCEMENT = "CHECK_ANNOUNCEMENT",
  CHECK_ANNOUNCEMENT_SUCCESS = "CHECK_ANNOUNCEMENT_SUCCESS",
  CHECK_ANNOUNCEMENT_FAIL = "CHECK_ANNOUNCEMENT_FAIL",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR"
}

const logoutOK = () => createAction(TypeKeys.LOGOUT_SUCCESS)
export const apiURL = process.env.REACT_APP_API_URL + "/api/v1/companies"

const login = (params: { email: string; password: string }) =>
  createAction(TypeKeys.LOGIN, {
    request: {
      data: { email: params.email, password: params.password },
      method: "post",
      url: `${apiURL}/companies/login`
    }
  })

const resetPassword = (email: string) =>
  createAction(TypeKeys.RESET_PASSWORD, {
    request: {
      data: { email },
      method: "post",
      url: `${apiURL}/registrants/reset_password`
    }
  })

const initialData = () =>
  createAction(TypeKeys.INITIAL_DATA, {
    request: {
      method: "get",
      url: `${apiURL}/companies/initial_data`
    }
  })

export interface ISignUpParams {
  name: string
  first_name: string
  last_name: string
  first_kana: string
  last_kana: string
  email: string
  trigger: string
  trigger_other: string
  introducer_id: number
  password?: string
  department: string
  position: string
  tel: string
}
const signUp = (params: ISignUpParams) =>
  createAction(TypeKeys.SIGNUP, {
    request: {
      data: {
        name: params.name,
        email: params.email,
        trigger: params.trigger,
        trigger_other: params.trigger_other,
        introducer_id: params.introducer_id,
        first_kana: params.first_kana,
        first_name: params.first_name,
        last_kana: params.last_kana,
        last_name: params.last_name,
        password: params.password,
        department: params.department,
        position: params.position,
        tel: params.tel ? params.tel.replace(/-/g, "") : ""
      },
      method: "post",
      url: `${apiURL}/companies`
    }
  })

export interface IUpdateRegistrantParams {
  id: number
  first_name: string
  last_name: string
  first_kana: string
  last_kana: string
  email: string
  password?: string
  department: string
  position: string
  tel: string
}
const updateRegistrant = (params: IUpdateRegistrantParams) =>
  createAction(TypeKeys.UPDATE_REGISTRANT, {
    request: {
      data: params,
      method: "put",
      url: `${apiURL}/registrants/${Number(params.id)}`
    }
  })

export interface IUpdateCompanyParams {
  id: number
  name: string
  name_kana: string
  zip_code: string
  address1: string
  address2: string
  president: string
  establishment: number
  capital: number
  employee: number
  executive: number
  vision: string
  industries: number
  industry_other: string
  urls: string
  fetch_homepage: boolean
  eye_catch: IFile | null
  files: IFile[]
  businesses: IBusiness[]
  sales: string
  president_profile: string
  president_position_name: string
  president_birth_year: number
  president_hometown: string
  president_first_job_desc: string
  president_how_become: string
  president_motivation: string
  president_generation: string
  president_hobbies: string
  other: string
}
const updateCompany = (args: IUpdateCompanyParams) => {
  const params = {}
  args.businesses.forEach((business, i) => {
    if (business.id) {
      params[`businesses_attributes[${i}][id]`] = business.id
    }
    params[`businesses_attributes[${i}][description]`] = business.description
    params[`businesses_attributes[${i}][rate]`] = business.rate
    if (business.deleted) {
      params[`businesses_attributes[${i}][_destroy]`] = "1"
    }
  })
  if (args.eye_catch && args.eye_catch.blob) {
    params["eye_catch"] = args.eye_catch.blob
  }
  if (args.eye_catch && args.eye_catch.deleted) {
    params["delete_eye_catch"] = "true"
  }
  args.files.filter((f) => f.blob).forEach((f, i) => (params[`files[${i}]`] = f.blob))
  args.files.filter((f) => f.deleted && f.id).forEach((f, i) => (params[`delete_files[${i}]`] = f.id))

  return createAction(TypeKeys.UPDATE_COMPANY, {
    request: {
      data: {
        name: args.name,
        name_kana: args.name_kana,
        vision: args.vision,
        zip_code: args.zip_code.replace(/-/g, ""),
        address1: args.address1,
        address2: args.address2,
        president: args.president,
        establishment: args.establishment,
        capital: args.capital,
        employee: args.employee,
        executive: args.executive,
        industries: args.industries,
        industry_other: args.industry_other,
        urls: args.urls,
        sales: args.sales,
        fetch_homepage: args.fetch_homepage,
        president_profile: args.president_profile,
        president_position_name: args.president_position_name,
        president_birth_year: args.president_birth_year > 0 ? args.president_birth_year : null,
        president_hometown: args.president_hometown,
        president_first_job_desc: args.president_first_job_desc,
        president_how_become: args.president_how_become,
        president_motivation: args.president_motivation,
        president_generation: args.president_generation,
        president_hobbies: args.president_hobbies,
        other: args.other,
        ...params
      },
      method: "put",
      url: `${apiURL}/companies/${args.id}`
    }
  })
}

const fetchCompany = (id: number) =>
  createAction(TypeKeys.FETCH_COMPANY, {
    request: {
      method: "get",
      url: `${apiURL}/companies/${id}`
    }
  })

const fetchPsychologicalSafety = () =>
  createAction(TypeKeys.FETCH_PSYCHOLOGICAL_SAFETY, {
    request: {
      data: {},
      method: "get",
      url: `${apiURL}/psychological_safeties`
    }
  })

const updateFirstPsychologicalSafety = (firstPsychologicalSafety: IFirstPsychologicalSafety) =>
  createAction(TypeKeys.UPDATE_FIRST_PSYCHOLOGICAL_SAFETY, {
    request: {
      data: firstPsychologicalSafety,
      method: "put",
      url: `${apiURL}/psychological_safeties/1`
    }
  })

const createQuestionnaire = (questionnaire: IQuestionnaireState) => {
  return createAction(TypeKeys.CREATE_QUESTIONNAIRE, {
    request: {
      data: questionnaire,
      method: "post",
      url: `${apiURL}/questionnaires`
    }
  })
}

const fetchEntryFirstPsychologicalSafeties = (entry_id: number) =>
  createAction(TypeKeys.FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES, {
    request: {
      data: { entry_id },
      method: "get",
      url: `${apiURL}/entries/${entry_id}/first_psychological_safeties`
    }
  })

const addressByZipCode = (zip_code: string) =>
  createAction(TypeKeys.ADDRESS_BY_ZIP_CODE, {
    request: {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/v1/zip_code_masters/address?zip_code=${zip_code}`
    }
  })

const logout = () =>
  createAction(TypeKeys.LOGOUT, {
    request: {
      method: "GET",
      url: `${apiURL}/companies/logout`
    }
  })

const renewalPassword = (args: { uuid: string; password: string }) => {
  return createAction(TypeKeys.RENEWAL_PASSWORD, {
    request: {
      data: { ...args },
      method: "post",
      url: `${apiURL}/registrants/renewal_password`
    }
  })
}

const fetchEntries = () =>
  createAction(TypeKeys.FETCH_ENTRIES, {
    request: {
      data: {},
      method: "get",
      url: `${apiURL}/entries`
    }
  })

const fetchEntry = (id: number) =>
  createAction(TypeKeys.FETCH_ENTRY, {
    request: {
      data: {},
      method: "get",
      url: `${apiURL}/entries/${id}`
    }
  })

const fetchNotices = () =>
  createAction(TypeKeys.FETCH_NOTICES, {
    request: {
      data: {},
      method: "get",
      url: `${apiURL}/company_notices`
    }
  })

const fetchRooms = () =>
  createAction(TypeKeys.FETCH_ROOMS, {
    request: {
      data: {},
      method: "get",
      url: `${apiURL}/rooms`
    }
  })

const fetchMessages = (id: number) =>
  createAction(TypeKeys.FETCH_MESSAGES, {
    request: {
      method: "get",
      url: `${apiURL}/rooms/${id}/messages`
    }
  })

const fetchMessagesNewer = (id: number, newest_id: number) =>
  createAction(TypeKeys.FETCH_MESSAGES_NEWER, {
    request: {
      method: "get",
      url: `${apiURL}/rooms/${id}/messages?newest_id=${newest_id}`
    }
  })

const createMessage = (arg: { roomID: number; content?: string; content_type: string; file?: File }) =>
  createAction(TypeKeys.CREATE_MESSAGE, {
    request: {
      data: arg,
      method: "post",
      url: `${apiURL}/rooms/${arg.roomID}/messages`
    }
  })

const deleteMessage = (roomID: number, id: number) =>
  createAction(TypeKeys.DELETE_MESSAGE, {
    request: {
      method: "delete",
      data: {},
      url: `${apiURL}/rooms/${roomID}/messages/${id}`
    }
  })

const checkMessages = (roomID: number, ids: number[]) => {
  return createAction(TypeKeys.CHECK_MESSAGES, {
    request: {
      data: { id: ids },
      method: "post",
      url: `${apiURL}/rooms/${roomID}/messages/checked`
    }
  })
}

const checkNotices = (ids: number[]) =>
  createAction(TypeKeys.CHECK_NOTICES, {
    request: {
      data: { ids: ids.join(",") },
      method: "post",
      url: `${apiURL}/company_notices/checked`
    }
  })

const fetchAvailableSchedules = (entry_id: number) => {
  return createAction(TypeKeys.FETCH_AVAILABLE_SCHEDULES, {
    request: {
      data: { entry_id },
      method: "get",
      url: `${apiURL}/meetings/available_schedules`
    }
  })
}

const fetchNews = () =>
  createAction(TypeKeys.FETCH_NEWS, {
    request: {
      method: "get",
      url: process.env.REACT_APP_NEWS_URL
    }
  })

const createScout = (partner_id: number, problem_id: number, message: string) => {
  return createAction(TypeKeys.CREATE_SCOUT, {
    request: {
      method: "post",
      data: { partner_id, problem_id, message },
      url: `${apiURL}/scouts`
    }
  })
}

const createWebMeeting = (params: {
  start_at: Date
  entry_id: number
  way: string
  kind: string
  video_type?: string
  description?: string
}) =>
  createAction(TypeKeys.CREATE_WEB_MEETING, {
    request: {
      data: { ...params, start_at: formatTimeStr(params.start_at) },
      method: "post",
      url: `${apiURL}/meetings`
    }
  })

const searchPartners = (conditions: IScoutConditions) =>
  createAction(TypeKeys.SEARCH_PARTNERS, {
    request: {
      data: conditions,
      method: "get",
      url: `${apiURL}/partners`,
      options: {
        loader: true
      }
    }
  })

const fetchPartner = (id: number) =>
  createAction(TypeKeys.FETCH_PARTNER, {
    request: {
      method: "get",
      url: `${apiURL}/partners/${id}`
    }
  })

const fetchIntroducers = () =>
  createAction(TypeKeys.FETCH_INTRODUCERS, {
    request: {
      method: "get",
      url: `${apiURL}/introducers`
    }
  })

const updateBookmark = (partner_id: number, bookmarked: boolean) =>
  createAction(TypeKeys.UPDATE_BOOKMARK, {
    request: {
      method: "post",
      data: { bookmarked, partner_id },
      url: `${apiURL}/bookmarks/upsert`
    }
  })

const updateEntryLabel = (entry_id: number, label_status: LabelType) =>
  createAction(TypeKeys.UPDATE_ENTRY_LABEL, {
    request: {
      method: "post",
      data: { entry_id, label_status },
      url: `${apiURL}/entries/${entry_id}/update_label`
    }
  })

const reSchedule = (args: { entry_id: number; message: string }) =>
  createAction(TypeKeys.RESCHEDULE, {
    request: {
      data: args,
      method: "post",
      url: `${apiURL}/entries/${args.entry_id}/reschedule`
    }
  })

const setViewState = (params: { [key: string]: any }) => createAction(TypeKeys.SET_VIEW_STATE, params)

const setTemporaryCompany = (params: { [key: string]: any }) => createAction(TypeKeys.SET_TEMPORARY_COMPANY, params)

const setModal = (args: {
  isOpen: boolean
  component: React.ReactNode
  content?: any
  close: () => void
  shouldCloseOnOverlayClick: boolean
  style?: object
}) => createAction(TypeKeys.SET_MODAL, args)

const setUID = (uid: string, gid: string) => createAction(TypeKeys.SET_UID, { uid, gid })

const recoverSession = (sessionID: string, masquerade: boolean) =>
  createAction(TypeKeys.RECOVER_SESSION, { sessionID, masquerade })

const recoverData = (data: IStoreState) => createAction(TypeKeys.RECOVER_DATA, data)

const clearPartners = () => createAction(TypeKeys.CLEAR_PARTNERS, {})

const checkAnnouncement = (id: number, agreed: boolean | null) =>
  createAction(TypeKeys.CHECK_ANNOUNCEMENT, {
    request: {
      data: { id, agreed },
      method: "post",
      url: `${apiURL}/announcement_deliveries/upsert`
    }
  })

// MiddleWareのajaxで自動生成されるActionの定義
type initialDataSuccess = {
  type: TypeKeys.INITIAL_DATA_SUCCESS
  payload: {
    data: {
      company: ICompany
      registrant: IRegistrant
      recruitment: IRecruitment
      staffs: IStaff[]
      rooms: IRoom[]
      notices: INotice[]
      announcements: IAnnouncement[]
      prefectures: IPrefecture[]
      problem_categories: IProblemCategory[]
      problem_category_details: IProblemCategoryDetail[]
      license_categories: ILicenseCategory[]
      licenses: ILicense[]
      company_verification: ICompanyVerification
    }
  }
}

type signupSuccess = {
  type: TypeKeys.SIGNUP_SUCCESS
  payload: {
    data: {
      company: ICompany
      registrant: IRegistrant
      recruitment: IRecruitment
      auth: IAuth
    }
  }
}

type updateRegistrantSuccess = {
  type: TypeKeys.UPDATE_REGISTRANT_SUCCESS
  payload: {
    data: {
      registrant: IRegistrant
    }
  }
}

type fetchCompanySuccess = {
  type: TypeKeys.FETCH_COMPANY_SUCCESS
  payload: {
    data: {
      company: ICompany
    }
  }
}

type updateCompanySuccess = {
  type: TypeKeys.UPDATE_COMPANY_SUCCESS
  payload: {
    data: {}
  }
}

type resetPasswordSuccess = {
  type: TypeKeys.RESET_PASSWORD_SUCCESS
  payload: {}
}

type checkNoticesSuccess = {
  type: TypeKeys.CHECK_NOTICES_SUCCESS
  payload: { data: {} }
}

type loginSuccess = {
  type: TypeKeys.LOGIN_SUCCESS
  payload: {
    data: {
      company: ICompany
      registrant: IRegistrant
      recruitment: IRecruitment
      staffs: IStaff[]
      rooms: IRoom[]
      auth: IAuth
    }
  }
}

type changePath = {
  type: TypeKeys.CHANGE_PATH
  payload: {
    action: string
    location: { pathname: string; search: string; hash: string }
  }
}

type addressByZipCodeSuccess = {
  type: TypeKeys.ADDRESS_BY_ZIP_CODE_SUCCESS
  payload: {
    data: { address: string }
  }
}

type fetchEntriesSuccess = {
  type: TypeKeys.FETCH_ENTRIES_SUCCESS
  payload: {
    data: { entries: IEntry[] }
  }
}

type fetchEntrySuccess = {
  type: TypeKeys.FETCH_ENTRY_SUCCESS
  payload: {
    data: IEntry
  }
}

type fetchRoomsSuccess = {
  type: TypeKeys.FETCH_ROOMS_SUCCESS
  payload: {
    data: { rooms: IRoom[] }
  }
}

type fetchMessagesSuccess = {
  type: TypeKeys.FETCH_MESSAGES_SUCCESS
  payload: {
    data: { messages: IMessage[] }
  }
}

type fetchMessagesNewerSuccess = {
  type: TypeKeys.FETCH_MESSAGES_NEWER_SUCCESS
  payload: {
    data: { messages: IMessage[] }
  }
}

type fetchNoticesSuccess = {
  type: TypeKeys.FETCH_NOTICES_SUCCESS
  payload: {
    data: { notices: INotice[] }
  }
}

type fetchPsychologicalSafetySuccess = {
  type: TypeKeys.FETCH_PSYCHOLOGICAL_SAFETY_SUCCESS
  payload: {
    data: { first_psychological_safety: IFirstPsychologicalSafety }
  }
}

type updateFirstPsychologicalSafetySuccess = {
  type: TypeKeys.UPDATE_FIRST_PSYCHOLOGICAL_SAFETY_SUCCESS
  payload: {
    data: { first_psychological_safety: IFirstPsychologicalSafety }
  }
}

type fetchEntryFirstPsychologicalSafetiesSuccess = {
  type: TypeKeys.FETCH_ENTRY_FIRST_PSYCHOLOGICAL_SAFETIES_SUCCESS
  payload: {
    data: IFirstPsychologicalSafety[]
  }
}

type createMessageSuccess = {
  type: TypeKeys.CREATE_MESSAGE_SUCCESS
  payload: { data: IMessage }
}

type createWebMeetingSuccess = {
  type: TypeKeys.CREATE_WEB_MEETING_SUCCESS
  payload: { data: { id: number; kind: string; meeting_at: string; bellbird_setting_url: string } }
}

type deleteMessageSuccess = {
  type: TypeKeys.DELETE_MESSAGE_SUCCESS
  payload: { data: IMessage }
}

type createScoutSuccess = {
  type: TypeKeys.CREATE_SCOUT_SUCCESS
  payload: { data: IScout }
}

type fetchMessagesFail = {
  type: TypeKeys.FETCH_MESSAGES_FAIL
  payload: { error: any }
}

type searchPartnersSuccess = {
  type: TypeKeys.SEARCH_PARTNERS_SUCCESS
  payload: {
    data: IPagenatedPartners
  }
}

type fetchPartnerSuccess = {
  type: TypeKeys.FETCH_PARTNER_SUCCESS
  payload: {
    data: IPartner
  }
}

type fetchIntroducersSuccess = {
  type: TypeKeys.FETCH_INTRODUCERS_SUCCESS
  payload: {
    data: { introducers: IIntroducer[] }
  }
}

type updateBookmarkSuccess = {
  type: TypeKeys.UPDATE_BOOKMARK_SUCCESS
  payload: {
    data: { partner_id: number; bookmarked: boolean }
  }
}

type updateEntryLabelSuccess = {
  type: TypeKeys.UPDATE_ENTRY_LABEL_SUCCESS
  payload: {
    data: { entry_id: number; label_status: string }
  }
}

type fetchAvailableSchedulesSuccess = {
  type: TypeKeys.FETCH_AVAILABLE_SCHEDULES_SUCCESS
  payload: {
    data: { available_schedules: Date[] }
  }
}

type fetchNewsSuccess = {
  type: TypeKeys.FETCH_NEWS_SUCCESS
  payload: { data: INews[] }
}

type createQuestionnaireSuccess = {
  type: TypeKeys.CREATE_QUESTIONNAIRE_SUCCESS
  payload: {
    data: IQuestionnaire
  }
}

type checkAnnouncementSuccess = {
  type: TypeKeys.CHECK_ANNOUNCEMENT_SUCCESS
  payload: {
    data: { id: number }
  }
}

type internalServerError = {
  type: TypeKeys.INTERNAL_SERVER_ERROR
  payload: { error: IAjaxErrorDetails }
}

type AutoGenerateActions =
  | addressByZipCodeSuccess
  | changePath
  | initialDataSuccess
  | signupSuccess
  | resetPasswordSuccess
  | updateRegistrantSuccess
  | fetchCompanySuccess
  | updateCompanySuccess
  | checkNoticesSuccess
  | fetchEntriesSuccess
  | fetchEntrySuccess
  | fetchNoticesSuccess
  | fetchRoomsSuccess
  | fetchMessagesSuccess
  | fetchMessagesNewerSuccess
  | createMessageSuccess
  | createWebMeetingSuccess
  | createScoutSuccess
  | deleteMessageSuccess
  | loginSuccess
  | fetchMessagesFail
  | searchPartnersSuccess
  | fetchPartnerSuccess
  | fetchAvailableSchedulesSuccess
  | fetchIntroducersSuccess
  | fetchNewsSuccess
  | updateBookmarkSuccess
  | fetchPsychologicalSafetySuccess
  | updateFirstPsychologicalSafetySuccess
  | updateEntryLabelSuccess
  | fetchEntryFirstPsychologicalSafetiesSuccess
  | createQuestionnaireSuccess
  | checkAnnouncementSuccess
  | internalServerError

export const Actions = {
  addressByZipCode,
  initialData,
  login,
  logout,
  logoutOK,
  recoverData,
  recoverSession,
  resetPassword,
  renewalPassword,
  setViewState,
  setTemporaryCompany,
  setModal,
  setUID,
  signUp,
  updateRegistrant,
  fetchCompany,
  updateCompany,
  fetchEntries,
  fetchEntry,
  fetchNotices,
  fetchRooms,
  fetchMessages,
  fetchMessagesNewer,
  createScout,
  createMessage,
  deleteMessage,
  checkMessages,
  checkNotices,
  fetchAvailableSchedules,
  fetchNews,
  searchPartners,
  fetchPartner,
  clearPartners,
  fetchIntroducers,
  updateBookmark,
  fetchPsychologicalSafety,
  updateFirstPsychologicalSafety,
  fetchEntryFirstPsychologicalSafeties,
  updateEntryLabel,
  reSchedule,
  createWebMeeting,
  createQuestionnaire,
  checkAnnouncement
}
export type Actions = ActionsUnion<typeof Actions> | AutoGenerateActions
