import { ActionsUnion, createAction } from "./types";
import { ISecondInterview } from "../types/state";
import { apiURL } from "./index"

export enum TypeKeys {
  CREATE_SECOND_INTERVIEW = "CREATE_SECOND_INTERVIEW",
  CREATE_SECOND_INTERVIEW_SUCCESS = "CREATE_SECOND_INTERVIEW_SUCCESS",
  CREATE_SECOND_INTERVIEW_FAIL = "CREATE_SECOND_INTERVIEW_FAIL",
}

const createSecondInterview = (data: Partial<ISecondInterview>) => {
  return createAction(TypeKeys.CREATE_SECOND_INTERVIEW, {
    request: {
      method: "post",
      data: data,
      url: `${apiURL}/second_interviews`,
    },
  });
};

type createSecondInterviewSuccess = {
  type: TypeKeys.CREATE_SECOND_INTERVIEW_SUCCESS;
  payload: {
    data: ISecondInterview;
  };
};

type AutoGenerateActions = createSecondInterviewSuccess;

export const SecondInterviewActions = {
  createSecondInterview,
};

export type SecondInterviewActions =
  | ActionsUnion<typeof SecondInterviewActions>
  | AutoGenerateActions;
