import * as React from "react"
import { IStoreState } from "../../types/state"
import { useSelector } from "react-redux"
import { IProblemFormState } from "./index"

export const CategoryForm = (props: {
  problem: IProblemFormState
  updateValue: (args: { [key: string]: any }) => void
}) => {
  const problemCategories = useSelector((state: IStoreState) => state.problemCategories)

  return (
    <div id="problem_category">
      <label className="pure-u-1 problemLabel">業務カテゴリー</label>
      {problemCategories.map((problemCategory) => {
        const key = problemCategory.slug
        return (
          <label className="selectBtn" key={key}>
            <input
              type="radio"
              value={key}
              checked={key === props.problem.problem_category}
              onChange={(e: React.ChangeEvent<any>) => {
                props.updateValue({
                  ...props.problem,
                  problem_category: e.target.value,
                  problem_category_details: [],
                  section_other: ""
                })
              }}
            />
            <span>{problemCategory.name}</span>
          </label>
        )
      })}
    </div>
  )
}
