import * as React from "react";

const customStyle = {
  overlay: {
    position: "fixed" as const,
    top: 0,
    zIndex: 100,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)"
  },
  cvSpinner: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  spinner: {
    width: "40px",
    height: "40px",
    border: "4px #ddd solid",
    borderTop: "4px #2e93e6 solid",
    borderRadius: "50%",
    animation: "sp-anime 0.8s infinite linear"
  }
};

const Loader = (props: { isVisible: boolean }) => {
  return (
    <div
      style={{
        ...customStyle.overlay,
        display: props.isVisible ? "block" : "none"
      }}
    >
      <div style={customStyle.cvSpinner}>
        <span style={customStyle.spinner} />
      </div>
    </div>
  );
};

export default Loader;
