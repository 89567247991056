import * as React from "react"
import { useMemo } from "react"
import style from "../../style/InterviewInfo.module.scss"
import { MEETING_KIND, MEETING_TYPE, MEETING_TYPE_STR } from "../../lib/Definition"

interface IInterviewFlowDispatchProps {}

interface IInterviewFlowStateProps {
  kind: MEETING_KIND;
  type: MEETING_TYPE;
}

interface IInterviewFlowProps extends IInterviewFlowDispatchProps, IInterviewFlowStateProps {}

export const InterviewFlow = ({ kind, type }: IInterviewFlowProps) => {
  const meetingType = useMemo(() => MEETING_TYPE_STR[type], [type])

  return (
    <tr>
      <th>
        面談の流れ
        <br className="PCHide" />
        の確認
      </th>
      <td>
        <div className={style.interviewFlowTitle}>
          {kind}（{meetingType}）の流れ
        </div>
        <div className={style.interviewFlowStepsWrapper}>
          {kind === MEETING_KIND.FIRST_INTERVIEW && (
            <dl>
              <dt>1.アイスブレイク（目安時間：5-10分）</dt>
              <dd>お互いの心理的安全性プログラムの記入結果もぜひ参考にしてください。</dd>
              <dt>2.業務内容のマッチング（目安時間：20-40分）</dt>
              <dd>お互いのWeb履歴書および企業情報を閲覧しながらマッチングを進めましょう。</dd>
              <dt>3.終了</dt>
              <dd>面談終了後、応募者から二次面談の希望有無の連絡を待ちましょう。</dd>
            </dl>
          )}
          {kind === MEETING_KIND.SECOND_INTERVIEW && (
            <dl>
              <dt>1.最初の3カ月で取組む具体的な業務内容の確認（目安時間：20分）</dt>
              <dd>
                今回の課題に対しての解決方法や想定するスケジュール感など、<br />
                具体的な業務の進め方のすり合わせを行ってください。
              </dd>
              <dt>2.具体的な業務条件についての確認（目安時間：20分）</dt>
              <dd>
                二次面談希望時に応募者より提出された、時給単価や稼働時間など、<br />
                実際に業務する場合を想定した具体的条件を確認してください。
              </dd>
              <dt>3.終了</dt>
              <dd>面談終了後、応募者からの契約希望有無の連絡を待ちましょう。</dd>
            </dl>
          )}
        </div>
        {type === MEETING_TYPE.ONLINE && (
          <div className={style.interviewFlowRequirementWrapper}>
            <div className={style.interviewFlowRequirementTitle}>
              Web面談については以下の環境をご用意ください
            </div>
            <ul>
              <li>インターネットに接続する環境</li>
              <li>カメラ付きのパソコンとマイク付きイヤホン（もしくはカメラ付きのスマートフォン）</li>
            </ul>
          </div>
        )}
      </td>
    </tr>
  )
}
