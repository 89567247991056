import * as React from "react"
import { RECRUITMENT_STATUS_EN, PROBLEM_STATUS_EN, PROBLEM_CHECKED_BO_STATUS_EN } from "../../../lib/Definition"
import { IProblem, IStoreState } from "../../../types/state"
import { IProblemFormState } from "../../ProblemForm"
import { useMemo } from "react"
import { useSelector } from "react-redux"

const convertProblemToStr = (problem: IProblemFormState | IProblem) => {
  return [
    problem.title,
    problem.problem_category,
    problem.problem_category_details.sort().join(" "),
    problem.section_other,
    problem.request,
    problem.situation,
    problem.goal,
    problem.publish_type
  ].join(" ")
}

interface IConfirmUpdateRecruitmentProps {
  content: { problemForm: IProblemFormState }
  closeModal: (result: boolean) => void
}

export const ConfirmUpdateRecruitment = ({ content, closeModal }: IConfirmUpdateRecruitmentProps) => {
  const recruitment = useSelector((state: IStoreState) => state.recruitment)
  const problemForm = content.problemForm

  const originalProblem = useMemo(() => {
    return recruitment.problems.find((p) => p.id === problemForm.id)
  }, [recruitment.problems, problemForm])

  const isEdited = useMemo(() => {
    if (!originalProblem) return false
    return convertProblemToStr(problemForm) !== convertProblemToStr(originalProblem)
  }, [problemForm, originalProblem])

  const isResume = useMemo(() => {
    return (
      recruitment.status === RECRUITMENT_STATUS_EN.active &&
      problemForm.status === PROBLEM_STATUS_EN.active &&
      problemForm.checked_bo_status === PROBLEM_CHECKED_BO_STATUS_EN.progress_resume
    )
  }, [recruitment.status, problemForm])

  const isResumeInClosedRecruitment = useMemo(() => {
    return (
      recruitment.status !== RECRUITMENT_STATUS_EN.active &&
      problemForm.status === PROBLEM_STATUS_EN.active &&
      problemForm.checked_bo_status === PROBLEM_CHECKED_BO_STATUS_EN.progress_resume
    )
  }, [recruitment.status, problemForm])

  const isClose = useMemo(() => {
    if (!originalProblem) return false
    return problemForm.status === PROBLEM_STATUS_EN.closed && problemForm.status !== originalProblem.status
  }, [problemForm, originalProblem])

  const isUpdatable = useMemo(() => {
    return isEdited || isResume || isResumeInClosedRecruitment || isClose
  }, [isEdited, isResume, isResumeInClosedRecruitment, isClose])

  return (
    <main>
      <div className="alCent mb10">
        {isUpdatable && (
          <pre>
            案件ID：{problemForm.id}につきまして、
            <br />
            {isEdited && (
              <>
                内容を編集しました。
                <br />
              </>
            )}
            {isResume && <>JOINS管理担当者の確認後、入力した内容で案件が公開されます。</>}
            {isResumeInClosedRecruitment && (
              <>
                JOINS管理担当者の確認後、入力した内容で案件が公開され、
                <br />
                募集開始の告知メールが配信されます。
              </>
            )}
            {isClose && <>募集終了いたします。</>}
          </pre>
        )}
        {!isUpdatable && <pre>更新されたデータはありません。</pre>}
      </div>
      {isUpdatable && (
        <>
          <div className="alCent mb10">上記でよろしければ「確認」ボタンを押してください。</div>
          <div className="formBtnArea formWrap">
            <button className="btn" onClick={() => closeModal(false)}>
              キャンセル
            </button>
            <button className="btn btnLogin" onClick={() => closeModal(true)}>
              確認
            </button>
          </div>
        </>
      )}
      {!isUpdatable && (
        <div className="formBtnArea formBtnAreaSingle">
          <button className="btn btnLogin" onClick={() => closeModal(false)}>
            OK
          </button>
        </div>
      )}
    </main>
  )
}
