import * as React from "react";
import style from "../../style/InterviewInfo.module.scss";

interface IVideoTestJoinsDispatchProps {}

interface IVideoTestJoinsStateProps {
  videoSettingUrl: string;
}

interface IVideoTestJoinsProps extends IVideoTestJoinsDispatchProps, IVideoTestJoinsStateProps {}

export const VideoTestJoins = ({ videoSettingUrl }: IVideoTestJoinsProps) => {
  return (
    <tr>
      <th>
        Web会議
        <br className="PCHide" />
        ツール
        <br className="PCHide" />
        適合性
        <br className="PCHide" />
        確認テスト
      </th>
      <td>
        <div>
          <a
            href={videoSettingUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            テスト用Web面談ツールでカメラ、マイクの設定およびテストを行う
          </a>
        </div>
        <div className={style.meetingInfoTableDescription}>
          本番は画面下部のボタンから入室してください。<br />
          お使いの端末のカメラとマイクがWeb会議に適合しているかを事前にテスト、設定してください。
        </div>
      </td>
    </tr>
  )
}
