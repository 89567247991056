import * as React from "react"
import { useSelector } from "react-redux"
import { IStoreState } from "../../types/state"
import { CategoryForm } from "./CategoryForm"
import { CategoryDetailsForm } from "./CategoryDetailsForm"
import { CategoryOtherForm } from "./CategoryOtherForm"
import { ErrorMessage } from "../common/ErrorMessage"
import { IProblemFormState } from "./index"

interface ICategoryFormGroupDispatchProps {
  updateValue: (args: { [key: string]: any }) => void
}

interface ICategoryFormGroupStateProps {
  problem: IProblemFormState
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
}

interface ICategoryFormGroupProps extends ICategoryFormGroupDispatchProps, ICategoryFormGroupStateProps {}

export const CategoryFormGroup = ({ problem, isSubmitted, validates, updateValue }: ICategoryFormGroupProps) => {
  const problemCategories = useSelector((state: IStoreState) => state.problemCategories)
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  const category = problemCategories.find((pc) => pc.slug === problem.problem_category)
  const categoryDetails = problemCategoryDetails.filter((pcd) => problem.problem_category_details.includes(pcd.slug))
  const isCategoryOther = category && category.other_flag
  const isCategoryDetailOther = !!categoryDetails.find((categoryDetail) => categoryDetail.other_flag)

  return (
    <div className="mb14">
      <CategoryForm problem={problem} updateValue={updateValue} />
      {problem.problem_category && (
        <>
          {!isCategoryOther && <CategoryDetailsForm problem={problem} updateValue={updateValue} />}
          {(isCategoryOther || isCategoryDetailOther) && (
            <CategoryOtherForm problem={problem} updateValue={updateValue} />
          )}
        </>
      )}
      {isSubmitted && validates["problem_category"] && (
        <ErrorMessage cls="mb20">{validates["problem_category"](problem)}</ErrorMessage>
      )}
      {isSubmitted && validates["problem_category_details"] && (
        <ErrorMessage cls="mb20">{validates["problem_category_details"](problem)}</ErrorMessage>
      )}
      {isSubmitted && validates["section_other"] && (
        <ErrorMessage cls="mb20">{validates["section_other"](problem)}</ErrorMessage>
      )}
    </div>
  )
}
