import * as React from "react"
import { ErrorMessage } from "./common/ErrorMessage"

const URLListForm = (props: {
  values: { urls: string; isSubmitted: boolean }
  validates: { [key: string]: (value: any) => string | null }
  updateValue: (args: { [key: string]: any }) => void
}) => {
  return (
    <>
      <div className="formRow">
        <label className="formHead">
          ホームページURL<span className="label label_opt">任意</span>
        </label>
        <div className="formData">
          <input
            className="formWidth_m"
            type="text"
            value={props.values.urls}
            placeholder="https://joins.co.jp"
            onChange={(e: React.ChangeEvent<any>) => {
              props.updateValue({
                urls: e.target.value,
                fetch_homepage: !!e.target.value
              })
            }}
          />
          {props.values.isSubmitted && props.validates["urls"] && (
            <ErrorMessage>{props.validates["urls"](props.values)}</ErrorMessage>
          )}
        </div>
      </div>
    </>
  )
}

export default URLListForm
