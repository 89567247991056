import * as React from "react";
import { IFile } from "../types/state";
import FileField from "./FileField";

const FileUploadList = (props: {
  files: IFile[];
  label: any;
  required?: boolean;
  note?: string;
  updateValue: (files: IFile[]) => void;
}) => (
  <div className="formRow">
    <label className="formHead">
      {props.label}
      {props.required === true ? (
        <span className="label label_req">必須</span>
      ) : props.required === false ? (
        <span className="label label_opt">任意</span>
      ) : null}
      <br />
      {props.note && <small>{props.note}</small>}
    </label>
    <div className="formData">
      {props.files.map((f, i) => {
        if (f.deleted) {
          return null;
        }
        return (
          <div className="formDataBox" key={`file-${i}`}>
            <a
              href={props.files[i].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.files[i].name}
            </a>
            <button
              className="btn btnS"
              onClick={() =>
                props.updateValue(
                  props.files.map((file, idx) =>
                    idx === i
                      ? { ...file, blob: undefined, deleted: true }
                      : file
                  )
                )
              }
            >
              削除
            </button>
          </div>
        );
      })}
      <FileField
        updateValue={(file: File) => {
          props.updateValue([
            ...props.files,
            {
              name: file.name,
              url: URL.createObjectURL(file),
              blob: file
            }
          ]);
        }}
      />
    </div>
  </div>
);

export default FileUploadList;
