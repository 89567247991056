import * as React from "react"

export const ConfirmDeleteRecruitment = (props: {
  content: { problemId: number }
  closeModal: (result: boolean) => void
}) => {
  return (
    <main>
      <div className="alCent mb10">
        <pre>
          案件ID：{props.content.problemId}につきまして、
          <br />
          削除されます。
        </pre>
      </div>
      <div className="alCent mb10">上記でよろしければ「確認」ボタンを押してください。</div>
      <div className="formBtnArea formWrap">
        <button className="btn" onClick={() => props.closeModal(false)}>
          キャンセル
        </button>
        <button className="btn btnLogin" onClick={() => props.closeModal(true)}>
          確認
        </button>
      </div>
    </main>
  )
}
