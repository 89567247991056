import * as React from "react"
import { IPagenatedPartners, IScoutConditions } from "../../types/state"
import { PartnerList } from "./PartnerList"
import { ScoutConditionForm } from "./ScoutConditionForm"
import style from "../../style/Scout.module.scss"
import Pagination from "../common/Pagination"
import { parseQueryParams } from "../../lib/Util"
import { IBitFlagDefinition } from "../SelectBitFlagField"

export interface IScoutListDispatchProps {
  search: (conditions: IScoutConditions) => void
  changePath: (path: string) => void
  updateBookmark: (id: number, bookmarked: boolean) => void
  clearCondition: () => IScoutConditions
}

export interface IScoutListStateProps {
  partners: IPagenatedPartners
  conditions: IScoutConditions
  conditionStrs: string[]
  prefectureDefinitions: IBitFlagDefinition
}

export interface IScoutListProps extends IScoutListDispatchProps, IScoutListStateProps {}

export const ScoutList = (props: IScoutListProps) => {
  const { partners, conditions, conditionStrs, search, changePath, updateBookmark } = props

  const changePage = React.useCallback(
    (page: { page?: number } | undefined) => {
      if (!page?.page || page.page === partners.current_page) return
      const newConditions = { ...conditions, page: page.page }
      search(newConditions)
      window.scrollTo(0, 0)
    },
    [conditions, partners.current_page, search]
  )

  const showPagination = React.useMemo(
    () => partners.partners.length > 0 && Object.keys(parseQueryParams()).length !== 0,
    [partners.partners]
  )

  return (
    <main className="contentsWrapper-transparent">
      <h2 className="contentsTitle contentsTitleScout">スカウト</h2>
      <div className={style.scout}>
        <div className={style.searchArea}>
          <ScoutConditionForm {...props} />
        </div>
        <div className={style.listArea}>
          <div className={style.listAreaWrap}>
            <PartnerList
              conditionStrs={conditionStrs}
              updateBookmark={updateBookmark}
              changePath={changePath}
              partners={partners}
            />
          </div>
          {showPagination && (
            <Pagination currentPage={partners.current_page} totalPages={partners.total_pages} changePage={changePage} />
          )}
        </div>
      </div>
    </main>
  )
}
