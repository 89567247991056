import * as React from "react";
import { NormalTextField } from "./NormalTextField";
import { ErrorMessage } from "./common/ErrorMessage"

export interface IResetPasswordStateProps {
  email: string;
  error: string;
  isSubmitted: boolean;
}
export interface IResetPasswordDispatchProps {
  updateValue: (args: any) => void;
  resetPassword: (values: { email: string }) => void;
}
const validates: { [key: string]: (value: any) => string | null } = {};
validates["email"] = val => {
  return val.email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
    ? null
    : "正しいメールアドレスを入力してください。";
};
const ResetPasswordForm = (
  props: IResetPasswordStateProps & IResetPasswordDispatchProps
) => {
  return (
    <main className="is-outside">
      <div className="container">
        <form
          className="pure-form"
          onSubmit={e => {
            e.preventDefault();
            if (validates["email"](props)) {
              props.updateValue({ isSubmitted: true });
              window.scrollTo(0, 0);
              return;
            }
            props.resetPassword({ email: props.email });
          }}
        >
          <ErrorMessage>{props.error}</ErrorMessage>
          <h1>パスワード再発行申請</h1>
          <p>
            入力されたメールアドレスに「
            <strong>【JOINS】パスワード再発行のお知らせ</strong>
            」メールを送信いたします。メールに記載されたURLにアクセスし、新しいパスワードをご登録ください。
            <br />
            URLの有効期限の72時間を経過しますと、再度申請が必要となりますのでご注意ください。
          </p>
          <div className="formWrap singleColumnForm loginForm">
            <NormalTextField
              label="メールアドレス"
              name="email"
              values={props}
              updateValue={props.updateValue}
              validates={validates}
            />
          </div>
          <div className="formBtnArea formBtnAreaSingle">
            <button className="btn btnEdit" type="submit">
              パスワード再発行メールを申請する
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default ResetPasswordForm;
