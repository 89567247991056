import * as React from "react";
import { Link } from "react-router-dom";
import { companyPath } from "../../routes/paths";
import { Wrapper } from "./Wrapper";

export const ConfirmationProfile = () => {
  return (
    <Wrapper>
      <h2>企業確認をするためには</h2>
      <p>
        <Link to={companyPath}>企業概要</Link>
        のページにて
      </p>
      <ul>
        <li>会社名</li>
        <li>会社の所在地</li>
        <li>代表者名</li>
      </ul>
      <p>を事前にご入力ください。</p>
    </Wrapper>
  );
};
