import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import { Dispatch, Action } from "redux";
import { IEntry } from "../types/state";
import { push } from "connected-react-router";
import { SecondInterviewInfo, ISecondInterviewInfoProps } from "../components/SecondInterview/SecondInterviewInfo";

class SecondInterview extends React.Component<ISecondInterviewInfoProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
    dispatch(Actions.fetchEntry(entryID));
  }
  render() {
    return <SecondInterviewInfo {...this.props} />;
  }
}

function mapStateToProps(state: any) {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
  return {
    entry: state.entries.filter((entry: IEntry) => entry.id === entryID)[0]
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    reSchedule: (entry_id: number, message: string) => {
      dispatch(Actions.reSchedule({ entry_id, message }) as any)
        .then(() => dispatch(push("/")))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondInterview as any);
