import * as React from "react"
import { IEntry, IRecruitment } from "../types/state"
import { PartnerProfileInfo } from "./PartnerProfileInfo"
import { PartnerEntryInfo } from "./PartnerEntryInfo"
import style from "../style/Recruitment.module.scss"
import { ScrollContext } from "../containers/ScrollToTop"
import { EMPTY_APOLOGY } from "../lib/Definition"
import { PartnerManagementHeader } from "./headers/PartnerManagementHeader"

export interface IEntryRejectDispatchProps {
  rejectEntry: (entry: IEntry, apology: string) => void
}

export interface IEntryRejectStateProps {
  entry: IEntry
  recruitment: IRecruitment
}

export interface IEntryRejectProps extends IEntryRejectDispatchProps, IEntryRejectStateProps {}

const EntryRejectForm = (props: IEntryRejectProps) => {
  const partner = props.entry.partner
  const [rejectState, setRejectState] = React.useState({
    status: "edit",
    message: EMPTY_APOLOGY
  })
  const ctx = React.useContext(ScrollContext)
  if (!partner) {
    return null
  }
  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 id="js-entryRejectForm" className="contentsTitle contentsTitlePartner">
        【応募ID：{props.entry.id}】 との面談辞退
      </h2>
      <h3 className="contentsSubTitle">不成立通知</h3>
      {rejectState.status === "edit" ? (
        <>
          <div className={style.scoutInfo}>
            <div className={style.scoutInfoText}>
              応募者に対して
              <b className="joinsPinkTxt">不成立のお知らせ</b>
              を通知いたします。
              <br />
              面談辞退の理由など、応募者に特に伝えたいメッセージがあれば下記にご記入のうえ、「送信内容を確認する」ボタンを押してください。
              <span className="grayTxt">（メッセージがなければ未記入のままボタンを押してください。）</span>
            </div>
            <div className="pure-form">
              <div className="formRow formRowSolo">
                <label className="formHead">
                  応募者へのメッセージ
                  <span className="label label_opt">任意</span>
                </label>
                <div className="formData">
                  <textarea
                    className="textarea mb10mb20"
                    onChange={(e) =>
                      setRejectState({
                        ...rejectState,
                        message: e.target.value
                      })
                    }
                    value={rejectState.message === EMPTY_APOLOGY ? " " : rejectState.message}
                  />
                </div>
              </div>
              <div className="formBtnArea">
                <button
                  className="btn btnEdit"
                  onClick={(e) => {
                    e.preventDefault()
                    window.history.back()
                  }}
                >
                  戻る
                </button>
                <button
                  className="btn btnSave"
                  onClick={(e) => {
                    e.preventDefault()
                    ctx.scrollTo("js-entryRejectForm")
                    setRejectState({ ...rejectState, status: "confirm" })
                  }}
                >
                  送信内容を
                  <br className="PCHide" />
                  確認する
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={style.scoutInfo}>
            <div className={style.scoutInfoText}>
              以下の内容でよろしければ、
              <b className="joinsPinkTxt">送信するボタン</b>
              をクリックしてください。
            </div>
            <div className={style.listRow + " " + style.listRowNoBorder + " mb40mb32"}>
              <div className={style.listHead}>応募者へのメッセージ</div>
              <div className={style.listData}>
                {rejectState.message.split("\n").map((m, idx) => (
                  <div key={idx}>{m}</div>
                ))}
              </div>
            </div>
            <div className="formBtnArea">
              <button
                className="btn btnEdit"
                onClick={(e) => {
                  e.preventDefault()
                  ctx.scrollTo("js-entryRejectForm")
                  setRejectState({ ...rejectState, status: "edit" })
                }}
              >
                戻る
              </button>
              <button
                className="btn btnSave"
                onClick={(e) => {
                  e.preventDefault()
                  props.rejectEntry(props.entry, rejectState.message)
                }}
              >
                送信する
              </button>
            </div>
          </div>
        </>
      )}
      <PartnerProfileInfo
        {...partner}
        noNameMessage="貴社がWeb面談を希望した場合に開示されます。"
        isCareerSheetDisclosed={true}
        isPRDisclosed={true}
      />
      <PartnerEntryInfo entry={props.entry} recruitment={props.recruitment} />
    </div>
  )
}

export default EntryRejectForm
