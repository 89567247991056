import * as React from "react";
import { IFirstPsychologicalSafety } from "../types/state";
import { TextAreaField } from "./TextAreaField";
import { Link } from "react-router-dom";
import { firstPsychologicalSafetyPath } from "../routes/paths";
import style from "../style/Psychology.module.scss";

export interface IEditFirstPsychologicalSafetyFormDispatchProps {
  alert: (content: string) => Promise<void>;
  updateValue: (args: any) => void;
  updateFirstPsychologicalSafety: (firstPsychologicalSafety: IFirstPsychologicalSafety) => void;
}

export interface FirstPsychologicalSafety extends IFirstPsychologicalSafety {
  error: string | undefined;
  errorField: string | undefined;
}

export interface IEditFirstPsychologicalSafetyFormStateProps {
  enableCancel: boolean;
  firstPsychologicalSafety: FirstPsychologicalSafety;
}

export interface IEditFirstPsychologicalSafetyFormProps extends IEditFirstPsychologicalSafetyFormDispatchProps, IEditFirstPsychologicalSafetyFormStateProps {}

const validates: { [key: string]: (value: IFirstPsychologicalSafety) => string | null; } = {}
validates["like"] = (val) => {
  return val.like ? null : "好きなことやものを入力してください。";
}

export const EditFirstPsychologicalSafetyForm = (props: IEditFirstPsychologicalSafetyFormProps) => {
  return (
    <main className="contentsWrapper">
      <h2 className={style.partnerTopTitle}>
        一次面談向け
        <br className="PCHide" />
        心理的安全性プログラム
      </h2>
      <form
        className="pure-form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          const error = Object.keys(validates).filter((field) => {
            return validates[field](props.firstPsychologicalSafety);
          })[0];
          if (error) {
            props.alert("入力に誤りがあります").then(() => {
              props.updateValue({ scrollID: error, isSubmitted: true });
            });
            return;
          }
          props.updateValue({ inputChanged: false });
          props.updateFirstPsychologicalSafety(props.firstPsychologicalSafety);
        }}
      >
        <div className="formWrap">
          {props.firstPsychologicalSafety.error && (
            <div className="text-danger">
              {props.firstPsychologicalSafety.error}
            </div>
          )}
          <TextAreaField
            name="like"
            label={
              <>
                好きなことやものを
                <br className="SPHide" />
                3個以上教えてください
              </>
            }
            required={true}
            values={props.firstPsychologicalSafety}
            updateValue={props.updateValue}
            validates={validates}
            placeholder="記入例： 一人娘、犬（ゴールデンレトリーバー）、サッカー、サーフィン、スノーボード、ステーキ、餃子、黒かりんとう、温泉、持ち物を減らすこと"
            cls={`${style.ansewrForm} ${style.ansewrFormHeight01}`}
          />
          <TextAreaField
              name="specialties_and_proceeding"
              label={
                <>
                  業務を進める上で、特に自分の能力を発揮できる、
                  <br className="SPHide" />
                  頑張れる業務内容・仕事の進め方を教えてください
                </>
              }
              required={false}
              values={props.firstPsychologicalSafety}
              updateValue={props.updateValue}
              validates={validates}
              placeholder="例：複数の意見を取りまとめて、整理して、タスクに整理して進行を推進していること"
              cls={`${style.ansewrForm} ${style.ansewrFormHeight03}`}
              type="noborder"
          />
          <TextAreaField
              name="weak"
              label={
                <>
                  業務を進める上で、これだけは苦手、あるいはストレスがかかる
                  <br className="SPHide" />
                  業務内容・仕事の進め方を教えてください
                </>
              }
              required={false}
              values={props.firstPsychologicalSafety}
              updateValue={props.updateValue}
              validates={validates}
              placeholder="例：定型業務で、正確性とスピードが求められる業務をやること"
              cls={`${style.ansewrForm} ${style.ansewrFormHeight03}`}
              type="noborder"
          />
          <div className={"formBtnArea" + (props.enableCancel ? "" : " formBtnAreaSingle")}>
            {props.enableCancel && (
              <Link className="btn" to={firstPsychologicalSafetyPath}>
                キャンセル
              </Link>
            )}
            <button className="btn btnSave" type="submit">
              上記内容を保存
            </button>
          </div>
        </div>
      </form>
    </main>
  );
};
