import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from "../style/RadioButtons.module.scss"
import clsx from "clsx"
import { ErrorMessage } from "./common/ErrorMessage"

interface IRadioButtonsProps {
  name: string;
  value?: string;
  descriptions?: { [key: string]: string[] };
  master: { [key: string]: string };
  rootCls?: string;
  groupCls?: string;
  itemCls?: string;
  isSubmitted?: boolean;
  validates?: { [key: string]: (value: any) => string | null };
  onChange: (args: string) => void;
}

export const RadioButtons = ({
  name,
  value,
  descriptions,
  master,
  rootCls,
  groupCls,
  itemCls,
  isSubmitted,
  validates,
  onChange
}: IRadioButtonsProps) => {
  return (
    <FormControl id={name} className={clsx(styles.formControl, rootCls)}>
      <RadioGroup className={clsx("radioGroup", groupCls)}>
        {Object.keys(master).map((key) => (
          <div key={`radio-label-${key}`} className={clsx(styles.radioItemWrap, itemCls)}>
            <div className={styles.formControlLabel}>
              <FormControlLabel
                control={
                  <Radio
                    className={styles.greenLabel}
                    checked={value === key}
                    value={key}
                    name={name}
                    size="small"
                  />
                }
                label={<span className={styles.label}>{master[key]}</span>}
                onChange={() => onChange(key)}
              />
            </div>
            {descriptions && (
              <ul className={styles.radioDescription}>
                {descriptions[key].map((desc: string, index: number) => (
                  <li key={`radio-description-${key}-${index}`} className={styles.publicStatusDescription}>{desc}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </RadioGroup>
      {isSubmitted && validates && validates[name] && (
        <ErrorMessage cls="mb08">
          {validates[name]({ [name]: value })}
        </ErrorMessage>
      )}
    </FormControl>
  )
}
