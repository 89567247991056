import * as React from "react";

export default function Confirm(props: {
  content: any;
  closeModal: (result: boolean) => void;
}) {
  return (
    <main>
      <div className="alCent mb10">{props.content}</div>
      <div className="formBtnArea formWrap">
        <button className="btn btnLogin" onClick={() => props.closeModal(true)}>
          {"はい"}
        </button>
        <button className="btn" onClick={() => props.closeModal(false)}>
          {"いいえ"}
        </button>
      </div>
    </main>
  );
}
