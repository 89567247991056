import * as React from "react";
import { EntryList, IEntriesDispatchProps, IEntriesStateProps } from "./EntryList";
import { ProceduralFlow } from "./ProceduralFlow.";

export interface IHomeDispatchProps extends IEntriesDispatchProps {}

export interface IHomeStateProps extends IEntriesStateProps {}

export interface IHomeProps extends IHomeDispatchProps, IHomeStateProps {}

export const HomePage = (props: IHomeProps) => {
  return (
    <main className="contentsWrapper-transparent">
      <h2 className="contentsTitle contentsTitleManagement">人材管理</h2>
      <EntryList
        changePath={props.changePath}
        recruitment={props.recruitment}
        entries={props.entries}
        psychological_status={props.psychological_status}
      />
      <ProceduralFlow />
    </main>
  );
};
