import docCookie from "./cookies";
import DateWithOffset from "../lib/DateWithOffset";

export const EngineTypes = {
  LOCAL_STORAGE: "localStorage",
  SESSION_STORAGE: "sessionStorage",
  COOKIE: "cookie",
  NOOP: "noop"
};

export default class GeneralStorage {
  static get EngineTypes() {
    return EngineTypes;
  }
  private _engine:
    | LocalStorageEngine
    | SessionStorageEngine
    | CookieStorageEngine
    | NoopStorageEngine;

  constructor(engines = [EngineTypes.LOCAL_STORAGE, EngineTypes.COOKIE]) {
    this._engine = this.getStorage(engines);
  }

  get length() {
    return this._engine.length;
  }

  get type() {
    return this._engine.type;
  }

  getStorage(engines = [EngineTypes.LOCAL_STORAGE, EngineTypes.COOKIE]) {
    let engine:
      | LocalStorageEngine
      | SessionStorageEngine
      | CookieStorageEngine
      | NoopStorageEngine
      | null = null;
    engines.forEach(e => {
      if (engine) {
        return;
      }
      switch (e) {
        case EngineTypes.LOCAL_STORAGE:
          if (!isStorageEnabled()) {
            return;
          }
          return (engine = new LocalStorageEngine());
        case EngineTypes.SESSION_STORAGE:
          if (!isStorageEnabled()) {
            return;
          }
          return (engine = new SessionStorageEngine());
        case EngineTypes.COOKIE:
          return (engine = new CookieStorageEngine());
        case EngineTypes.NOOP:
          return (engine = new NoopStorageEngine());
        default:
          return;
      }
    });
    if (!engine) {
      engine = new NoopStorageEngine();
    }
    return engine;
  }

  getItem(key: string) {
    const value = this._engine.get(key);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  }

  setItem(key: string, value: any) {
    const jsonStr = JSON.stringify(value);
    this._engine.set(key, jsonStr);
  }

  removeItem(key: string) {
    console.log(key);
    this._engine.remove(key);
  }
}

function isStorageEnabled() {
  if (!localStorage) {
    return false;
  }
  if (isMobileSafariPrivate()) {
    return false;
  }
  return true;
}

function isMobileSafariPrivate() {
  const dummyKey = new DateWithOffset().getTime().toString();
  try {
    localStorage.setItem(dummyKey, "dummy value");
  } catch (err) {
    return true;
  }
  localStorage.removeItem(dummyKey);
  return false;
}

class LocalStorageEngine {
  get length() {
    return localStorage.length;
  }

  get type() {
    return EngineTypes.LOCAL_STORAGE;
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  }

  remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }
}

class SessionStorageEngine {
  get length() {
    return sessionStorage.length;
  }

  get type() {
    return EngineTypes.SESSION_STORAGE;
  }

  get(key: string) {
    return sessionStorage.getItem(key);
  }

  set(key: string, value: string) {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  }

  remove(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }
}

class CookieStorageEngine {
  get length() {
    return docCookie.keys().filter(item => !!item).length;
  }

  get type() {
    return EngineTypes.COOKIE;
  }

  get(key: string) {
    return docCookie.getItem(key);
  }

  set(key: string, value: string) {
    docCookie.setItem(key, value);
  }

  remove(key: string) {
    docCookie.removeItem(key);
  }
}

class NoopStorageEngine {
  get length() {
    return Object.keys(this).length;
  }

  get type() {
    return EngineTypes.NOOP;
  }

  get(key: string) {
    // return new object
    return this[key] && JSON.parse(JSON.stringify(this[key]));
  }

  set(key: string, value: string) {
    this[key] = value;
  }

  remove(key: string) {
    delete this[key];
  }
}
