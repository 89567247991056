import * as React from "react";
import style from "../style/Questionnaire.module.scss";
import storedUseState from "../lib/storedUseState";
import { IEntry, IQuestionnaireState } from "../types/state";
import { Phases } from "../lib/Definition";
import { ScrollContext } from "../containers/ScrollToTop";

export interface QuestionLabels {
  getInterviewReview: () => any;
  getPsychologicalSafetyReview: () => any;
  getInterviewReviewRead: () => any;
  getPsychologicalSafetyReviewRead: () => any;
}

export interface IQuestionnaireDispatchProps {
  onSubmitForm: (questionnaire: IQuestionnaireState) => void;
}

export interface IQuestionnaireStateProps {
  phase: Phases;
  entry: IEntry;
}

export interface IQuestionnaireProps
  extends IQuestionnaireDispatchProps,
    IQuestionnaireStateProps {}

export const Questionnaire = (props: IQuestionnaireProps) => {
  const { phase, entry } = props;

  const [state, setState] = storedUseState("QuestionnaireFormState", {
    isSubmitted: false,
    completed: false,
  });

  const ctx = React.useContext(ScrollContext);

  const [questionnaireState, setQuestionnaireStateState] = storedUseState<
    IQuestionnaireState
  >("Questionnaire", {
    entry_id: entry.id as number,
    phase: phase,
    interview_review: "",
    psychological_safety_review: "",
  });

  const labelsForFirstInterview = (): QuestionLabels => {
    return {
      getInterviewReview: () => {
        return (
          <label className={style.question}>
            <span className={style.questionNumber}>1</span>
            <u>サービス登録から一次面談実施までの一連の流れ</u>
            について、感じたことがありましたら教えてください。
            <span className={style.questionHint}>
              手順について分かりづらかったこと、やりにくかったこと／良かったこと、等
            </span>
          </label>
        );
      },
      getInterviewReviewRead: () => {
        return (
          <label className={style.question}>
            <span className={style.questionNumber}>1</span>
            <u>サービス登録から一次面談実施までの一連の流れ</u>
            について、感じたことがありましたら教えてください。
          </label>
        );
      },
      getPsychologicalSafetyReview: () => (
        <label className={style.question}>
          <span className={style.questionNumber}>2</span>
          <u>「心理的安全性プログラム」</u>
          について、感じたことがありましたら教えてください。
          <span className={style.questionHint}>
            面談時の不安を減らすことに役立った／役立たなかった、今後共に仕事をできそうな相手か判断するのに役立った／役立たなかった、等
          </span>
        </label>
      ),
      getPsychologicalSafetyReviewRead: () => (
        <label className={style.question}>
          <span className={style.questionNumber}>2</span>
          <u>「心理的安全性プログラム」</u>
          について、感じたことがありましたら教えてください。
        </label>
      ),
    };
  };

  const labelsForSecondInterview = (): QuestionLabels => {
    return {
      getInterviewReview: () => {
        return (
          <label className={style.question}>
            <span className={style.questionNumber}>1</span>
            一次面談実施後から二次面談実施までの一連の流れについて、感じたことがありましたら教えてください。
            <span className={style.questionHint}>
              手順について分かりづらかったこと、やりにくかったこと／良かったこと、等
            </span>
          </label>
        );
      },
      getInterviewReviewRead: () => {
        return (
          <label className={style.question}>
            <span className={style.questionNumber}>1</span>
            一次面談実施後から二次面談実施までの一連の流れについて、感じたことがありましたら教えてください。
          </label>
        );
      },
      getPsychologicalSafetyReview: () => (
        // <label className={style.question}>
        //   <span className={style.questionNumber}>2</span>
        //   二次面談向け「心理的安全性プログラム」について、感じたことがありましたら教えてください。
        //   <span className={style.questionHint}>
        //     面談時の不安を減らすことに役立った／役立たなかった、今後共に仕事をできそうな相手か判断するのに役立った／役立たなかった、等
        //   </span>
        // </label>
        <>
        </>
      ),
      getPsychologicalSafetyReviewRead: () => (
        // <label className={style.question}>
        //   <span className={style.questionNumber}>2</span>
        //   二次面談向け「心理的安全性プログラム」について、感じたことがありましたら教えてください。
        // </label>
        <>
        </>
      ),
    };
  };

  const getQuestionLabels = (phase: Phases) => {
    switch (phase) {
      case Phases.SECOND_INTERVIEW:
        return labelsForFirstInterview();
      case Phases.CONTRACT:
        return labelsForSecondInterview();
      default:
        return;
    }
  };

  const labels = getQuestionLabels(phase);
  if (!labels) {
    return null;
  }

  if (state.isSubmitted) {
    return null;
  }

  return (
    <div id="js-Questionnaire">
      {!state.completed && (
        <div className={style.container}>
          <h2 className={style.mainTitle}>
            当社サービス向上のため、
            <br />
            よろしければ<u className="joinsOrangeTxt">アンケート</u>
            にご協力ください。
          </h2>
          <form
            className={style.form + " pure-form"}
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              setState({ ...state, completed: true });
            }}
          >
            {labels.getInterviewReview()}
            <textarea
              name="first_interview_review"
              id="first_interview_review"
              className={style.textarea}
              value={questionnaireState.interview_review}
              onChange={(e: React.ChangeEvent<any>) =>
                setQuestionnaireStateState({
                  ...questionnaireState,
                  interview_review: e.target.value,
                })
              }
            />

            {labels.getPsychologicalSafetyReview()}
            {phase === Phases.SECOND_INTERVIEW &&
            <textarea
              name="psychological_safety_review"
              id="psychological_safety_review"
              className={style.textarea}
              value={questionnaireState.psychological_safety_review}
              onChange={(e: React.ChangeEvent<any>) =>
                setQuestionnaireStateState({
                  ...questionnaireState,
                  psychological_safety_review: e.target.value,
                })
              }
            />}
            <div className="formBtnArea formBtnAreaSingle">
              <button
                type="submit"
                className={"btn btnSave"}
                onClick={() => ctx.scrollTo("js-Questionnaire")}
              >
                入力内容を確認する
              </button>
            </div>
          </form>
        </div>
      )}
      {state.completed && (
        <div className={style.container}>
          <h2 className={style.mainTitle}>アンケート入力内容確認</h2>

          <form
            className={style.form + " pure-form"}
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              props.onSubmitForm(questionnaireState);
              setState({ ...state, isSubmitted: true });
            }}
          >
            {labels.getInterviewReviewRead()}
            <div className={style.textareaReadonly}>
              {questionnaireState.interview_review}
            </div>

            {labels.getPsychologicalSafetyReviewRead()}
            {phase === Phases.SECOND_INTERVIEW &&
            <div
              className={
                style.textareaReadonly + " " + style.textareaUnderArrow
              }
            >
              {questionnaireState.psychological_safety_review}
            </div>}

            <div className="formBtnArea">
              <button
                className="btn btnEdit"
                onClick={() => setState({ ...state, completed: false })}
              >
                {"キャンセル"}
              </button>
              <button
                type="submit"
                className="btn btnSave"
                onClick={() => window.scrollTo(0, 0)}
              >
                {"送信"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
