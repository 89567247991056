import * as React from "react";
import style from "../../style/InterviewInfo.module.scss";
import { entryFirstPsychologicalSafetiesPath, entryPath } from "../../routes/paths"
import { Link } from "react-router-dom"
import { IEntry } from "../../types/state"
import { MEETING_KIND, PSYCHOLOGICAL_STATUS } from "../../lib/Definition"

interface IBeforehandCheckDispatchProps {}

interface IBeforehandCheckStateProps {
  entry: IEntry;
  kind: MEETING_KIND;
}

interface IBeforehandCheckProps extends IBeforehandCheckDispatchProps, IBeforehandCheckStateProps {}

export const BeforehandCheck = ({ entry, kind }: IBeforehandCheckProps) => {
  return (
    <tr>
      <th>
        面談資料の
        <br className="PCHide" />
        事前確認
      </th>
      <td>
        {kind === MEETING_KIND.FIRST_INTERVIEW && (
          <div className={style.psychologicalSafetyLinkArea}>
            {entry.partner.psychological_status !== PSYCHOLOGICAL_STATUS.first_done && (
              <>
                <div>
                  <span className={style.disabledLink}>
                    応募者の心理的安全性プログラムを確認する
                  </span>
                </div>
                <div className={style.meetingInfoTableDescription}>
                  応募者様の人柄や価値観の理解に役立ちます。事前に確認し面談の話題としてご活用ください。<br />
                  ※ 応募者の記入が完了していない場合は確認できません。
                </div>
              </>
            )}
            {entry.partner.psychological_status === PSYCHOLOGICAL_STATUS.first_done && (
              <Link
                to={`${entryFirstPsychologicalSafetiesPath}/${entry.id}?b=0`}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                応募者の心理的安全性プログラムを確認する
              </Link>
            )}
          </div>
        )}
        <div>
          <Link
            to={`${entryPath}/${entry.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            応募者の人材情報・Web履歴書を確認する
          </Link>
        </div>
        <div className={style.meetingInfoTableDescription}>
          応募者様の経歴及びスキルなどの基本情報や志望動機が確認できます。<br />
          スキルマッチなどの判断にご活用ください。
        </div>
      </td>
    </tr>
  )
}
