import * as React from "react"
import style from "../../style/Recruitment.module.scss"
import { ContractConditions, ICancelContractEntry, IEntry, IRecruitment } from "../../types/state"
import { isWithdrawn } from "../../lib/Util"
import { Link } from "react-router-dom"
import { PartnerProfileInfo } from "../PartnerProfileInfo"
import { PartnerEntryInfo } from "../PartnerEntryInfo"
import { PartnerSkillInfo } from "../PartnerSkillInfo"
import { PartnerFavoriteInfo } from "../PartnerFavoriteInfo"
import { FirstInterviewInfo } from "./FirstInterviewInfo"
import { SecondInterviewInfo } from "./SecondInterviewInfo"
import { PartnerDecline } from "./PartnerDecline"
import { ContractInfo } from "./ContractInfo"
import storedUseState from "../../lib/storedUseState"
import { InterviewReject } from "./InterviewReject"
import { ScoutForm } from "../ScoutForm"
import { CancelContractForm } from "./CancelContractForm"
import { ENTRY_STATUS, LabelType } from "../../lib/Definition"
import { LabelButton } from "../LabelButton"
import { ModalArgs } from "../../thunks"
import { Label } from "../Label"
import { PartnerManagementHeader } from "../headers/PartnerManagementHeader"
import { CancelInterviewButton } from "../buttons/CancelInterviewButton"
import { PartnerWithdrawInfo } from "../PartnerWithdrawInfo"
import DateWithOffset from "../../lib/DateWithOffset"
import { AboutMeeting } from "./AboutMeeting"
import { ApologyInfo } from "./ApologyInfo"
import { useMemo, useState } from "react"
import { useEntryStatus } from "../../hooks/useEntryStatus"
import { ContractEntry } from "./ContractEntry"
import { CancelContractButton } from "./CancelContractButton"
import { CanceledContractInfo } from "./CanceledContractInfo"
import { usePartnerProblems } from "../../hooks/usePartnerProblems"

export interface IEntryDispatchProps {
  dispatch: (args: any) => void
  confirmContract: (startDate: string) => Promise<boolean>
  acceptSecondInterview: (entry: IEntry) => void
  reject: (entry: IEntry, apology: string) => void
  acceptContract: (entry: IEntry, contractConditions: ContractConditions) => void
  changePath: (path: string) => void
  createScout: (partnerID: number, problemId: number, message: string) => void
  cancelContract(entry: IEntry, cancel_contract_entry: ICancelContractEntry): any
  alert: (content: string) => Promise<void>
  updateEntryLabel: (entry_id: number, label_status: LabelType) => void
  openModal: (args: ModalArgs) => void
}

export interface IEntryStateProps {
  entry: IEntry
  recruitment: IRecruitment
  registrantEmail: string
  psychological_status: string
  isNeededVerify: boolean
}

export interface IEntryProps extends IEntryDispatchProps, IEntryStateProps {}

export const EntryInfo = (props: IEntryProps) => {
  const entry: IEntry = props.entry
  const [state, setState] = storedUseState("EntryInfo", {
    showRejectInterview: false,
    showCancelContractForm: false
  })

  const [showScoutForm, setShowScoutForm] = useState(false)

  const showWithdrawInfo = useMemo(() => {
    if (!entry || !entry.partner) return false
    return isWithdrawn(entry.partner)
  }, [entry])

  const { hasActiveProblem, scoutableProblems, isEnteredForAllProblems } = usePartnerProblems(entry?.partner)

  const scoutable = useMemo(() => {
    return entry?.can_scout && !!scoutableProblems.length
  }, [entry, scoutableProblems])

  const { isAfterFirstInterview, isUnderContract, isAfterContract } = useEntryStatus(entry)

  if (!entry?.partner?.entries) return null

  const partner = entry.partner
  const problem = props.recruitment.problems.find((pro) => pro.id === (entry as IEntry).problem_id)

  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 className="contentsTitle contentsTitlePartner alignItemsCenter">
        <div className={style.titleWrap}>
          <span>応募ID：{entry.id} </span>
          <Label type={entry.label_status} />
        </div>
      </h2>

      <div className={style.navigation}>
        <LabelButton
          openModal={props.openModal}
          onSelect={(label_status) => {
            props.updateEntryLabel(entry.id, label_status)
          }}
        />
        {entry.partner.status !== "withdraw" &&
          (([
            ENTRY_STATUS.first_interview_accepted,
            ENTRY_STATUS.first_interview_date_requested,
            ENTRY_STATUS.first_interview_date_accepted
          ].includes(entry.entry_status) &&
            (!entry.meeting_at || entry.meeting_at.getTime() > new DateWithOffset().getTime())) ||
            ([
              ENTRY_STATUS.second_interview_accepted,
              ENTRY_STATUS.second_interview_date_requested,
              ENTRY_STATUS.second_interview_date_accepted
            ].includes(entry.entry_status) &&
              !entry.contract_reason)) &&
          (!entry.second_interview?.meeting_at ||
            entry.second_interview.meeting_at.getTime() > new DateWithOffset().getTime()) &&
          !state.showRejectInterview &&
          !entry.declined_by && (
            <CancelInterviewButton
              onClick={() => {
                setState({ ...state, showRejectInterview: true })
              }}
            />
          )}
      </div>

      {showWithdrawInfo && <PartnerWithdrawInfo partner={entry.partner} />}

      {!showWithdrawInfo && (
        <>
          {scoutable && (
            <>
              {showScoutForm === false ? (
                <>
                  <div className={style.entryFinish}>
                    <h1 className={style.entryFinishMessage}>
                      この応募者に<u>再度面談</u>を申し込むことが可能です。
                    </h1>
                    <p className={style.normal}>
                      「面談により、案件を進めるには社内調整が必要なことが分かり採用を一旦保留とした」
                      <br />
                      「募集案件では他の候補者を選んだが、他案件で相談したい」
                    </p>
                    <p className={style.normal + " mb40mb32"}>
                      上記のような理由で
                      <b className="joinsGreenTxt">
                        一度不成立となった応募者に対して、
                        <br className="SPHide" />
                        一次面談（Web会議）を再度お申し込みいただくことが可能です。
                      </b>
                      <br />
                    </p>
                    <button className="btn btnSave" onClick={() => setShowScoutForm(true)}>
                      この応募者に再面談を申し込む
                    </button>
                  </div>
                </>
              ) : (
                <ScoutForm
                  scoutableProblems={scoutableProblems}
                  isEntriedBefore
                  isNeededVerify={props.isNeededVerify}
                  hasActiveProblem={hasActiveProblem}
                  isEnteredForAllProblems={isEnteredForAllProblems}
                  onClickBackButton={() => setShowScoutForm(false)}
                  onClickSave={(problemId, message) => {
                    props.createScout(partner.id, problemId, message)
                  }}
                />
              )}
            </>
          )}
          {state.showRejectInterview && (
            <InterviewReject
              entry={entry}
              close={() => setState({ ...state, showRejectInterview: false })}
              reject={props.reject}
            />
          )}

          <CanceledContractInfo entry={entry} />

          {isUnderContract && (
            <div className={style.entryFinishMessageWrapper}>
              {(entry.condition_reward_type && entry.cloudsign_status === "manual_concluded") ||
              (!entry.condition_reward_type &&
                (entry.cloudsign_status === "concluded" ||
                  entry.cloudsign_status === "manual_concluded" ||
                  entry.cloudsign_status === "")) ? (
                <>
                  <h3 id="nowContract" className="contentsTitle contentsSubTitlePlane">
                    契約中
                  </h3>
                  {!state.showCancelContractForm && (
                    <CancelContractButton
                      entry={entry}
                      onClick={() => {
                        setState({ ...state, showCancelContractForm: true })
                      }}
                    />
                  )}
                  {state.showCancelContractForm && (
                    <CancelContractForm
                      entry={entry}
                      cancelContract={(cancelContractEntry: ICancelContractEntry) => {
                        props.cancelContract(entry, cancelContractEntry)
                      }}
                      alert={props.alert}
                      close={() => {
                        setState({ ...state, showCancelContractForm: false })
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <h1 className={style.entryFinishMessage}>
                    {entry.partner.last_name} {entry.partner.first_name} 様とのご契約の合意がとれました。
                  </h1>
                  <p className={style.entryFinishMessageDetail}>
                    電子契約締結サービス「クラウドサイン」より
                    <br className="SPHide" />
                    メールにて5営業日以内に契約書を送信いたします。
                    <br className="SPHide" />
                    契約希望条件について調整点がある場合は
                    <br className="SPHide" />
                    メッセージ機能でパートナー人材と連絡をとり、調整を行ってください
                  </p>
                </>
              )}
            </div>
          )}

          <ApologyInfo entry={entry} />

          {
            // パートナーからの辞退
            (entry.first_interview_reject_reason ||
              entry.second_interview_reject_reason ||
              entry.contract_reject_reason) && <PartnerDecline entry={entry} />
          }

          {isAfterContract && !entry.declined_by && <ContractEntry entry={props.entry} />}

          {
            // 二次面談結果受信後
            entry.contract_reason && (
              <ContractInfo
                entry={entry}
                alert={props.alert}
                registrantEmail={props.registrantEmail}
                confirmContract={props.confirmContract}
                acceptContract={props.acceptContract}
                reject={props.reject}
              />
            )
          }

          {
            // 一次面談結果受信後
            entry.second_interview_entry.succeed_reason && isAfterFirstInterview && !entry.declined_by && (
              <SecondInterviewInfo
                entry={entry}
                acceptSecondInterview={props.acceptSecondInterview}
                reject={props.reject}
              />
            )
          }

          {entry.meeting_at && (
            <FirstInterviewInfo
              entry={entry}
              changePath={props.changePath}
              psychological_status={props.psychological_status}
            />
          )}

          <PartnerProfileInfo
            {...partner}
            noNameMessage="※ 応募者の氏名は貴社が一次面談を希望した場合に公開されます"
            isCareerSheetDisclosed={true}
            isPRDisclosed={true}
            showVerification={true}
          />

          {entry.problem_id && <PartnerEntryInfo entry={entry} recruitment={props.recruitment} />}

          <PartnerSkillInfo {...partner} />

          <PartnerFavoriteInfo {...partner.favorite} isInsuranceDetailDisclosed={true} />

          {entry.entry_status === ENTRY_STATUS.first_interview_requested && !entry.declined_by && problem && (
            <AboutMeeting problem={problem} />
          )}

          {entry.entry_status === ENTRY_STATUS.first_interview_requested && !entry.declined_by && (
            <div className="formBtnArea">
              <Link className="btn btnEdit" to={`/entry_reject/${entry.id}`}>
                面談
                <br className="PCHide" />
                希望しない
              </Link>

              <Link className="btn btnSave" to={`/entry_accept/${entry.id}`}>
                面談
                <br className="PCHide" />
                希望する
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  )
}
