import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import FirstPsychologicalSafetyInfo, {
  IFirstPsychologicalSafetyInfoProps,
  IFirstPsychologicalSafetyInfoDispatchProps,
  IFirstPsychologicalSafetyInfoStateProps,
} from "../components/FirstPsychologicalSafetyInfo";
import { IStoreState, IFirstPsychologicalSafety } from "../types/state";
import { push } from "connected-react-router";
import { useState, useEffect } from "react";
import { editFirstPsychologicalSafetyPath } from "../routes/paths";
import { fetchPsychologicalSafety } from "../thunks";

const FirstPsychologicalSafety = (
  props: Partial<IFirstPsychologicalSafetyInfoProps> & {
    fetchPsychologicalSafety: () => Promise<{}>;
    changePath: (path: string) => void;
  }
) => {
  const [fetched, setFetched] = useState(!!props.firstPsychologicalSafety);
  const fetchPsychologicalSafety = props.fetchPsychologicalSafety;

  useEffect(() => {
    if (!fetched) {
      fetchPsychologicalSafety().then(() => {
        setFetched(true);
      });
    }
  }, [fetchPsychologicalSafety, fetched]);

  if (!fetched) {
    return null;
  }
  if (
    !props.firstPsychologicalSafety ||
    props.firstPsychologicalSafety.id === 0
  ) {
    setTimeout(() => props.changePath(editFirstPsychologicalSafetyPath));
    return null;
  }
  return (
    <FirstPsychologicalSafetyInfo
      firstPsychologicalSafety={
        props.firstPsychologicalSafety as IFirstPsychologicalSafety
      }
      changePath={props.changePath}
    />
  );
};

function mapStateToProps(
  state: IStoreState
): Partial<IFirstPsychologicalSafetyInfoStateProps> {
  return {
    firstPsychologicalSafety:
      state.firstPsychologicalSafeties[0] &&
      state.firstPsychologicalSafeties[0].isSelf
        ? state.firstPsychologicalSafeties[0]
        : undefined,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action<any>>
): IFirstPsychologicalSafetyInfoDispatchProps & {
  fetchPsychologicalSafety: () => Promise<{}>;
} {
  return {
    fetchPsychologicalSafety: () => {
      return dispatch(fetchPsychologicalSafety() as any);
    },
    changePath: (path: string) => {
      dispatch(push(path));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstPsychologicalSafety);
