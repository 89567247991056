import * as React from "react";
import { IEditCompany } from "./EditCompanyForm";
import DateWithOffset from "../lib/DateWithOffset";
import YearField from "./YearField";

interface IProfileDetailInputProps {
  values: IEditCompany;
  valueKey: string;
  label: string;
  inputType?: any;
  inputOverride?: any;
  updateValue?: (args: { [key: string]: any }) => void;
}

const ProfileDetailInput = (props: IProfileDetailInputProps) => {
  return (
    <div className="formDataBox">
      <label className="problemLabel">{props.label}</label>
      {props.inputOverride ||
        (props.inputType === "text" ? (
          <input
            type="text"
            id={props.valueKey}
            name={props.valueKey}
            className="formWidth_m"
            value={props.values[props.valueKey]}
            onChange={(e: React.ChangeEvent<any>) => {
              props.updateValue && props.updateValue(e.currentTarget.value);
            }}
          />
        ) : (
          <>
            <textarea
              id={props.valueKey}
              name={props.valueKey}
              className="textarea"
              value={props.values[props.valueKey]}
              onChange={(e: React.ChangeEvent<any>) => {
                props.updateValue && props.updateValue(e.currentTarget.value);
              }}
            />
          </>
        ))}
    </div>
  );
};

export const EditPresidentProfile = (props: {
  values: IEditCompany;
  validates: { [key: string]: (value: any) => string | null };
  updateValue: (args: { [key: string]: any }) => void;
}) => {
  return (
    <div className="formRow">
      <div className="formHead">
        代表者のプロフィール <span className="label label_opt">任意</span>
      </div>

      <div className="formData locationWrap">

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_position_name"}
          label={"肩書きと氏名を教えてください。"}
          inputType={"text"}
          updateValue={(val) => {
            props.updateValue({ president_position_name: val });
          }}
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_birth_year"}
          label={"生まれた年を教えてください。"}
          inputOverride={
            <YearField
              values={{
                year: props.values.president_birth_year,
                startYear: 1940,
                endYear: new DateWithOffset().getFullYear(),
                isSubmitted: props.values.isSubmitted,
              }}
              updateValue={(val) => {
                props.updateValue({ president_birth_year: val });
              }}
            />
          }
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_hometown"}
          label={"出身地を教えてください。"}
          inputType={"text"}
          updateValue={(val) => {
            props.updateValue({ president_hometown: val });
          }}
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_first_job_desc"}
          label={"卒業後、最初にどんな仕事をされていましたか？"}
          updateValue={(val) => {
            props.updateValue({ president_first_job_desc: val });
          }}
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_how_become"}
          label={"今のポジションに就いたきっかけを教えてください。"}
          updateValue={(val) => {
            props.updateValue({ president_how_become: val });
          }}
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_motivation"}
          label={"この仕事を選んだのはどんな理由ですか？"}
          updateValue={(val) => {
            props.updateValue({ president_motivation: val });
          }}
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_generation"}
          label={"社長で何代目ですか？"}
          inputType={"text"}
          updateValue={(val) => {
            props.updateValue({ president_generation: val });
          }}
        />

        <ProfileDetailInput
          values={props.values}
          valueKey={"president_hobbies"}
          label={"趣味を教えてください。"}
          updateValue={(val) => {
            props.updateValue({ president_hobbies: val });
          }}
        />
      </div>
    </div>
  );
};
