import { connect } from "react-redux";
import { INews, INotice, IStoreState } from "../types/state";
import NoticeList, {
  INoticeListProps,
  INoticeListStateProps,
} from "../components/NoticeList";
import * as React from "react";
import { Actions } from "../actions";
import { Action, Dispatch } from "redux";
import { push } from "connected-react-router";

class Notice extends React.Component<INoticeListProps & { news: INews[] }> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    if (
      process.env.NODE_ENV === "production" &&
      this.props.notices.length === 0
    ) {
      dispatch(Actions.fetchNews());
    }
  }
  componentWillUnmount() {
    const { dispatch } = this.props as any;
    const unchecked = this.props.notices.filter(
      (notice: INotice) => !notice.checked
    );
    if (unchecked.length > 0) {
      dispatch(
        Actions.checkNotices(unchecked.map((notice: INotice) => notice.id))
      );
    }
  }
  render() {
    return <NoticeList {...this.props} />;
  }
}

function mapStateToProps(state: IStoreState): INoticeListStateProps {
  const notices = [
    ...state.notices,
    ...state.news.map((n, idx) => {
      return {
        id: n.pubDate.getTime(),
        notifier_id: idx,
        notice_type: "news",
        checked: true,
        contents: n.title,
        link: n.link,
        created_at: n.pubDate,
      };
    }),
  ].sort((a, b) => b.created_at.getTime() - a.created_at.getTime());

  return {
    notices,
    masquerade: state.auth.masquerade,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    changePath: (path: string) => dispatch(push(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
