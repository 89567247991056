import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import EntryAcceptForm, { IEntryAcceptProps } from "../components/EntryAcceptForm";
import { Dispatch, Action } from "redux";
import { IEntry } from "../types/state";
import { updateEntry } from "../thunks";

class EntryAccept extends React.Component<IEntryAcceptProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    dispatch(Actions.fetchEntries());
  }
  render() {
    return <EntryAcceptForm {...this.props} />;
  }
}

function mapStateToProps(state: any) {
  const entryID = parseInt(window.location.href.split("/").pop() as string, 10);
  return {
    entry:
      state.entries.filter((entry: IEntry) => entry.id === entryID)[0] || {},
    recruitment: state.recruitment
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    acceptEntry: (entry: IEntry) =>
      dispatch(updateEntry({ ...entry, entry_status: "first_interview_accepted" }) as any)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryAccept);
