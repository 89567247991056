import { IRecruitment, PublishType, ProblemCheckedBoStatus } from "../types/state"
import { ActionsUnion, createAction } from "./types"
import { dToS } from "../lib/Util"
import { apiURL } from "./index"

export enum ProblemTypeKeys {
  CREATE_PROBLEM = "CREATE_PROBLEM",
  CREATE_PROBLEM_SUCCESS = "CREATE_PROBLEM_SUCCESS",
  CREATE_PROBLEM_FAIL = "CREATE_PROBLEM_FAIL",
  UPDATE_PROBLEM = "UPDATE_PROBLEM",
  UPDATE_PROBLEM_SUCCESS = "UPDATE_PROBLEM_SUCCESS",
  UPDATE_PROBLEM_FAIL = "UPDATE_PROBLEM_FAIL",
  DELETE_PROBLEM = "DELETE_PROBLEM",
  DELETE_PROBLEM_SUCCESS = "DELETE_PROBLEM_SUCCESS",
  DELETE_PROBLEM_FAIL = "DELETE_PROBLEM_FAIL"
}

export interface ICreateProblemParams {
  title: string
  problem_category: string
  problem_category_details: string[]
  section_other: string
  request: string
  situation: string
  goal: string
  publish_type: PublishType
}
const createProblem = (params: ICreateProblemParams) => {
  return createAction(ProblemTypeKeys.CREATE_PROBLEM, {
    request: {
      data: params,
      method: "post",
      url: `${apiURL}/problems`
    }
  })
}

export interface IUpdateProblemParams {
  id: number
  title: string
  problem_category: string
  problem_category_details: string[]
  section_other: string
  request: string
  situation: string
  goal: string
  publish_type: PublishType
  checked_bo_status: ProblemCheckedBoStatus
  expired_date: Date | null
}
const updateProblem = (params: IUpdateProblemParams) => {
  const data = {
    ...params,
    expired_date: dToS(params.expired_date)
  }
  return createAction(ProblemTypeKeys.UPDATE_PROBLEM, {
    request: {
      data,
      method: "put",
      url: `${apiURL}/problems/${params.id}`
    }
  })
}

const deleteProblem = (problemId: number) => {
  return createAction(ProblemTypeKeys.DELETE_PROBLEM, {
    request: {
      data: {},
      method: "delete",
      url: `${apiURL}/problems/${problemId}`
    }
  })
}

type createProblemSuccess = {
  type: ProblemTypeKeys.CREATE_PROBLEM_SUCCESS
  payload: {
    data: {
      recruitment: IRecruitment
    }
  }
}

type updateProblemSuccess = {
  type: ProblemTypeKeys.UPDATE_PROBLEM_SUCCESS
  payload: {
    data: {
      recruitment: IRecruitment
    }
  }
}

type deleteProblemSuccess = {
  type: ProblemTypeKeys.DELETE_PROBLEM_SUCCESS
  payload: {
    data: {
      recruitment: IRecruitment
    }
  }
}

type AutoGenerateActions = createProblemSuccess | updateProblemSuccess | deleteProblemSuccess

export const ProblemActions = {
  createProblem,
  updateProblem,
  deleteProblem
}
export type ProblemActions = ActionsUnion<typeof ProblemActions> | AutoGenerateActions
