import * as React from "react";
import style from "../style/Recruitment.module.scss";
import { LabelType } from "../lib/Definition";

export interface ILabelDispatchProps {
  onClick?: (type: LabelType) => void;
}

export interface ILabelStateProps {
  type: LabelType;
  separator?: string;
  interactive?: boolean;
  iconOnly?: boolean;
}

export interface ILabelProps
  extends ILabelDispatchProps,
    ILabelStateProps {}

export const Label = (props: ILabelProps) => {
  let icon,
    text,
    separator = "";

  switch (props.type) {
    case "interested":
      icon = style.happyFaceIcon;
      text = "興味あり";
      break;
    case "considering":
      icon = style.normalFaceIcon;
      text = "検討中";
      break;
    case "disinterested":
      icon = style.sadFaceIcon;
      text = "興味なし";
      break;
    case "nothing":
      text = "ラベルなし";
  }

  separator = props.separator || separator;

  const classNames: string[] = [];
  props.interactive && classNames.push(style.interactive);

  return (
    <>
    {props.iconOnly && icon == null ? (null) : (
    props.iconOnly ? (<i className={icon + " " + style.iconInline } />) : (
    <div
        className={`${style.faceIconWrapper} ${classNames.join(" ")}`}
        onClick={() => {
          props.onClick && props.onClick(props.type);
        }}
      >
        <div className={`${style.faceIconRow}`}>
          <i className={icon} />
          <span>
            {separator}⋯ {text}
          </span>
        </div>
    </div>))
    }
    </>
  );
};