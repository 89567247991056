import * as React from "react";
import { parseZenNum } from "../lib/Util";
import { ErrorMessage } from "./common/ErrorMessage"

export const NormalTextField = (props: {
  values: { [key: string]: any };
  label?: any;
  name: string;
  suffix?: string;
  cls?: string;
  type?: string;
  note?: string;
  error?: string | null;
  hint?: any;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  center?: boolean;
  validates?: { [key: string]: (value: any) => string | null };
  updateValue?: (args: { [key: string]: any }) => void;
}) => {
  const error =
    props.error ||
    (props.values.isSubmitted &&
      props.validates &&
      props.validates[props.name] &&
      props.validates[props.name](props.values));
  const [state, setState] = React.useState(
    typeof props.values[props.name] === "number"
      ? props.values[props.name] === -1
        ? ""
        : `${props.values[props.name]}`
      : props.values[props.name]
  );
  return (
    <>
      <div className={`${props.hint ? "formRow noUnderline" : "formRow"} ${props.center && "center mb40 noUnderline"}`}>
        {props.label && (
          <label className="formHead">
            {props.label}
            {props.required === true ? (
              <span className="label label_req">必須</span>
            ) : props.required === false ? (
              <span className="label label_opt">任意</span>
            ) : null}
          </label>
        )}
        <div className="formData">
          {props.note && <p className="formLead">{props.note}</p>}
          <input
            type="text"
            className={props.cls ? props.cls : "formWidth_s"}
            id={props.name}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readonly}
            name={props.name}
            value={state}
            maxLength={props.maxLength}
            onBlur={() => {
              if (
                (props.type === "number" &&
                !state.match(/^\d+$/) &&
                props.updateValue) ||
                (props.type === "step" &&
                !state.match(/^\d+(?:\.\d+)?$/) &&
                props.updateValue)
              )  {
                const num = parseZenNum(state);
                if (isNaN(num)) {
                  props.updateValue({
                    [props.name]: -1,
                  });
                  setState("");
                } else {
                  props.updateValue({
                    [props.name]: num,
                  });
                  setState(`${num}`);
                }
              }
            }}
            onChange={(e: React.ChangeEvent<any>) => {
              (props.type !== "number" || e.target.value.match(/^\d+$/)) &&
                props.updateValue &&
                props.updateValue({
                  [props.name]:
                    props.type === "number"
                      ? parseInt(e.target.value, 10)
                      : e.target.value,
                });
              setState(e.target.value);
            }}
          />
          {props.suffix && (
            <label className="formData_inline">{props.suffix}</label>
          )}
          <ErrorMessage cls="mb08">{error}</ErrorMessage>
        </div>
      </div>
      {props.hint && <div className="formHint">{props.hint}</div>}
    </>
  );
};
