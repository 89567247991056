import { IProblemCategoryDetail } from "../types/state"
import { Actions, TypeKeys } from "../actions"

const initialState: IProblemCategoryDetail[] = []

const problemCategoryDetails = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return action.payload.data.problem_category_details
    }
    default: {
      return state
    }
  }
}

export default problemCategoryDetails
