import * as React from "react"
import { Link } from "react-router-dom"
import { loginPath, resetPasswordPath, signUpPath } from "../routes/paths"
import { PasswordField } from "./PasswordField"
import { NormalTextField } from "./NormalTextField"
import { ErrorMessage } from "./common/ErrorMessage"
import { strToTag } from "../lib/Util"

export interface ILoginStateProps {
  email: string
  password: string
  nextPath: string
  isSubmitted: boolean
  error: string
  errorField?: string
}
export interface ILoginDispatchProps {
  updateValue: (args: any) => void
  login: (args: { nextPath: string; email: string; password: string }) => void
}
export interface ILoginProps extends ILoginStateProps, ILoginDispatchProps {}

const validates: { [key: string]: (value: any) => string | null } = {}
validates["email"] = (val) => {
  return val.email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
    ? null
    : "正しいメールアドレスを入力してください。"
}
validates["password"] = (val) => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : null
}
const LoginForm = (props: ILoginProps) => {
  return (
    <main className="is-outside">
      <div className="container">
        <h1>企業用ログインページ</h1>
        <p className="mt30 mb30 alCent fz14">
          案件に応募したいパートナー人材の方は、
          <br />
          <a href={`${process.env.REACT_APP_FRONT_URL}${loginPath}`} className="link">
            こちらの「<b>パートナー人材用ログインページ</b>」
          </a>
          より
          <br className="SPHide" />
          ログインしてください。
        </p>
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault()
            const errors = Object.keys(validates).filter((field) => {
              return validates[field](props)
            })
            if (errors.length > 0) {
              props.updateValue({ isSubmitted: true })
              window.scrollTo(0, 0)
              return
            }
            props.login({
              nextPath: props.nextPath,
              email: props.email,
              password: props.password
            })
          }}
        >
          <div className="singleColumnForm loginForm">
            {props.error && !props.errorField && <ErrorMessage>{strToTag(props.error)}</ErrorMessage>}
            <NormalTextField
              name="email"
              label="メールアドレス"
              values={props}
              updateValue={props.updateValue}
              error={props.errorField === "email" ? props.error : ""}
              validates={validates}
            />
            <PasswordField
              values={props}
              label="パスワード"
              error={props.errorField === "password" ? props.error : ""}
              updateValue={props.updateValue}
              validates={validates}
            />
          </div>
          <div className="formBtnArea formBtnAreaSingle">
            <button type="submit" className="btn btnLogin">
              ログイン
            </button>
          </div>
          <p className="agreementWrap">
            <Link to={resetPasswordPath} className="link">
              パスワードを忘れた方はこちら
            </Link>
          </p>
          <div className="formBtnArea formBtnAreaSingle">
            <Link to={signUpPath} className="link">
              まだ会員登録（無料）していない方はこちら
            </Link>
          </div>
        </form>
      </div>
    </main>
  )
}

export default LoginForm
