import { connect } from "react-redux";
import ResetPasswordForm, {
  IResetPasswordDispatchProps,
  IResetPasswordStateProps
} from "../components/ResetPasswordForm";
import { resetPassword } from "../thunks";
import { IStoreState, IAjaxError } from "../types/state";
import { Actions } from "../actions";
import { Dispatch } from "redux";

function mapStateToProps(state: IStoreState): IResetPasswordStateProps {
  return {
    email: state.viewState.email || "",
    isSubmitted: state.viewState.isSubmitted || false,
    error: state.viewState.error || ""
  };
}
function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): IResetPasswordDispatchProps {
  return {
    updateValue: (state: { [key: string]: any }) => {
      dispatch(Actions.setViewState({ ...state }) as any);
    },
    resetPassword: params => {
      dispatch(resetPassword(params) as any).catch((e: IAjaxError) => {
        dispatch(Actions.setViewState({
          error: e.payload.error.message,
          errorField: e.payload.error.field,
          scrollID: e.payload.error.field
        }) as any);
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordForm);
