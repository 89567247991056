import * as React from "react";
import { HomeButton } from "../buttons/HomeButton";
import { IEntry, IQuestionnaireState } from "../../types/state";
import { Phases } from "../../lib/Definition";
import { Questionnaire } from ".././Questionnaire";
import storedUseState from "../../lib/storedUseState";

export interface ISecondInterviewRequested {
  entry: IEntry;
  changePath: (path: string) => void;
  submitQuestionnaire: (questionnaire: IQuestionnaireState) => void;
}

export const SecondInterviewRequested = (props: ISecondInterviewRequested) => {
  const { entry } = props;

  const [state, setState] = storedUseState("EntryAcceptFinishState", {
    questionnaireSubmitted: false,
  });

  const onSubmitForm = (questionnaire: IQuestionnaireState) => {
    props.submitQuestionnaire(questionnaire);
    setState({ ...state, questionnaireSubmitted: true });
  };

  const getQuestionnaire = (phase: Phases) => {
    if (state.questionnaireSubmitted) {
      return null;
    }
    return (
      <Questionnaire phase={phase} entry={entry} onSubmitForm={onSubmitForm} />
    );
  };

  return (
    <div className="contentsWrapper-transparent">
      <div style={{ textAlign: "center", padding: "64px", background: "#fff" }}>
        {!state.questionnaireSubmitted && (
          <h2
            style={{margin: "0"}}
          >二次面談の希望を送信しました。</h2>
        )}
        {state.questionnaireSubmitted && (
          <h3 className="contentsSubTitle contentsSubTitlePlane">
            送信完了いたしました。
          </h3>
        )}
        {/*<p>
          二次面談では具体的な業務内容や契約内容の確認のほか、
          <br className="SPHide" />
          より深い相互理解のための
          <b className="joinsGreenTxt">二次面談向け心理的安全性プログラム</b>の
          <br className="SPHide" />
          実施をおすすめしております。
        </p>
        <p className="mb40">
          面談前に
          <a
            href={props.entry.second_psychological_safty_path}
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>こちら</b>
          </a>
          <b>よりシートをダウンロードして入力してください。</b>
        </p>*/}
        <p className="mb32mb16">
          {state.questionnaireSubmitted && (
            <>
              アンケートへのご協力
              <br className="PCHide" />
              ありがとうございました。
            </>
          )}
        </p>
        <p
          style={{marginBottom: "64px"}}
        >応募者からの面談日時候補のご連絡をお待ちください。</p>
        {getQuestionnaire(Phases.SECOND_INTERVIEW)}
        
      </div>
      <div style={{width: "100%", margin: "60px 0"}}>
        <HomeButton changePath={props.changePath} />
      </div>
    </div>
  );
};
