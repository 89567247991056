import * as React from "react";
import style from "../../style/Recruitment.module.scss";
import { dateStr, strToTag } from "../../lib/Util";
import { IEntry } from "../../types/state";

export const ContractEntry = (props: { entry: IEntry }) => {
  const entry = props.entry;
  return (
    <>
      <h3 className="contentsSubTitle">契約合意内容</h3>
      <div className={style.listArea}>
        <div className={style.listRow}>
          <div className={style.listHead}>
            <div className="mb4">パートナー人材の契約者名義</div>
            <small className="mt10 grayTxt">
              ※ 屋号など、個人名と異なる場合もあります。
            </small>
          </div>
          <div className={style.listData}>
            {entry.contract_partner_name || entry.partner.last_name + " " + entry.partner.first_name}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>
            {entry.condition_reward_type
              ? "報酬形態"
              : "時給単価（税抜）"}
          </div>
          <div className={style.listData}>
            {entry.condition_reward_type
              ? entry.condition_reward_type
              : entry.condition_reward
                ? `${entry.condition_reward} 円`
                : "未入力"}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>目安稼働時間／月</div>
          <div className={style.listData}>
            {entry.contract_work_time
              ? `${entry.contract_work_time} 時間`
              : "未入力"}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>契約開始日</div>
          <div className={style.listData}>
            {entry.contract_start_at
              ? dateStr(entry.contract_start_at)
              : "未入力"}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>
            具体的な業務内容についての
            <br className="SPHide" />
            追記・修正
          </div>
          <div className={style.listData}>
            {entry.contract_amendment
              ? strToTag(entry.contract_amendment)
              : "未入力"}
          </div>
        </div>
      </div>
    </>
  );
};
