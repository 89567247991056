import * as React from "react"
import style from "../style/Message.module.scss"
import { strToTag } from "../lib/Util"
import LinkifiedText from "./LinkifiedText"
import { useEffect, useMemo, useState } from "react"
import clsx from "clsx"

const PADDING_BOTTOM = 30

export interface ITimeLineMessage {
  id: number
  avater: string
  url: string | null
  name: string
  isOwn: boolean
  checked: boolean
  postedTime: string
  content: string
  content_type: string
}

export interface ITimeLineDispatchProps {
  deleteMessage: (id: number) => void
}

export interface ITimeLineStateProps {
  messages: ITimeLineMessage[]
  withoutWrap?: boolean
  scroll?: number
}

export interface ITimeLineProps extends ITimeLineDispatchProps, ITimeLineStateProps {}

export const Timeline = (props: ITimeLineProps) => {
  const [scroll, setScroll] = useState(props.scroll)
  const [messageSize, setMessageSize] = useState(props.messages.length)

  const paddingBottom = useMemo(() => {
    if (props.withoutWrap) return PADDING_BOTTOM
    return PADDING_BOTTOM + (props.scroll ?? 0)
  }, [props.withoutWrap, props.scroll])

  useEffect(() => {
    if (scroll !== props.scroll || messageSize !== props.messages.length) {
      window.scrollTo(0, 99999)
      setScroll(props.scroll)
      setMessageSize(props.messages.length)
    }
  }, [props.scroll, props.messages, scroll, messageSize])

  return (
    <div className={props.withoutWrap ? "" : style.boardWrap} style={{ paddingBottom }}>
      {props.messages.map((message) => {
        return (
          <React.Fragment key={message.id}>
            <div className={clsx(style.bubble, message.isOwn ? style.bubbleOwn : style.bubbleGuest)}>
              {!message.isOwn && <div className={style.bubbleIcon + " " + style.bubbleIconGuest} />}
              <div className={style.bubbleTxt}>
                <p className={message.isOwn ? style.bubbleTxtNameOwn : style.bubbleTxtName}>{message.name}</p>
                {message.content_type === "text" && (
                  <p className={style.bubbleTxtMessage}>
                    {" "}
                    <LinkifiedText target="_blank">{strToTag(message.content)} </LinkifiedText>
                  </p>
                )}
                {message.content_type === "removed" && (
                  <p className={style.bubbleTxtMessage}>
                    <i style={{ fontSize: "0.8rem" }}>メッセージが削除されました</i>
                  </p>
                )}
                {message.content_type === "image" && message.url && (
                  <img style={{ width: "100%" }} src={message.url} alt="画像" />
                )}
                {["text", "image", "removed"].indexOf(message.content_type) === -1 && message.url && (
                  <a href={message.url} target="_blank" rel="noopener noreferrer">
                    {message.content_type}
                  </a>
                )}
                <div className={style.bubbleTxtTimestamp}>{message.postedTime}</div>

                {message.isOwn && !message.checked && message.content_type !== "removed" && (
                  <div
                    className={style.bubbleOption + " " + style.bubbleDelete}
                    onClick={() => props.deleteMessage(message.id)}
                  />
                )}
                {message.isOwn && message.checked && message.content_type !== "removed" && (
                  <div className={style.bubbleOption + " " + style.bubbleAlreadyRead}>既読</div>
                )}
              </div>
              {message.isOwn && <div className={style.bubbleIcon + " " + style.bubbleIconOwn} />}
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}
