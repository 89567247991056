import * as React from "react";
import { connect } from "react-redux";
import { Actions } from "../actions";
import { Dispatch, Action } from "redux";
import { IStoreState } from "../types/state";
import { push } from "connected-react-router";
import { HomePage, IHomeProps, IHomeStateProps } from "../components/HomePage";

class Home extends React.Component<IHomeProps> {
  componentDidMount() {
    const { dispatch } = this.props as any;
    dispatch(Actions.fetchEntries());
  }
  render() {
    return <HomePage {...this.props} />;
  }
}

function mapStateToProps(state: IStoreState): IHomeStateProps {
  return {
    entries: state.entries.map((entry) => {
      return {
        ...entry,
        problem: state.recruitment.problems.filter(
          (p) => p.id === entry.problem_id
        )[0],
      };
    }),
    recruitment: state.recruitment,
    psychological_status: state.company.psychological_status,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    dispatch,
    changePath: (path: string) => dispatch(push(path)),
    updateValue: (state: { [key: string]: any }) =>
      dispatch(Actions.setViewState(state))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
