import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Timeline, ITimeLineMessage } from "./Timeline"
import { roomsPath } from "../routes/paths"
import { IRoom, IStoreState } from "../types/state"
import style from "../style/Message.module.scss"
import { showProhibitedMatters } from "../thunks"
import { JOINS_COMPANY_ID } from "../lib/Definition"

const TEXT_AREA_MIN_HEIGHT = 22

export interface IMessageBoardDispatchProps {
  fetchMessages: () => any
  checkMessages: (messages: ITimeLineMessage[]) => void
  createMessage: (args: { file?: File; content?: string; content_type: string }) => void
  deleteMessage: (id: number) => void
  changePath: (path: string) => void
  updateValue: (args: { [key: string]: any }) => void
}

export interface IMessageBoardStateProps {
  content: string
  commentHeight: number
  room?: IRoom
  masquerade: boolean
  messages: ITimeLineMessage[]
}

export interface IMessageBoardProps extends IMessageBoardDispatchProps, IMessageBoardStateProps {}

export const MessageBoard = ({
  fetchMessages,
  checkMessages,
  createMessage,
  deleteMessage,
  changePath,
  room,
  masquerade,
  messages
}: IMessageBoardProps) => {
  const company = useSelector((state: IStoreState) => state.company)
  const dispatch = useDispatch()
  const formWrapRef: React.RefObject<HTMLDivElement> = useRef(null)
  const fileInputRef = useRef(null)
  const commentRef = useRef(null)
  const [fetched, setFetched] = useState(false)
  const [checked, setChecked] = useState(false)
  const [content, setContent] = useState("")
  const [commentHeight, setCommentHeight] = useState(TEXT_AREA_MIN_HEIGHT)
  const [formHeight, setFormHeight] = useState(0)

  useEffect(() => {
    fetchMessages().finally(() => {
      setFetched(true)
    })
  }, [fetchMessages])

  useEffect(() => {
    if (checked || masquerade || messages.length === 0) return
    checkMessages(messages)
    setChecked(true)
  }, [checkMessages, masquerade, messages, checked, setChecked])

  const onClickSend = (e: React.MouseEvent) => {
    e.preventDefault()
    createMessage({
      content,
      content_type: "text"
    })
    setContent("")
  }

  const onClickProhibitedMattersLink = useCallback(() => {
    dispatch(showProhibitedMatters(true))
  }, [dispatch])

  useEffect(() => {
    if (commentRef.current) {
      const dom: HTMLTextAreaElement = commentRef.current as any
      if (dom) {
        dom.scrollTop = 9999
        setCommentHeight(Math.min(Math.max(TEXT_AREA_MIN_HEIGHT, dom.scrollTop), window.innerHeight * 0.3))
      }
    }
  }, [content])

  useEffect(() => {
    if (formWrapRef.current && messages.length > 0) {
      setFormHeight(formWrapRef.current.offsetHeight)
    }
  }, [content, messages, commentHeight])

  useEffect(() => {
    // JOINSの場合ポーリング
    if (company.id === JOINS_COMPANY_ID) {
      const interval = setInterval(() => fetchMessages(), 10 * 1000)
      return () => clearInterval(interval)
    }
  }, [company.id, fetchMessages])

  if (!room || room.status === "discarded") {
    return null
  }

  return (
    <>
      <div className={style.messageHeader}>
        <div className={style.messageHeaderWrap}>
          <span className={style.messageBack + " btn btnEdit"} onClick={() => changePath(roomsPath)}>
            戻る
          </span>
          <h2 className={style.messageHeaderTitle}>{room.name}さんとのメッセージ</h2>
        </div>
      </div>

      <div>
        <Timeline messages={messages} deleteMessage={deleteMessage} scroll={formHeight} />
        {fetched && messages.length === 0 && <p className="alCent">下の送信フォームからメッセージを送ってみましょう</p>}
        <div ref={formWrapRef} className={style.messageFormWrap}>
          <div className={style.messageFormArea}>
            <span className={style.prohibitedMattersContentDetail}>
              ※メッセージの内容は、直接契約などの不正やトラブル防止のために、事務局が確認する場合があります。
              <br />
              JOINSでの禁止行為については、
              <span className="link" onClick={onClickProhibitedMattersLink}>
                こちら
              </span>
              よりご確認ください。
            </span>
          </div>
          <div className={style.messageFormArea} style={{ height: commentHeight + 30 }}>
            <div
              className={style.messageFormAdd}
              onClick={() => {
                ;(fileInputRef.current as any).click()
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={(e: any) => {
                  const file = e.currentTarget.files[0]
                  if (!file) {
                    return
                  }
                  const content_type = file.type.match(/^image\//) ? "image" : file.name
                  e.preventDefault()
                  createMessage({ file, content_type })
                }}
              />
            </div>
            <div className={style.messageFormAddNotice}>画像やファイルを追加</div>

            <div className={style.messageFormTextArea}>
              <textarea
                style={{ height: commentHeight + 18 }}
                value={content}
                onChange={(e) => {
                  setContent(e.target.value)
                }}
              />
              <textarea
                ref={commentRef}
                style={{
                  position: "absolute",
                  left: 9999,
                  height: 0,
                  zIndex: -1,
                  width: "100%"
                }}
                value={content}
                onChange={() => {}}
                rows={1}
              />
            </div>
            <div className={style.messageFormBtn}>
              <button onClick={onClickSend} className="btn btnSave">
                送信
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
