import * as React from "react";
import styles from "../../style/ActivateStaff.module.scss"

export const Expired = () => {
  return (
    <main>
      <div className="container">
        <h1 className={styles.pageTitle}>
          JOINS企業向け管理ツール
          <br />
          担当者登録フォーム
        </h1>
        <div className={styles.leadText}>
          URLの有効期限が切れています。
          <br />
          管理者に再度招待を発行して頂くようご依頼ください。
        </div>
      </div>
    </main>
  );
};
