import * as React from "react"
import { useCallback, useContext, useMemo, useState } from "react"
import { IProblem, IRecruitment, IStoreState } from "../../types/state"
import { Link } from "react-router-dom"
import * as Definition from "../../lib/Definition"
import { problemCopyPath, problemEditPath } from "../../routes/paths"
import { strToTag, dateStr, getProblemCategoryDetailNames, getProblemCategoryName } from "../../lib/Util"
import style from "../../style/Recruitment.module.scss"
import { PROBLEM_STATUS, PROBLEM_STATUS_EN } from "../../lib/Definition"
import { useSelector } from "react-redux"
import { StatusBadge } from "../StatusBadge"
import clsx from "clsx"
import { ScrollContext } from "../../containers/ScrollToTop"
import { useStaff } from "../../hooks/useStaff"

interface IProblemInfoDispatchProps {
  onClickDelete: (problemId: number) => void
}

interface IProblemInfoStateProps {
  problem: IProblem
  recruitment: IRecruitment
}

interface IProblemInfoProps extends IProblemInfoDispatchProps, IProblemInfoStateProps {}

export const ProblemInfo = ({ problem, recruitment, onClickDelete }: IProblemInfoProps) => {
  const ctx = useContext(ScrollContext)

  const problemCategory = useSelector((state: IStoreState) => state.problemCategories)
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)

  const [isOpened, setIsOpened] = useState(false)

  const elementId = useMemo(() => `problem-${problem.id}`, [problem.id])

  const onClickShowDetail = useCallback(() => {
    if (isOpened) ctx.scrollTo(elementId)
    setIsOpened(!isOpened)
  }, [isOpened, setIsOpened, ctx, elementId])

  const { isStaffPermittedProblem, isIndividualStaff } = useStaff()
  const showActionButtons = useMemo(() => {
    return isStaffPermittedProblem(problem.id)
  }, [isStaffPermittedProblem, problem.id])

  return (
    <div id={elementId} className={clsx(style.taskWrap, isOpened && style.taskWrapOpened)} key={elementId}>
      <h3 className={style.taskNumber}>
        <StatusBadge
          value={PROBLEM_STATUS[problem.status]}
          stroked
          active={problem.status === PROBLEM_STATUS_EN.active}
          transparent={problem.status === PROBLEM_STATUS_EN.closed}
        />
        {recruitment.problems.length === 1 && "案件情報"}
        {recruitment.problems.length !== 1 && `案件情報（案件ID：${problem.id}）`}
      </h3>

      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>募集タイトル</dt>
        <dd>{strToTag(problem.title)}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>業務カテゴリー</dt>
        <dd>{getProblemCategoryName(problemCategory, problem)}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>業務カテゴリー詳細</dt>
        <dd>{getProblemCategoryDetailNames(problemCategoryDetails, problem)}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>
          ① 契約から3ヶ月経過時点で、
          <br className="SPHide" />
          パートナー人材に
          <br className="SPHide" />
          実現して欲しい状態
        </dt>
        <dd>{strToTag(problem.request)}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>
          ② 1の実現が必要だと考えた
          <br className="SPHide" />
          背景と1の実現が
          <br className="SPHide" />
          できていない理由
        </dt>
        <dd>{strToTag(problem.situation)}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>
          ③ 1を実現した後に、
          <br className="SPHide" />
          さらに実現していきたいこと
        </dt>
        <dd>{strToTag(problem.goal)}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>公開設定</dt>
        <dd>{Definition.PUBLISH_TYPE_STR[problem.publish_type]}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>募集期限</dt>
        <dd>{dateStr(problem.expired_date) || "-"}</dd>
      </dl>
      <dl className={clsx(style.taskList, style.taskListDtWide)}>
        <dt>案件ステータス</dt>
        <dd>{PROBLEM_STATUS[problem.status]}</dd>
      </dl>
      {problem.video_url && (
        <dl className={clsx(style.taskList, style.taskListDtWide)}>
          <dt>動画</dt>
          <dd>
            <div dangerouslySetInnerHTML={{ __html: problem.video_url }} />
          </dd>
        </dl>
      )}

      {showActionButtons && (
        <div className={clsx(style.linkButtonArea)}>
          <Link to={`${problemEditPath}/${problem.id}`} className={clsx("btn btnEdit", style.linkButton)}>
            編集
          </Link>
          {!isIndividualStaff && (
            <Link to={`${problemCopyPath}/${problem.id}`} className={clsx("btn btnEdit", style.linkButton)}>
              複製
            </Link>
          )}
          <button className={clsx("btn", style.linkButton)} onClick={() => onClickDelete(problem.id)}>
            削除
          </button>
        </div>
      )}

      <div className={clsx(style.taskWrapMask, isOpened && style.taskWrapMaskOpened)}>
        <span
          className={clsx(style.showDetailLink, isOpened && style.showDetailLinkOpened)}
          onClick={() => onClickShowDetail()}
        >
          {isOpened ? "閉じる" : "詳しく見る"}
        </span>
      </div>
    </div>
  )
}
