import { IEntry } from "../types/state"
import { useMemo } from "react"
import { ENTRY_STATUS, GUIDANCE_MEETING_STATUS } from "../lib/Definition"

export const useEntryStatus = (entry?: IEntry) => {
  const isSecondInterviewSkipped = useMemo(() => {
    if (!entry) return false
    return entry.entry_status === ENTRY_STATUS.second_interview_skipped
  }, [entry])

  const isNotGuidanceMeetingDone = useMemo(() => {
    if (!entry) return false
    return entry.guidance_meeting_status === GUIDANCE_MEETING_STATUS.un_executed
  }, [entry])

  const isAfterFirstInterview = useMemo(() => {
    if (!entry) return false
    return [
      ENTRY_STATUS.second_interview_requested,
      ENTRY_STATUS.second_interview_accepted,
      ENTRY_STATUS.second_interview_date_requested,
      ENTRY_STATUS.second_interview_date_accepted,
      ENTRY_STATUS.second_interview_end,
      ENTRY_STATUS.contract_requested,
      ENTRY_STATUS.contract_accepted,
      ENTRY_STATUS.contract_end
    ].includes(entry.entry_status)
  }, [entry])

  const isUnderContract = useMemo(() => {
    if (!entry) return false
    return entry.entry_status === ENTRY_STATUS.contract_accepted && !entry.declined_by
  }, [entry])

  const isAfterContract = useMemo(() => {
    if (!entry) return false
    return [ENTRY_STATUS.contract_accepted, ENTRY_STATUS.contract_end].includes(entry.entry_status)
  }, [entry])

  const isContractCanceled = useMemo(() => {
    if (!entry) return false
    return ENTRY_STATUS.contract_end === entry?.entry_status
  }, [entry])

  return {
    isSecondInterviewSkipped,
    isNotGuidanceMeetingDone,
    isAfterFirstInterview,
    isUnderContract,
    isAfterContract,
    isContractCanceled
  }
}
