import * as React from "react";
import { entryPath } from "../../routes/paths";
import { Link } from "react-router-dom";
import { IEntry } from "../../types/state";
import styles from "../../style/ScheduleForm.module.scss";

export interface IPartnerDetailsButton {
  entry: IEntry;
}

export const PartnerDetailsButton = (props: IPartnerDetailsButton) => {
  return (
    <span className="contentsTitleCompanyLink">
      <Link
        to={`${entryPath}/${props.entry.id}`}
        className={styles.companyContentLink}
      >
        人材情報を見る
      </Link>
    </span>
  );
};
