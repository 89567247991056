import * as React from "react";
import { IEntry, IRecruitment, IProblem, IScout } from "../types/state"
import style from "../style/Table.module.scss";
import { EntrySummaryTable } from "./EntrySummaryTable";
import { EntrySummaryTableTitle } from "./EntrySummaryTableTitle";
import { EntrySummaryScoutRow } from "./EntrySummaryScoutRow";
import { PROBLEM_STATUS_EN } from "../lib/Definition"

export interface IEntriesDispatchProps {
  changePath: (path: string) => void;
}

export interface IComputedEntry extends IEntry {
  problem: IProblem;
}

export interface IEntriesStateProps {
  recruitment: IRecruitment;
  psychological_status: string;
  entries: IComputedEntry[];
}

export interface IEntriesProps extends IEntriesDispatchProps, IEntriesStateProps {}

const buildScoutListByProblemId = (problemId: number | undefined, scouts: IScout[], entries: IEntry[]) => {
  const scoutEntries = scouts
    .filter(scout => scout.problem?.id === problemId)
    .map(scout => (
      entries.find((entry) => (
        entry?.scout?.id === scout.id && entry.created_at > scout.created_at
      ))
    ))
    .filter(e => e) as IComputedEntry[]

  const scoutsWithoutEntry = scouts.filter((scout) => (
    scout.status !== "accepted" && scout.problem?.id === problemId
  ))

  return { scoutEntries, scoutsWithoutEntry }
}

export const EntryList = (props: IEntriesProps) => {
  const scoutList = React.useMemo(() => {
    const resultList: {
      problem: IProblem | null,
      scoutEntries: IComputedEntry[],
      scoutsWithoutEntry: IScout[]
    }[] = []
    // 案件指定なしのスカウト
    const { scoutEntries, scoutsWithoutEntry } = buildScoutListByProblemId(undefined, props.recruitment.scouts, props.entries)
    if (scoutEntries.length || scoutsWithoutEntry.length) {
      resultList.push({ problem: null, scoutEntries, scoutsWithoutEntry })
    }
    // 案件指定ありのスカウト
    props.recruitment.problems.forEach(problem => {
      const { scoutEntries, scoutsWithoutEntry } = buildScoutListByProblemId(problem.id, props.recruitment.scouts, props.entries)
      if (scoutEntries.length || scoutsWithoutEntry.length) {
        resultList.push({ problem, scoutEntries, scoutsWithoutEntry })
      }
    })
    return resultList
  }, [props.recruitment.scouts, props.recruitment.problems, props.entries])

  return (
    <>
      <p className="grayTxt">
        ※ 応募があったパートナー人材の氏名は貴社が一次面談を希望した場合に公開されます
      </p>
      {scoutList.length > 0 && (
        <>
          <h3 className="contentsSubTitle contentsSubTitleProblem">
            スカウト／再申し込みしたパートナー人材
          </h3>
          {scoutList.map((data, index) => (
            <EntrySummaryTable
              key={data.problem?.id ?? 0}
              title={
                data.problem
                  ? <EntrySummaryTableTitle
                      index={index-1}
                      problem={data.problem}
                      cls={style.scoutTableTitle}
                      bordered={data.problem.status !== PROBLEM_STATUS_EN.closed}
                    />
                  : <h3 className={style.scoutTableTitle}>対象の募集案件なし</h3>
              }
              changePath={props.changePath}
              entries={data.scoutEntries}
              psychological_status={props.psychological_status}
              customRows={data.scoutsWithoutEntry.map((scout) => (
                <EntrySummaryScoutRow
                  key={scout.id}
                  scout={scout}
                  changePath={props.changePath}
                />
              ))}
            />
          ))}
        </>
      )}

      <h3 className="contentsSubTitle contentsSubTitleProblem">
        案件別の応募者一覧
      </h3>
      {props.recruitment.problems.map((problem, index) => {
        const entries = props.entries.filter(entry => entry?.problem_id === problem.id && !entry?.scout)
        return (
          <EntrySummaryTable
            key={index}
            title={<EntrySummaryTableTitle index={index} problem={problem} />}
            changePath={props.changePath}
            entries={entries}
            psychological_status={props.psychological_status}
          />
        );
      })}
    </>
  );
};
