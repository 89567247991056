import { Actions, TypeKeys } from "../actions";
import { IRegistrant } from "../types/state";

const initialState: IRegistrant = {
  id: 0,
  email: "",
  first_kana: "",
  first_name: "",
  last_kana: "",
  last_name: "",
  position: "",
  tel: "",
  department: ""
};

const partner = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TypeKeys.RECOVER_DATA: {
      return Object.assign({}, action.payload.registrant);
    }
    case TypeKeys.LOGIN_SUCCESS:
    case TypeKeys.SIGNUP_SUCCESS: {
      return Object.assign({}, action.payload.data.registrant);
    }
    case TypeKeys.INITIAL_DATA_SUCCESS: {
      return Object.assign({}, action.payload.data.registrant);
    }
    case TypeKeys.UPDATE_REGISTRANT_SUCCESS: {
      return Object.assign({}, action.payload.data.registrant);
    }
    case TypeKeys.LOGOUT_SUCCESS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
export default partner;
