import { routerMiddleware } from "connected-react-router";
import { createHashHistory, createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import ajax from "../middlewares/ajax";
import saveState from "../middlewares/saveState";
import sendAction from "../middlewares/sendAction";
import createRootReducer from "../reducers";

export function getStoreAndHistory() {
  let history;

  if (window.Cordova) {
    history = createHashHistory();
  } else {
    history = createBrowserHistory({ basename: "/app_companies/" });
  }

  const store = createStore(
    createRootReducer(history), // new root reducer with router state
    {},
    compose(
      applyMiddleware(
        routerMiddleware(history),
        ajax,
        thunk,
        saveState,
        sendAction
      )
    )
  );
  return { store, history };
}
