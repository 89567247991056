import * as React from "react"
import { Box, Grid } from "@material-ui/core"
import CancelButton from "../../buttons/CancelButton"
import SendButton from "../../buttons/SendButton"

export interface ICancelOrSend {
  disabled?: boolean
  onCancel: () => void
  onSend: () => void
}

const CancelOrSend = (props: ICancelOrSend) => {
  return (
    <Box mt={3}>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <Grid item container direction="column" xs={6} alignItems="stretch">
          <CancelButton onClick={() => props.onCancel()} />
        </Grid>
        <Grid item container direction="column" xs={6} alignItems="stretch">
          <SendButton onClick={() => props.onSend()} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CancelOrSend
