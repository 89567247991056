import * as React from "react";
import { IEntry } from "../types/state";
import style from "../style/Recruitment.module.scss";
import secondStyle from "../style/MeetingRequestForm.module.scss";
import { TextAreaField } from "./TextAreaField";

export interface IReScheduleFormDispatchProps {
  close: () => void;
  reSchedule: (entry_id: number, message: string) => void;
}

export interface IReScheduleFormStateProps {
  entry: IEntry;
}
export interface IReScheduleFormProps
  extends IReScheduleFormDispatchProps,
    IReScheduleFormStateProps {}

export const RescheduleForm = (props: IReScheduleFormProps) => {
  const [state, setState] = React.useState({ message: "", complete: false });
  return state.complete ? (
    <>
      <div className="contentsWrapper mb32mb16">
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            props.reSchedule(props.entry.id, state.message);
          }}
        >
          <div className={style.scoutInfoText}>
            以下の内容でよろしければ、
            <b className="joinsPinkTxt">送信するボタン</b>
            をクリックしてください。
          </div>
          <div className={style.scountInfoReply + " mb40mb32"}>
            <div className={style.scoutInfoTitle}>応募者へのメッセージ</div>
            <div className={style.scoutInfoMessage}>
              {state.message.split("\n").map((m, idx) => (
                <div key={idx}>{m}</div>
              ))}
            </div>
          </div>
          <div className="formBtnArea">
            <button
              className="btn btnEdit"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setState({ ...state, complete: false });
              }}
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="btn btnSave"
              disabled={!state.message}
            >
              送信
            </button>
          </div>
        </form>
      </div>
    </>
  ) : (
    <>
      <div className="contentsWrapper mb32mb16">
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            setState({ ...state, complete: true });
          }}
        >
          <p className={secondStyle.selectAreaTitleNoNum}>
            <b>
              下記にメッセージを添えて、応募者に別日程の面談候補日時の再選択を依頼してください。
            </b>
          </p>
          <TextAreaField
            name="message"
            label="メッセージ"
            type="scout"
            required={true}
            values={state}
            validates={{
              message: e => (e.message ? null : "メッセージを入力してください")
            }}
            updateValue={val => setState({ ...state, message: val["message"] })}
          />
          <div className="formBtnArea">
            <button className="btn btnEdit" onClick={() => props.close()}>
              キャンセル
            </button>
            <button
              type="submit"
              className="btn btnSave"
              disabled={!state.message}
            >
              送信内容を
              <br className="PCHide" />
              確認する
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
