import { apiURL } from ".";
import { CustomFile } from "../components/CheckTrustForm/CustomUploader/useImageUploader";
import { ActionsUnion, createAction } from "./types";

export enum CompanyVerificationTypeKeys {
  SEND_COMPANY_VERIFICATION = "SEND_COMPANY_VERIFICATION",
  SEND_COMPANY_VERIFICATION_SUCCESS = "SEND_COMPANY_VERIFICATION_SUCCESS",
  SEND_COMPANY_VERIFICATION_FAIL = "SEND_COMPANY_VERIFICATION_FAIL",
  CHECK_COMPANY_VERIFICATION = "CHECK_COMPANY_VERIFICATION",
  CHECK_COMPANY_VERIFICATION_SUCCESS = "CHECK_COMPANY_VERIFICATION_SUCCESS",
  CHECK_COMPANY_VERIFICATION_FAIL = "CHECK_COMPANY_VERIFICATION_FAIL",
}

const sendCompanyVerification = (
  corporate_number: number | null,
  personal_files: CustomFile[] | null,
  open_business_files: CustomFile[] | null
  ) => {
    const params = {};
    if (personal_files && open_business_files) {
      personal_files.forEach((val: File, i) => {
        params[`personal_files[${i}]`] = val;
        });
      open_business_files.forEach((val: File, i) => {
        params[`open_business_files[${i}]`] = val;
        });
    }

  return createAction(CompanyVerificationTypeKeys.SEND_COMPANY_VERIFICATION, {
    request: {
      method: "post",
      url: `${apiURL}/company_verifications`,
      data: {
        corporate_number,
        ...params,
      },
    },
  });
};

const checkCompanyVerification = () => {
  return createAction(CompanyVerificationTypeKeys.CHECK_COMPANY_VERIFICATION, {
    request: {
      method: "get",
      url: `${apiURL}/company_verifications/check_verified`,
      data: {},
    },
  });
};

// MiddleWareのajaxで自動生成されるActionの定義
type sendCompanyVerificationSuccess = {
  type: CompanyVerificationTypeKeys.SEND_COMPANY_VERIFICATION_SUCCESS;
  payload: {
    data: {}
  };
};

type checkCompanyVerificationSuccess = {
  type: CompanyVerificationTypeKeys.CHECK_COMPANY_VERIFICATION_SUCCESS;
  payload: {
    data: { isRequested: boolean, verified: boolean }
  };
};

type AutoGenerateActions =
  | sendCompanyVerificationSuccess
  | checkCompanyVerificationSuccess

export const CompanyVerificationActions = {
  sendCompanyVerification,
  checkCompanyVerification,
};
export type CompanyVerificationActions =
  | ActionsUnion<typeof CompanyVerificationActions>
  | AutoGenerateActions;
