import * as React from "react"
import style from "../style/Badge.module.scss"

export interface IStatusBadgeProps {
  value: any
  active?: boolean
  stroked?: boolean
  bordered?: boolean
  transparent?: boolean
}

export const StatusBadge = (props: IStatusBadgeProps) => {
  const type = props.stroked ? style.stroked : style.default
  let active = ""
  let transparent = ""
  if (props.active) {
    active = props.stroked ? style.stroked__active : style.default__active
  }
  if (props.transparent) {
    transparent = props.stroked ? style.stroked__transparent : style.default__transparent
  }
  const bordered = props.bordered ? style.default__bordered : ""
  return <span className={`${type} ${active} ${bordered} ${transparent}`}>{props.value}</span>
}
