import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { Actions } from "../actions";
import { IStoreState } from "../types/state";
import { IMonthlyQuestionnaireParams, MonthlyQuestionnaireActions } from "../actions/monthly_questionnaire"
import { monthlyQuestionnaireFinishPath } from "../routes/paths"

export function createMonthlyQuestionnaire(params: IMonthlyQuestionnaireParams) {
  return (dispatch: Dispatch<any>, _getState: () => IStoreState) => {
    return new Promise<{}>((resolve, reject) => {
      dispatch(Actions.setViewState({ loaderVisible: true }))
      dispatch(MonthlyQuestionnaireActions.createMonthlyQuestionnaire(params) as any)
        .then((res: any) => {
          dispatch(push(monthlyQuestionnaireFinishPath))
          resolve(res)
        })
        .catch((e: any) => {
          dispatch(Actions.setViewState({ error: e.payload.error.message }) as any)
          reject(e)
        })
        .finally(() =>
          dispatch(Actions.setViewState({ loaderVisible: false }))
        )
    })
  }
}
