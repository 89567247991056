import * as React from "react";
import style from "../../style/Recruitment.module.scss";
import { IEntry } from "../../types/state"
import { EMPTY_APOLOGY } from "../../lib/Definition"

export interface IApologyInfoDispatchProps {}

export interface IApologyInfoStateProps {
  entry: IEntry;
}

export interface IApologyInfoProps extends IApologyInfoDispatchProps, IApologyInfoStateProps {}

export const ApologyInfo = ({
  entry
}: IApologyInfoProps) => {
  if (!entry.apology) return null

  return (
    <>
      <h3 className="contentsSubTitle">応募辞退</h3>
      <div className={style.scountInfoReply + " mb40mb32"}>
        <div className={style.scoutInfo}>
          {entry.apology !== EMPTY_APOLOGY ? (
            <>
              <div className={style.scoutInfoText}>
                <b>応募辞退の連絡を送信しました。</b>
              </div>
              <div className={style.scoutInfoReply}>
                <div className={style.scoutInfoTitle}>
                  応募人材へのメッセージ
                </div>
                <div className={style.scoutInfoMessage + " mb0"}>
                  {entry.apology.split("\n").map((m, idx) => (
                    <div key={idx}>{m}</div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={style.scoutInfoText + " mb0"}>
                <b>応募辞退の連絡を送信しました。</b>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
