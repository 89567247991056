import { connect } from "react-redux";
import { RecruitmentInfo, IRecruitmentInfoProps } from "../components/RecruitmentInfo";
import { IStoreState } from "../types/state";
import { parseQueryParams } from "../lib/Util";

function mapStateToProps(state: IStoreState): IRecruitmentInfoProps {
  const contractList: string[][] = [];
  const problemID = parseQueryParams()["problem_id"];
  if (state.company.contract_condition) {
    const list = state.company.contract_condition.split("\n");
    list.forEach(c => {
      if (c.match(/^[^\s]/)) {
        contractList.push([c]);
      } else if (c) {
        contractList[contractList.length - 1].push(c);
      }
    });
  }
  return {
    contractList,
    problemID: problemID ? Number(problemID) : 0,
    company: state.company,
    recruitment: {
      ...state.recruitment,
      problems: state.recruitment.problems
    }
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentInfo);
