import * as React from "react"
import { IPartner } from "../types/state"
import style from "../style/Recruitment.module.scss"

const PartnerProfileAndInterviewInfo = (props: IPartner & { noNameMessage: string; applyId: number }) => {
  return (
    <>
      <h3 className="contentsSubTitle">面談情報</h3>
      <div className={style.listArea}>
        <div className={style.listRow}>
          <div className={style.listHead}>名前</div>
          <div className={style.listData}>
            {props.first_name ? (
              `${props.last_name} ${props.first_name}（${props.last_kana} ${props.first_kana}）`
            ) : (
              <span style={{ color: "#888" }}>{props.noNameMessage}</span>
            )}
          </div>
        </div>
        <div className={style.listRow}>
          <div className={style.listHead}>応募ID</div>
          <div className={style.listData}>{props.applyId}</div>
        </div>
      </div>
    </>
  )
}

export default PartnerProfileAndInterviewInfo
