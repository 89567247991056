import * as React from "react"
import { Link } from "react-router-dom"
import { IEntry, IQuestionnaireState } from "../types/state"
import PartnerProfileAndInterviewInfo from "./PartnerProfileAndInterviewInfo"
import style from "../style/Recruitment.module.scss"
import { homePath, psychologicalSafetyPath, secondInterviewTodosPath } from "../routes/paths"
import { ENTRY_STATUS, Phases } from "../lib/Definition"
import { Questionnaire } from "./Questionnaire"
import storedUseState from "../lib/storedUseState"
import { PartnerManagementHeader } from "./headers/PartnerManagementHeader"
import { useEntryStatus } from "../hooks/useEntryStatus"
import { guidanceMeetingPath } from "../routes/paths"

export interface IEntryAcceptFinishDispatchProps {
  changePath: (path: string) => void
  submitQuestionnaire: (questionnaire: IQuestionnaireState) => void
}
export interface IEntryAcceptFinishStateProps {
  entry: IEntry
  firstPsychologicalSafetyStatus: "first_wait" | "first_done"
}

export interface IEntryAcceptFinishProps extends IEntryAcceptFinishDispatchProps, IEntryAcceptFinishStateProps {}

const EntryAcceptFinish = (props: IEntryAcceptFinishProps) => {
  const { entry } = props
  const partner = entry.partner

  const [state, setState] = storedUseState("EntryAcceptFinishState", {
    questionnaireSubmitted: false
  })

  const { isNotGuidanceMeetingDone } = useEntryStatus(entry)

  if (!partner) return null

  const onSubmitForm = (questionnaire: IQuestionnaireState) => {
    props.submitQuestionnaire(questionnaire)
    setState({ ...state, questionnaireSubmitted: true })
  }

  const getQuestionnaireSubmittedMessage = () => {
    return (
      <>
        <h3 className="contentsSubTitle contentsSubTitlePlane">送信完了いたしました。</h3>
      </>
    )
  }

  const getFirstInterviewMessage = () => {
    return (
      <div className={style.entryFinishMessageWrapper}>
        <h1 className={style.entryFinishMessage}>一次面談の希望を送信しました。</h1>

        {props.firstPsychologicalSafetyStatus === "first_wait" && (
          <>
            <p className={style.entryFinishMessageDetail}>
              次に面談の準備を行います。
              <br />
              業務内容やスキルの確認だけでなく、お互いの人柄や価値観も知り合って頂くことを目的とした
              <br className="SPHide" />
              「心理的安全性プログラム」をご用意しております。
            </p>
            <div className="formBtnArea formBtnAreaSingle">
              <button className="btn btnSave" onClick={() => props.changePath(psychologicalSafetyPath)}>
                心理的安全性プログラムを入力する
              </button>
            </div>
          </>
        )}
      </div>
    )
  }

  const getSecondInterviewMessage = () => {
    return (
      <>
        {!state.questionnaireSubmitted && (
          <h3 className="contentsSubTitle contentsSubTitlePlane">
            {partner.last_name} {partner.first_name} 様との二次面談のご希望を受け付けました。
          </h3>
        )}
        {state.questionnaireSubmitted && getQuestionnaireSubmittedMessage()}

        <p className="mb32mb16">
          {state.questionnaireSubmitted && (
            <>
              アンケートへのご協力
              <br className="PCHide" />
              ありがとうございました。 <br />
              <br />
            </>
          )}
          二次面談までに事前にご準備いただくことを
          <br className="SPHide" />
          <Link to={secondInterviewTodosPath + "/" + entry.id}>対応することリスト</Link>
          にまとめましたので、ご確認のうえ実施ください。
        </p>
        <button
          className={"btn btnSave" + (state.questionnaireSubmitted ? " mb0" : " mb60mb30")}
          onClick={() => props.changePath(secondInterviewTodosPath + "/" + entry.id)}
        >
          対応することリスト
        </button>
      </>
    )
  }

  const getContractMessage = () => {
    return (
      <>
        {!state.questionnaireSubmitted && (
          <h3 className="contentsSubTitle contentsSubTitlePlane">
            {partner.last_name} {partner.first_name}
            様とのご契約の合意がとれました。
          </h3>
        )}
        {state.questionnaireSubmitted && getQuestionnaireSubmittedMessage()}

        <p className={state.questionnaireSubmitted ? "mb10mb0" : "mb64mb32"}>
          {state.questionnaireSubmitted && (
            <>
              アンケートへのご協力
              <br className="PCHide" />
              ありがとうございました。 <br />
              <br />
              応募者との契約につきましては
            </>
          )}
          電子契約締結サービス
          <br className="SPHide" />
          「クラウドサイン」よりメールにて5営業日以内にご連絡いたします。
          <br className="SPHide" />
          契約希望条件について調整点がある場合は
          <br className="SPHide" />
          メッセージ機能でパートナー人材と連絡をとり、調整を行ってください。
        </p>
        {isNotGuidanceMeetingDone && (
          <div>
            <h3 className="contentsSubTitle contentsSubTitlePlane">業務開始前に、必ずガイダンス会議を行ってください</h3>
            <p className="mb64mb32">
              契約締結にあたり、JOINS担当者が 契約に関する重要事項や
              <br className="SPHide" />
              人材と仕事を開始する上での留意点 などをご説明させていただく会議です。
              <br />
              ガイダンス会議のご予約がまだの方は、
              <Link to={guidanceMeetingPath} className="link" target="_blank" rel="noopener noreferrer">
                こちらより
              </Link>
              、ご都合のよろしい日時をご予約ください。
            </p>
          </div>
        )}
      </>
    )
  }

  const getMessage = (phase: Phases) => {
    switch (phase) {
      case Phases.FIRST_INTERVIEW:
        return getFirstInterviewMessage()
      case Phases.SECOND_INTERVIEW:
        return getSecondInterviewMessage()
      case Phases.CONTRACT:
        return getContractMessage()
    }
  }

  const getQuestionnaire = (phase: Phases) => {
    if (state.questionnaireSubmitted) {
      return null
    }
    return <Questionnaire phase={phase} entry={entry} onSubmitForm={onSubmitForm} />
  }

  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 className="contentsTitle contentsTitlePartner">応募ID：{props.entry.id}</h2>

      {props.entry.entry_status === ENTRY_STATUS.first_interview_accepted && <>{getMessage(Phases.FIRST_INTERVIEW)}</>}
      {props.entry.entry_status === ENTRY_STATUS.second_interview_accepted && (
        <>
          <div className={style.statusCommentArea}>
            {getMessage(Phases.SECOND_INTERVIEW)}
            {getQuestionnaire(Phases.SECOND_INTERVIEW)}
          </div>
        </>
      )}
      {props.entry.contract_reason && (
        <>
          <div className={style.statusCommentArea}>
            {getMessage(Phases.CONTRACT)}
            {getQuestionnaire(Phases.CONTRACT)}
          </div>
        </>
      )}
      <PartnerProfileAndInterviewInfo
        {...partner}
        noNameMessage="貴社がWeb面談を希望した場合に開示されます。"
        applyId={props.entry.id}
      />
      <div className="formBtnArea formBtnAreaSingle">
        <button className="btn btnSave" onClick={() => props.changePath(homePath)}>
          一覧に戻る
        </button>
      </div>
    </div>
  )
}

export default EntryAcceptFinish
