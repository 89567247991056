import * as React from "react";
import { ICompany } from "../../types/state";
import style from "../../style/GuidanceMeeting.module.scss";
import { GuidanceOtherInfo } from "./GuidanceOtherInfo";
import { GuidanceMeetingLink } from "../buttons/GuidanceMeetingLink"

export interface IGuidanceMeetingInfoProps {
  company: ICompany;
}

export const GuidanceMeetingInfo = (props: IGuidanceMeetingInfoProps) => {
  return (
    <main className="contentsWrapper-transparent">
      <h2 className={style.guidanceTopTitle}>契約の前に、<br className="PCHide" />「ガイダンス会議」にご参加ください。</h2>
      <h2 className={style.guidanceSubTitle}>契約締結に際して重要事項等の説明と、<br />トラブル回避のためのポイントなどをJOINSの担当者がご案内いたします。</h2>
      <p className="PCCenterSPLeft">スムーズな立ち上がりは成果への近道です。<br />思いがけない勘違いなどからトラブルになるケースもありますので、<br className="SPHide" />これまでに実際にあったトラブル事例なども紹介しながら回避策のご案内をいたします。<br />
        好事例のご紹介や、その他契約締結や業務開始にあたっての質疑にもお答えします。</p>
      <p className="PCCenterSPLeft mb40mb32">下記のボタンよりご都合の良い日時をご予約お願いします
      </p>
      {props.company.google_calendar_link ? (
        <div>
          <div className="formBtnArea formBtnAreaSingle">
            <a href={props.company.google_calendar_link} className="btn btnSave" target="_blank" rel="noopener noreferrer">
              ガイダンス会議予約フォームはこちら
            </a>
          </div>
          <p className="PCCenterSPLeft mt40mt20">
            また、上記予約フォームの営業担当者スケジュールでご希望の日程がない場合、<br className="SPHide" />
            他担当者とガイダンス会議を実施していただくことも可能です。<br />
            <GuidanceMeetingLink>
              こちらより幅広い日程をご予約いただけます。
            </GuidanceMeetingLink>
          </p>
          <GuidanceOtherInfo />
        </div>
      ) : (
        <div>
          <div className="formBtnArea formBtnAreaSingle">
            <GuidanceMeetingLink button>
              ガイダンス会議予約フォームはこちら
            </GuidanceMeetingLink>
          </div>
          <GuidanceOtherInfo />
        </div>
      )}
    </main>
  );
}
