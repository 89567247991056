import * as React from "react";
import { connect } from "react-redux";
import { GuidanceMeetingInfo, IGuidanceMeetingInfoProps } from "../components/GuidanceMeeting/GuidanceMeetingInfo";
import { Dispatch, Action } from "redux";
import { IStoreState } from "../types/state";

class GuidanceMeeting extends React.Component<IGuidanceMeetingInfoProps> {
  render() {
    return <GuidanceMeetingInfo {...this.props} />;
  }
}

function mapStateToProps(state: IStoreState) {
  return {
    company: state.company
  };
}

function mapDispatchToProps(_dispatch: Dispatch<Action<any>>) {}

export default connect(mapStateToProps, mapDispatchToProps)(GuidanceMeeting);
