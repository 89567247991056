import * as React from "react";
import { Link } from "react-router-dom";
import { dateStr, getProblemTitle } from "../lib/Util"
import { IProblem, IStoreState } from "../types/state"
import style from "../style/Table.module.scss";
import { companyRecruitmentPath } from "../routes/paths";
import { StatusBadge } from "./StatusBadge";
import { PROBLEM_STATUS, PROBLEM_STATUS_EN } from "../lib/Definition"
import { useSelector } from "react-redux"

export const EntrySummaryTableTitle = (props: {
  index: number;
  problem: IProblem;
  cls?: string;
  bordered?: boolean;
}) => {
  const { index, problem } = props;
  const problemCategoryDetails = useSelector((state: IStoreState) => state.problemCategoryDetails)
  const title = getProblemTitle(problemCategoryDetails, problem);
  const status = PROBLEM_STATUS[problem.status];
  const expiredDate = dateStr(problem.expired_date)
  const className = props.cls || style.problemTableTitle
  const bordered = props.bordered || false

  return (
    <h3 className={className}>
      <StatusBadge value={status} active={problem.status === PROBLEM_STATUS_EN.active} bordered={bordered} />
      <div className={style.problemTitleText}>
        <Link to={`${companyRecruitmentPath}?problem_id=${problem.id}#${index.toString()}`}>
          {title}
        </Link>
        {expiredDate && (
          <span className={style.expiredDate}>（応募期限：{expiredDate}）</span>
        )}
      </div>
    </h3>
  );
};
