import * as React from "react"
import HintTooltip from "../forms/common/HintTooltip/HintTooltip"
import styles from "../../style/ProblemContentsForm.module.scss"
import { IProblemFormState } from "./index"
import { ErrorMessage } from "../common/ErrorMessage"
import { PublicStatusLabel } from "./PublicStatusLabel"

export const RequestForm = (props: {
  problem: IProblemFormState
  isSubmitted: boolean
  validates: { [key: string]: (value: any) => string | null }
  updateValue: (args: { [key: string]: any }) => void
}) => {
  return (
    <div id="request">
      <div className={styles.labelWrap}>
        <label className="pure-u-1 problemLabel" onClick={(e) => e.preventDefault()}>
          ① 契約から3ヶ月経過時点で、パートナー人材に実現して欲しい状態
          <HintTooltip
            title={
              <span>
                記入ポイント
                <ul>
                  <li>●●が完了している状態などと具体的に記入すると伝わりやすくなります。</li>
                  <li>具体性が高いほど、人材とのミスマッチが減少します。</li>
                </ul>
              </span>
            }
          />
        </label>
        <PublicStatusLabel publishType={props.problem.publish_type} problemContentsType="request" />
      </div>
      <textarea
        className="textarea"
        value={props.problem.request}
        onChange={(e: React.ChangeEvent<any>) => {
          props.updateValue({
            ...props.problem,
            request: e.target.value
          })
        }}
        placeholder={
          "記入例\n" +
          "（a）従業員の勤怠管理のためのデジタルツールの調査・比較・決定・導入まで行われ、従業員向けのマニュアルが完成し、社内向け説明、利用開始が完了している状態\n" +
          "\n" +
          "（b）既存ECサイト（ヤフーショッピング）の売上増のための現状分析・優先課題を特定した上で、社内担当者を巻き込んだチームで、PDCAサイクルが回り始め、具体的な改善の作業が1-2件ほど完了した状態"
        }
      />
      {props.isSubmitted && props.validates["request"] && (
        <ErrorMessage cls="mb20">{props.validates["request"](props.problem)}</ErrorMessage>
      )}
    </div>
  )
}
