import { IPartner, IStoreState } from "../types/state"
import { useMemo } from "react"
import { PROBLEM_STATUS_EN, STAFF_ROLES_EN } from "../lib/Definition"
import { useSelector } from "react-redux"

export const usePartnerProblems = (partner?: IPartner) => {
  const recruitment = useSelector((state: IStoreState) => state.recruitment)
  const company = useSelector((state: IStoreState) => state.company)
  const staffs = useSelector((state: IStoreState) => state.staffs)

  const staff = useMemo(() => staffs?.find(s => s.id === company.staff_id), [staffs, company])
  const isIndividualStaff = useMemo(() => staff?.role === STAFF_ROLES_EN.individual, [staff])

  const activeProblems = useMemo(() => (
    recruitment.problems.filter(p => {
      if (isIndividualStaff && !staff?.problem_ids?.includes(p.id!)) return false
      return p.status === PROBLEM_STATUS_EN.active
    })
  ), [recruitment.problems, isIndividualStaff, staff])

  const hasActiveProblem = useMemo(() => !!activeProblems.length, [activeProblems])

  const partnerEnteredProblemIds = useMemo(() => {
    if (!partner?.entries) return []
    return partner.entries.map(e => e.problem?.id).filter(v => v)
  }, [partner])

  const partnerScoutedProblemIds = useMemo(() => {
    if (!partner?.scouts) return []
    return partner.scouts.map(s => s.problem?.id).filter(v => v)
  }, [partner])

  const scoutableProblems = useMemo(() => (
    activeProblems.filter(p => (
      p.id && !partnerEnteredProblemIds.includes(p.id)) && !partnerScoutedProblemIds.includes(p.id)
    )
  ), [activeProblems, partnerEnteredProblemIds, partnerScoutedProblemIds])

  const isEnteredForAllProblems = useMemo(() => {
    return hasActiveProblem && !activeProblems.find(p => p.id && !partnerEnteredProblemIds.includes(p.id))
  }, [hasActiveProblem, activeProblems, partnerEnteredProblemIds])

  return {
    activeProblems,
    hasActiveProblem,
    partnerEnteredProblemIds,
    partnerScoutedProblemIds,
    scoutableProblems,
    isEnteredForAllProblems
  }
}
