import * as React from "react"
import { IEntry, IQuestionnaireState } from "../types/state"
import { PartnerProfileInfo } from "./PartnerProfileInfo"
import style from "../style/Recruitment.module.scss"
import { homePath } from "../routes/paths"
import { Phases } from "../lib/Definition"
import { Questionnaire } from "./Questionnaire"
import storedUseState from "../lib/storedUseState"
import { PartnerManagementHeader } from "./headers/PartnerManagementHeader"

export interface IEntryRejectFinishDispatchProps {
  changePath: (path: string) => void
  submitQuestionnaire: (questionnaire: IQuestionnaireState) => void
}
export interface IEntryRejectFinishStateProps {
  entry: IEntry
}

export interface IEntryRejectFinishProps extends IEntryRejectFinishDispatchProps, IEntryRejectFinishStateProps {}

const EntryRejectFinish = (props: IEntryRejectFinishProps) => {
  const { entry } = props
  const partner = entry.partner

  if (!partner) {
    return null
  }

  const [state, setState] = storedUseState("EntryRejectFinishState", {
    questionnaireSubmitted: false
  })

  const onSubmitForm = (questionnaire: IQuestionnaireState) => {
    props.submitQuestionnaire(questionnaire)
    setState({ ...state, questionnaireSubmitted: true })
  }

  const getQuestionnaireSubmittedMessage = () => {
    return (
      <>
        <h3 className="contentsSubTitle contentsSubTitlePlane">送信完了いたしました。</h3>
        <p className="mb0">
          アンケートへのご協力
          <br className="PCHide" />
          ありがとうございました。 <br />
        </p>
      </>
    )
  }

  const getFirstInterviewMessage = () => {
    return (
      <div className={style.entryFinishMessageWrapper}>
        <h1 className={style.entryFinishMessage}>
          今回の応募につきまして、
          <br />
          一次面談の希望をしないことを受領いたしました。
          <br />
          JOINSより応募者に対し、その旨ご連絡いたします。
        </h1>
      </div>
    )
  }

  const getSecondInterviewMessage = () => {
    return (
      <div className={style.listArea + " " + style.listAreaPt0 + " alCent" + (state.questionnaireSubmitted && " mb0")}>
        {!state.questionnaireSubmitted && (
          <h3 className="contentsSubTitle contentsSubTitlePlane mb0">
            今回の応募につきまして、
            <br />
            二次面談の希望をしないことを受領いたしました。
            <br />
            JOINSより {partner.last_name} {partner.first_name} 様に対し、その旨ご連絡いたします。
          </h3>
        )}
        {state.questionnaireSubmitted && getQuestionnaireSubmittedMessage()}
      </div>
    )
  }

  const getContractMessage = () => {
    return (
      <div className={style.listArea + " " + style.listAreaPt0 + " alCent" + (state.questionnaireSubmitted && " mb0")}>
        {!state.questionnaireSubmitted && (
          <h3 className="contentsSubTitle contentsSubTitlePlane mb0">
            今回の応募につきまして、
            <br />
            ご契約を希望しないことを受領致しました。
            <br />
            JOINSより {partner.last_name} {partner.first_name} 様に対し、その旨ご連絡いたします。
          </h3>
        )}
        {state.questionnaireSubmitted && getQuestionnaireSubmittedMessage()}
      </div>
    )
  }

  const getMessage = (phase: Phases) => {
    switch (phase) {
      case Phases.FIRST_INTERVIEW:
        return getFirstInterviewMessage()
      case Phases.SECOND_INTERVIEW:
        return getSecondInterviewMessage()
      case Phases.CONTRACT:
        return getContractMessage()
    }
  }

  const getQuestionnaire = (phase: Phases) => {
    if (state.questionnaireSubmitted) {
      return null
    }
    return <Questionnaire phase={phase} entry={entry} onSubmitForm={onSubmitForm} />
  }

  return (
    <div className="contentsWrapper-transparent">
      <PartnerManagementHeader />
      <h2 className="contentsTitle contentsTitlePartner">応募ID：{props.entry.id}</h2>

      {!props.entry.contract_reason && !props.entry.second_interview_entry.succeed_reason && (
        <>{getMessage(Phases.FIRST_INTERVIEW)}</>
      )}
      {!props.entry.contract_reason && props.entry.second_interview_entry.succeed_reason && (
        <>
          <div className={style.statusCommentArea}>
            {getMessage(Phases.SECOND_INTERVIEW)}
            {getQuestionnaire(Phases.SECOND_INTERVIEW)}
          </div>
        </>
      )}

      {props.entry.contract_reason && (
        <>
          <div className={style.statusCommentArea}>
            {getMessage(Phases.CONTRACT)}
            {getQuestionnaire(Phases.CONTRACT)}
          </div>
        </>
      )}

      <PartnerProfileInfo
        {...partner}
        noNameMessage="貴社がWeb面談を希望した場合に開示されます。"
        isCareerSheetDisclosed={true}
        isPRDisclosed={true}
      />
      <div className="formBtnArea formBtnAreaSingle">
        <button className="btn btnSave" onClick={() => props.changePath(homePath)}>
          一覧に戻る
        </button>
      </div>
    </div>
  )
}

export default EntryRejectFinish
