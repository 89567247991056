import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Actions, IUpdateRegistrantParams } from "../actions";
import { EditRegistrantForm, IEditRegistrantDispatchProps, IEditRegistrantStateProps } from "../components/EditRegistrantForm";
import { updateRegistrant } from "../thunks";
import { IStoreState, IAjaxError } from "../types/state";
import { showAlert } from "../thunks";

function mapStateToProps(state: IStoreState): IEditRegistrantStateProps {
  const registrant = {
    ...state.registrant,
    showPassword: false,
    password: "",
    isSubmitted: false,
    ...state.viewState
  };
  return {
    registrant: registrant,
    enableCancel: !!state.registrant.id
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): IEditRegistrantDispatchProps {
  return {
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any;
    },
    updateValue: (state: { [key: string]: any }) => {
      return dispatch(Actions.setViewState({ ...state, isEdit: true }) as any);
    },
    updateRegistrant: (isUpdate: boolean, params: IUpdateRegistrantParams) => {
      dispatch(updateRegistrant(isUpdate, params) as any).catch(
        (e: IAjaxError) => {
          dispatch(Actions.setViewState({
            error: e.payload.error.message,
            errorField: e.payload.error.field,
            scrollID: e.payload.error.field
          }) as any);
        }
      );
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRegistrantForm);
