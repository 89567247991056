import * as React from "react";
import { ErrorMessage } from "./common/ErrorMessage"

export const RadioField = (props: {
  values: { [key: string]: any };
  name: string;
  label?: any;
  cls?: string;
  required?: boolean;
  note?: string;
  master: { [key: string]: string };
  validates?: { [key: string]: (value: any) => string | null };
  updateValue: (args: string) => void;
  formrownoborder?: boolean;
  reverseOrder?: boolean;
  children?: React.ReactNode;
}) => {
  let keys = Object.keys(props.master);
  if (props.reverseOrder) {
    keys = keys.reverse()
  }

  return  (
    <div className={"formRow" + (props.formrownoborder ? " formRowNoBorder" : "")}>
      {props.label && (
        <label className="formHead">
          {props.label}
          {props.required === true ? (
            <span className="label label_req">必須</span>
          ) : props.required === false ? (
            <span className="label label_opt">任意</span>
          ) : null}
        </label>
      )}
      <div className="formData">
        {props.note && <p className="formLead">{props.note}</p>}
        {keys.map((key) => (
          <label className={props.cls ? props.cls : "selectBtn"} key={key}>
            <input
              type="radio"
              value={key}
              onChange={() => props.updateValue(key)}
              checked={props.values[props.name] === key}
            />
            <span>{props.master[key]}</span>
          </label>
        ))}
        {props.values.isSubmitted &&
          props.validates &&
          props.validates[props.name] && (
            <ErrorMessage cls={props.children ? "mb24" : "mb08"}>
              {props.validates[props.name](props.values)}
            </ErrorMessage>
          )}
        {props.children}
      </div>
    </div>
  );
}
