import * as React from "react"
import NameField from "./NameField"
import KanaNameField from "./KanaNameField"
import * as Definition from "../lib/Definition"
import { PasswordField } from "./PasswordField"
import { NormalTextField } from "./NormalTextField"
import { RadioField } from "./RadioField"
import SearchSelectInput from "./SearchSelectInput"
import { ErrorMessage } from "./common/ErrorMessage"
import { Link } from "react-router-dom"
import { loginPath } from "../routes/paths"

export interface ISignUpDispatchProps {
  updateValue: (args: any) => void
  signUp: (params: {
    name: string
    first_name: string
    last_name: string
    first_kana: string
    last_kana: string
    email: string
    trigger: string
    trigger_other: string
    introducer_id: number
    department: string
    position: string
    tel: string
    password: string
  }) => void
}

export interface ISignUpStateProps {
  last_kana: string
  first_kana: string
  last_name: string
  first_name: string
  email: string
  agreement: boolean
  password: string
  name: string
  department: string
  tel: string
  position: string
  trigger: string
  trigger_other: string
  introducer_id: number
  isSubmitted: boolean
  introducers: { [key: string]: string }
  introducer_permit: boolean
  error: string
  errorField: string
}

const validates: { [key: string]: (value: any) => string | null } = {}
validates["name"] = (val) => {
  if (val.name.match(/\(株\)|\(合\)|\(有\)|（合）|（株）|（有）/)) {
    return "（株）（有）（合）などは利用できません。正式名称をご入力ください。"
  }
  if (val.name.match(/　+/)) {
    return "全角スペースは利用できません。スペースを詰めるか、半角スペースをご利用ください。"
  }
  return val.name ? null : "会社名を入力してください。"
}
validates["first_name"] = (val) => {
  return val.first_name ? null : "名前を入力してください。"
}
validates["last_name"] = (val) => {
  return val.last_name ? null : "苗字を入力してください。"
}
validates["first_kana"] = (val) => {
  return !val.first_kana || !val.first_kana.match(/^[0-9A-Za-zァ-ヶー]*$/) ? "カナで入力してください。" : null
}
validates["last_kana"] = (val) => {
  return !val.last_kana || !val.last_kana.match(/^[0-9A-Za-zァ-ヶー]*$/) ? "カナで入力してください。" : null
}
validates["tel"] = (val) => {
  return !val.tel ? "電話番号を入力してください。" : null
}
validates["department"] = (val) => {
  return !val.department ? "担当者部署名を入力してください。" : null
}
validates["introducer_id"] = (val) => {
  return !val.introducer_id ? "紹介元企業を選択してください" : null
}
validates["trigger"] = (val) => {
  return !val.trigger ? "きっかけを入力してください。" : null
}
validates["trigger_other"] = (val) => {
  return val.trigger === "other" && val.trigger_other === "" ? "きっかけ(その他)を入力してください。" : null
}
validates["position"] = (val) => {
  return !val.position ? "担当者役職を選択してください。" : null
}
validates["email"] = (val) => {
  return val.email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
    ? null
    : "正しいメールアドレスを入力してください。"
}
validates["password"] = (val) => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内の半角英数字（記号含む）で入力してください。"
    : null
}

const SignUpForm = (props: ISignUpDispatchProps & ISignUpStateProps) => {
  return (
    <main className="is-signup">
      <div className="container">
        <h1>企業向けご利用登録フォーム</h1>
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault()
            const error = Object.keys(validates).filter((field) => {
              return validates[field](props)
            })[0]
            if (error) {
              props.updateValue({ scrollID: error, isSubmitted: true })
              return
            }
            props.signUp({
              first_name: props.first_name,
              last_name: props.last_name,
              first_kana: props.first_kana,
              last_kana: props.last_kana,
              email: props.email,
              tel: props.tel,
              position: props.position,
              introducer_id: props.trigger === "introduction" ? props.introducer_id : 0,
              trigger: props.trigger,
              trigger_other: props.trigger === "other" ? props.trigger_other : "",
              department: props.department,
              name: props.name,
              password: props.password
            })
          }}
        >
          <div className="formWrap">
            {props.error && !props.errorField && <ErrorMessage>{props.error}</ErrorMessage>}
            <NormalTextField
              cls="formWidth_m"
              name="name"
              label="会社名"
              required={true}
              placeholder="○×▲株式会社"
              values={props}
              updateValue={props.updateValue}
              error={props.errorField === "name" ? props.error : ""}
              validates={validates}
            />
            <NameField values={props} updateValue={props.updateValue} validates={validates} />
            <KanaNameField values={props} updateValue={props.updateValue} validates={validates} />
            <NormalTextField
              cls="formWidth_m"
              name="department"
              label="担当者部署名"
              note="代表の場合、「代表」とご記入ください。"
              placeholder="○○部○○課"
              required={true}
              values={props}
              validates={validates}
              updateValue={props.updateValue}
            />
            <RadioField
              values={props}
              name="position"
              label="担当者役職"
              note="最も近い役職をお選びください。"
              required={true}
              master={Definition.POSITIONS}
              validates={validates}
              updateValue={(key) => props.updateValue({ position: key })}
            />
            <NormalTextField
              cls="formWidth_m"
              name="email"
              label="メールアドレス"
              placeholder="sample@example.com"
              required={true}
              values={props}
              updateValue={props.updateValue}
              error={props.errorField === "email" ? props.error : ""}
              validates={validates}
            />
            <NormalTextField
              cls="formWidth_m"
              name="tel"
              label="電話番号"
              placeholder="04511112222"
              required={true}
              values={props}
              updateValue={props.updateValue}
              validates={validates}
            />
            <SearchSelectInput
              cls="formWidth_l"
              label="紹介元"
              name="introducer_id"
              placeholder={"紹介元の一部の文字を入力すると候補が表示されます"}
              hint={"※ 紹介元がない場合は「該当なし」を選択してください。"}
              required
              values={props}
              master={props.introducers}
              validates={validates}
              updateValue={(val: string) => {
                const introducerID = parseInt(val, 10)
                props.updateValue({
                  introducer_id: introducerID,
                  trigger: introducerID > 0 ? "introduction" : ""
                })
              }}
            />
            {props.introducer_id < 0 && (
              <RadioField
                values={props}
                name="trigger"
                label={
                  <>
                    当社サービスを
                    <br className="SPHide" />
                    知ったきっかけ
                  </>
                }
                note="最も近い理由を選択してください。"
                required={true}
                master={Definition.TARGET_REASON}
                validates={validates}
                updateValue={(key) => {
                  if (key !== "other") {
                    props.updateValue({ trigger: key, trigger_other: "" })
                  } else {
                    props.updateValue({ trigger: key })
                  }
                }}
              />
            )}
            {props.trigger === "other" && (
              <NormalTextField
                cls="formWidth_m"
                name="trigger_other"
                label={
                  <>
                    サービスを知った
                    <br className="SPHide" />
                    きっかけ（その他）
                  </>
                }
                placeholder=""
                required={true}
                values={props}
                updateValue={props.updateValue}
                validates={validates}
              />
            )}
            <PasswordField
              label="パスワード"
              note={
                <>
                  当サービスにログインするためのパスワードです。
                  <br />
                  6文字以上32文字以内の半角英数字（記号含む）で決定の上、ご入力ください。
                </>
              }
              cls="formWidth_m"
              values={props}
              error={props.errorField === "password" ? props.error : ""}
              updateValue={props.updateValue}
              validates={validates}
            />
            <div className="signUpCheckWrap">
              <div className="signUpCheckArea">
                <div className="signUpCheck">
                  <input
                    className="pure-checkbox"
                    type="checkbox"
                    name="agreement"
                    checked={props.agreement}
                    onChange={() => {
                      props.updateValue({ agreement: !props.agreement })
                    }}
                  />
                  <p>
                    当社の
                    <a
                      href={`${process.env.REACT_APP_ROOT_URL}/policies/company`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      個人情報保護方針および利用規約
                    </a>
                    を確認しこの内容に同意する
                  </p>
                </div>
                {props.introducer_id > 0 && (
                  <div className="signUpCheck">
                    <input
                      className="pure-checkbox"
                      type="checkbox"
                      name="introducer_permit"
                      checked={props.introducer_permit}
                      onChange={() => {
                        props.updateValue({
                          introducer_permit: !props.introducer_permit
                        })
                      }}
                    />
                    <p>
                      {
                        props.introducers[
                          Object.keys(props.introducers).filter((i: string) => i === `${props.introducer_id}`)[0]
                        ]
                      }
                      に貴社の募集内容、貴社へのパートナー人材の応募・面談・契約状況にかかる進捗情報、および応募したパートナー人材の概要を閲覧することに同意する。
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="formRow formBtnArea formBtnAreaSingle">
              <button
                type="submit"
                className="btn btnLogin"
                disabled={!props.agreement || (props.introducer_id > 0 && !props.introducer_permit)}
              >
                利用登録（無料）
              </button>
            </div>
          </div>
          <div className="formBtnArea formBtnAreaSingle">
            <Link to={loginPath} className="link">
              会員の方はこちらからログイン
            </Link>
          </div>
        </form>
      </div>
    </main>
  )
}

export default SignUpForm
