import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Actions, IUpdateCompanyParams } from "../actions";
import { EditCompanyForm, IEditCompanyDispatchProps, IEditCompanyStateProps, } from "../components/EditCompanyForm";
import { updateCompany, showAlert } from "../thunks";
import { IStoreState, IAjaxError, IBusiness } from "../types/state";

function mapStateToProps(state: IStoreState): IEditCompanyStateProps {
  let businesses: IBusiness[];
  if (state.company.businesses.length > 0) {
    businesses = state.company.businesses;
  } else {
    businesses = [
      {
        description: "",
        rate: "",
      },
    ];
  }
  const company = {
    ...state.company,
    businesses,
    isSubmitted: false,
    spinner: false,
    fetch_homepage: false,
    ...state.viewState,
  };
  return {
    company,
    enableCancel: !!state.company.sales,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<IStoreState>
): IEditCompanyDispatchProps {
  return {
    alert: (content: string) => {
      return dispatch(showAlert(content) as any) as any;
    },
    updateValue: (state: { [key: string]: any }) => {
      if (state.zip_code && state.zip_code.length === 7) {
        dispatch(Actions.addressByZipCode(state.zip_code) as any);
      }
      return dispatch(Actions.setViewState({ ...state, isEdit: true }) as any);
    },
    updateCompany: (isUpdate: boolean, params: IUpdateCompanyParams) => {
      dispatch(updateCompany(isUpdate, params) as any).catch(
        (e: IAjaxError) => {
          dispatch(
            Actions.setViewState({
              error: e.payload.error.message,
              errorField: e.payload.error.field,
              scrollID: e.payload.error.field,
            }) as any
          );
        }
      );
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyForm);
