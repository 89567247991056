import * as React from "react";
import { useCallback } from "react";
import { ErrorMessage } from "../common/ErrorMessage";
import { NormalTextField } from "../NormalTextField";
import { FileUploader } from "./CustomUploader/FileUploader";
import { Wrapper } from "./Wrapper";
import { CustomFile } from "./CustomUploader/useImageUploader";
import ProfileRegisterThanks from "../modals/ProfileRegisterThanks";
import { ModalArgs } from "../../thunks";

interface IAnnounceDocumentSubmitTypeProps {
  openModal: (args: ModalArgs) => void;
  setIsEdit: (isEdit: boolean) => void;
  sendCompanyVerification: (corporate_number: number | null, personal_files: CustomFile[] | null, open_business_files: CustomFile[] | null) => void;
}

export const AnnounceDocumentSubmitType = ({ openModal, setIsEdit, sendCompanyVerification }: IAnnounceDocumentSubmitTypeProps) => {
  const [corporateNumber, setCorporateNumber] = React.useState("");
  const [isCorporateNumberError, setIsCorporateNumberError] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const eventQueryParam = params.get('ev');

    if (eventQueryParam === 'signup') {
      openModal({
        component: (props: any) => <ProfileRegisterThanks {...props} />,
      })
    }
  }, [openModal]);

  React.useEffect(() => {
    if (corporateNumber === "") {
      return;
    }
    const valLength = 13;
    if (
      corporateNumber.toString().match(/^[0-9]*$/) &&
      corporateNumber.toString().length === valLength
    ) {
      setIsCorporateNumberError(false);
    } else {
      setIsCorporateNumberError(true);
    }
  }, [corporateNumber]);

  const updateCorporateNumber = useCallback((val: any) => {
    setCorporateNumber(val["corporate_number"]);
    setIsEdit(true);
  }, [setIsEdit]);

  const [personalFiles, setPersonalFiles] = React.useState<CustomFile[]>([]);
  const [openBusinessFiles, setOpenBusinessFiles] = React.useState<CustomFile[]>([]);
  const [isFilesError, setIsFilesError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (personalFiles.length && openBusinessFiles.length) {
      setIsFilesError(false);
    } else {
      setIsFilesError(true);
    }
  }, [personalFiles, openBusinessFiles]);

  const updatePersonalFiles = useCallback((personalFiles: CustomFile[]) => {
    setPersonalFiles(personalFiles);
    // ページアクセス時にすぐ呼び出されるためtrueになるのを防ぐ
    if (personalFiles.length > 0) {
      setIsEdit(true);
    }
  }, [setIsEdit]);

  const updateOpenBusinessFiles = useCallback((openBusinessFiles: CustomFile[]) => {
    setOpenBusinessFiles(openBusinessFiles);
    // ページアクセス時にすぐ呼び出されるためtrueになるのを防ぐ
    if (openBusinessFiles.length > 0) {
      setIsEdit(true);
    }
  }, [setIsEdit]);

  return (
    <Wrapper>
      <>
        <h2>法人に該当する方へ</h2>
        <p>
          人材を募集されるにあたり、企業確認審査の申請をお願いしております。
        </p>
        <p>
          下記より、貴社法人番号をご入力いただくことで企業確認審査を申請いただけます。
        </p>
        <p>
          申請後、審査完了までには最短当日から最大5営業日程度のお時間をいただいております。
          <br />
          審査が完了いたしましたらメールおよびお知らせ欄にてお知らせいたします。
        </p>
        <form
          className="pure-form mb40"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            sendCompanyVerification(parseInt(corporateNumber, 10), null, null);
            setIsEdit(false);
          }}
        >
          <NormalTextField
            cls="formWidth_m"
            name="corporate_number"
            type="number"
            required={false}
            center={true}
            placeholder="法人番号を13桁の数字で入力してください。"
            values={{ corporate_number: corporateNumber}}
            updateValue={updateCorporateNumber}
          />
          {isCorporateNumberError && (
            <ErrorMessage>13桁の数字で入力してください。</ErrorMessage>
          )}
          <div className="formBtnArea formBtnAreaSingle mt30">
            <button
              className="btn btnSave"
              type="submit"
              disabled={isCorporateNumberError === null || isCorporateNumberError}
            >
              上記内容で申請
            </button>
          </div>
        </form>
        <h2>個人事業主に該当する方へ</h2>
        <p>
          以下のフォームより下記書類2種類をお送りください
        </p>
        <form
          className="pure-form"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            sendCompanyVerification(null, personalFiles, openBusinessFiles);
            setIsEdit(false);
          }}
        >
          <h3>代表者さまの本人確認書類</h3>
          {<FileUploader setPdf={updatePersonalFiles}/>}
          <h3>個人事業主の名刺、または開業届など事業を運営していることがわかる書類</h3>
          {<FileUploader setPdf={updateOpenBusinessFiles}/>}
          <div className="formBtnArea formBtnAreaSingle">
            <button
              className="btn btnSave"
              type="submit"
              disabled={isFilesError}
            >
              上記内容で申請
            </button>
          </div>
        </form>
      </>
    </Wrapper>
  );
};
